import React, { useEffect, useState } from "react";

import { ttimg1,ttimg2,ttimg3,ttimg4,ttimg5,ttimg6,ttimg7,ttimg8,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTWeddingdresscleaning() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg15} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg14} alt="" />
                               </div>
                                <p>Your wedding dress will most probably be the most valuable and treasured dress you will ever wear, and so demands expert cleaning.</p>
                                <p>We at T&T Dry Cleaners are specialists in cleaning wedding dresses. Your treasured dress will be inspected and pre-spotted before a delicate specialist cleaning process is carried out. The dress will then be re-inspected and finally steam pressed and hand finished.</p>
                                <p>Our Wedding Dresses service include care of:</p>
                                <ul>
                                    <li>Veil/Headpiece</li>
                                    <li>Gloves</li>
                                    <li>Garter</li>
                                </ul>
                                <p>We look forward to providing you with our superior services.<br/> Bring your wedding dress for a free examination and quote.</p>                                
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg16} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTWeddingdresscleaning;