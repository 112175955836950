import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { ttw46,ttw2,ttw3,ttw4,ttw5,ttw6,ttw7,ttw8,ttw9,ttw10,ttw11,ttw12,ttw13,ttw14,ttw15,ttw16,ttw17,ttw18,ttw19,ttw20,ttw21,ttw22,ttw23,ttw24,ttw25,ttw26,ttw27,ttw28,ttw29,ttw30,ttw31,ttw32,ttw33,ttw34,ttw35,ttw36,ttw37,ttw39,ttw40,ttw41,ttw42 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";

function SectionNine () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <section className="secbg2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="secinfogrid">                            
                            <h2 className="text-center">Our Lovable Customer Feedback!</h2>
                            <p className="text-center secinhdrp">We deeply appreciate your feedback and strive to provide exceptional service that exceeds your expectations.</p>
                            <div className="row">
                                <div className="col-md-12">
                                    <Swiper
                                        spaceBetween={60}
                                        navigation={true}
                                        modules={[Navigation]}
                                        centeredSlides={true}
                                        centerInsufficientSlides={true}
                                        centeredSlidesBounds={true}
                                        breakpoints={{
                                        640: {
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                        },
                                        }}
                                        className="t12testi-slider"
                                    >
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw39} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Linda Carpenter</div>
                                                        <div className="cardinfoarea">London, UK</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>Outstanding service! My shoes were restored to perfection. Highly recommend this expert team.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw40} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Rachel Martinez</div>
                                                        <div className="cardinfoarea">UK</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>Impressed by the meticulous restoration of my shoes. The attention to detail is exceptional, and the results speak for themselves.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw41} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Daniel Clark</div>
                                                        <div className="cardinfoarea">UK</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>The craftsmanship in restoring my shoes was the best. I am delighted with the outcome and will definitely return for future shoe care needs.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        {/* <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw39} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Linda Carpenter</div>
                                                        <div className="cardinfoarea">Leland, USA</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>Outstanding service! My shoes were restored to perfection. Highly recommend this expert team.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw40} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Rachel Martinez</div>
                                                        <div className="cardinfoarea">UK</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>Impressed by the meticulous restoration of my shoes. The attention to detail is exceptional, and the results speak for themselves.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw41} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Daniel Clark</div>
                                                        <div className="cardinfoarea">UK</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>The craftsmanship in restoring my shoes was the best. I am delighted with the outcome and will definitely return for future shoe care needs.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide> */}
                                    </Swiper>
                                </div>
                                <div className="col-md-12">
                                    <div className="hreviewdflex">
                                        <div className="hreviewleft">
                                            <img src={ttw46} alt="" style={{maxHeight: "100px"}}/>
                                        </div> 
                                        <div className="hreviewright">
                                            <a className="btn btnheloreview">Read More <br/>Google Reviews</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionNine;
