import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { shopimg } from "../../../assets/img";
import { fetchRandomShopList, fetchShopList, } from "../../../redux/Actions/checkoutPageActions";
import { fetchProductAllCategories } from "../../../redux/Actions/productCategoriesAction";
import { NavLink } from 'react-router-dom';
import { fetchShopHomeData } from "../../../redux/Actions/shopHomeAction";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

function NearestShops() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const settings: any = useSelector<any>((state) => state.settings);
  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const shopsList: any = useSelector<any>((state) => state.shopsList?.data);
  const [showAllServices, setShowAllServices] = useState(false);
  // const postalCode = JSON.parse(localStorage.getItem("postalCode") || "");

  useEffect(() => {
    dispatch(fetchShopHomeData());  
  }, []);

  useEffect(()=>{
    
  },[shopHomeData])

  console.log("shopHomeData",shopHomeData)
  const handleShopClick = (currentShopHome: any, currentShopName: any) => {
    localStorage.setItem("shop_id", currentShopHome);
    localStorage.setItem("currentShopHome", currentShopHome);
    localStorage.setItem("currentShopName", currentShopName);
    const shopName = currentShopName;
    const resultOne = shopName.replace(/\s(.)/g, function ($1: string) {
      return $1.toLowerCase();
    });
    const resultTwo = resultOne.replace(/\s/g, "");
    const finalResult = resultTwo.replace(/^(.)/, function ($1: string) {
      return $1.toLowerCase();
    });
    //navigate(`/shop/${finalResult}`);
    window.open(`/shop/${finalResult}`, '_blank');
  };
  const handleShopbook = (currentShopHome: any, currentShopName: any) => {
    localStorage.setItem("shop_id", currentShopHome);
    localStorage.setItem("currentShopHome", currentShopHome);
    localStorage.setItem("currentShopName", currentShopName);
    const finalResult = currentShopName.toLowerCase().replace(/\s+/g, '');
    window.open(`/shop/${finalResult}/productLists`, '_blank');
};
  const filteredShops = shopHomeData?.shops?.filter(
    (shop: any) => shop.id !== shopHomeData?.profile?.id
  );
  console.log("filteredShops",filteredShops)
  return (
    <>      
    {filteredShops.length > 1 &&
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="home-shop-hdr">
              <h2 className="d-inline-block slidershopshdr">Shops Nearby</h2>
              {/* <NavLink className="ms-2" to={'./shoplist'}>View All <i className="fas fa-angle-right ms-2"></i></NavLink> */}
            </div>
          </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="slsligrid">
                <Swiper
                spaceBetween={15}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
                className="home-slidershops"
              >
          {filteredShops?.map((item: any) => {
            return (
              <>
              <SwiperSlide>
                <div className="d-flex" key={item.id}>
                  <div className="shop-grid">
                    <div className="shop-img text-center">
                      {item?.setting?.shop_logo_url ? <img className="p-3" src={item?.setting?.shop_logo_url || shopimg} alt="" /> : <img src={shopimg} alt="" />}
                      {/* <img src={item?.setting?.shop_logo_url || shopimg} alt="" /> */}
                    </div>
                    <div className="shop-details">
                      <h3
                        onClick={() => handleShopClick(item?.id, item?.name)}
                        style={{ cursor: "pointer" }}
                      >
                        {item?.name}
                      </h3>
                      <p className="clr-grey shop-details-adrs">
                        <i className="fas fa-map-marker-alt me-2"></i>
                        {item?.address?.text_address}
                      </p>
                      {item?.rating > 0 && (
                        <div className="strrating-grid">
                          <div>
                            <span className="strrating">
                              {Array.from({ length: 5 }, (_, index) => {
                                const roundedRating = Math.round(item?.rating * 2) / 2;
                                if (roundedRating - index >= 1) {
                                  return <i className="fas fa-star" key={index}></i>;
                                } else if (roundedRating - index === 0.5) {
                                  return <i className="fas fa-star-half-alt" key={index}></i>;
                                } else {
                                  return <i className="far fa-star" key={index}></i>;
                                }
                              })}
                            </span>
                            <span className="strratingcount">
                            <a
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate("/shopreviews", { state: { 
                                        shopId: item.id,
                                        shopName: item.name,
                                        shopLogo: item?.setting?.shop_logo_url || shopimg,
                                        shopAddress: item?.address?.text_address, 
                                      } });
                                    }}
                                  >
                                    {item?.rating_count} Reviews
                                  </a>
                            </span>
                          </div>
                          <div></div>
                        </div>
                      )}
                      <ul className="d-none">
                        {item?.recent_products.map((services: any) => {
                          return (
                            <>
                              <li>
                                <div>
                                  {services?.groupcategory?.subcategory.name}
                                  <span className="clr-grey ms-4">
                                    <i className="far fa-clock me-2"></i>
                                    {services?.groupcategory?.process_time}hrs
                                  </span>
                                </div>
                                <div className="shop-price">
                                  {settings?.WebmasterSettings?.currency}{" "}
                                  {
                                    services?.group_category_default_option_price?.price
                                  }
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                      <div className="shop-serv-link">
                        <a onClick={() => handleShopClick(item?.id, item?.name)} className="btn btn1">View Shop<i className="fas fa-angle-right"></i></a>
                        <a onClick={() => handleShopbook(item?.id, item?.name)} className="btn btn2">Book Now<i className="fas fa-angle-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                </SwiperSlide>
              </>
            );
          })}
        </Swiper>
                </div>
            </div>
        
        </div>
      </div>
    }
    </>
  );
}

export default NearestShops;
