import React,{ useEffect,useState  } from 'react'
import axios from 'axios'
import { Link,useNavigate,useParams } from 'react-router-dom';
import { Forgotpwd } from '../../assets/img'
import { toast, ToastContainer } from "react-toastify";

const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url=process.env.REACT_APP_BASE_URL;




function ResetPassword() {
    const [emailValue, setEmailValue] = useState("")  
    const [updatePwdResult, setUpdatePwdResult] = useState< any | null>({})
    const navigate = useNavigate();
    const params=useParams()
  const [pwdValue, setPwdValue] = useState("")
  const [confirmPwdValue, setConfirmPwdValue] = useState("")
  const [genError, setGenError] = useState("") 
  const handleEmailChange = (e: any) => {    
    setEmailValue(e.target.value);
  }
 
  const handlePwdChange = (e: any) => {    
   
    setPwdValue(e.target.value);
  }

  const handleConfirmPwdChange = (e: any) => {    
    setConfirmPwdValue(e.target.value);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()  
    
    const headers = {'Accept': 'application/json' }
      
        axios.post(`${base_url}/api/forgot_password/update`,{ email:emailValue,new_password:pwdValue,confirm_password:confirmPwdValue,token:params.token },{
        headers: headers

  }).then(e=>{setUpdatePwdResult({result:e.data})
    if(e.data.Response.response_code ==="1" )
    {
       
     // window.location.href=`${client_base_url}/`
     setTimeout(()=>{
      toast(e.data.Response.response_message);
    },2000)
     navigate("/login");
    }
    else
    {
      setGenError(e.data.Response.response_message);
      event.preventDefault()
      return false
    }
  })
   
}

return (


<div className="section-signin">
    <div className="container">
        <div className="row">
            <div className="col-md-5 col-centered">
                <div className="signin" style={{ height: "37rem" }}>
                    <div className="signin-header forgot-pwd">
                        <img src={Forgotpwd} className="img-fluid" alt="" />
                        <h1>Reset Password</h1>
                    </div> 
                    <div className="signin-conent">
                        <form className="form-signin" name="reset" method="POST" action={`${process.env.REACT_APP_BACKEND_URL}/forgot_password/update`} onSubmit={handleSubmit} >
                            <div className="form-group">
                                <label>Your Email <span className="required">*</span></label>
                                <input type="email" name="email" value={emailValue} className="form-control signin-form" required  onChange={handleEmailChange}  />
                                <input type="hidden" name="_token" value="LCUPd7GfPqn4XrtKOCDCA7SPSFoj3YwAHxyoo9lY" />
                            </div>
                            <div className="form-group">
                                <label>New Password <span className="required">*</span></label>
                                <input type="password" name="password" value={pwdValue} className="form-control" required onChange={handlePwdChange } />
                            </div>
                            <div className="form-group">
                                <label>Confirm Password <span className="required">*</span></label>
                                <input type="password" name="password_confirmation" value={confirmPwdValue} className="form-control"  onChange={handleConfirmPwdChange} required />
                            </div>

                            <div className="text-danger mb-8">{genError}</div> 
                            <br/> 
                            <div className="form-group text-center">
                                <input type="hidden" value="56c30c250e18c305d9085eda0b589bc3a499a45c3647cfee609ee715e03efd1d" name="token" />
                                <input type="hidden" value=" " name="redirect_to" />
                                <button type="submit" className="btn btn-primary account-btn hover-btn">Reset Password</button>
                            </div>
                        </form>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
    <ToastContainer />
</div>

)

}

export default ResetPassword;