import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { fetchFaqData } from "../../redux/Actions/policyActions";

import axios from "axios";

function SectionSevenThemeThree() {
  const navigate = useNavigate();
  const [productList, setProductList] = useState<any | null>({});
  const [blogList, setBlogList] = useState<any | null>({});
  const faqData: any = useSelector<any>((state) => state?.faq);
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const dispatch = useDispatch<any>();
  const data: any = useSelector<any>((state) => state.homeJsonList);
  const jsonData = data?.theme_10.home?.section_7;

  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });

    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);
  return (
    <>
      <section className="cate-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-start">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <h2 style={{
                      color: `#${jsonData.h2color}`
                    }}>
                      {jsonData.title}
                      {/* On Demand Laundry,{" "}
                      <span className="primary-text">
                        Dry Cleaning And Ironing
                      </span>{" "}
                      Categories */}
                    </h2>
                  </div>
                  <div className="col-md">
                    <p style={{
                      color: `#${jsonData.paracolor}`
                    }}>{jsonData.paragraph}</p>
                  </div>
                  {/* <div className="col-md-auto">
                    <a
                      style={{
                        background: `#${jsonData.btn_group.btn1.bg_color}`,
                        color: `#${jsonData.btn_group.btn1.color}`,
                      }}
                      href="#book-now"
                      className="btn title-btn hover-btn"
                    >
                      <i className="uil uil-package me-2"></i>{" "}
                      {jsonData.btn_group.btn1.text}
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Swiper
                spaceBetween={5}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 8,
                  },
                }}
                className="home-slider"
              >
                {Object.keys(productList).length > 0 &&
                  productList?.data?.data?.category?.map((item: any) => {
                    return (
                      <SwiperSlide>
                        <div className="card-cate">
                          <img src={item.web_banner_img} alt="" />
                          <div className="card-cate-body">
                            <p className="secondary-text">
                              {item.sub_categories.length} Items
                            </p>
                            <h3 style={{
                      color: `#${jsonData.h3color}`
                    }}>{item.main_category_name}</h3>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("pricing");
                              }}
                              className="btn primary-btn rounded-circle hover-btn"
                            >
                              <i className="uil uil-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionSevenThemeThree;
