import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { shop_data } from "../edit/data";

function SectionSevenThemeThree() {
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  // const jsonData: any = shop_data

  const navigateToPricing = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/pricing`);
    } else {
      navigate("/pricing");
    }
  } else {
    navigate("/pricing");
  }
  };


  return (
    <>
      <section className="sh3-cate-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh3-section-title text-start">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <h2 style={{
                      color: `#${jsonData?.theme_3?.home?.section_7?.h2color}`
                    }}>
                    {jsonData?.theme_3?.home?.section_7?.title}
                    </h2>
                  </div>
                  <div className="col-md">
                  <p style={{
                      color: `#${jsonData?.theme_3?.home?.section_7?.paracolor}`
                    }}>{jsonData?.theme_3?.home?.section_7?.paragraph}</p>
                  </div>
                  {/* <div className="col-md-auto">
                    <a
                      style={{
                        background: `#${jsonData.btn_group.btn1.bg_color}`,
                        color: `#${jsonData.btn_group.btn1.color}`,
                      }}
                      href="#book-now"
                      className="btn title-btn hover-btn"
                    >
                      <i className="uil uil-package me-2"></i>{" "}
                      {jsonData.btn_group.btn1.text}
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Swiper
                spaceBetween={5}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 8,
                  },
                }}
                className="home-slider"
              >
                {jsonData?.theme_3?.home?.section_7?.card_section?.collection.map((item: any) => {
                    return (
                      <SwiperSlide>
                        <div className="sh3-card-cate">
                          <img src={item.icon} alt="" />
                          <div className="sh3-card-cate-body">
                          <h3 style={{
                      color: `#${jsonData?.theme_3?.home?.section_7?.h3color}`
                    }}>{item.card_title}</h3>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigateToPricing();
                              }}
                              className="btn primary-btn rounded-circle hover-btn"
                            >
                              <i className="uil uil-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionSevenThemeThree;
