import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { t3w1,t3w2,t3w3,t3w4,t3w5,t3w6,t3w7,t3w8,t3w9,t3w10,t3w11,t3w12,t3w13,t3w14,t3w15,t3w16,t3w17,t3w18,t3w19,t3w20,t3w21,t3w22,t3w23,t3w24,t3w25,t3w26,t3w27,t3w28,t3w29,t3w30,t3w31,t3w32,t3w33,t3w34,t3w35,t3w36,t3w37,t3w39,t3w40,t3w41,t3w42 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";

function SectionSeven () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <section className="secpy1">
            <div className="container">
                <div className="row"> 
                    <div className="col-md-12">
                        <div className="secinfogrid">
                            <h2 className="text-center secinfogridh2c1">Quality Dry Cleaning</h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row h-100">
                                        <div className="col-md-12">
                                            <div className="secinfocard1 text-end">
                                                <span>Dry Cleaning</span>
                                                <p>We specialize in dry cleaning a wide range of items, from delicate wedding
gowns to everyday garments like suits, shirts, ties, and skirts. Our expertise
extends to cleaning various materials including silk, polyester, cotton, rayon,
leather, fur, and other animal skins. Stubborn stains are not a problem most of
the time.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="secinfocard1 text-end">
                                                <span>Laundry</span>
                                                <p>We are here to take care of your household laundry with professional and fast washing
machines and dryers with high quality cleaning with material suitable to the type of
your laundries. Multiple options thus are available for different type of fabric.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="secinfocard1 text-end">
                                                <span>Turnaround time</span>
                                                <p>Customers can expect a turnaround time of 24-72 hours usually depending upon
the volume and nature of the items. Same day and 2 hours service could also be
obtained which may incur extra charges.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="secinfocard1 text-end">
                                                <span>Cost of Dry Cleaning Service</span>
                                                <p>The average cost a customer can expect is between £3 to £17 for the routine
clothes like Shirt, trousers, jacket, suit etc. However, the garments like Puffer
jackets, evening gowns, Bridal dresses, duvets etc will be at a higher price
depending upon the delicacy, embroidery and extra work on the fabric. Curtains
are charged on per meter basis. Garments requiring stains removal and
preservation may be charged extra.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="seccenterflex">
                                    <div className="secimg">
                                        <img src={t3w14} alt="" />
                                    </div>
                                    <div className="text-center">
                                      {/* <NavLink className="btn btnprime" to="/shoe-cleaning">
                                          Our Services
                                      </NavLink> */}
                                      <div className="secinfocard1">
                                          <span>Repair and alteration Services</span>
                                          <p>Enhance your wardrobe with our expert repair and alteration services. From fixing tears to adjusting hems, we handle all garment types with precision. Enjoy convenience with our laundry and dry cleaning, plus free pick-up and delivery.</p>
                                      </div>
                                  </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row h-100">
                                        <div className="col-md-12">
                                            <div className="secinfocard1">
                                                <span>Bridal &amp; Evening Wear</span>
                                                <p>To safeguard the cherished memories embedded in your wedding gown, proper
                                                cleaning and storage are paramount to prevent fading, damage, and aging.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="secinfocard1">
                                                <span>Pick n Drop</span>
                                                <p>Customers Scheduling a pickup can be arranged from our website, WhatsApp
chat, Mobile app or a phone call. After a thorough inspection the clothes will
undergo the required process and will be delivered to the desired location after
quality control check. Payments could be made through a secure link, app or

over the phone.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="secinfocard1">
                                                <span>Express Services</span>
                                                <p>Same Day services and 2 hours services can also be provided at an extra cost.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="secinfocard1">
                                                <span>Clothes repair services</span>
                                                <p>We at NovaClean provide re-sizing, new zips, trousers lengthen/shorten,
turn ups, waist adjustments, relines, torn vents, shirt sleeves trousers
lengthen/shorten, dress lengthen/shorten, buttons, hand stitched hems,
tapering, curtain shortening and width adjustments and much more. Prices for
clothes alteration are decided on individual basis depending upon the type of
work required</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionSeven;
