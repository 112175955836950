import axios from "axios";
import React, { useEffect, useState } from "react";
import { ElfsightWidget } from "react-elfsight-widget";

function GoogleReviews() {
  const [widgetId, setWidgetId] = useState("");
  const [error, setError] = useState(false);
  const base_url = process.env.REACT_APP_BACKEND_URL;

  // useEffect(() => {
  //   axios
  //     .get(`${base_url}/api/reviews`, {
  //       headers: {
  //         Accept: "application/json",
  //         "Access-Control-Allow-Methods": "GET, POST",
  //       },
  //     })
  //     .then((response) => {
  //       if (response.status !== 401) {
  //         setWidgetId(response.data.data.elfsight_widget_id);
  //       }
  //     })
  //     .catch(() => {});
  // }, []);

  const renderWidgetSection = () => (
    <section className="greview-section" id="noreview">
      {/* <div className="text-center aos-init" data-aos="zoom-in-up">
        <h2>Our Reviews</h2>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <ElfsightWidget
        
        className={`py-md-5 py-3 elfsight-app-${widgetId}`}
        widgetId={widgetId}
      />
          </div>
        </div>
      </div>
      
      {/* <div className="aos-init" data-aos="zoom-in-up">
        <div className="text-center">
          <a href="#" className="btn howitbtn hover-btn">
            Book Now
          </a>
        </div>
      </div> */}
    </section>
  );

  return widgetId !== "" ? renderWidgetSection() : null;
}

export default GoogleReviews;
