import React, { useEffect, useState } from 'react';
// import MyProfileHeader from '../../myProfileHeader';
// import MyFirstFlat from '../../myFirstFlat';
import './../myProfile/myProfile.css';
import './../myProfile/deleteProfilePopup.css';
import DeletePopup from '../../deletePopup';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import DeleteProfilePopup from './deleteProfilePopup';
import { ToastContainer } from 'react-toastify';
import { myprofile2,myorders,myaddress,logout2 } from '../../../assets/img'
import { getNotification, getSettingsData } from "../../../redux/Actions";
import { fetchShopHomeData } from '../../../redux/Actions/shopHomeAction';

function MyProfile() {
    const location = useLocation();
    const dispatch = useDispatch<any>();
    const [profile, setProfile] = useState({})
    const [deleteChange, setDeleteChange] = useState(false)
    const state: any = useSelector(state => state);
    let url = location.pathname;
    let splitURL: any = url.toString().split("/");
    const navigate = useNavigate();

    const rand = Math.random();

    useEffect(() => {
        if (splitURL.length > 3) {
            console.log("shomeapp2")
          dispatch(fetchShopHomeData());
        }
      }, []);

    const navigateToUrl = (url:string) => {
        const shopName: any = localStorage.getItem("currentShopName");
        if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());
    
        let URL = window.location.href.split("/");
        if (URL.length > 4) {
          navigate(`/shop/${finalResult}${url}`);
        } else {
          navigate(url);
        }
    } else {
        navigate(url);
      }
      };

    const token = localStorage.getItem("token");
    useEffect(() => {
        const base_url = process.env.REACT_APP_BACKEND_URL;
        axios({
            method: "get",
            url: `${base_url}/api/my_profile`,
            headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST', 'Authorization': 'Bearer ' + token },
        }).then((response) => {
            if (response.status != 401) {
                setProfile(response.data.data);
                localStorage.setItem("username",response.data.data.name)
            }
        }).catch(function (error) {
            
        })

    }, [state])

    const handleLogout = (e: any) => {
        e.preventDefault();
        localStorage.clear();
        navigate("/login")
        window.location.reload();
    }

    // const profileData: Profile = {profile};

    // const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) =>
    //     obj[key];

    // interface Profile {
    //     id: number;
    //     name: string;
    //     email: string;
    //     mobile: string;
    //     photo: string;
    // }

    const profileData: { [unit: string]: number } = profile;

    return (
        <div>
            <div className="breadcrumpset">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumpview">
                                <h2>My Profile</h2>
                                <ul>
                                    <li>
                                        <a href="/"><i className="fa fa-home" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <span> My Profile</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-myprofile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 custom-myprofile">
                            <h1>My Profile</h1>
                            <span className="float-end"><a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/')}}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Back to home</a></span>
                        </div>
                    </div>
                    {/* {getKeyValue<keyof Profile, Profile>("name")(profile)} */}
                                    {/* <h5>{}</h5> */}
                    <div className="row">
                        <div className="col-md-4">
                            <div className="user-profile" >
                                <div className="user-profile-details">
                                    <span className="db-img">
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/editProfile')}} ><i className="fas fa-pencil-alt" aria-hidden="true"></i></a>
                                    </span>
                                    <img className='user-profile-img' src={`${profileData.web_photo}?bust=${rand}`} alt='' />
                                    <h4><small>Hello,</small> {profileData.name}</h4>
                                </div>
                                <div className="orders-account">
                                    <div className="myorders" onClick={() => navigateToUrl('/myProfile')}>
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myProfile')}}>
                                            <i className="fas fa-layer-group"></i>
                                            <h4>My Profile</h4>
                                        </a>
                                    </div>
                                    <div className="myorders" onClick={() => navigateToUrl('/myOrders')} >
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myOrders')}}>
                                            <i className="fas fa-tag"></i>
                                            <h4>My Orders</h4>
                                        </a>
                                    </div>
                                    <div className="myorders" onClick={() => navigateToUrl('/myAddress')}  >
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/myAddress')}}>
                                            <i className="fas fa-map-marker-alt"></i>
                                            <h4>My Address</h4>
                                        </a>
                                    </div>
                                    {/* <div className="myorders">
                                        <a data-bs-toggle="modal" data-bs-target="#deleteprofile" data-id="1" onClick={() => setDeleteChange(!deleteChange)}><i className="fa fa-trash"></i></a>
                                        <h4>Delete Profile</h4>
                                    </div> */}
                                    <div className="myorders">
                                        <a
                                        style={{ cursor: "pointer" }}
                                        data-bs-target="#deleteprofile" 
                                        data-id="1" 
                                        onClick={() => setDeleteChange(!deleteChange)}>
                                            <i className="fa fa-trash"></i>
                                            <h4>Delete Profile</h4>
                                        </a>
                                    </div>
                                    <div className="myorders">
                                        <a href='#' onClick={(e) => { handleLogout(e) }}>
                                            <i className="fas fa-power-off"></i>
                                            <h4>Logout</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="profile-details-right">
                                <div className="profileinfo-header">
                                    <h5>Personal Information</h5>
                                    <span className="float-end"><a href='#' onClick={(e) => {e.preventDefault(); navigateToUrl('/editProfile')}}><i className="fas fa-pencil-alt" aria-hidden="true"></i></a></span>
                                </div>
                                <div className="personal-info">
                                    <label>Name:</label>
                                    <p>{profileData.name}</p>
                                </div>
                                <div className="personal-info">
                                    <label>Email Address:</label>
                                    <p>{profileData.email}</p>
                                </div>
                                <div className="personal-info">
                                    <label>Phone Number:</label>
                                    <p>{profileData.mobile}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deleteChange && <DeleteProfilePopup close={() => setDeleteChange(false)} />}
            <ToastContainer />
        </div>
    )
}

export default MyProfile;
