import { ColorPicker } from "primereact/colorpicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  setShopHomeCms,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../../redux/Actions";
import ImageCropper from "../../../imageCropper/imageCropper";

function SectionTwelveBannerThemeFour() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [bannerImageChange, setBannerImageChange] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const images: Array<string> = [
    "/assets/home/trustimg4.jpg",
    "/assets/home/trustimg4.jpg",
    "/assets/home/trustimg4.jpg",
    "/assets/home/trustimg4.jpg",
    "/assets/home/trustimg4.jpg",
    "/assets/home/trustimg4.jpg",
    "/assets/home/trustimg4.jpg",
  ];
 

  const saveJsonDataToFile = () => {
    dispatch(setShopHomeCms(editData));
    dispatch(saveThemeJsonData(editData));
  };


//   const updateImageSectionBanner = async (e: any) => {
//     const image = e.target.files[0];
//     setCropImageHeight("150");
//     setCropImageWidth("150");
//     setSelectedCropImage(image);
//     setShowCropModal(true);
//     setBannerImageChange(true);
//   };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));

    // if (imageLink != false && bannerImageChange == true) {
    //   handleBannerImageChange(imageLink);
    // }
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);



  const handleChange = (e: any) => {
    const { value, name, type, checked } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
        home: {
          ...prevJsonData.theme_4.home,
          section_12: {
            ...prevJsonData.theme_4.home.section_12,
            [name]: value,
          },
        },
      },
    }));
  };


//   const handleBannerImageChange = (image: string) => {
//     //Update the banner value in the JSON data
//     setEditData((prevJsonData: any) => ({
//       ...prevJsonData,
//       theme_4: {
//         ...prevJsonData.theme_4,
//         home: {
//           ...prevJsonData.theme_4.home,
//           section_12: {
//             ...prevJsonData.theme_4.home.section_12,
//             card_image: image,
//           },
//         },
//       },
//     }));
//   };

  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section 12 Theme 4 Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_4?.home?.section_12?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
    </div>
      </div>
                  <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      value={editData?.theme_4?.home?.section_12?.description}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                  
                  <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_4?.home?.section_12?.h2color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_12?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_4?.home?.section_12?.h3color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_12?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_4?.home?.section_12?.paracolor
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_12?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
                </div>
              </div>
            </div>
          
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default SectionTwelveBannerThemeFour;
