import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import {
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
} from "../../../assets/img";
import axios from "axios";
import "swiper/css";
import "swiper/css/navigation";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../../edit/data";
import { fetchHomeJsonList } from "../../../redux/Actions";

function SectionFifteenThemeThree() {
  const [blogList, setBlogList] = useState<any | null>({});
  const Url = window.location.host;
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const [productList, setProductList] = useState<any | null>({});
  const dispatch = useDispatch<any>();
  const blogUrl = `https://blog.${Url}`;

  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);

  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);
  useEffect(() => {}, [homedata]);

  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });

    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  return (
    <>
      <section className="latestnews-section" id="news-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <span className="yellow-btn">
                  {homedata?.theme_10?.home?.section_15?.sub_heading_top}
                </span>
                <h2 style={{
                      color: `#${homedata?.theme_10?.home?.section_15?.h2color}`
                    }}>{homedata?.theme_10?.home?.section_15?.title}</h2>
                <p style={{
                      color: `#${homedata?.theme_10?.home?.section_15?.paracolor}`
                    }}>{homedata?.theme_10?.home?.section_15?.paragraph}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Swiper
                spaceBetween={15}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                className="home-slider"
              >
                {blogList?.length > 0 && (
                  <SwiperSlide>
                    <div className="latestnews-card">
                      <div className="latestnews-img">
                        <img src={testimonialimg1} alt="" />
                        <div className="latestnews-info">
                          <div className="latestnews-top">
                            <span className="latestnews-tag">
                              {blogList[0].category_name}
                            </span>
                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                          </div>
                          <div className="latestnews-bottom">
                            <div className="profile-user">
                              <a className="profile-user-img">
                                <img
                                  src={blogList[0].grid_sm_img}
                                  className="rounded-circle"
                                />
                              </a>
                              <div className="profile-user-info latestnews-user-info">
                                {/* <h6><a target='_blank' href="">Orlando Waters</a></h6> */}
                                <span className="latestnews-date" style={{
                      color: `#${homedata?.theme_10?.home?.section_15?.h3color}`
                    }}>
                                  <i className="uil uil-calendar-alt me-2"></i>
                                  {blogList[0].formatted_date}{" "}
                                  {blogList[0].formatted_month}{" "}
                                  {blogList[0].formatted_year}
                                </span>
                              </div>
                            </div>
                            <h3>
                              <a
                                target="_blank"
                                style={{
                                  color: `#${homedata?.theme_10?.home?.section_15?.h3color}`
                                }}
                                href={`${blogUrl}/blogg/${blogList[0].id}/${blogList[0].url_slug}`}
                              >
                                {blogList[0].page_title.substring(0, 80)}
                                {blogList[0].page_title.length > 80
                                  ? "..."
                                  : ""}
                              </a>
                            </h3>
                            {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
                {blogList?.length > 1 && (
                  <SwiperSlide>
                    <div className="latestnews-card">
                      <div className="latestnews-img">
                        <img src={testimonialimg2} alt="" />
                        <div className="latestnews-info">
                          <div className="latestnews-top">
                            <span className="latestnews-tag">
                              {blogList[1].category_name}
                            </span>
                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                          </div>
                          <div className="latestnews-bottom">
                            <div className="profile-user">
                              <a className="profile-user-img">
                                <img
                                  src={blogList[1].grid_sm_img}
                                  className="rounded-circle"
                                />
                              </a>
                              <div className="profile-user-info latestnews-user-info">
                                {/* <h6><a target='_blank' href="">Richard Carg</a></h6> */}
                                <span className="latestnews-date" style={{
                      color: `#${homedata?.theme_10?.home?.section_15?.h3color}`
                    }}>
                                  <i className="uil uil-calendar-alt me-2"></i>
                                  {blogList[1].formatted_date}{" "}
                                  {blogList[1].formatted_month}{" "}
                                  {blogList[1].formatted_year}
                                </span>
                              </div>
                            </div>
                            <h3>
                              <a
                                target="_blank"
                                style={{
                                  color: `#${homedata?.theme_10?.home?.section_15?.h3color}`
                                }}
                                href={`${blogUrl}/blogg/${blogList[1].id}/${blogList[1].url_slug}`}
                              >
                                {blogList[1].page_title.substring(0, 80)}
                                {blogList[1].page_title.length > 80
                                  ? "..."
                                  : ""}
                              </a>
                            </h3>
                            {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
                {blogList?.length > 2 && (
                  <SwiperSlide>
                    <div className="latestnews-card">
                      <div className="latestnews-img">
                        <img src={testimonialimg3} alt="" />
                        <div className="latestnews-info">
                          <div className="latestnews-top">
                            <span className="latestnews-tag">
                              {blogList[2].category_name}
                            </span>
                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                          </div>
                          <div className="latestnews-bottom">
                            <div className="profile-user">
                              <a className="profile-user-img">
                                <img
                                  src={blogList[2].grid_sm_img}
                                  className="rounded-circle"
                                />
                              </a>
                              <div className="profile-user-info latestnews-user-info">
                                {/* <h6><a target='_blank' href="">Andrea Masadoni</a></h6> */}
                                <span className="latestnews-date" style={{
                      color: `#${homedata?.theme_10?.home?.section_15?.h3color}`
                    }}>
                                  <i className="uil uil-calendar-alt me-2"></i>
                                  {blogList[2].formatted_date}{" "}
                                  {blogList[2].formatted_month}{" "}
                                  {blogList[2].formatted_year}
                                </span>
                              </div>
                            </div>
                            <h3>
                              <a
                                target="_blank"
                                style={{
                                  color: `#${homedata?.theme_10?.home?.section_15?.h3color}`
                                }}
                                href={`${blogUrl}/blogg/${blogList[2].id}/${blogList[2].url_slug}`}
                              >
                                {blogList[2].page_title.substring(0, 80)}
                                {blogList[2].page_title.length > 80
                                  ? "..."
                                  : ""}
                              </a>
                            </h3>
                            {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="load-more mt-5">
                <a
                  target="_blank"
                  href={blogUrl}
                  className="btn title-btn hover-btn"
                  style={{
                    background: `#${homedata?.theme_10?.home?.section_15?.btn_group?.btn1?.bg_color}`,
                    color: `#${homedata?.theme_10?.home?.section_15?.btn_group?.btn1?.color}`,
                  }}
                >
                  {homedata?.theme_10?.home?.section_15?.btn_group.btn1.text}
                  <i className="uil uil-arrow-circle-right"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionFifteenThemeThree;
