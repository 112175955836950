  import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { appstore, googleplay } from "../../../assets/img";
import { useDispatch, useSelector } from "react-redux";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload,
});

function FooterFiveShop() {
  const navigate = useNavigate();

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});
  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);

  const appStore =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.app_store_link;
  const playStore =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.play_store_link;
  const facebook =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link1;
  const twitter =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link2;
  const instagram =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link6;
  const dispatch = useDispatch<any>();

  // useEffect(() => {
  //   if (Object.keys(settings).length === 0) {
  //     axios({
  //       method: "get",
  //       url: `${base_url}/api/settings`,
  //       headers: {
  //         Accept: "application/json",
  //         "Access-Control-Allow-Methods": "GET, POST",
  //       },
  //     })
  //       .then((response) => {
  //         if (response.status != 401) {
  //           setSettings(response.data.data);
  //           dispatch(setSettingsAll(response.data.data));
  //         }
  //       })
  //       .catch(function (error) {});
  //   }
  // });

  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();
  useEffect(() => {
  }, [homedata]);

  return (
    <div>
      {homedata && (
        <style type="text/css">
          {`
            :root {
              --footer-header: #${homedata?.theme_5?.home?.footer?.section_heading_color};
              --footer-paragraph: #${homedata?.theme_5?.home?.footer?.section_paragraph_color};
          }
          `}
        </style>
      )}
      <footer
        className="sh5-footer"
        style={{
          background: `#${homedata?.theme_5?.home?.footer?.bg_color}`,
        }}
      >
        <div className="container">
          <div className="row sh5-footer-one">
            <div className="col-md-12">
              <div className="sh5-home-links">
                <ul>
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return { color: isActive ? "#efc10b" : "" };
                      }}
                      className="nav-bar-link"
                      to={"/aboutUs"}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return { color: isActive ? "#efc10b" : "" };
                      }}
                      className="nav-bar-link"
                      to={"/areaCovered"}
                    >
                      Area Covered
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return { color: isActive ? "#efc10b" : "" };
                      }}
                      className="nav-bar-link"
                      to={"/faq"}
                    >
                      FAQ
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      style={({ isActive }) => {
                        return { color: isActive ? "#efc10b" : "" };
                      }}
                      className="nav-bar-link"
                      to={"/reviews"}
                    >
                      Reviews
                    </NavLink>
                  </li> */}
                  <li>
                    <a target="_blank" href={blogUrl}>
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row sh5-footer-one">
            {/* <div
              className="col-md-3 col-sm-6">
              <div
                className="footer-two">
                <div
                  className="footerlist-two">
                  <h4>We are here for you..</h4>
                  <ul>
                    <li><span>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t1_en}</span></li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-md-4 col-sm-6">
              <div className="sh5-footer-three">
                <div className="sh5-footerlist-two">
                  {homedata?.theme_5?.home?.footer?.footer_section
                    ?.needhelp_section?.is_enable && (
                    <h4>
                      {
                        homedata?.theme_5?.home?.footer?.footer_section
                          ?.needhelp_section?.text
                      }
                    </h4>
                  )}
                  {/* <h4>Need Help?</h4> */}
                  <ul>
                  <li>
                      <span>
                        {
                          homedata?.theme_5?.home?.footer?.footer_section
                            ?.callnow_for_Services_section?.mbl_no?.label
                        }{" "}
                        {":"}
                      </span>
                      <span>
                        <a
                          href={`tel:${homedata?.theme_5?.home?.footer?.footer_section?.callnow_for_Services_section?.mbl_no?.value}`}
                        >
                          {
                            homedata?.theme_5?.home?.footer?.footer_section
                              ?.callnow_for_Services_section?.mbl_no?.value
                          }
                        </a>
                      </span>
                    </li>
                    <li>
                      <span>
                        {
                          homedata?.theme_5?.home?.footer?.footer_section
                            ?.callnow_for_Services_section?.email?.label
                        }{" "}
                        {":"}
                      </span>
                      <span>
                        <a
                          href={`mailto:${homedata?.theme_5?.home?.footer?.footer_section?.callnow_for_Services_section?.email?.value}`}
                        >
                          {
                            homedata?.theme_5?.home?.footer?.footer_section
                              ?.callnow_for_Services_section?.email?.value
                          }
                        </a>
                      </span>
                    </li>
                    {/* <li><span>Email :</span><span><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}</a></span></li> */}
                    {/* <li><i
                      className="fa fa-envelope" aria-hidden="true"></i><span><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}</a></span></li>
                    <li><i
                      className="fa fa-envelope" aria-hidden="true"></i><span><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}</a></span></li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="sh5-footer-three">
                <div className="sh5-footerlist-two">
                  {homedata?.theme_5?.home?.footer?.footer_section
                    ?.policy_section?.is_enable && (
                    <h4>
                      {
                        homedata?.theme_5?.home?.footer?.footer_section
                          ?.policy_section?.text
                      }
                    </h4>
                  )}{" "}
                  <ul>
                    <li>
                      <NavLink to={"/termsAndConditions"}>
                        <i className="fas fa-angle-right me-1"></i>Conditions of
                        use
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/privacyPolicy"}>
                        <i className="fas fa-angle-right me-1"></i>Privacy
                        Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/cookiePolicy"}>
                        <i className="fas fa-angle-right me-1"></i>Cookie Policy
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="sh5-footer-three">
                <div className="sh5-footerlist-two">
                  {homedata?.theme_5?.home?.footer?.footer_section
                    ?.quickconnect_section?.is_enable && (
                    <h4>
                      {
                        homedata?.theme_5?.home?.footer?.footer_section
                          ?.quickconnect_section?.text
                      }
                    </h4>
                  )}{" "}
                  <div className="sh5-app-icons">
                    <ul className="list-inline">
                    <li>
                      <a
                        target="_blank"
                        className="hvr-float-shadow imggbg"

                        href={
                          homedata?.theme_5?.home?.footer?.footer_section?.mobile_link_section
                            ?.ios_link
                        }
                      >
                        <img src={appstore} alt="appstore" className="img img-fluid"
 />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        className="hvr-float-shadow imggbg2"

                        href={
                          homedata?.theme_5?.home?.footer?.footer_section?.mobile_link_section
                            ?.android_link
                        }
                      >
                        <img src={googleplay} alt="appstore" className="img img-fluid" />
                      </a>
                    </li>
                      {/* <li>
                        <a
                          target={appStore == "" ? "" : "_blank"}
                          href={appStore == "" ? "javascript:;" : appStore}
                          className="hvr-float-shadow imggbg"
                        >
                          <img
                            src={appstore}
                            className="img img-fluid"
                            alt="appstore"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          target={playStore == "" ? "" : "_blank"}
                          href={playStore == "" ? "javascript:;" : playStore}
                          className="hvr-float-shadow imggbg2"
                        >
                          <img
                            src={googleplay}
                            className="img img-fluid"
                            alt="appstore"
                          />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <p className="sh5-footer-last">
                {homedata?.theme_5?.home?.footer?.site_title_en} | Powered by&nbsp; <a href="http://www.bestatservices.com" target="blank">www.bestatservices.com</a>
              </p>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="sh5-social-links text-end">
                <div className="sh5-footer-social md-mt">
                  <ul className="list-inline">
                    {homedata?.theme_5?.home?.footer?.footer_section
                      ?.social_media?.facebook?.is_enable && (
                      <li>
                        <a
                          href={
                            `https://${homedata?.theme_5?.home?.footer?.footer_section
                              ?.social_media?.facebook?.facebook_link}`
                          }
                          target="_blank"
                          className="foo-icon"
                        >
                          <i className="fab fa-facebook"></i>
                        </a>
                      </li>
                    )}
                    {homedata?.theme_5?.home?.footer?.footer_section
                      ?.social_media?.instagram?.is_enable && (
                      <li>
                        <a
                          href={
                            `https://${homedata?.theme_5?.home?.footer?.footer_section
                              ?.social_media?.instagram?.instagram_link}`
                          }
                          target="_blank"
                          className="foo-icon"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    )}
                    {/* {homedata?.theme_5?.home?.footer?.footer_section
                      ?.social_media?.whatsapp?.is_enable && (
                      <li>
                        <a
                          href={
                            `https://${homedata?.theme_5?.home?.footer?.footer_section
                              ?.social_media?.whatsapp?.whatsapp_link}`
                          }
                          target="_blank"
                          className="foo-icon"
                        >
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                    )} */}
                    {homedata?.theme_5?.home?.footer?.footer_section
                      ?.social_media?.linkedin?.is_enable && (
                      <li>
                        <a
                          href={
                            `https://${homedata?.theme_5?.home?.footer?.footer_section
                              ?.social_media?.linkedin?.linkedin_link}`
                          }
                          target="_blank"
                          className="foo-icon"
                        >
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    )}
                    {homedata?.theme_5?.home?.footer?.footer_section
                      ?.social_media?.youtube?.is_enable && (
                      <li>
                        <a
                          href={
                            `https://${homedata?.theme_5?.home?.footer?.footer_section
                              ?.social_media?.youtube?.youtube_link}`
                          }
                          target="_blank"
                          className="foo-icon"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    )}
                    {homedata?.theme_5?.home?.footer?.footer_section
                      ?.social_media?.twitter?.is_enable && (
                      <li>
                        <a
                          href={
                            `https://${homedata?.theme_5?.home?.footer?.footer_section
                              ?.social_media?.twitter?.twitter_link}`
                          }
                          target="_blank"
                          className="foo-icon"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    )}

                    {/* {Object.keys(settings).length > 0 && 
                      settings.WebsiteSettings.social_link1 !=='' &&
                        <li><a className="foo-icon" target="_blank" href={facebook}><i className="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                      }
                                            {Object.keys(settings).length > 0 && 
                      settings.WebsiteSettings.social_link6 !=='' &&
                        <li><a className="foo-icon" target="_blank" href={instagram}><i className="fab fa-instagram" aria-hidden="true"></i></a></li>
                                            }
                                            {Object.keys(settings).length > 0 && 
                      settings.WebsiteSettings.social_link2 !=='' &&
                        <li><a className="foo-icon" target="_blank" href={twitter}><i className="fab fa-twitter" aria-hidden="true"></i></a></li>
                                            } */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default FooterFiveShop; 
