import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import {
  fetchThemeEdit,
  getHomeJsonList,
  getSettingsData,
  setShopHomeCms,
  setShopHomeJsonList,
} from "../../../redux/Actions";
import FooterOne from "../theme-one/footerOne";
import HeaderOne from "../theme-one/headerOne";
import HeaderThree from "../theme-three/headerThree";
import HeaderThreeEdit from "./header-edit/header3-edit";
import HeaderOneEdit from "./header-edit/header1-edit";
import SectionOneBannerThemeThree from "../theme-three/SectionOneBannerThemeThree";
import SectionOneBannerThemeThreeBlack from "../theme-ten/SectionOneBannerThemeThree";
import SectionOneEditThemeThree from "./section1Edit/SectionOneEditThemeThree";
import SectionOneBannerThemeOne from "../theme-one/SectionOneBannerThemeOne";
import SectionThreeThemeThree from "../theme-three/sectionThreeThemeThree";
import SectionThreeThemeThreeBlack from "../theme-ten/sectionThreeThemeThree";
import SectionThreeThemeOne from "../theme-one/sectionThreeThemeOne";
import SectionThreeThemeThreeEdit from "./SectionThreeEdit/SectionThreeThemeThreeEdit";
import SectionThreeThemeOneEdit from "./SectionThreeEdit/SectionThreeThemeOneEdit";
import SectionFiveThemeThree from "../theme-three/sectionFiveThemeThree";
import SectionFiveThemeThreeBlack from "../theme-ten/sectionFiveThemeThree";
import SectionFiveThemeOne from "../theme-one/SectionFiveThemeOne";
import SectionFourThemeThree from "../theme-three/sectionFourThemeThree";
import SectionFourThemeThreeBlack from "../theme-ten/sectionFourThemeThree";
import SectionFourThemeOne from "../theme-one/sectionFourThemeOne";
import SectionFourBannerThemeThree from "./sectionFourBanner/SectionFourBannerThemeThree";
import SectionFourBannerThemeOne from "./sectionFourBanner/SectionFourBannerThemeOne";
import SectionSixThemeThree from "../theme-three/sectionSixThemeThree";
import SectionSixThemeThreeBlack from "../theme-ten/sectionSixThemeThree";
import SectionSixThemeOne from "../theme-one/sectionSixThemeOne";
import SectionSixBannerThemeThree from "./sectionSixBanner/SectionSixBannerThemeThree";
import SectionSixBannerThemeOne from "./sectionSixBanner/SectionSixBannerThemeOne";
import SectionSevenThemeThree from "../theme-three/sectionSevenThemeThree";
import SectionSevenThemeThreeBlack from "../theme-ten/sectionSevenThemeThree";
import SectionSevenThemeOne from "../theme-one/sectionSevenThemeOne";
import SectionSevenBannerThemeThree from "./sectionSevenBanner/SectionSevenBannerThemeThree";
import SectionSevenBannerThemeOne from "./sectionSevenBanner/SectionSevenBannerThemeOne";
import SectionNineThemeOne from "../theme-one/sectionNineThemeOne";
import SectionNineThemeThree from "../theme-three/sectionNineThemeThree";
import SectionNineThemeThreeBlack from "../theme-ten/sectionNineThemeThree";
import SectionNineBannerThemeOne from "./sectionNineBanner/SectionNineBannerThemeOne";
import SectionNineBannerThemeThree from "./sectionNineBanner/SectionNineBannerThemeThree";
import SectionFiveThemeThreeEdit from "./SectionFiveEdit/SectionFiveThemeThreeEdit";
import SectionFiveThemeOneEdit from "./SectionFiveEdit/SectionFiveThemeOneEdit";
import SectionOneEditThemeOne from "./section1Edit/sectionOneEditThemeOne";
import SectionTenBannerThemeThree from "../theme-three/SectionTenBannerThemeThree";
import SectionTenBannerThemeThreeBlack from "../theme-ten/SectionTenBannerThemeThree";
import SectionTenThemeThree from "./section10Edit/sectionTenThemeThree";
import SectionTenBannerThemeOne from "../theme-one/SectionTenBannerThemeOne";
import SectionTenThemeOne from "./section10Edit/sectionTenThemeOne";
import SectionTwelveBannerThemeThree from "../theme-three/SectionTwelveBannerThemeThree";
import SectionTwelveBannerThemeThreeBlack from "../theme-ten/SectionTwelveBannerThemeThree";
import SectionTwelveThemeThree from "./section12Edit/sectionTwelveThemeThree";
import SectionEightThemeOneEdit from "./SectionEightThemeThreeEdit/SectionEightThemeOneEdit";
import SectionEightThemeThreeEdit from "./SectionEightThemeThreeEdit/SectionEightThemeThreeEdit";
import SectionElevenThemeThreeEdit from "./SectionElevenEdit/SectionElevenThemeThreeEdit";
import SectionFifteenThemeThreeEdit from "./SectionFifteenEdit/SectionFifteenThemeThreeEdit";
import SectionThirteenThemeThreeEdit from "./clickAway/SectionThirteenThemeThreeEdit";
import SectionElevenThemeThree from "../theme-three/SectionElevenThemeThree";
import SectionElevenThemeThreeBlack from "../theme-ten/SectionElevenThemeThree";
import SectionThirteenThemeThree from "../theme-three/SectionThirteenThemeThree";
import SectionThirteenThemeThreeBlack from "../theme-ten/SectionThirteenThemeThree";
import SectionFifteenThemeThree from "../theme-three/SectionFifteenThemeThree";
import SectionFifteenThemeThreeBlack from "../theme-ten/SectionFifteenThemeThree";
import SectionEightThemeOne from "../theme-one/SectionEightThemeOne";
import SectionEightThemeThree from "../theme-three/SectionEightThemeThree";
import SectionEightThemeThreeBlack from "../theme-ten/SectionEightThemeThree";
import SectionThemeThreeEditor from "./ThemeEditor/SectionThemeThreeEditor";
import SectionThemeOneEditor from "./ThemeEditor/SectionThemeOneEditor";
import HeaderTwo from "../theme-two/headerTwo";
import HeaderTwoEdit from "./header-edit/header2-edit";
import SectionTwoBannerThemeTwo from "../theme-two/section-two";
import SectionOneThemeTwo from "../theme-two/section-one";
import SectionTwoEditThemeTwo from "./section2Edit/sectionTwoEditThemeTwo";
import SectionThreeThemeTwo from "../theme-two/section-three";
import SectionFiveThemeTwo from "../theme-two/section-five";
import SectionSixThemeTwo from "../theme-two/section-six";
import SectionSevenThemeTwo from "../theme-two/section-seven";
import SectionEightThemeTwo from "../theme-two/section-eight";
import SectionNineThemeTwo from "../theme-two/section-nine";
import SectionOneThemeTwoEdit from "./section1Edit/sectionOneEditThemeTwo";
import SectionThreeThemeTwoEdit from "./section3/sectionThreeThemeTwo";
import SectionFiveThemeTwoEdit from "./SectionFiveEdit/SectionFiveThemeTwoEdit";
import SectionSevenBannerThemeTwo from "./sectionSevenBanner/SectionSevenBannerThemeTwo";
import SectionEightThemeTwoEdit from "./SectionEightThemeThreeEdit/SectionEightThemeTwoEdit";
import SectionNineBannerThemeTwo from "./sectionNineBanner/SectionNineBannerThemeTwo";
import SectionThemeTwoEditor from "./ThemeEditor/SectionThemeTwoEditor";
import SectionSixBannerThemeTwo from "./sectionSixBanner/SectionSixBannerThemeTwo";
import FooterTwo from "../theme-two/footerTwo";
import FooterThree from "../theme-three/footerThree";
import FooterOneEdit from "./footer-edit/footer1-edit";
import FooterThreeEdit from "./footer-edit/footer3-edit";
import FooterTwoEdit from "./footer-edit/footer2-edit";
import SectionOneThemeFour from "../theme-four/section-one";
import SectionTwoBannerThemeFour from "../theme-four/section-two";
import SectionThreeThemeFour from "../theme-four/section-three";
import SectionFourThemeFour from "../theme-four/section-four";
import SectionFiveThemeFour from "../theme-four/section-five";
import SectionSixThemeFour from "../theme-four/section-six";
import SectionSevenThemeFour from "../theme-four/section-seven";
import SectionEightThemeFour from "../theme-four/section-eight";
import SectionNineThemeFour from "../theme-four/section-nine";
import SectionTenThemeFour from "../theme-four/section-ten";
import SectionElevenThemeFour from "../theme-four/section-eleven";
import SectionTwelveThemeFour from "../theme-four/section-twelve";
import SectionThirteenThemeFour from "../theme-four/section-thirteen";
import SectionFourteenThemeFour from "../theme-four/section-fourteen";
import FooterFour from "../theme-four/footerFour";
import HeaderFourEdit from "./header-edit/header4-edit";
import SectionOneEditThemeFour from "./section1Edit/SectionOneEditThemeFour";
import SectionTwoThemeFourEdit from "./section2Edit/sectionTwoEditThemeFour";
import SectionThreeThemeFourEdit from "./SectionThreeEdit/SectionThreeThemeFourEdit";
import SectionFourThemeFourEdit from "./sectionFourBanner/SectionFourBannerThemeFour";
import SectionEightThemeFourEdit from "./SectionEightThemeThreeEdit/SectionEightThemeFourEdit";
import SectionSixBannerThemeFour from "./sectionSixBanner/SectionSixBannerThemeFour";
import SectionNineBannerThemeFour from "./sectionNineBanner/SectionNineBannerThemeFour";
import SectionTwelveBannerThemeFour from "./sectionTwelveBanner/SectionTwelveBannerThemeFour";
import SectionFiveThemeFourEdit from "./SectionFiveEdit/SectionFiveThemeFourEdit";
import SectionTenThemeFourEdit from "./section10Edit/sectionTenThemeFour";
import SectionThemeFourEditor from "./ThemeEditor/SectionThemeFourEditor";
import SectionSevenBannerThemeFour from "./sectionSevenBanner/SectionSevenBannerThemeFour";
import SectionElevenThemeFourEdit from "./SectionElevenEdit/SectionElevenThemeFourEdit";
import FooterFourEdit from "./footer-edit/footer4-edit";
import SectionOneThemeFive from "../theme-five/section-one";
import SectionOneEditThemeFive from "./section1Edit/SectionOneEditThemeFive";
import HeaderFive from "../theme-five/headerFive";
import HeaderFiveEdit from "./header-edit/header5-edit";
import SectionFourThemeFive from "../theme-five/section-four";
import SectionFiveThemeFive from "../theme-five/section-five";
import SectionSixThemeFive from "../theme-five/section-six";
import SectionSevenThemeFive from "../theme-five/section-seven";
import SectionEightThemeFive from "../theme-five/section-eight";
import FooterFive from "../theme-five/footerFive";
import SectionSevenBannerThemeFive from "./sectionSevenBanner/SectionSevenBannerThemeFive";
import SectionFourThemeFiveEdit from "./sectionFourBanner/SectionFourBannerThemeFive";
import SectionFiveThemeFiveEdit from "./SectionFiveEdit/SectionFiveThemeFiveEdit";
import FooterFiveEdit from "./footer-edit/footer5-edit";
import SectionEightThemeFiveEdit from "./SectionEightThemeThreeEdit/SectionEightThemeFiveEdit";
import SectionThemeFiveEditor from "./ThemeEditor/SectionThemeFiveEditor";
import SectionSixBannerThemeFive from "./sectionSixBanner/SectionSixBannerThemeFive";
import { fetchShopHomeData } from "../../../redux/Actions/shopHomeAction";
import HeaderFour from "../theme-four/headerFour";
import HeaderTen from "../theme-ten/header";
import HeaderTenEdit from "./header-edit/header10-edit";
import FooterTen from "../theme-ten/footer";
import SectionThreeThemeThreeEditBlack from "./SectionThreeEdit/SectionThreeThemeThreeEditBlack";
import SectionFourBannerThemeThreeBlack from "./sectionFourBanner/SectionFourBannerThemeThreeBlack";
import SectionFiveThemeThreeEditBlack from "./SectionFiveEdit/SectionFiveThemeThreeEditBlack";
import SectionSixBannerThemeThreeBlack from "./sectionSixBanner/SectionSixBannerThemeThreeBlack";
import SectionSevenBannerThemeThreeBlack from "./sectionSevenBanner/SectionSevenBannerThemeThreeBlack";
import SectionEightThemeThreeEditBlack from "./SectionEightThemeThreeEdit/SectionEightThemeThreeEditBlack";
import SectionNineBannerThemeThreeBlack from "./sectionNineBanner/SectionNineBannerThemeThreeBlack";
import SectionTenThemeThreeBlack from "./section10Edit/SectionTenThemeThreeBlack";
import SectionElevenThemeThreeEditBlack from "./SectionElevenEdit/SectionElevenThemeThreeEditBlack";
import SectionTwelveThemeThreeBlack from "./section12Edit/SectionTwelveThemeThreeBlack";
import SectionThirteenThemeThreeEditBlack from "./clickAway/SectionThirteenThemeThreeEditBlack";
import FooterTenEdit from "./footer-edit/footer10-edit";
import SectionOneEditThemeThreeBlack from "./section1Edit/SectionOneEditThemeThreeBlack";
import SectionFifteenThemeThreeEditBlack from "./SectionFifteenEdit/SectionFifteenThemeThreeEditBlack";

function  EditShopTheme() {
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const settingsData: any = useSelector<any>((state) => state?.settings);

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    const shop_id: any = url.searchParams.get("shop_id");
    localStorage.setItem("currentShopHome", shop_id);
    const host = window.location.hostname;
    // checks the token if its not in localhost
    if (host !== "localhost") {
      if (token != null) {
        dispatch(fetchThemeEdit(token)).then((res: any) => {
          if (res == false) {
            navigate("/");
          }
        });
      } else {
        navigate("/");
      }
    }
  }, []);
  useEffect(() => {
    if (settingsData == undefined) {
      dispatch(getSettingsData());
    }
    dispatch(fetchShopHomeData()).then((response: any) => {
      dispatch(setShopHomeCms(response.data.data.cms_json));
    });
  }, []);

  useEffect(() => {
    if(shopHomeData?.template == 1){
      import("../../shopHome/theme-one/sh1.css");
    }else if(shopHomeData?.template == 2){
      import("../../shopHome/theme-two/sh2.css");
    }else if(shopHomeData?.template == 3){
      import("../../shopHome/theme-three/sh3.css");
    }else if(shopHomeData?.template == 4){
      import("../../shopHome/theme-four/sh4.css");
    }else if(shopHomeData?.template == 5){
      import("../../shopHome/theme-five/sh5.css");
    }else if(shopHomeData?.template == 9){
      import("../../../assets/css/laundryheap.css");
    }else if(shopHomeData?.template == 10){
      import("../../shopHome/theme-ten/sh10.css");
    }else if(shopHomeData?.template == 11){
      import("../../../assets/css/ihate.css");
    }

  }, [shopHomeData]);

  return (
    <>
      <style type="text/css">
        {` 
            :root {
              --primary-color: #${
                jsonData?.[`theme_${shopHomeData?.template}`]?.home?.header
                  ?.primary_color
              }; !important
              --secondary-color: #${
                jsonData?.[`theme_${shopHomeData?.template}`]?.home?.header
                  ?.secondary_color
              }; !important
          }
          `}
      </style>
      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#headerEditmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 && <HeaderOne />}
        {shopHomeData?.template == 2 && <HeaderTwo />}
        {shopHomeData?.template == 3 && <HeaderThree />}
        {shopHomeData?.template == 4 && <HeaderFour />}
        {shopHomeData?.template == 5 && <HeaderFive />}
        {shopHomeData?.template == 10 && <HeaderTen />}
      </div>
      <div
        className={`section-editor ${
          shopHomeData?.template == 4 ? "section-editor4" : ""
        }`}
      >
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#sectiononeEditmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneBannerThemeOne />
            </>
          )}
        {shopHomeData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneThemeTwo />
            </>
          )}
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneBannerThemeThree />
            </>
          )}
          {shopHomeData?.template == 10 &&
              <SectionOneBannerThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneThemeFour />
            </>
          )}
        {shopHomeData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[0]?.is_section_enable === true && (
            <>
              <SectionOneThemeFive />
            </>
          )}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section2Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {/* {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoBannerThemeOne />
            </>
          )} */}
        {shopHomeData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoBannerThemeTwo />
            </>
          )}
        {/* {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoBannerThemeThree />
            </>
          )} */}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoBannerThemeFour />
            </>
          )}
        {/* {shopHomeData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[1]?.is_section_enable === true && (
            <>
              <SectionTwoThemeFive />
            </>
          )} */}
      </div>
      <div
        className="modal fade"
        id="section2Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {/* {shopHomeData?.template == 1 && <SectionTwoEditThemeOne />} */}
        {shopHomeData?.template == 2 && <SectionTwoEditThemeTwo />}
        {/* {shopHomeData?.template == 3 && <SectionTwoEditThemeThree />} */}
        {shopHomeData?.template == 4 && <SectionTwoThemeFourEdit />}
      </div>
      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section3Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[2]?.is_section_enable === true && (
            <SectionThreeThemeOne />
          )}
        {shopHomeData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[2]?.is_section_enable === true && (
            <>
              <SectionThreeThemeTwo />
            </>
          )}
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[2]?.is_section_enable === true && (
            <SectionThreeThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionThreeThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[2]?.is_section_enable === true && (
            <SectionThreeThemeFour />
          )}
        {/* {shopHomeData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[2]?.is_section_enable === true && (
            <SectionThreeThemeFive />
          )} */}
      </div>

      <div
        className="modal fade"
        id="section3Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionThreeThemeOneEdit />}
        {shopHomeData?.template == 2 && <SectionThreeThemeTwoEdit />}
        {shopHomeData?.template == 3 && <SectionThreeThemeThreeEdit />}
        {shopHomeData?.template == 10 && <SectionThreeThemeThreeEditBlack />}
        {shopHomeData?.template == 4 && <SectionThreeThemeFourEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section4Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[3]?.is_section_enable === true && (
            <SectionFourThemeOne />
          )}
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[3]?.is_section_enable === true && (
            <SectionFourThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionFourThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[3]?.is_section_enable === true && (
            <SectionFourThemeFour />
          )}
        {shopHomeData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[3]?.is_section_enable === true && (
            <SectionFourThemeFive />
          )}
      </div>
      <div
        className="modal fade"
        id="section4Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionFourBannerThemeOne />}
        {shopHomeData?.template == 3 && <SectionFourBannerThemeThree />}
        {shopHomeData?.template == 10 && <SectionFourBannerThemeThreeBlack />}
        {shopHomeData?.template == 4 && <SectionFourThemeFourEdit />}
        {shopHomeData?.template == 5 && <SectionFourThemeFiveEdit />}
      </div>
      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section5Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[4]?.is_section_enable === true && (
            <SectionFiveThemeOne />
          )}
        {shopHomeData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[4]?.is_section_enable === true && (
            <>
              <SectionFiveThemeTwo />
            </>
          )}
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[4]?.is_section_enable === true && (
            <SectionFiveThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionFiveThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[4]?.is_section_enable === true && (
            <SectionFiveThemeFour />
          )}
        {shopHomeData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[4]?.is_section_enable === true && (
            <SectionFiveThemeFive />
          )}
      </div>

      <div
        className="modal fade"
        id="section5Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionFiveThemeOneEdit />}
        {shopHomeData?.template == 2 && <SectionFiveThemeTwoEdit />}
        {shopHomeData?.template == 3 && <SectionFiveThemeThreeEdit />}
        {shopHomeData?.template == 10 && <SectionFiveThemeThreeEditBlack />}
        {shopHomeData?.template == 4 && <SectionFiveThemeFourEdit />}
        {shopHomeData?.template == 5 && <SectionFiveThemeFiveEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section6Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[5]?.is_section_enable === true && (
            <SectionSixThemeOne />
          )}
        {shopHomeData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[5]?.is_section_enable === true && (
            <>
              <SectionSixThemeTwo />
            </>
          )}
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[5]?.is_section_enable === true && (
            <SectionSixThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionSixThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[5]?.is_section_enable === true && (
            <SectionSixThemeFour />
          )}
        {shopHomeData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[5]?.is_section_enable === true && (
            <SectionSixThemeFive />
          )}
      </div>

      <div
        className="modal fade"
        id="section6Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionSixBannerThemeOne />}
        {shopHomeData?.template == 2 && <SectionSixBannerThemeTwo />}
        {shopHomeData?.template == 3 && <SectionSixBannerThemeThree />}
        {shopHomeData?.template == 10 && <SectionSixBannerThemeThreeBlack />}
        {shopHomeData?.template == 4 && <SectionSixBannerThemeFour />}
        {shopHomeData?.template == 5 && <SectionSixBannerThemeFive />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section7Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[6]?.is_section_enable === true && (
            <SectionSevenThemeOne />
          )}
        {shopHomeData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[6]?.is_section_enable === true && (
            <>
              <SectionSevenThemeTwo />
            </>
          )}
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[6]?.is_section_enable === true && (
            <SectionSevenThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionSevenThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[6]?.is_section_enable === true && (
            <SectionSevenThemeFour />
          )}
        {shopHomeData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[5]?.is_section_enable === true && (
            <SectionSevenThemeFive />
          )}
      </div>

      <div
        className="modal fade"
        id="section7Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionSevenBannerThemeOne />}
        {shopHomeData?.template == 2 && <SectionSevenBannerThemeTwo />}
        {shopHomeData?.template == 3 && <SectionSevenBannerThemeThree />}
        {shopHomeData?.template == 10 && <SectionSevenBannerThemeThreeBlack />}
        {shopHomeData?.template == 4 && <SectionSevenBannerThemeFour />}
        {shopHomeData?.template == 5 && <SectionSevenBannerThemeFive />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section8Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[7]?.is_section_enable === true && (
            <SectionEightThemeOne />
          )}
        {shopHomeData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[7]?.is_section_enable === true && (
            <>
              <SectionEightThemeTwo />
            </>
          )}
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[7]?.is_section_enable === true && (
            <SectionEightThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionEightThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[7]?.is_section_enable === true && (
            <SectionEightThemeFour />
          )}
        {shopHomeData?.template == 5 &&
          jsonData?.theme_5?.home?.sections[7]?.is_section_enable === true && (
            <SectionEightThemeFive />
          )}
      </div>

      <div
        className="modal fade"
        id="section8Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionEightThemeOneEdit />}
        {shopHomeData?.template == 2 && <SectionEightThemeTwoEdit />}
        {shopHomeData?.template == 3 && <SectionEightThemeThreeEdit />}
        {shopHomeData?.template == 10 && <SectionEightThemeThreeEditBlack />}
        {shopHomeData?.template == 4 && <SectionEightThemeFourEdit />}
        {shopHomeData?.template == 5 && <SectionEightThemeFiveEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section9Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[8]?.is_section_enable === true && (
            <SectionNineThemeOne />
          )}
        {shopHomeData?.template == 2 &&
          jsonData?.theme_2?.home?.sections[8]?.is_section_enable === true && (
            <>
              <SectionNineThemeTwo />
            </>
          )}
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[8]?.is_section_enable === true && (
            <SectionNineThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionNineThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[8]?.is_section_enable === true && (
            <SectionNineThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section9Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionNineBannerThemeOne />}
        {shopHomeData?.template == 2 && <SectionNineBannerThemeTwo />}
        {shopHomeData?.template == 3 && <SectionNineBannerThemeThree />}
        {shopHomeData?.template == 10 && <SectionNineBannerThemeThreeBlack />}
        {shopHomeData?.template == 4 && <SectionNineBannerThemeFour />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section10Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 &&
          jsonData?.theme_1?.home?.sections[9]?.is_section_enable === true && (
            <SectionTenBannerThemeOne />
          )}
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[9]?.is_section_enable === true && (
            <SectionTenBannerThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionTenBannerThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[9]?.is_section_enable === true && (
            <SectionTenThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section10Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionTenThemeOne />}
        {shopHomeData?.template == 3 && <SectionTenThemeThree />}
        {shopHomeData?.template == 10 && <SectionTenThemeThreeBlack />}
        {shopHomeData?.template == 4 && <SectionTenThemeFourEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section11Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[10]?.is_section_enable === true && (
            <SectionElevenThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionElevenThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[10]?.is_section_enable === true && (
            <SectionElevenThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section11Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 3 && <SectionElevenThemeThreeEdit />}
        {shopHomeData?.template == 10 && <SectionElevenThemeThreeEditBlack />}
        {shopHomeData?.template == 4 && <SectionElevenThemeFourEdit />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section12Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[11]?.is_section_enable === true && (
            <SectionTwelveBannerThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionTwelveBannerThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[11]?.is_section_enable === true && (
            <SectionTwelveThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section12Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 3 && <SectionTwelveThemeThree />}
        {shopHomeData?.template == 10 && <SectionTwelveThemeThreeBlack />}
        {/* <SectionTwelveThemeThree /> */}
        {shopHomeData?.template == 4 && <SectionTwelveBannerThemeFour />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section13Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[12]?.is_section_enable === true && (
            <SectionThirteenThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionThirteenThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[12]?.is_section_enable === true && (
            <SectionThirteenThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section13Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 3 && <SectionThirteenThemeThreeEdit />}
        {shopHomeData?.template == 10 && <SectionThirteenThemeThreeEditBlack />}
      </div>
      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#section15Editmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 3 &&
          jsonData?.theme_3?.home?.sections[14]?.is_section_enable === true && (
            <SectionFifteenThemeThree />
          )}
          {shopHomeData?.template == 10 && <SectionFifteenThemeThreeBlack />}
        {shopHomeData?.template == 4 &&
          jsonData?.theme_4?.home?.sections[14]?.is_section_enable === true && (
            <SectionFourteenThemeFour />
          )}
      </div>

      <div
        className="modal fade"
        id="section15Editmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 3 && <SectionFifteenThemeThreeEdit />}
        {shopHomeData?.template == 10 && <SectionFifteenThemeThreeEditBlack />}
      </div>

      <div className="section-editor">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#footerEditmodal"
          className="section-editor-icon"
        >
          <i className="fas fa-edit"></i>
        </a>
        {shopHomeData?.template == 1 && <FooterOne />}
        {shopHomeData?.template == 2 && <FooterTwo />}
        {shopHomeData?.template == 3 && <FooterThree />}
        {shopHomeData?.template == 10 && <FooterTen />}
        {shopHomeData?.template == 4 && <FooterFour />}
        {shopHomeData?.template == 5 && <FooterFive />}
      </div>

      <div
        className="modal fade"
        id="sectiononeEditmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionOneEditThemeOne />}
        {shopHomeData?.template == 2 && <SectionOneThemeTwoEdit />}
        {shopHomeData?.template == 3 && <SectionOneEditThemeThree />}
        {shopHomeData?.template == 10 && <SectionOneEditThemeThreeBlack />}
        {shopHomeData?.template == 4 && <SectionOneEditThemeFour />}
        {shopHomeData?.template == 5 && <SectionOneEditThemeFive />}
      </div>
      <div
        className="modal fade"
        id="headerEditmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <HeaderOneEdit />}
        {shopHomeData?.template == 2 && <HeaderTwoEdit />}
        {shopHomeData?.template == 3 && <HeaderThreeEdit />}
        {shopHomeData?.template == 4 && <HeaderFourEdit />}
        {shopHomeData?.template == 5 && <HeaderFiveEdit />}
        {shopHomeData?.template == 10 && <HeaderTenEdit />}
      </div>

      <div
        className="modal fade"
        id="footerEditmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {/* <FooterEdit /> */}
        {shopHomeData?.template == 1 && <FooterOneEdit />}
        {shopHomeData?.template == 2 && <FooterTwoEdit />}
        {shopHomeData?.template == 3 && <FooterThreeEdit />}
        {shopHomeData?.template == 10 && <FooterTenEdit />}
        {shopHomeData?.template == 4 && <FooterFourEdit />}
        {shopHomeData?.template == 5 && <FooterFiveEdit />}
      </div>
      <div className="theme-changer-home">
        <a href="/">
          <i className="fas fa-sign-out-alt"></i>
        </a>
      </div>
      <div className="theme-changer">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#themeColorEditmodal"
        >
          <i className="fas fa-cog"></i>
        </a>
      </div>
      <div
        className="modal fade"
        id="themeColorEditmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        {shopHomeData?.template == 1 && <SectionThemeOneEditor />}
        {shopHomeData?.template == 2 && <SectionThemeTwoEditor />}
        {shopHomeData?.template == 3 && <SectionThemeThreeEditor />}
        {shopHomeData?.template == 10 && <SectionThemeThreeEditor />}
        {shopHomeData?.template == 4 && <SectionThemeFourEditor />}
        {shopHomeData?.template == 5 && <SectionThemeFiveEditor />}
      </div>
    </>
  );
}

export default EditShopTheme;
