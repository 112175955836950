import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShopHomeCms, saveThemeJsonData } from "../../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";

function SectionFourBannerThemeThreeBlack() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);

  const handleChange = (e: any) => {
    const { value, name, type, checked } = e.target;
    setEditData((prevData:any) => ({
      ...prevData,
      theme_10:{
        ...prevData.theme_10,
      home: {
        ...prevData.theme_10.home,
        section_4: {
          ...prevData.theme_10.home.section_4,
          [name]: value,
          title: name === "section_4.title" ? value : prevData.theme_10.home.section_4.title,
        },
      },
    }
    }));
  };

  const saveSection4DataToFile = () => {
    dispatch(setShopHomeCms(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      // dispatch(setShopHomeCms(editData));
      setEditData(editData);
    }
  }, [editData]);

  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section4 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      name="section_4.title"
                      value={editData?.theme_10?.home?.section_4?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
                <div className="col-md-auto">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_10?.home?.section_4?.h2color
                        }
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_4?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveSection4DataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
    </>
  );
}

export default SectionFourBannerThemeThreeBlack;
