import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import {
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
} from "../../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../../edit/data";
import { fetchHomeJsonList } from "../../../redux/Actions";

function SectionOpeningHours() {
  const navigate = useNavigate();
  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);

  return (
    <>
      <section className="sh3-opening-hours" id="sh3openinghours">
        <div className="container"> 
          <div className="row">
            <div className="col-md-12">
              <div className="sh3-section-title">
                <h2>Opening Hours</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
                <div className="table-responsive">
                    <table className="table table-striped sh3ophtable table-dark">
                        <tbody>
                            <tr>
                                <td>Monday</td>
                                <td>8:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Tuesday</td>
                                <td>8:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Wednesday</td>
                                <td>8:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Thursday</td>
                                <td>8:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Friday</td>
                                <td>8:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Saturday</td>
                                <td>8:00 AM - 8:00 PM</td>
                            </tr>
                            <tr>
                                <td>Sunday</td>
                                <td>8:00 AM - 8:00 PM</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
}
export default SectionOpeningHours;
