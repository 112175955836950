import React from "react";
import { useSelector } from "react-redux/es/exports";
import SectionOne from "./sectionOne";
import SectionTwo from "./sectionTwo";
import SectionThree from "./sectionThree";
import SectionFour from "./sectionFour";
import SectionFive from "./sectionFive";
import SectionSix from "./sectionSix";
import SectionSeven from "./sectionSeven";
import SectionEight from "./sectionEight";
import SectionNine from "./sectionNine";
import SectionTen from "./sectionTen";
import SectionEleven from "./sectionEleven";
import SectionContact from "./sectionContact";


function ThemeThirteen() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  

  return (
    <>
        <div className="home-thirteen">
            <SectionOne/>
            {/* <SectionTwo/> */}
            <SectionThree/>
            <SectionFour/>
            <SectionFive/>
            <SectionSix/>
            <SectionSeven/>
            {/* <SectionEight/> */}
            {/* <SectionNine/> */}
            {/* <SectionTen/> */}            
            <SectionEleven/>
            <SectionContact/>
        </div>
    </>
  );
}

export default ThemeThirteen;
