import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notification1 } from "../../assets/img";
import "../notification/notification-box.css";
import { getNotification } from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";

function Notification() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const notificationList: any = useSelector<any>(
    (state) => state?.all_notification
  );
  useEffect(() => {
    dispatch(getNotification());    
  }, []);

  return (
    <div className="notification notification-dropdown">
      <div className="notification-header">
        <p>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate("/notifications");
            }}
            target="_blank"
            className="header-notification"
          >
            Notification
          </a>
        </p>
        {/* <i className="fa fa-cog float-end" aria-hidden="true"></i> */}
      </div>
      <div className="notification-items">
        {/* {notificationList?.map((notification: any) => (
          <li role="presentation" className="presentations">
            <div className="alert-dismissible nf-dismissible">
              <div className="notification-content">
                <img
                  src={notification1}
                  className="img-fluid"
                  alt="notification"
                />
                <div className="nf-content">
                  <p>{notification.title}</p>
                  <span>{notification.body}</span>
                </div>
              </div>
            </div>
          </li>
        ))} */}
      </div>
      <p className="notify-seeall">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate("/notifications");
          }}
          className="all-nitification"
          target="_blank"
        >
          See All Notification
        </a>
      </p>
    </div>
  );
}

export default Notification;
