import React, { useEffect, useState } from "react";

import { ttimg1,ttimg2,ttimg3,ttimg4,ttimg5,ttimg6,ttimg38,ttimg39,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTDryCleaning() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg38} alt="" />
                               </div>
                                <p>T & T Dry Cleaners provides expert professional dry cleaning. At T & T Dry Cleaners we offer a dry cleaning service for practically any clothing item. From silks to leathers, to curtains and wedding gowns, we do it all, better and faster than anyone! We use the most advanced dry cleaning process. Your garments will come back feeling beautiful every time!</p>
                                <p>To ensure perfect results every time, all garments are pre-spotted by our experienced staff. Once dry cleaned, the garments are quality checked and are then handed over to the pressers. When the garments have been pressed, they are again quality checked and hand finished to ensure the best finish. Only when the garments meet our stringent quality standards are your garments packaged and ready for delivery to you.</p>
                                <p>Need your cleaning done in a hurry - then use our trusted Same Day Service? Just have your cleaning in by 10:00 a.m. and we will process it and have it ready by 5:00 p.m. This service is available Monday to Saturday. If you have any queries or special requirements, please call our Customer Services on 0208 3166675. We will try to accommodate you. We always aim to please our customers.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg39} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg12} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTDryCleaning;