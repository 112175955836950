import React, { useEffect, useState } from "react";

import { ttimg1,ttimg2,ttimg3,ttimg4,ttimg5,ttimg6,ttimg7,ttimg8,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTLaundry() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg6} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg1} alt="" />
                               </div>
                                <p>At T&T Dry Cleaners we take pride in our laundering service whether it is everyday garments, to shirts or your personal bed linen.
                                Every care is taken at each step of our process to ensure your laundry is
                                beautifully finished prior to being returned to you. </p>
                                <p>We offer a wide range of Laundry Services, including:</p>
                                <ul>
                                    <li>Shirt Service</li>
                                    <li>Coin- Operated Self-Serve Machines</li>
                                    <li>Flexible collection & delivery</li>
                                    <li>Tumble Dryers</li>
                                    <li>Services washes</li>
                                    <li>Large Machines available</li>
                                    <li>Commercial & Domestic Service available</li>
                                </ul>
                                <h4>Contract work is always welcome</h4>
                                <p>T&T Dry Cleaners offers you a complete range of cleaning solutions at very competitive rates to meet our customers specific requirements. If you need daily, weekly, monthly domestic, office, commercial, or one-off services, you have come to the right place. We value our customers equally and you receive the same level of care and attention regardless of the size of the contract.</p>
                                <p>We provide you with a full range of domestic and contract Laundry Service for:</p>
                                <ul>
                                    <li>Restaurant- waiter uniform, chef uniform</li>
                                    <li>Hotel</li>
                                    <li>Nursing and Retirement Homes</li>
                                    <li>Housing associations</li>
                                    <li>Schools</li>
                                    <li>Bed Linen- Duvet Covers, Sheets, Pillow Cases</li>
                                    <li>Towels</li>
                                    <li>Private apartments</li>
                                    <li>City residences</li>
                                </ul>
                                <p>Call us on <b>0208 3166675</b> for a quote. </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg3} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg4} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg5} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTLaundry;