import React, { useEffect, useState } from "react";
import axios from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getUserDetails, setUserdetails } from "../../redux/Actions";
import { toast, ToastContainer } from "react-toastify";
import { auth, provider } from "../../../src/config/firebase";
import { signInWithPopup, FacebookAuthProvider, OAuthProvider } from "firebase/auth";
import { NavLink } from "react-router-dom";
import { fbicon, gicon, gsignupicon, appelsignin,appelsignup } from "../../assets/img";
import { checkShopListFlow } from "../../utility";

const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = process.env.REACT_APP_BASE_URL;

const loginResultData = {
  result: {
    Response: {
      response_code: "",
      response_message: "",
      token: "",
    },
    data: {
      user_info: {
        id: "",
        name: "",
        email: "",
        mobile: "",
        notification_interest_list: [],
        android_profile_img: "",
        ios_profile_img: "",
      },
    },
  },
};

function Login() {
  const notify = (message: string) => toast(message);

  const settingsData: any = useSelector<any>((state) => state?.settings);
  const [emailValue, setEmailValue] = useState("");
  const [pwdValue, setPwdValue] = useState("");
  const [genError, setGenError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [genRegError, setGenRegError] = useState("");
  const [loginResult, setLoginResult] = useState<any | null>(loginResultData);
  const [regNameValue, setRegNameValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [googleSignUp, setGoogleSignUp] = useState(false);
  const [regRefToken, setRegRefToken] = useState("");
  const [isIhaveActive, setIsIhaveActive] = useState(
    "d-inline no-float active"
  );
  const [signinFormClass, setSigninFormClass] = useState("active in");
  const [isNewUser, setIsNewUser] = useState("d-inline no-float");
  const [newUserFormClass, setNewUserFormClass] = useState("tab-pane fade");
  const [loginPasswordType, setLoginPasswordType] = useState("password");
  const [registerPasswordType, setRegisterPasswordType] = useState("password");

  const [userTypeValue, setUserTypeValue] = useState("I have account");

  const params = useParams<any>();

  var metaTitle = "";
  var metaDescription = "";

  const navigateToShopHome = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
      const finalResult = shopName
        .trim()
        .replace(/\s(.)/g, (match: any) => match.toUpperCase())
        .replace(/\s/g, "")
        .replace(/^(.)/, (match: any) => match.toLowerCase());
      navigate(`/shop/${finalResult}`);
    } else {
      navigate("/");
    }
  };

  const navigateToCheckout = () => {
    if(checkShopListFlow()){
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());

      navigate(`/shop/${finalResult}/checkOut`);
  }else {
    navigate("/checkout");
  }
}else {
  navigate("/checkout");
}

  };

  const initialValues = {
    email: "",
    password: "",
    name: "",
    mobile_number: "",
    referral_text: "",
    terms_and_condition_accept: false,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [isChecked, setIsChecked] = useState(true);
  const [keepSigned, setkeepSigned] = useState(false);

  const [regError, setRegError] = useState(false);

  const NAME_REGEX = new RegExp(/^[a-zA-Z ]+$/);
  const PHONE_REGEX = new RegExp(
    /^(?:(?:(?:00\s?|\+)44\s?|0\s?|)7(?:[1345789]\d{2}|624)\s?\d{3}\s?\d{3})$/
  );
  const US_PHONE_REGEX = new RegExp(
    /^(?:(?:(?:00\s?|\+)1\s?|0\s?|)7(?:[1345789]\d{2}|624)\s?\d{3}\s?\d{3})$/
  );
  const EMAIL_REGEX = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  const PASSWORD_REGEX =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

  const settings: any = useSelector<any>((state) => state.settings);

  const keepSignedChange = (data: any) => {
    if (data === "checked") {
      if (isChecked === true) {
        setkeepSigned(true);
      }
      setIsChecked(!isChecked);
      if (isChecked === false && localStorage.getItem("remember_token")) {
        localStorage.removeItem("remember_token");
      }
    }
  };

  // const [loginResult, setLoginResult] = useState<any>();
  const handleChange = (e: any) => {
    setEmailValue(e.target.value);
  };
  const handlePwdChange = (e: any) => {
    setPwdValue(e.target.value);
  };

  const handleRegNameChange = (e: any) => {
    // setRegNameValue(e.target.value);
    const { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      setFormValues({ ...formValues, [name]: checked });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  // const handleRegMobileChange = (e: any) => {
  //   setRegMobileValue(e.target.value);
  // }
  // const handleRegPwdChange = (e: any) => {
  //   setRegPwdValue(e.target.value);
  // }
  // const handleRegEmailChange = (e: any) => {
  //   setRegEmailValue(e.target.value);
  // }

  const handleuserTypeChange = (e: any) => {
    alert(e.target.value);
    // e.target.value="I have account"
    //setUserTypeValue(e.target.value);
  };
  useEffect(() => {
    metaTitle = "Login | "+ settingsData?.WebsiteSettings?.site_title_en;
    metaDescription = "Login to your "+ settingsData?.WebsiteSettings?.site_title_en +" account. Access your profile, manage appointments, and more. Sign in securely and conveniently online.";
    document.title = metaTitle;
    document.getElementById("title")?.setAttribute("content", metaTitle);
    document.getElementById("description")?.setAttribute("content", metaDescription);

    let url = window.location.pathname;
    if (url === "/login" && localStorage.getItem("remember_token")) {
      let remeber_token = localStorage.getItem("remember_token");
      handleKeepSigned(remeber_token);
    }
    if (url === "/signUp") {
      toggleClass("register");
    } else {
      toggleClass("signin");
    }
    if (params.tokenref) {
      setRegRefToken(params.tokenref);
      setIsIhaveActive("d-inline no-float");
      setSigninFormClass("tab-pane fade");
      setIsNewUser("d-inline no-float active");
      setNewUserFormClass("active in");
      setUserTypeValue("New User");
    }
  }, []);

  const handleKeepSigned = async (remember_token: any) => {
    const base_url = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      Accept: "application/json",
    };
    try {
      const response: any = await axios.post(
        `${base_url}/api/user_login`,
        { remember_token: remember_token },
        { headers: headers }
      );
      // const result = await response.then(response => response);

      if (response) {
        if (response.data.Response.response_code === "-1") {
          toast(response.data.Response.response_message);
          // notify(response.data.Response.response_message)
          return;
        }
        // setPostalCodeList(response.data.data[0])
        dispatch(getUserDetails(response.data));
        // setLoginResult(response.data);
        localStorage.setItem("token", response.data.Response.token);
        if (response.data.Response.remember_token) {
          localStorage.setItem(
            "remember_token",
            response.data.Response.remember_token
          );
        }
        const isFromCheckout = localStorage.getItem("isCheckout");
        const isCompleteOrder = localStorage.getItem("isCompleteOrder");
        const order_id = localStorage.getItem("order_id");

        if (order_type === 1) {
          // window.location.href = `/checkout`;
          navigateToCheckout();
          return;
        }
        if (isFromCheckout) {
          // navigate("/checkout");
          navigateToCheckout();
        } else {
          if (isCompleteOrder) {
            const shopName: any = localStorage.getItem("currentShopName");
            const finalResult = shopName
              .trim()
              .replace(/\s(.)/g, (match: any) => match.toUpperCase())
              .replace(/\s/g, "")
              .replace(/^(.)/, (match: any) => match.toLowerCase());

            if (shopName) {
              navigate(`/shop/${finalResult}/checkOut?order_id=${order_id}`);
            } else {
              navigate(`/checkout?order_id=${order_id}`);
            }
            localStorage.removeItem("isCompleteOrder");
          } else {
            navigateToShopHome();
            // window.location.reload();
          }
        }
      }
    } catch (error) { }
  };

  const handleSubmit = async (event?: any) => {
    event.preventDefault();
    setIsSubmit(true);
    const headers = {
      Accept: "application/json",
    };
    // getNotificationToken().then(async () => {
    //   const base_url = process.env.REACT_APP_BACKEND_URL;
    //   const headers = {
    //     Accept: "application/json",
    //   };
    //   try {
    //     const fcm_token = localStorage.getItem("fcm_token") || "";
    //     const payload = {
    //       email: emailValue,
    //       password: pwdValue,
    //       remember: keepSigned,
    //       device_type: "web",
    //       fcm_token: fcm_token,
    //     };
    //     const response: any = await axios.post(
    //       `${base_url}/api/user_login`,
    //       payload,
    //       { headers: headers }
    //     );
    //     // const result = await response.then(response => response);

    //     if (response) {
    //       if (response.data.Response.response_code === "-1") {
    //         toast(response.data.Response.response_message);
    //         // notify(response.data.Response.response_message)
    //         return;
    //       }
    //       // setPostalCodeList(response.data.data[0])
    //       dispatch(getUserDetails(response.data));
    //       // setLoginResult(response.data);
    //       localStorage.setItem("token", response.data.Response.token);
    //       if (response.data.Response.remember_token) {
    //         localStorage.setItem(
    //           "remember_token",
    //           response.data.Response.remember_token
    //         );
    //       }
    //       const isFromCheckout = localStorage.getItem("isCheckout");
    //       const isCompleteOrder = localStorage.getItem("isCompleteOrder");
    //       const order_id = localStorage.getItem("order_id");

    //       if (order_type === 1) {
    //         window.location.href = `/checkout`;
    //         return;
    //       }
    //       if (isFromCheckout) {
    //         navigate("/checkout");
    //       } else {
    //         if (isCompleteOrder) {
    //           navigate(`/checkout?order_id=${order_id}`);
    //           localStorage.removeItem("isCompleteOrder");
    //         } else {
    //           navigate("/");
    //           // window.location.reload();
    //         }
    //       }
    //     }
    //   } catch (error) {}
    // });

    const fcm_token = localStorage.getItem("fcm_token") || "";
    const payload = {
      email: emailValue,
      password: pwdValue,
      remember: keepSigned,
      device_type: "web",
      fcm_token: fcm_token,
    };
    const response: any = await axios.post(
      `${base_url}/api/user_login`,
      payload,
      { headers: headers }
    );
    // const result = await response.then(response => response);

    if (response) {
      if (response.data.Response.response_code === "-1") {
        toast(response.data.Response.response_message);
        // notify(response.data.Response.response_message)
        return;
      }
      // setPostalCodeList(response.data.data[0])
      dispatch(getUserDetails(response.data));
      // setLoginResult(response.data);
      localStorage.setItem("token", response.data.Response.token);
      if (response.data.Response.remember_token) {
        localStorage.setItem(
          "remember_token",
          response.data.Response.remember_token
        );
      }
      const isFromCheckout = localStorage.getItem("isCheckout");
      const isCompleteOrder = localStorage.getItem("isCompleteOrder");
      const order_id = localStorage.getItem("order_id");

      if (order_type === 1) {
        navigateToCheckout();
        // window.location.href = `/checkout`;
        return;
      }
      if (isFromCheckout) {
        navigateToCheckout();
        // navigate("/checkout");
      } else {
        if (isCompleteOrder) {
          const shopName: any = localStorage.getItem("currentShopName");
          const finalResult = shopName
            .trim()
            .replace(/\s(.)/g, (match: any) => match.toUpperCase())
            .replace(/\s/g, "")
            .replace(/^(.)/, (match: any) => match.toLowerCase());

          if (shopName) {
            navigate(`/shop/${finalResult}/checkOut?order_id=${order_id}`);
          } else {
            navigate(`/checkout?order_id=${order_id}`);
          }
          localStorage.removeItem("isCompleteOrder");
        } else {
          navigateToShopHome();
          // navigate("/");
          // window.location.reload();
        }
      }
    }
  };

  // useEffect(()=>{
  //   if (formErrors.name !== '' || formErrors.email !== '' || formErrors.mobile_number !== '' || formErrors.password !== '' || formErrors.terms_and_condition_accept !== '') {
  //     setRegError(true);
  //   }else{
  //     setRegError(false);
  //   }
  // })

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors, isSubmit]);

  const validate = (values: any) => {
    const errors = {
      email: "",
      password: "",
      name: "",
      mobile_number: "",
      terms_and_condition_accept: "",
    };
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = "Email ID format is invalid";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }
    if (!values.name) {
      errors.name = "Name is required";
    } else if (!NAME_REGEX.test(values.name)) {
      errors.name = "Name is not valid";
    }
    if (values.name.length > 50) {
      errors.name = "Name should be less than 50 characters";
    }
    if (!values.mobile_number) {
      errors.mobile_number = "Mobile number is required";
    } else if (!PHONE_REGEX.test(values.mobile_number)) {
      errors.mobile_number = "Mobile number format is invalid";
    }

    if (!values.terms_and_condition_accept === true) {
      errors.terms_and_condition_accept =
        "Please accept the terms and conditions";
    }
    return errors;
  };

  const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, provider);
    const socialLogin = { is_social_login: "yes" };
    var event = Object.assign(result, socialLogin);
    socialLoginSubmit(event);
  };

  const signUpWithGoogle = async () => {
    const result = await signInWithPopup(auth, provider);
    const { displayName, email } = result.user;
    console.log("result",result.user)
    const updatedFormValues = {
      ...formValues,
      name: displayName || "",
      email: email || "",
  };
  setFormValues(updatedFormValues);
  setGoogleSignUp(true)
  setError(true)
  setErrorMessage("We need your mobile number additionally for order updates")
  setFormErrors({});
  };

  const signInWithApple = async () => {
    const provider = new OAuthProvider("apple.com");
    const result = await signInWithPopup(auth, provider);
    const socialLogin = { is_social_login: "yes" };
    const event = Object.assign(result, socialLogin);
    socialLoginSubmit(event);
  };

  const signUpWithApple = async () => {
    const provider = new OAuthProvider("apple.com");
    const result = await signInWithPopup(auth, provider);
    const { displayName, email } = result.user;
    const updatedFormValues = {
      ...formValues,
      name: displayName || "",
      email: email || "",
    };
    setFormValues(updatedFormValues);
    setGoogleSignUp(true);
    setError(true);
    setErrorMessage(
      "We need your mobile number additionally for order updates"
    );
    setFormErrors({});
  };


  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const socialLogin = { is_social_login: "yes" };
    var event = Object.assign(result, socialLogin);
    socialLoginSubmit(event);
  };

  const socialLoginSubmit = async (event: any) => {
    const userEmail = event.user.email;
    const userName = event.user.displayName;
    const userUid = event.user.uid;
    const is_social_login = event.is_social_login;

    const base_url = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      Accept: "application/json",
    };
    try {
      const response: any = await axios.post(
        `${base_url}/api/user_login`,
        {
          email: userEmail,
          userName: userName,
          userUid: userUid,
          is_social_login: is_social_login,
        },
        { headers: headers }
      );
      // const result = await response.then(response => response);

      if (response) {
        if (response.data.Response.response_code == "-1") {
          toast(response.data.Response.response_message);
          // notify(response.data.Response.response_message)
          return;
        }
        // setPostalCodeList(response.data.data[0])
        dispatch(getUserDetails(response.data));
        // setLoginResult(response.data);
        localStorage.setItem("token", response.data.Response.token);

        const isFromCheckout = localStorage.getItem("isCheckout");
        if (isFromCheckout) {
          navigateToCheckout()
        } else {
          navigate("/");
          window.location.reload();
        }
      }
    } catch (error) { }
  };

  const order_type: any = useSelector<any>((state) => state?.orderType);

  const handleRegistrationSubmit = (event: any) => {
    event.preventDefault();
    setError(false)
    setFormErrors(validate(formValues));
    if (regError) {
      return;
    }
    if (!EMAIL_REGEX.test(formValues.email)) {
      return false;
    }
    if (!NAME_REGEX.test(formValues.name)) {
      return false;
    }
    if (formValues.name.length > 50) {
      return false;
    }
    if (formValues.password.length === 0 && !googleSignUp) {
      return false;
    }
    if (formValues.terms_and_condition_accept === false) {
      return false;
    }
    if (!PHONE_REGEX.test(formValues.mobile_number)) {
      return false;
    }
    setIsSubmit(true);

    let SignUpViaGoogle;
    if(googleSignUp){
      SignUpViaGoogle = 1
    }else{
      SignUpViaGoogle = 0
    }

    const headers = { Accept: "application/json" };

    axios
      .post(
        `${base_url}/api/user_registration`,
        {
          name: formValues.name,
          email: formValues.email,
          password: formValues.password,
          mobile: formValues.mobile_number,
          referral_text: formValues.referral_text,
          tokenref: regRefToken,
          google_sign_up: SignUpViaGoogle
        },
        {
          headers: headers,
        }
      )
      .then((e) => {
        if (e.data.Response.response_code == "-1") {
          toast(e.data.Response.response_message);
          // notify(response.data.Response.response_message)
          return;
        }
        setLoginResult({ result: e.data });

        if (
          e.data.Response.response_code == "1" &&
          e.data.Response.response_message == "registered successfully"
        ) {
          // window.location.reload();
          dispatch(getUserDetails(e.data));
          // setLoginResult(response.data);
          localStorage.setItem("token", e.data.Response.token);
          setGoogleSignUp(false)
          const isFromCheckout = localStorage.getItem("isCheckout");
          if (isFromCheckout) {
            navigateToCheckout()
          } else {
            navigate("/")
          }
          // window.location.href = `${client_base_url}/`
          return false;
        } else {
          setGenRegError(e.data.Response.response_message);
          event.preventDefault();
          return false;
        }
      });
    event.preventDefault();
  };

  const toggleClass = (e: any) => {
    //e.preventDefault()
    if (e === "signin") {
      setIsNewUser("d-inline no-float");
      setNewUserFormClass("tab-pane fade");
      setIsIhaveActive("d-inline no-float active");
      setSigninFormClass("active in");
      setUserTypeValue("I have account");
      //isIhaveActive="d-inline no-float active";
      //alert(isIhaveActive)
    } else if (e === "register") {
      // alert("register")
      setIsIhaveActive("d-inline no-float");
      setSigninFormClass("tab-pane fade");
      setIsNewUser("d-inline no-float active");
      setNewUserFormClass("active in");
      setUserTypeValue("New User");
    }
  };

  // const fetchData = async () => {
  //       const base_url = "https://revamp.dreamguystech.com";
  //       try {
  //           const response :any = await axios.post(`${base_url}/api/user_login`,{ email: "john@dreamguystech.com", password: "john" },{headers:headers});
  //           // const result = await response.then(response => response);

  //           if (response) {
  //               // setPostalCodeList(response.data.data[0])
  //               setEmailValue(response.data)
  //
  //               localStorage.setItem("token",response.Response.token)
  //           }
  //       } catch (error) {
  //
  //       }
  //   }

  //   useEffect(()=>{
  //     // fetchData();
  //   },[])

  //  if(e.currentTarget.dataset.type=="signin")
  //  {

  //   setIsNewUser("d-inline no-float")
  //   setNewUserFormClass("tab-pane fade")
  //   setIsIhaveActive("d-inline no-float active");
  //   setSigninFormClass('active in')
  //   setUserTypeValue("I have account");
  //   //isIhaveActive="d-inline no-float active";
  // //alert(isIhaveActive)

  //  }
  //  else if(e.currentTarget.dataset.type=="register")
  // // alert("register")
  // {
  //  setIsIhaveActive("d-inline no-float");
  //  setSigninFormClass('tab-pane fade')
  //  setIsNewUser("d-inline no-float active")
  //  setNewUserFormClass("active in")
  //  setUserTypeValue("New User");
  // }

  return (
    <div className="section-signin">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="my-5">
              <div className="login-wrapper">
                <div className="login-grid">
                  <ul className="login-nav" role="tablist">
                    <li className={isIhaveActive} id="Ihaveaccount">
                      <a
                        href="#user"
                        role="tab"
                        data-bs-toggle="tab"
                        className="big"
                        id="login"
                        onClick={() => toggleClass("signin")}
                        data-type="signin"
                        onChange={handleuserTypeChange}
                      >
                        <input
                          type="radio"
                          name="type"
                          value="I have account"
                          data-type="signin"
                          checked={userTypeValue == "I have account"}
                        />
                        <span data-type="signin">Log in</span>
                      </a>
                    </li>
                    <li className={isNewUser} id="NewUser">
                      <a
                        href="#new"
                        role="tab"
                        data-bs-toggle="tab"
                        className="big"
                        data-type="register"
                        onClick={() => toggleClass("register")}
                      >
                        <input
                          type="radio"
                          name="type"
                          value="New User"
                          checked={userTypeValue == "New User"}
                          data-type="register"
                        />
                        <span
                          data-type="register"
                          onChange={handleuserTypeChange}
                        >
                          Sign up
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className={newUserFormClass} id="new">
                      <div className="signin-conent-wrapper">
                        <form
                          className="form-signin"
                          id="signup"
                          role="form"
                          method="POST"
                          onSubmit={handleRegistrationSubmit}
                        >
                          <div className="mandatory-message text-center">
                            <small>All the fields are mandatory</small>
                          </div>
                          <div className="form-group">
                            <label>Name </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control signin-form"
                              name="name"
                              value={formValues.name}
                              maxLength={20}
                              onChange={handleRegNameChange}
                            />
                            <input
                              type="hidden"
                              name="_token"
                              value="FBbQwFf2DmbwnlueNvqwZjDULKy5w9DwqGPKwXYO"
                            />
                            <div className="name_error text-danger">
                              {formErrors.name}
                            </div>
                            <div className="name_error"></div>
                          </div>
                          <div className="form-group">
                            <label>Email </label>
                            <input
                              id="email"
                              type="email"
                              className="form-control signin-form valid"
                              name="email"
                              value={formValues.email}
                              aria-invalid="false"
                              onChange={handleRegNameChange}
                            />
                            <div className="name_error text-danger">
                              {formErrors.email}
                            </div>
                          </div>
                          {!googleSignUp &&
                          <div className="form-group position-relative">
                            <label>Password </label>
                            <input
                              id="password"
                              type={registerPasswordType}
                              className="form-control signin-form error"
                              name="password"
                              value={formValues.password}
                              aria-invalid="true"
                              onChange={handleRegNameChange}
                            />
                            {/* <div className="password_error text-danger"><label id="password-error" className="error" >Password can't be less than 6</label></div> */}
                            <button
                              className="eye-btn"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setRegisterPasswordType(
                                  registerPasswordType == "text"
                                    ? "password"
                                    : "text"
                                );
                              }}
                            >
                              <i
                                className={
                                  registerPasswordType == "password"
                                    ? "fas fa-eye-slash"
                                    : "far fa-eye"
                                }
                              ></i>
                            </button>
                            <div className="name_error text-danger">
                              {formErrors.password}
                            </div>
                          </div>
                          }
                          <div className="form-group signup-mobno">
                            <label>Mobile </label>
                            <div>
                              <div className="input-mob-no">
                                <div className="input-group">
                                  <span className="input-group-addon">
                                    {
                                      settings?.WebmasterSettings
                                        ?.mobile_notification_prefix
                                    }
                                  </span>
                                  <input
                                    id="mobile_number"
                                    placeholder="7123456789"
                                    className="form-control signin-form"
                                    name="mobile_number"
                                    value={formValues.mobile_number}
                                    onChange={handleRegNameChange}
                                  />
                                </div>
                                <input
                                  id="mobile"
                                  name="mobile_number"
                                  type="hidden"
                                  value=""
                                />
                              </div>
                              <div className="name_error text-danger">
                                {formErrors.mobile_number}
                              </div>
                            </div>
                          </div>
                          <div className="form-group signup-mobno">
                            {window.location.hostname.includes("Whiterose") ||
                              settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose") &&

                              <label>Referred By </label>
                            }
                            <div>
                              <div className="input-mob-no">
                                <div className="input-group">
                                  <input
                                    type={
                                      window.location.hostname.includes("Whiterose") || 
                                      settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose") ? "text" : "hidden"
                                    }
                                    id="referral_text"
                                    placeholder="e.g: Social Media, Website, Friends, etc."
                                    className="form-control signin-form"
                                    name="referral_text"
                                    value={formValues.referral_text}
                                    onChange={handleRegNameChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group checkbox">
                            <label>
                              <input
                                id="terms_and_condition_accept"
                                name="terms_and_condition_accept"
                                type="checkbox"
                                onChange={handleRegNameChange}
                              />{" "}
                              I have read and agree the
                              <NavLink
                                to={"/termsAndConditions"}
                                target="_blank"
                              >
                                {" "}
                                Terms & Conditions
                              </NavLink>
                            </label>
                            <div className="name_error text-danger">
                              {formErrors.terms_and_condition_accept}
                            </div>
                          </div>
                          {error &&
                          <div className="name_error text-danger">
                          {errorMessage}
                        </div>
                          }
                          <div className="form-group text-center mb-0">
                            <button
                              id="submit_btn"
                              type="submit"
                              className="btn account-btn hover-btn"
                            >
                              Create a new account
                            </button>
                            <div className="form-group text-center mt-3">or</div>
                            <div className="social-login">
                            <button type="button" className="btn">
                              <img
                                className="img-fluid"
                                src={gsignupicon}
                                style={{ maxHeight: "40px" }}
                                alt=""
                                onClick={signUpWithGoogle}
                              />
                            </button>
                            <button type="button" className="btn">
                                <img
                                  style={{ maxHeight: "40px" }}
                                  className="img-fluid"
                                  src={appelsignup}
                                  alt=""
                                  onClick={signUpWithApple}
                                />
                              </button>
                            </div>
                          </div>
                          <div className="form-group hidden d-none">
                            <label> </label>
                            <input
                              id="tokenref"
                              type="hidden"
                              className="form-control signin-form"
                              name="tokenref"
                              value={regRefToken}
                            />
                            <div className="tokenref_error"></div>
                          </div>
                        </form>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className={signinFormClass} id="user">
                      <form
                        name="form"
                        className="form-signin"
                        method="GET"
                        onSubmit={handleSubmit}
                      >
                        <div className="signin-conent-wrapper">
                          <div className="form-group">
                            <label>
                              Email <span className="required">*</span>
                            </label>
                            <input
                              type="email"
                              name="email"
                              value={emailValue}
                              className="form-control signin-form"
                              onChange={handleChange}
                            />
                            <input
                              type="hidden"
                              name="_token"
                              value="FBbQwFf2DmbwnlueNvqwZjDULKy5w9DwqGPKwXYO"
                            />
                          </div>
                          {/* <div className="name_error text-danger">{formErrors.email}</div> */}
                          <div className="form-group position-relative">
                            <label>
                              Password <span className="required">*</span>
                            </label>
                            <input
                              type={loginPasswordType}
                              name="password"
                              className="form-control signin-form"
                              value={pwdValue}
                              onChange={handlePwdChange}
                            />
                            <button
                              className="eye-btn"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setLoginPasswordType(
                                  loginPasswordType == "text"
                                    ? "password"
                                    : "text"
                                );
                              }}
                            >
                              <i
                                className={
                                  loginPasswordType == "password"
                                    ? "fas fa-eye-slash"
                                    : "far fa-eye"
                                }
                              ></i>
                            </button>
                          </div>
                          {/* <div className="name_error text-danger">{formErrors.password}</div> */}
                          <div className="form-group">
                            <label className="md-check">
                              <input
                                type="checkbox"
                                name="remember"
                                onChange={() => keepSignedChange("checked")}
                              />{" "}
                              <i className="primary"></i>{" "}
                              <span style={{ verticalAlign: "text-bottom" }}>
                                {" "}
                                Keep me signed in
                              </span>
                            </label>
                          </div>
                          <div className="name_error text-danger">
                            {genError}
                          </div>

                          <div className="form-group text-center">
                            <input
                              type="hidden"
                              name="_token"
                              value="FBbQwFf2DmbwnlueNvqwZjDULKy5w9DwqGPKwXYO"
                            />
                            <input type="hidden" value=" " name="redirect_to" />
                            <button
                              type="submit"
                              className="btn account-btn hover-btn"
                            >
                              Sign in
                            </button>
                          </div>
                          <div className="form-group text-center">or</div>
                          <div className="social-login">
                            <button type="button" className="btn">
                              <img
                                className="img-fluid"
                                src={gicon}
                                style={{ maxHeight: "40px" }}
                                alt=""
                                onClick={signInWithGoogle}
                              />
                            </button>
                            <button type="button" className="btn">
                              <img
                                style={{ maxHeight: "40px" }}
                                className="img-fluid"
                                src={appelsignin}
                                alt=""
                                onClick={signInWithApple}
                              />
                            </button>
                            {/* <button type="button" className="btn">
                              <img
                                className="img-fluid"
                                src={fbicon}
                                alt=""
                                onClick={signInWithFacebook}
                              />
                            </button> */}
                          </div>
                          <div className="text-center">
                            <a
                              href="/forgotPassword"
                              className="Forgot-password"
                            >
                              Forgot password?
                            </a>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="login-offer-grid">
                  <h2>
                    New user will get additional <span>offer</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
