import React from "react";
import { useSelector } from "react-redux/es/exports";
import SectionOffer from "../theme-four/section-offer";
import GoogleReviews from "../../googleReview";
import SectionOne from "./sectionOne";
import SectionTwo from "./sectionTwo";
import NearestShops from "../nearestShops";
function ThemeTweleve() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  let shopURL = window.location.href.split("/");
  
  return (
    <>
    <div className="home-tweleve">
      <SectionOne/>
      <SectionTwo/>
      {<NearestShops/>}
      </div>
    </>
  );
}

export default ThemeTweleve;
