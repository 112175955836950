import React from 'react'
import { easytoimg2,easytoimg3,easytoimg4,service1,service2,service3,service4,service5 } from '../../assets/img'
import "./laundryService.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

function LaundryService() { 
  let shopURL = window.location.href;
  return (
    <>
    {shopURL?.includes("londonslaundry") ? (
      <>
        <div className="easelaundry-wrapper">
        
        <div className="container-fluid">
        <div className="row">
        <div className="col-lg-6">
        <div data-aos="zoom-in-up" className="easelaundry-content-left text-start aos-init">
        <div className="section-title text-start">
        <h1 className="text-start">Ease to Made Laundry</h1>
        </div>
        <div className="">
        <p>The need of dry cleaning and laundries are increasing day by day. Customers who look for dry cleaning and laundry services at London, they can use our professional services to make effective and quick laundry services. We offer free collection along with fastest delivery services across the London through London's Laundry App. Customers who are seeking the dry cleaning and laundry to be utilized our services. We have the specialist team who collect your clothes at your place directly and at right time that you have selected. The team of London's Laundry App will wash, dry clean, iron, fold, pack your clothes perfectly and deliver your clothes within 24 hours!!!</p>
        <p>The additional services are shoe repairs and alterations and more along with our customized services. Customers can utilize our benefits and enjoy our services which are very wonder to you all. It is near to you and cost effective services. “Don’t wait, Approach us and Get effective services”. London's Laundry App is available all the day to help for you.</p>
        </div>
        </div>
        </div>
        <div className="col-lg-6 p-0">
        <div className="easelaundry-img-right aos-init" data-aos="zoom-in-up">
        <img src={easytoimg2} className="img-fluid aos-init"  alt="" data-aos="zoom-in-up" />
        </div>
        </div>
        </div>
        
        </div>
        
            </div>
      </>
    ) : (
      <>
      <div className="our-service-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center aos-init mb-5" data-aos="zoom-in-up">
                        <h1 className="mb-2">Our Services</h1>
                    </div>
                </div>
            </div>
            <div className="our-service-inner aos-init" data-aos="zoom-in-up">
                
                <div className="our-service-wrapper">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="service-content">
                                <h3>Welcome to Madhinah Dry cleaning</h3>
                                <p className="show-more">Let us turn your laundry nightmare into a more pleasant experience with a range of quality laundry solutions. Whether you need help with your washing and ironing for your home or business we can offer a convenient and competitively priced service. Get your clothes looking crisp and fresh with our professional ironing service. To make your life even easier we can also pick up your dirty clothes and drop off your freshly washed, folded and ironed laundry. Did you know that the average family can spend up to 8-9 hours per week doing laundry? That’s a lot of time spent sorting, washing, drying, folding and ironing clothes! And that’s not to mention the “difficult” items such as sports gear or duvets. For fast, convenient laundry services contact us.</p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <Swiper
                                spaceBetween={15}
                                navigation={false}
                                autoplay = {true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                    },
                                }}
                                className="hover-offer"
                            >
                                <SwiperSlide>
                                    <img src={service1} alt="services" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={service2} alt="services" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={service3} alt="services" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={service4} alt="services" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={service5} alt="services" />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </>
    )
  }
    
    </>
  )
}

export default LaundryService;