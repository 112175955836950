import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { data } from "../data";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {
  trustimg,
  trustimg2,
  trustimg3,
  trustimg4,
  priorityicon7,
  priorityicon8,
  priorityicon9,
} from "../../../assets/img";
import { fetchHomeJsonList, saveThemeJsonData, fetchCroppedImage } from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";
import ImageCropper from "../../imageCropper/imageCropper";

function SectionTenThemeThreeBlack() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const [editedSection2Data, setEditedSection2Data] = useState<any>(editData);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const [cardActiveTab, setCardActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);

  const icons = [
    "assets/home/trustimg.jpg",
    "assets/home/trustimg.png",
    "assets/home/trustimg2.jpg",
    "assets/home/trustimg3.png",
    "assets/home/trustimg4.jpg",
    "assets/home/trustimg.jpg",
    "assets/home/trustimg.png",
    "assets/home/trustimg2.jpg",
    "assets/home/trustimg3.png",
    "assets/home/trustimg4.jpg",
  ];

  const addCollection = () => {
    let updatedCollection: any = [...editData.theme_10.home.section_10.list_Section];

    let newCard = {
      text: "",
      paragraph: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        ...prevJsonData.theme_10,
      home: {
        ...prevJsonData.theme_10.home,
        section_10: {
          ...prevJsonData.theme_10.home.section_10,
          list_Section: updatedCollection,
        },
      },
    },
    }));
  };
  const removeCollection = (index: any) => {
    let updatedCollection: any = [...editData.theme_10.home.section_10.list_Section];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        ...prevJsonData.theme_10,
      home: {
        ...prevJsonData.theme_10.home,
        section_10: {
          ...prevJsonData.theme_10.home.section_10,
          list_Section: updatedCollection,
        },
      },
    },
    }));
  };

  const addCardCollection = () => {
    let updatedCollection: any = [...editData.theme_10.home.section_10.card_section];

    let newCard = {
      card_text: "",
      card_paragraph: "",
      card_bg_color: "192335",
    };
    updatedCollection.splice(cardActiveTab + 1, 0, newCard);
    setCardActiveTab(cardActiveTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        ...prevJsonData.theme_10,
      home: {
        ...prevJsonData.theme_10.home,
        section_10: {
          ...prevJsonData.theme_10.home.section_10,
          card_section: updatedCollection,
        },
      },
    },
    }));
  };
  const removeCardCollection = (index: any) => {
    let updatedCollection: any = [...editData.theme_10.home.section_10.card_section];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        ...prevJsonData.theme_10,
      home: {
        ...prevJsonData.theme_10.home,
        section_10: {
          ...prevJsonData.theme_10.home.section_10,
          card_section: updatedCollection,
        },
      },
    },
    }));
  };

  const handleChange = (e: any, index?: any) => {
    const { value, name, text, paragraph } = e.target;

    let updatedCollection: any = [...editData.theme_10.home.section_10.list_Section];
    if (index != undefined) {
      updatedCollection = [...editData.theme_10.home.section_10.list_Section];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        ...prevJsonData.theme_10,
      home: {
        ...prevJsonData.theme_10.home,
        section_10: {
          ...prevJsonData.theme_10.home.section_10,
          [name]: value,
          list_Section: updatedCollection,
        },
      },
    },
    }));
  };
  const handleCardChange = (e: any, index?: any) => {
    const { value, name, text, paragraph } = e.target;

    let updatedCollection: any = [...editData.theme_10.home.section_10.card_section];
    if (index != undefined) {
      updatedCollection = [...editData.theme_10.home.section_10.card_section];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        ...prevJsonData.theme_10,
      home: {
        ...prevJsonData.theme_10.home,
        section_10: {
          ...prevJsonData.theme_10.home.section_10,
          [name]: value,
          card_section: updatedCollection,
        },
      },
    },
    }));
  };

  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  useEffect(() => {
    // Log the updated JSON data whenever it changes
  }, [editedSection2Data]);

  const handleImageChange = (image: string) => {
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        ...prevJsonData.theme_10,
      home: {
        ...prevJsonData.theme_10.home,
        section_10: {
          ...prevJsonData.theme_10.home.section_10,
          banner: image,
        },
      },
    },
    }));
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if(imageLink != false){
      handleImageChange(imageLink)
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Section10 Theme Edit
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              {/* <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_10?.home?.section_10?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sub_heading_top"
                      value={editData?.theme_10?.home?.section_10?.sub_heading_top}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="mainparagraph"
                      value={editData?.theme_10?.home?.section_10?.mainparagraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_10?.home?.section_10?.h2color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_10?.home?.section_10?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_10?.home?.section_10?.h3color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_10?.home?.section_10?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_10?.home?.section_10?.paracolor
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_10?.home?.section_10?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">Card Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="cardcolor"
                        value={
                          editData?.theme_10?.home?.section_10?.cardcolor
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="cardcolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_10?.home?.section_10?.cardcolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="modal-title">Banner</h5>
            <div className="tab-content">
              <div className="tab-pane fade show active">
                <div className="row">
                  <div className="col-md-12">
                    <div className="img-bg active">
                      <img
                        src={editData?.theme_10?.home?.section_10?.banner}
                        alt="Card Icon"
                      />
                    </div>
                    <div className="img-options w-100">
                      <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                          640: {
                            slidesPerView: 1,
                          },
                          768: {
                            slidesPerView: 4,
                          },
                          1024: {
                            slidesPerView: 6,
                          },
                        }}
                        className="homejson-slider"
                      >
                        {icons.map((icons, iconIndex) => (
                          <SwiperSlide key={iconIndex}>
                            <div
                              className="img-option"
                              onClick={() => handleImageChange(icons)}
                            >
                              <img
                                src={icons}
                                alt="Hero Image"
                                className="img-fluid"
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
            <h5 className="modal-title">List Section</h5>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_10?.home?.section_10?.list_Section.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            List Section {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_10?.home?.section_10?.list_Section.map(
                  (item: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row"></div>
                      <div className="mb-3">
                        <label className="form-label">
                          Text<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="text"
                          value={item.text}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={250}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Paragraph</label>
                        <input
                          type="text"
                          className="form-control"
                          name="paragraph"
                          value={item.paragraph}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={1200}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>

            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Card Section Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="card_section_title"
                      value={editData?.theme_10?.home?.section_10?.card_section_title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>
            </div>
            <h5 className="modal-title">Card Section</h5>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_10?.home?.section_10?.card_section.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              cardActiveTab === index ? "active" : ""
                            }`}
                            onClick={() => setCardActiveTab(index)}
                          >
                            Card Section {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCardCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_10?.home?.section_10?.card_section.map(
                  (item: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        cardActiveTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row"></div>
                      <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="card_text"
                          value={item.card_text}
                          onChange={(e) => handleCardChange(e, index)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Paragraph</label>
                        <input
                          type="text"
                          className="form-control"
                          name="card_paragraph"
                          value={item.card_paragraph}
                          onChange={(e) => handleCardChange(e, index)}
                          maxLength={1200}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Background</label>
                        <div className="input-group color-change">
                          <input
                            type="text"
                            className="form-control"
                            name="card_bg_color"
                            value={item.card_bg_color}
                            onChange={(e) => handleCardChange(e, index)}
                            maxLength={250}
                          />
                          <ColorPicker
                            name="card_bg_color"
                            onChange={(e) => {
                              handleCardChange(e, index);
                              saveJsonDataToFile();
                            }}
                            format="hex"
                            value={item.card_bg_color}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCardCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default SectionTenThemeThreeBlack;
