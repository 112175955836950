import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { ttw1,ttw2,ttw3,ttw4,ttw5,ttw6,ttw7,ttw8,ttw9,ttw10,ttw11,ttw12,ttw13,ttw14,ttw15,ttw16,ttw17,ttw18,ttw19,ttw20,ttw21,ttw22,ttw23,ttw24,ttw25,ttw26,ttw27,ttw28,ttw29,ttw30,ttw31,ttw32,ttw33,ttw34,ttw35,ttw36,ttw37,ttw39,ttw40,ttw41,ttw42 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";

function SectionFour () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <section className="secpb1">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="secimg">
                            <img src={ttw8} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="secinfoflex">
                            <div className="secinfogrid">
                                <span className="subh6">Let your shoes breathe easy</span>
                                <h2>Your Shoe's VIP Treatment: Designed for You</h2>
                                <p>Experience luxury shoe care with our VIP treatment, designed exclusively for your footwear needs. Our service offers unparalleled care, ensuring every detail is perfected to pamper your footwear with the utmost attention and precision.</p>
                                <a className="btn btnprime" href="#secidhww">See How it Works!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secbg1">
            <div className="container">
                <div className="row">                    
                    <div className="col-md-6 order-last order-md-first">
                        <div className="secinfoflex">
                            <div className="secinfogrid">
                                <span className="subh6">72H TURNAROUND TIME</span>
                                <h2>No need to plan in advance</h2>
                                <ul className="hpointlist2">
                                    <li>
                                        <span className="secavatar2"><img src={ttw10} alt="" /></span>
                                        <p>Schedule a collection today</p>
                                    </li>
                                    <li>
                                        <span className="secavatar2"><img src={ttw11} alt="" /></span>
                                        <p>Get your shoes back in 72h</p>
                                    </li>
                                </ul>
                                <a className="btn btnprime" href="#secidsr">Get Started !</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 order-first order-md-lst">
                        <div className="secimg">
                            <img src={ttw9} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secpy1">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="secimg">
                            <img src={ttw12} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="secinfoflex">
                            <div className="secinfogrid">
                                <span className="subh6">24/7 CUSTOMER SUPPORT</span>
                                <h2>We're here for you</h2>
                                <p>We are dedicated to providing exceptional support and assistance whenever you need it. Our team is committed to ensuring your satisfaction and resolving any queries promptly. Your needs are our priority.</p>
                                <a className="btn btnprime" href="#secidsr">Get a Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionFour;
