import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCroppedImage,
  fetchHomeJsonList,
  saveThemeJsonData,
} from "../../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ImageCropper from "../../imageCropper/imageCropper";
import CreatableSelect from "react-select/creatable";
import { data } from "./../data";

const SectionThreeThemeTwoEdit = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  // const jsonData = data;
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabTwo, setActiveTabTwo] = useState(0);
  const [activeTabThree, setActiveTabThree] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const images = [
    "assets/img/home/wing1.png",
    "assets/img/home/wing2.png",
    "assets/img/home/wing3.png",
    "assets/img/home/wing4.png",
    "assets/img/home/wing1.png",
    "assets/img/home/wing2.png",
    "assets/img/home/wing3.png",
    "assets/img/home/wing4.png",
  ];

  const addCollection = () => {
    setActiveTabTwo(activeTabTwo + 1);
    setEditData((prevJsonData: any) => {
      const updatedCards = [...prevJsonData.theme_2.home.section_3.cards];
      const activeCard = updatedCards[activeTab];
      const newServiceItem = { id: (activeCard.card_options.length + 1).toString(), list: "" };
  
      const newIndex = activeTabTwo + 1;

    const updatedCardOptions = [
      ...activeCard.card_options.slice(0, newIndex),
      newServiceItem,
      ...activeCard.card_options.slice(newIndex)
    ];
  
      const updatedActiveCard = {
        ...activeCard,
        card_options: updatedCardOptions,
      };
  
      updatedCards[activeTab] = updatedActiveCard;
  
      return {
        ...prevJsonData,
        theme_2: {
          ...prevJsonData.theme_2,
          home: {
            ...prevJsonData.theme_2.home,
            section_3: {
              ...prevJsonData.theme_2.home.section_3,
              cards: updatedCards,
            },
          },
        },
      };
    });
  };

  const removeCollection = (cardIndex: number, checklistIndex: number) => {
    setActiveTabTwo(activeTabTwo - 1);
    setEditData((prevJsonData: any) => {
      const updatedCards = [...prevJsonData.theme_2.home.section_3.cards];
      const activeCard = updatedCards[cardIndex];
      
      const updatedCard = activeCard.card_options.filter((_:any, index:any) => index !== checklistIndex);
  
      const updatedActiveCard = {
        ...activeCard,
        card_options: updatedCard,
      };
  
      updatedCards[cardIndex] = updatedActiveCard;
  
      return {
        ...prevJsonData,
        theme_2: {
          ...prevJsonData.theme_2,
          home: {
            ...prevJsonData.theme_2.home,
            section_3: {
              ...prevJsonData.theme_2.home.section_3,
              cards: updatedCards,
            },
          },
        },
      };
    });
  };

  const addServiceCollection = () => {
    setActiveTabThree(activeTabThree + 1);
    setEditData((prevJsonData: any) => {
      const updatedCards = [...prevJsonData.theme_2.home.section_3.cards];
      const activeCard = updatedCards[activeTab];
      const newServiceItem = { id: (activeCard.services.length + 1).toString(), list: "" };
  
      const newIndex = activeTabThree + 1;

    const updatedServices = [
      ...activeCard.services.slice(0, newIndex),
      newServiceItem,
      ...activeCard.services.slice(newIndex)
    ];
  
      const updatedActiveCard = {
        ...activeCard,
        services: updatedServices,
      };
  
      updatedCards[activeTab] = updatedActiveCard;
  
      return {
        ...prevJsonData,
        theme_2: {
          ...prevJsonData.theme_2,
          home: {
            ...prevJsonData.theme_2.home,
            section_3: {
              ...prevJsonData.theme_2.home.section_3,
              cards: updatedCards,
            },
          },
        },
      };
    });
  };
  

  const removeServiceCollection = (cardIndex: number, serviceIndex: number) => {
    setActiveTabThree(activeTabThree - 1);
    setEditData((prevJsonData: any) => {
      const updatedCards = [...prevJsonData.theme_2.home.section_3.cards];
      const activeCard = updatedCards[cardIndex];
      
      const updatedServices = activeCard.services.filter((_:any, index:any) => index !== serviceIndex);
  
      const updatedActiveCard = {
        ...activeCard,
        services: updatedServices,
      };
  
      updatedCards[cardIndex] = updatedActiveCard;
  
      return {
        ...prevJsonData,
        theme_2: {
          ...prevJsonData.theme_2,
          home: {
            ...prevJsonData.theme_2.home,
            section_3: {
              ...prevJsonData.theme_2.home.section_3,
              cards: updatedCards,
            },
          },
        },
      };
    });
  };
  


  const handleHeaderChange = (e: any, index?: any, checklistIndex?: any, isService?: boolean) => {
    const { value, name } = e.target;
  
    setEditData((prevJsonData: any) => {
      const updatedCards = [...prevJsonData.theme_2.home.section_3.cards];
  
      if (index !== undefined) {

        if (isService) {
          updatedCards[index].services[checklistIndex][name] = value;
        } else if (checklistIndex !== undefined) {

          updatedCards[index].card_options[checklistIndex][name] = value;
        } else {

          updatedCards[index][name] = value;
        }
      }
  
      return {
        ...prevJsonData,
        theme_2: {
          ...prevJsonData.theme_2,
          home: {
            ...prevJsonData.theme_2.home,
            section_3: {
              ...prevJsonData.theme_2.home.section_3,
              [name]: value,
              cards: updatedCards,
            },
          },
        },
      };
    });
  };
  

  const saveJsonData = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("300");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink);
    }
  };
  const handleImageChange = (image: string) => {
    let updated_card_section = [...editData.theme_2.home.section_3.cards];
    updated_card_section[activeTab].bg_img = image;
    //Update the banner value in the JSON data
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2: {
        ...prevJsonData.theme_2,
        home: {
          ...prevJsonData.theme_2.home,
          section_3: {
            ...prevJsonData.theme_2.home.section_3,
            cards: updated_card_section,
          },
        },
      },
    }));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Three Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
            <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_2?.home?.section_3?.h2color
                        }
                        onChange={handleHeaderChange}
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.section_3?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_2?.home?.section_3?.h3color
                        }
                        onChange={handleHeaderChange}
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.section_3?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_2?.home?.section_3?.paracolor
                        }
                        onChange={handleHeaderChange}
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.theme_2?.home?.section_3?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="edit-section-hdr">Cards</h4>
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_2?.home?.section_3?.cards?.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={1}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Card {index + 1}
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_2?.home?.section_3?.cards?.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.bg_img}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {images.map((icons, iconIndex) => (
                                <SwiperSlide key={iconIndex}>
                                  <div
                                    className="img-option"
                                    onClick={() => {
                                      handleImageChange(icons);
                                    }}
                                  >
                                    <img
                                      src={icons}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">Card Title</label>
                            <input
                              type="text"
                              className="form-control"
                              name="card_title"
                              value={card.card_title}
                              onChange={handleHeaderChange}
                              maxLength={250}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">Card Paragraph</label>
                            <input
                              type="text"
                              className="form-control"
                              name="card_paragraph"
                              value={card.card_paragraph}
                              onChange={handleHeaderChange}
                              maxLength={250}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                          <ul className="nav nav-tabs">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 2,
                                  },
                                  1024: {
                                    slidesPerView: 3,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {card.services.map(
                                  (checklist: any, checklistIndex: any) => (
                                    <SwiperSlide key={checklistIndex}>
                                      <li
                                        key={checklistIndex}
                                        className="nav-item nav-option"
                                      >
                                        <a
                                          className={`nav-link ${
                                            activeTabThree === checklistIndex
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            setActiveTabThree(checklistIndex)
                                          }
                                        >
                                          Service List {checklistIndex + 1}
                                        </a>
                                        <a
                                          className="tab-close"
                                          onClick={() => {
                                            removeServiceCollection(
                                              index,
                                              checklistIndex
                                            );
                                          }}
                                        >
                                          <i
                                            className="fas fa-times"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </li>
                                    </SwiperSlide>
                                  )
                                )}
                              </Swiper>
                            </ul>
                            <div className="tab-content">
                              {card.services.map(
                                (checklist: any, checklistIndex: any) => (
                                  <div
                                    key={checklistIndex}
                                    className={`tab-pane fade ${
                                      activeTabThree === checklistIndex
                                        ? "show active"
                                        : ""
                                    }`}
                                  >
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Service List<small>(max 250 char)</small>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="list"
                                        value={checklist.list}
                                        onChange={(e) => handleHeaderChange(e, index, checklistIndex, true)}
                                        maxLength={250}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="add-section">
                        <div className="row">
                          <div
                            onClick={() => {
                              addServiceCollection();
                            }}
                            className="col text-center"
                          >
                            Add Card<i className="fas fa-plus-square ms-2"></i>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">
                              Card Main Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="card_main_title"
                              value={card.card_main_title}
                              onChange={handleHeaderChange}
                              maxLength={250}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">
                              Card Main Paragraph
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="card_main_paragraph"
                              value={card.card_main_paragraph}
                              onChange={handleHeaderChange}
                              maxLength={250}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">Checklist</label>
                            <ul className="nav nav-tabs">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 2,
                                  },
                                  1024: {
                                    slidesPerView: 3,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {card.card_options.map(
                                  (checklist: any, checklistIndex: any) => (
                                    <SwiperSlide key={checklistIndex}>
                                      <li
                                        key={checklistIndex}
                                        className="nav-item nav-option"
                                      >
                                        <a
                                          className={`nav-link ${
                                            activeTabTwo === checklistIndex
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            setActiveTabTwo(checklistIndex)
                                          }
                                        >
                                          Check List {checklistIndex + 1}
                                        </a>
                                        <a
                                          className="tab-close"
                                          onClick={() => {
                                            removeCollection(
                                              index,
                                              checklistIndex
                                            );
                                          }}
                                        >
                                          <i
                                            className="fas fa-times"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </li>
                                    </SwiperSlide>
                                  )
                                )}
                              </Swiper>
                            </ul>
                            <div className="tab-content">
                              {card.card_options.map(
                                (checklist: any, checklistIndex: any) => (
                                  <div
                                    key={checklistIndex}
                                    className={`tab-pane fade ${
                                      activeTabTwo === checklistIndex
                                        ? "show active"
                                        : ""
                                    }`}
                                  >
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Check List<small>(max 250 char)</small>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="list"
                                        value={checklist.list}
                                        onChange={(e) =>
                                          handleHeaderChange(
                                            e,
                                            index,
                                            checklistIndex
                                          )
                                        }
                                        maxLength={250}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="add-section">
                        <div className="row">
                          <div
                            onClick={() => {
                              addCollection();
                            }}
                            className="col text-center"
                          >
                            Add Card<i className="fas fa-plus-square ms-2"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">Upload Background Image</label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  className="form-control"
                  name="image"
                  onChange={updateImageSectionOne}
                />
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SectionThreeThemeTwoEdit;
