import React, { useEffect } from "react";
import { useSelector } from "react-redux/es/exports";
import SectionTwelveBannerThemeThree from "./SectionTwelveBannerThemeThree";
import SectionOneBannerThemeThree from "./SectionOneBannerThemeThree";
import SectionElevenThemeThree from "./SectionElevenThemeThree";
import SectionThirteenThemeThree from "./SectionThirteenThemeThree";
import SectionFifteenThemeThree from "./SectionFifteenThemeThree";
import SectionThreeThemeThree from "./sectionThreeThemeThree";
import SectionFourThemeThree from "./sectionFourThemeThree";
import SectionFiveThemeThree from "./sectionFiveThemeThree";
import SectionSixThemeThree from "./sectionSixThemeThree";
import SectionSevenThemeThree from "./sectionSevenThemeThree";
import SectionEightThemeThree from "./SectionEightThemeThree";
import SectionTenBannerThemeThree from "./SectionTenBannerThemeThree";
import TTSlider from "../../components/ttSlider";
import SectionNineThemeThree from "./sectionNineThemeThree";
import SectionOffer from "../theme-four/section-offer";
import GoogleReviews from "../googleReview";
import HomeShopList from "../homeShopList";
import { checkShopListFlow, setValue } from '../../utility';
import DailyDeals from "../dailyDeals";
import { useParams } from "react-router-dom";

function ThemeThree() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const checkIsSectionEnabled = (index: any) => {
    if (settingsData?.template == 1) {
      return jsonData?.theme_1?.home?.sections[index]?.is_section_enable;
    }
    if (settingsData?.template == 3) {
      return jsonData?.theme_3?.home?.sections[index]?.is_section_enable;
    }
    if (settingsData?.template == 5) {
      return jsonData?.theme_5?.home?.sections[index]?.is_section_enable;
    }
  };

  var metaTitle = "";
  var metaDescription = "";

  useEffect(() => {
    if (window.location.href.includes('#service-section')) {
      metaTitle = settingsData?.WebsiteSettings?.site_title_en + " Services | Professional Laundry and Dry Cleaning Services in London";
      metaDescription = "Explore the range of professional laundry and dry cleaning services offered by "+ settingsData?.WebsiteSettings?.site_title_en +" in London. Book your services online today!";
      document.title = metaTitle;
      document.getElementById("title")?.setAttribute("content", metaTitle);
      document.getElementById("description")?.setAttribute("content", metaDescription);
    }else {
      metaTitle = settingsData?.WebsiteSettings?.site_title_en + " | Laundry and Dry Cleaning Services in London";
      metaDescription = settingsData?.WebsiteSettings?.site_title_en + " provides professional laundry and dry cleaning services in London. Book your laundry and dry cleaning services conveniently online today!";
      document.title = metaTitle;
      document.getElementById("title")?.setAttribute("content", metaTitle);
      document.getElementById("description")?.setAttribute("content", metaDescription);
    }
  }, []);

  return (
    <>
      {/* Theme Three */}
      {settingsData?.template == 3 && (
        <>
          {(settingsData?.WebsiteSettings?.site_title_en == "T&T" ||
            settingsData?.WebsiteSettings?.site_title_en == "TT") && (
            <TTSlider />
          )}

          {checkIsSectionEnabled(0) && <SectionOneBannerThemeThree />} 
          {/* {checkIsSectionEnabled(1) && <SectionTwoBannerThemeThree />} */}
          {/* <SectionOffer /> */}
          {checkShopListFlow() && <HomeShopList/>}
          {checkShopListFlow() && <DailyDeals/>}
          <GoogleReviews />
          {checkIsSectionEnabled(1) && <SectionThreeThemeThree />}
          {checkIsSectionEnabled(2) && <SectionFourThemeThree />}
          {checkIsSectionEnabled(3) && <SectionFiveThemeThree />}
          {checkIsSectionEnabled(4) && <SectionSixThemeThree />}
          {checkIsSectionEnabled(5) && <SectionSevenThemeThree />}
          {checkIsSectionEnabled(6) && <SectionEightThemeThree />}
          {checkIsSectionEnabled(7) && <SectionNineThemeThree />}
          {checkIsSectionEnabled(8) && <SectionTenBannerThemeThree />}
          {checkIsSectionEnabled(9) && <SectionElevenThemeThree />}
          {checkIsSectionEnabled(10) && <SectionTwelveBannerThemeThree />}
          {checkIsSectionEnabled(11) && <SectionThirteenThemeThree />}
          {/* 14 need to confirm */}
          {checkIsSectionEnabled(12) && <SectionFifteenThemeThree />}
        </>
      )}
    </>
  );
}

export default ThemeThree;
