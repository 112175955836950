import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorPicker } from "primereact/colorpicker";
import { saveThemeJsonData, setShopHomeCms } from "../../../../redux/Actions";

const HeaderTenEdit = () => {
  const dispatch = useDispatch<any>();
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const [editedHeaderData, setHeaderJsonData] = useState<any>();
  const saveHeaderJsonDataToFile = () => {
    dispatch(setShopHomeCms(editedHeaderData));
    dispatch(saveThemeJsonData(editedHeaderData));
  };
  const handleHeaderChange = (e: any) => {
    const { value, name, type, checked } = e.target;
    setHeaderJsonData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        home: {
          ...prevJsonData.theme_10.home,
          header: {
            ...prevJsonData.theme_10.home.header,
            [name]: value,
            nav: {
              ...prevJsonData.theme_10.home.header.nav,
              [name]: value,
              cart_section: {
                ...prevJsonData.theme_10.home.header.nav.cart_section,
                bg_color:
                  name === "cartbg_color"
                    ? value
                    : prevJsonData.theme_10.home.header.nav.cart_section
                        .bg_color,
                [name]: value,
                cart_label_section: {
                  ...prevJsonData.theme_10.home.header.nav.cart_section
                    .cart_label_section,
                  [name]: value,
                },
              },
            },
            btn_group: {
              ...prevJsonData.theme_10.home.header.btn_group,
              btn1: {
                ...prevJsonData.theme_10.home.header.btn_group.btn1,
                text:
                  name === "headerbtn1"
                    ? value
                    : prevJsonData.theme_10.home.header.btn_group.btn1.text,
                bg_color:
                  name === "headerbtn1bgcolor"
                    ? value
                    : prevJsonData.theme_10.home.header.btn_group.btn1.bg_color,
                color:
                  name === "headerbtn1color"
                    ? value
                    : prevJsonData.theme_10.home.header.btn_group.btn1.color,
                border_color:
                  name === "headerbtn1border"
                    ? value
                    : prevJsonData.theme_10.home.header.btn_group.btn1
                        .border_color,
                btn1_is_enable:
                  type === "checkbox" && name === "btn1_is_enable"
                    ? !prevJsonData.theme_10.home.header.btn_group.btn1
                        .btn1_is_enable
                    : prevJsonData.theme_10.home.header.btn_group.btn1
                        .btn1_is_enable,
              },
              btn2: {
                ...prevJsonData.theme_10.home.header.btn_group.btn2,
                text:
                  name === "headerbtn2"
                    ? value
                    : prevJsonData.theme_10.home.header.btn_group.btn2.text,
                bg_color:
                  name === "headerbtn2bgcolor"
                    ? value
                    : prevJsonData.theme_10.home.header.btn_group.btn2.bg_color,
                color:
                  name === "headerbtn2color"
                    ? value
                    : prevJsonData.theme_10.home.header.btn_group.btn2.color,
                border_color:
                  name === "headerbtn2border"
                    ? value
                    : prevJsonData.theme_10.home.header.btn_group.btn2
                        .border_color,
                btn2_is_enable:
                  type === "checkbox" && name === "btn2_is_enable"
                    ? !prevJsonData.theme_10.home.header.btn_group.btn2
                        .btn2_is_enable
                    : prevJsonData.theme_10.home.header.btn_group.btn2
                        .btn2_is_enable,
              },
            },
          },
        },
      },
    }));
  };
  useEffect(() => {
    setHeaderJsonData(jsonData);
  }, [jsonData]);
  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Header Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <h4 className="edit-section-hdr">Navigation Section </h4>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Nav Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="bg_color"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav?.bg_color
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="bg_color"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Menu Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="menu_font_color"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.menu_font_color
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="menu_font_color"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.menu_font_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Menu hover</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="menu_font_color_hover"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.menu_font_color_hover
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="menu_font_color_hover"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.menu_font_color_hover
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Menu Active</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="menu_font_color_active"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.menu_font_color_active
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="menu_font_color_active"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.menu_font_color_active
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Button 1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="headerbtn1"
                      value={
                        editedHeaderData?.theme_10?.home?.header?.btn_group.btn1
                          ?.text
                      }
                      onChange={handleHeaderChange}
                      maxLength={250}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="headerbtn1bgcolor"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.btn_group
                            ?.btn1?.bg_color
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="headerbtn1bgcolor"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.btn_group
                            ?.btn1?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="headerbtn1color"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.btn_group
                            .btn1?.color
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="headerbtn1color"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.btn_group
                            ?.btn_group?.btn1?.color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">border</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="headerbtn1border"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.btn_group
                            .btn1?.border_color
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="headerbtn1border"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.btn_group
                            ?.btn1?.border_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mb-3">
                    <label className="form-label">&nbsp;</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="btn1_is_enable"
                        onClick={handleHeaderChange}
                        checked={
                          editedHeaderData?.theme_10?.home?.header?.btn_group
                            ?.btn1.btn1_is_enable
                        }
                      />
                    </div>
                    {
                      editedHeaderData?.theme_10?.home?.header?.btn_group?.btn1
                        ?.btn1_is_enable
                    }
                  </div>
                </div>
              </div>
              <div className="edit-section">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Button 2</label>
                      <input
                        type="text"
                        className="form-control"
                        name="headerbtn2"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.btn_group
                            .btn2?.text
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Background</label>
                      <div className="input-group color-change">
                        <input
                          type="text"
                          className="form-control"
                          name="headerbtn2bgcolor"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.btn_group
                              .btn2?.bg_color
                          }
                          onChange={handleHeaderChange}
                          maxLength={250}
                        />
                        <ColorPicker
                          name="headerbtn2bgcolor"
                          onChange={(e) => {
                            handleHeaderChange(e);
                          }}
                          format="hex"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.btn_group
                              ?.btn2?.bg_color
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Color</label>
                      <div className="input-group color-change">
                        <input
                          type="text"
                          className="form-control"
                          name="headerbtn2color"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.btn_group
                              .btn2?.color
                          }
                          onChange={handleHeaderChange}
                          maxLength={250}
                        />
                        <ColorPicker
                          name="headerbtn2color"
                          onChange={(e) => {
                            handleHeaderChange(e);
                          }}
                          format="hex"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.btn_group
                              ?.btn2?.color
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Border</label>
                      <div className="input-group color-change">
                        <input
                          type="text"
                          className="form-control"
                          name="headerbtn2border"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.btn_group
                              .btn2?.border_color
                          }
                          onChange={handleHeaderChange}
                          maxLength={250}
                        />
                        <ColorPicker
                          name="headerbtn2border"
                          onChange={(e) => {
                            handleHeaderChange(e);
                          }}
                          format="hex"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.btn_group
                              ?.btn2?.border_color
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="mb-3">
                      <label className="form-label">&nbsp;</label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="btn2_is_enable"
                          onClick={handleHeaderChange}
                          checked={
                            editedHeaderData?.theme_10?.home?.header?.btn_group
                              ?.btn2?.btn2_is_enable
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <h4 className="edit-section-hdr">Nav Menu Cart Section </h4>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Cart text</label>
                    <input
                      type="text"
                      className="form-control"
                      name="label"
                      value={
                        editedHeaderData?.theme_10?.home?.header?.nav
                          ?.cart_section.cart_label_section?.label
                      }
                      onChange={handleHeaderChange}
                      maxLength={250}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.cart_section?.cart_label_section?.color
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="color"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.cart_section?.cart_label_section?.color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Cart BG</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="cartbg_color"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.cart_section?.bg_color
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="cartbg_color"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.cart_section?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Cart icon BG</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="cart_icon_bg_color"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.cart_section?.cart_icon_bg_color
                        }
                        onChange={handleHeaderChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="cart_icon_bg_color"
                        onChange={(e) => {
                          handleHeaderChange(e);
                        }}
                        format="hex"
                        value={
                          editedHeaderData?.theme_10?.home?.header?.nav
                            ?.cart_section?.cart_icon_bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Cart icon Color</label>
                      <div className="input-group color-change">
                        <input
                          type="text"
                          className="form-control"
                          name="cart_icon_color"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.nav
                              ?.cart_section?.cart_icon_color
                          }
                          onChange={handleHeaderChange}
                          maxLength={250}
                        />
                        <ColorPicker
                          name="cart_icon_color"
                          onChange={(e) => {
                            handleHeaderChange(e);
                          }}
                          format="hex"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.nav
                              ?.cart_section?.cart_icon_color
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Cart value Color</label>
                      <div className="input-group color-change">
                        <input
                          type="text"
                          className="form-control"
                          name="cart_value_color"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.nav
                              ?.cart_section?.cart_value_color
                          }
                          onChange={handleHeaderChange}
                          maxLength={250}
                        />
                        <ColorPicker
                          name="cart_value_color"
                          onChange={(e) => {
                            handleHeaderChange(e);
                          }}
                          format="hex"
                          value={
                            editedHeaderData?.theme_10?.home?.header?.nav
                              ?.cart_section?.cart_value_color
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveHeaderJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderTenEdit;
