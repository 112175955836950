import React from "react";
import {
  appdownload,
  appstore,
  googleplay,
} from "../../assets/img";
import { useSelector } from "react-redux";
import { data } from "../edit/data";

const SectionFiveThemeOne = () => {
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);
  // const jsonData = data
  const settingsData: any = useSelector<any>((state) => state?.settings);
  // HTML
  return (
    <>
      <section className="mobapp-section">
        <div className="mobapp-subtract-top"></div>
        <div className="mobapp-subtract-body">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="mobapp-content text-center">
                  <h2 className="section-title" style={{
                      color: `#${jsonData?.theme_1?.home?.section_5?.h2color}`
                    }}>
                    {jsonData.theme_1?.home?.section_5?.title}
                  </h2>
                  <p style={{
                      color: `#${jsonData?.theme_1?.home?.section_5?.paracolor}`
                    }}>{jsonData.theme_1?.home?.section_5?.paragraph}</p>
                  <ul className="applinks">
                    <li>
                      <a
                        target="_blank"
                        href={
                          jsonData.theme_1?.home?.section_5?.mobile_link_section
                            ?.ios_link
                        }
                      >
                        <img src={appstore} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href={
                          jsonData.theme_1?.home?.section_5?.mobile_link_section
                            ?.android_link
                        }
                      >
                        <img src={googleplay} alt="" />
                      </a>
                    </li>
                  </ul>

                  <ul className="appdata">
                  {jsonData.theme_1?.home?.section_5?.check_list.map(
              (items: any) => {
                return (
                  <li>
                    <img src={items.icons} alt="" />
                      <h4 style={{
                      color: `#${jsonData?.theme_1?.home?.section_5?.h3color}`
                    }}>{items.count}</h4>
                      <p style={{
                      color: `#${jsonData?.theme_1?.home?.section_5?.paracolor}`
                    }}>{items.title}</p>
                  </li>
                  );
                }
              )}

                  </ul>
                  {settingsData?.WebsiteSettings?.site_title_en.includes("Sasian") && (
                  <div className="sascallbtn py-5 text-center">
                    <a href="tel:+(44)7749300244" className="btn secondary-btn">Call Us</a>
                  </div>
                )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mobapp-img text-center">
                  <img src={jsonData?.theme_1?.home?.section_5?.banner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobapp-subtract-bottom"></div>
      </section>
    </>
  );
};

export default SectionFiveThemeOne;
