import React from "react";
import { useSelector } from "react-redux/es/exports";
import SectionOne from "./sectionOne";
import SectionTwo from "./sectionTwo";


function IhateIroning() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  

  return (
    <>
        <div className="home-ihateironing">
            <SectionOne/>
            <SectionTwo/>
        </div>
    </>
  );
}

export default IhateIroning;
