import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import { fetchGetShopByPinCode } from "../../redux/Actions/checkoutPageActions";
import DownloadApp from "../downloadApp";
import {
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  eco,
  priorityicon14,
  priorityicon15,
  priorityicon16,
  priorityicon17,
  appdownload,
  appstore,
  googleplay,
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
  trustimg,
  trustimg2,
  trustimg3,
  trustimg4,
  howwe1,
  howwe2,
  howwe3,
  howwe4,
} from "../../assets/img";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";
// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

function SectionEightThemeFour() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const base_url = process.env.REACT_APP_BACKEND_URL;

  // const base_url = "https://revamp.dreamguystech.com"

  // const fetchData = async () => {
  //     const base_url = "https://revamp.dreamguystech.com";
  //     try {
  //         const bodyFormData = new FormData();
  //         bodyFormData.append('keyword', 'Gu11');
  //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
  //         const response = await axios({
  //             method: "post",
  //             url: `${base_url}/api/get_postal_codes`,
  //             data: bodyFormData,
  //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
  //         })
  //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
  //         // const result = await response.then(response => response);

  //         if (response) {
  //             setPostalCodeList(response.data.data[0])
  //
  //         }
  //     } catch (error) {
  //
  //     }
  // }

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const [productList, setProductList] = useState<any | null>({});
  const [blogList, setBlogList] = useState<any | null>({});
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });

    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);

  const faqData: any = useSelector<any>((state) => state?.faq);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  // useEffect(() => {
  //     // fetchData();
  //     dispatch(fetchPostalCodes())
  //     // dispatch(fetchPostalCodes());
  // }, [])

  const handleChange = (e: any) => {
    const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm);
    // dispatch(fetchPostalCodes(searchTerm));
  };
  const handleSectionThreeThemeFour = async () => {
    const isValid = state.postalCodeList.some(
      (item: any) => item.postcode === postalCodeValue.toUpperCase()
    );
    setValue("postalCode", postalCodeValue);
    const results = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    );

    if (!results) {
      navigate("/areaNotCovered");
      return;
    }
    if (isValid) {
      navigate("/productLists");
    } else {
      navigate("/areaNotCovered");
    }
  };
  return (
    <>
      <style type="text/css">
        {` 
            .safety-section::after{
              background:#${jsonData?.theme_4?.home?.section_8?.part_two?.bg_color}
            }
          `}
      </style>
      <section className="safety-section">
        <div className="container">
          <div className="safety-info-grid">
            <div className="row">
              <div className="col-md-6">
                <div className="split-img">
                  <img
                    src={
                      jsonData?.theme_4?.home?.section_8?.part_one
                        ?.section_image
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-title text-start mt-5">
                  <h2 className="ps-0">
                    {jsonData?.theme_4?.home?.section_8?.part_one?.title}
                  </h2>
                  <h4>
                    {jsonData?.theme_4?.home?.section_8?.part_one?.sub_title}
                  </h4>
                  <p>
                    {jsonData?.theme_4?.home?.section_8?.part_one?.paragraph}
                  </p>
                </div>
                <div className="safety-content">
                  <ul>
                    {jsonData?.theme_4?.home?.section_8?.part_one?.card_section?.collection.map(
                      (item: any) => {
                        return (
                          <li>
                            <h3>{item.collection_title}</h3>
                            <p>{item.collection_paragraph}</p>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-3">
              <div className="safety-list-card-desc">
                <img src={eco} alt="" />
                <h3
                  className=""
                  style={{
                    color: `#${jsonData?.theme_4?.home?.section_8?.part_two?.text_color}`,
                  }}
                >
                  {jsonData?.theme_4?.home?.section_8?.part_two?.title}
                </h3>
                <p
                  className=""
                  style={{
                    color: `#${jsonData?.theme_4?.home?.section_8?.part_two?.text_color}`,
                  }}
                >
                  {jsonData?.theme_4?.home?.section_8?.part_two?.paragraph}
                </p>
              </div>
            </div>

            {jsonData?.theme_4?.home?.section_8?.part_two?.card_section?.collection.map(
              (item: any) => {
                return (
                  <>
                    <div className="col-md-3 d-flex">
                      <div className="safety-list-card safety-list-card-bg1">
                        <h3
                          style={{
                            color: `#${jsonData?.theme_4?.home?.section_8?.part_two?.text_color}`,
                          }}
                        >
                          {item.collection_title}
                        </h3>
                        <p
                          style={{
                            color: `#${jsonData?.theme_4?.home?.section_8?.part_two?.text_color}`,
                          }}
                        >
                          {item.collection_paragraph}
                        </p>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionEightThemeFour;
