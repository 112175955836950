import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { logowhiterose } from "../../../assets/img";
import { useDispatch, useSelector } from "react-redux";
import { checkShopListFlow } from "../../../utility";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload,
});

function FooterFourShop() {
  const navigate = useNavigate();
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const navigateToAboutUs = () => {
    if (checkShopListFlow()) {
      const shopName: any = localStorage.getItem("currentShopName");
      if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());

        navigate(`/shop/${finalResult}/aboutUs`);
      } else {
        navigate("/aboutUs");
      }
    } else {
      navigate("/aboutUs");
    }
  };

  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  return (
    <div>
      <style type="text/css">
        {` 
            .sh4-footer-sectionthree a {
              color: #${jsonData?.theme_4?.home?.footer?.section_paragraph_color} !important;
            }
            .sh4-footer-sectionfour li a {
              color: #${jsonData?.theme_4?.home?.footer?.section_paragraph_color} !important;
            }
            .sh4-footer h4 {
              color: #${jsonData?.theme_4?.home?.footer?.section_paragraph_color} !important;
            }
          `}
      </style>
      <footer
        className="sh4-footer"
        style={{
          background: `#${jsonData?.theme_4?.home?.footer?.bg_color}`,
          color: `#${jsonData?.theme_4?.home?.footer?.section_paragraph_color}`,
        }}
      >
        <div className="container">
          {settingsData?.WebsiteSettings?.site_title_en.includes(
            "Whiterose"
          ) && (
            <div className="row footer-sectionone text-center">
              <div className="col-md-12">
                <div className="sh4-logo-footer">
                  <img src={logowhiterose} alt="" />
                </div>
                {/* <p>{jsonData?.theme_4?.home?.footer?.text}</p> */}
              </div>
            </div>
          )}
          {/* <div className="row footer-sectiontwo">
            <div className="col-md-12"> */}
          {/* <div className="home-links">
                <ul>
                  <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}} className="nav-bar-link" to={'/aboutUs'}>About Us</NavLink></li>
                  <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}}  className="nav-bar-link" to={'/areaCovered'}>Area Covered</NavLink></li>

                  <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}}  className="nav-bar-link" to={'/faq'}>FAQ</NavLink></li>
                  <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}}  className="nav-bar-link" to={'/reviews'}>Reviews</NavLink></li>
                  <li><a target="_blank" href={blogUrl}>Blog</a></li>
                </ul>
              </div> */}

          {/* <div className="social-links">
                <div className='logo-footer'>
                  <img src={logo} alt="" />
                </div>
                <div className='app-social'>
                  <ul>
                    {settingsData != undefined && Object.keys(settingsData).length > 0 && 
                        settingsData?.WebmasterSettings.app_store_link !== '' && 
                      <li>
                        <a target='_blank' href={settingsData.WebmasterSettings.app_store_link}><img src={appstore} alt="" /></a>
                      </li>
                    }
                    {settingsData != undefined && Object.keys(settingsData).length > 0 && 
                        settingsData?.WebmasterSettings.play_store_link !== '' && 
                      <li>
                        <a target='_blank' href={settingsData.WebmasterSettings.play_store_link}><img src={googleplay} alt="" /></a>
                      </li>
                    }
                  </ul>
                </div>
                <div className="footer-social">
                  <ul className="list-inline">
                    {settingsData != undefined && Object.keys(settingsData).length > 0 &&
                      settingsData?.WebsiteSettings.social_link1 !== '' &&
                      <li><a className="foo-icon" target="_blank" href={facebook}><i className="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                    }
                    {settingsData != undefined && Object.keys(settingsData).length > 0 &&
                      settingsData?.WebsiteSettings.social_link6 !== '' &&
                      <li><a className="foo-icon" target="_blank" href={instagram}><i className="fab fa-instagram" aria-hidden="true"></i></a></li>
                    }
                    {settingsData != undefined && Object.keys(settingsData).length > 0 &&
                      settingsData?.WebsiteSettings.social_link2 !== '' &&
                      <li><a className="foo-icon" target="_blank" href={twitter}><i className="fab fa-twitter" aria-hidden="true"></i></a></li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row sh4-footer-sectionthree text-center">
            {settingsData?.WebsiteSettings?.site_title_en.includes(
              "Whiterose"
            ) && (
              <>
                <div className="col-md col-sm-12">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <a>Dry Cleaning</a>
                    </li>
                    <li>
                      <a>Shirt Service (Wash & Press)</a>
                    </li>
                    <li>
                      <a>Ironing Service</a>
                    </li>
                    <li>
                      <a>Duvet & Linen</a>
                    </li>
                    <li>
                      <a>Duvet, Pillow, Curtain and Soft furnishing</a>
                    </li>
                    <li>
                      <a>Bag Wash</a>
                    </li>
                    <li>
                      <a>Repairs and Alterations</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md col-sm-12">
                  <h4>Commercial Solutions</h4>
                  <ul>
                    <li>
                      <a>Hotel Guest Services</a>
                    </li>
                    <li>
                      <a>Hotel Staff Uniforms</a>
                    </li>
                    <li>
                      <a>Hotel Beddings, Towels, etc</a>
                    </li>
                    <li>
                      <a>Restaurants</a>
                    </li>
                    <li>
                      <a>Residential Complexes</a>
                    </li>
                    <li>
                      <a>Gyms and Spas</a>
                    </li>
                    <li>
                      <a>Retail</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md col-sm-12">
                  <h4>Help</h4>
                  <ul>
                    <li>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/Contactus");
                        }}
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href={blogUrl}>News & Articles</a>
                    </li>
                    <li>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/termsAndConditions");
                        }}
                      >
                        Terms and Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
            {!settingsData?.WebsiteSettings?.site_title_en.includes(
              "Whiterose"
            ) && (
              <>
                <div className="col-md col-sm-12">
                  <h4>Quick Links</h4>
                  <ul>
                    <li>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          navigateToAboutUs();
                        }}
                      >
                        About Us
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="javascript:void(0)"
                        data-bs-toggle="modal"
                        data-bs-target="#videomodal"
                      >
                        How it works
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="col-md col-sm-12">
                  <h4>Policies</h4>
                  <ul>
                    <li>
                      <NavLink to={"/termsAndConditions"}>
                        Terms of Use and Legal Info
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/privacyPolicy"}>Privacy Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/cookiePolicy"}>Cookie Preferences</NavLink>
                    </li>
                  </ul>
                  {/* <ul>
                <li>
                  <a href="">Lorem ipsum dolor</a>
                </li>
                <li>
                  <a href="">sit amet</a>
                </li>
                <li>
                  <a href="">consectetur adipiscing</a>
                </li>
                <li>
                  <a href="">Sed ut perspiciatis</a>
                </li>
                <li>
                  <a href="">At vero eos et</a>
                </li>
              </ul> */}
                  {/* <ul>
                <li><a href={`tel:${settingsData?.WebsiteSettings?.contact_t5}`}>{settingsData?.WebsiteSettings?.contact_t5}</a></li>
                <li><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`} className='primary-text'><i className='uil uil-envelopes me-2'></i>Email Us</a></li>
                <li><p>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t1_en}</p></li>                 
              </ul>*/}
                </div>
              </>
            )}
            {/* <div className="col-md col-sm-12">
              <h4>Help</h4>
              <ul>
                <li>
                  <a href="">Lorem ipsum dolor</a>
                </li>
                <li>
                  <a href="">sit amet</a>
                </li>
                <li>
                  <a href="">consectetur adipiscing</a>
                </li>
                <li>
                  <a href="">Sed ut perspiciatis</a>
                </li>
                <li>
                  <a href="">At vero eos et</a>
                </li>
              </ul>
            </div> */}
            {/* <div className="col-md col-sm-12">
              <div className="footer-two">
                <div
                  className="footerlist-two">
                  <h4>We are here for you..</h4>
                  <ul>
                    <li><span>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t1_en}</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Need Help?</h4>
                  <ul>
                    <li><span>Phone :</span><span><a href={`tel:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t5}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t5}</a></span></li>
                    <li><span>Email :</span><span><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}</a></span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-2 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Our Policy</h4>
                  <ul>
                    <li><NavLink to={"/termsAndConditions"}><i className="fas fa-angle-right me-1"></i>Conditions of use</NavLink></li>
                    <li><NavLink to={"/privacyPolicy"}><i className="fas fa-angle-right me-1"></i>Privacy Policy</NavLink></li>
                    <li><NavLink to={"/cookiePolicy"}><i className="fas fa-angle-right me-1"></i>Cookie Policy</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Quick Connect</h4>
                  <div
                    className="app-icons">
                    <ul
                      className="list-inline">
                      <li><a target={appStorel == ""? '' : "_blank"} href={appStorel == ""? 'javascript:;' : appStorel} className="hvr-float-shadow imggbg"><img src={appstore} className="img img-fluid" alt="appstore" /></a></li>
                      <li><a target={playStore == ""? '' : "_blank"} href={playStore == ""? 'javascript:;' : playStore} className="hvr-float-shadow imggbg2"><img src={googleplay} className="img img-fluid" alt="appstore" /></a></li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div> */}
          </div>
          <div className=" sh4-footer-sectionfour text-center">
            <div className="row">
              <div className="col-md col-sm-12">
                <h4>Connect with Us</h4>
                <ul className="sh4-footer-social">
                  {jsonData?.theme_4?.home?.footer?.social_media?.facebook
                    ?.is_enable && (
                    <li>
                      <a
                        href={
                          `https://${jsonData?.theme_4?.home?.footer?.social_media
                            ?.facebook?.facebook_link}`
                        }
                        target="_blank"
                      >
                        <i className="fab fa-facebook"></i>
                        {/* Facebook */}
                      </a>
                    </li>
                  )}
                  {jsonData?.theme_4?.home?.footer?.social_media?.instagram
                    ?.is_enable && (
                    <li>
                      <a
                        href={
                          `https://${jsonData?.theme_4?.home?.footer?.social_media
                            ?.instagram?.instagram_link}`
                        }
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                        {/* Instagram */}
                      </a>
                    </li>
                  )}
                  {jsonData?.theme_4?.home?.footer?.social_media?.twitter
                    ?.is_enable && (
                    <li>
                      <a
                        href={
                          `https://${jsonData?.theme_4?.home?.footer?.social_media?.twitter
                            ?.twitter_link}`
                        }
                        target="_blank"
                      >
                        <i className="fab fa-twitter"></i>
                        {/* Twitter */}
                      </a>
                    </li>
                  )}
                  {/* {jsonData?.theme_4?.home?.footer?.social_media?.whatsapp
                    ?.is_enable && (
                    <li>
                      <a
                        href={
                          jsonData?.theme_4?.home?.footer?.social_media
                            ?.whatsapp?.whatsapp_link
                        }
                        target="_blank"
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </li>
                  )} */}
                  {jsonData?.theme_4?.home?.footer?.social_media?.linkedin
                    ?.is_enable && (
                    <li>
                      <a
                        href={
                          `https://${jsonData?.theme_4?.home?.footer?.social_media
                            ?.linkedin?.linkedin_link}`
                        }
                        target="_blank"
                      >
                        <i className="fab fa-linkedin"></i>
                        {/* Linkedin */}
                      </a>
                    </li>
                  )}
                  {jsonData?.theme_4?.home?.footer?.social_media?.youtube
                    ?.is_enable && (
                    <li>
                      <a
                        href={
                          `https://${jsonData?.theme_4?.home?.footer?.social_media?.youtube
                            ?.youtube_link}`
                        }
                        target="_blank"
                      >
                        <i className="fab fa-youtube"></i>
                        {/* Youtube */}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md col-sm-12">
                <ul>
                {jsonData?.theme_4?.home?.footer?.mbl_number && (
                <li>
                  <a
                    href={`tel:${jsonData?.theme_4?.home?.footer?.mbl_number}`}
                  >
                    <i className="uil uil-phone-volume me-2"></i>
                    {jsonData?.theme_4?.home?.footer?.mbl_number}
                  </a>
                </li>
                )}
                {jsonData?.theme_4?.home?.footer?.email_val && (
                <li>
                  <a
                    href={`mailto:${jsonData?.theme_4?.home?.footer?.email_val}`}
                    className="primary-text"
                  >
                    <i className="uil uil-envelopes me-2"></i>
                    {jsonData?.theme_4?.home?.footer?.email_val}
                  </a>
                </li>    
                )}            
                <li>
                  <p>
                    {jsonData?.theme_4?.home?.footer?.address_val}
                  </p>
                </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md col-sm-12">
                <ul>
                  <li>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/privacyPolicy");
                      }}
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/cookiePolicy");
                      }}
                    >
                      Cookie Preferences
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/termsAndConditions");
                      }}
                    >
                      Guarantee and Cancellation Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {settingsData?.WebsiteSettings?.site_title_en.includes(
              "Whiterose"
            ) && (
              <div className="row">
                <div className="col-md-12">
                  <p className="sh4-footer-last">
                    © 2023 All rights reserved by White Rose DC&L Ltd
                  </p>
                </div>
              </div>
            )}
            {!settingsData?.WebsiteSettings?.site_title_en.includes(
              "Whiterose"
            ) && (
              <div className="row">
                <div className="col-md-12">
                  <p className="sh4-footer-last">
                    { settingsData?.WebsiteSettings?.site_title_en}
                    © {year}. All rights reserved. | Powered by&nbsp;
                    <a href="http://www.bestatservices.com" target="blank">
                      www.bestatservices.com
                    </a>
                    {/* © <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_title_en}</a> 2023. All rights reserved. |
                  Designed &amp; Developed By : <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url} target="_blank">{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}</a> */}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default FooterFourShop;
