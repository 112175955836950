import React, { useEffect, useState } from "react";
import {
  lh1,
  lh14,
  lh15,
  lh16,
  lh17,
  lh18,
  lh19,
  lh2,
  lh20,
  lh21,
  lh22,
  lh23,
  lh24,
  lh25,
  lh3,
  lh4,
  lh5,
  lh6,
  lh7,
  lh8,
  poweredby,
  poweredbybs,
} from "../../../assets/img";
import { useSelector } from "react-redux/es/exports";
import { Link, useNavigate, NavLink } from "react-router-dom";
import SampleVideo from "./../../../images/sample.mp4";
import axios from "../../../config";

function Sec9() {
  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const Url = window.location.host;
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const blogUrl = `https://blog.${Url}`;
  const navigate = useNavigate();
  const [settings, setSettings] = useState<any>({});
  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  const navigateToPricing = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
      const finalResult = shopName
        .trim()
        .replace(/\s(.)/g, (match: any) => match.toUpperCase())
        .replace(/\s/g, "")
        .replace(/^(.)/, (match: any) => match.toLowerCase());
      if (shopName) {
        navigate(`/shop/${finalResult}/pricing`);
      } else {
        navigate("/pricing");
      }
    } else {
      navigate("/pricing");
    }
  };

  const navigateToAboutUs = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
      const finalResult = shopName
        .trim()
        .replace(/\s(.)/g, (match: any) => match.toUpperCase())
        .replace(/\s/g, "")
        .replace(/^(.)/, (match: any) => match.toLowerCase());
      if (shopName) {
        navigate(`/shop/${finalResult}/aboutUs`);
      } else {
        navigate("/aboutUs");
      }
    } else {
      navigate("/aboutUs");
    }
  };

  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
      const finalResult = shopName
        .trim()
        .replace(/\s(.)/g, (match: any) => match.toUpperCase())
        .replace(/\s/g, "")
        .replace(/^(.)/, (match: any) => match.toLowerCase());
      if (shopName) {
        navigate(`/shop/${finalResult}/areaCovered`);
      } else {
        navigate("/areaCovered");
      }
    } else {
      navigate("/areaCovered");
    }
  };

  const navigateToTermsAndConditions = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
      const finalResult = shopName
        .trim()
        .replace(/\s(.)/g, (match: any) => match.toUpperCase())
        .replace(/\s/g, "")
        .replace(/^(.)/, (match: any) => match.toLowerCase());
      if (shopName) {
        navigate(`/shop/${finalResult}/termsAndConditions`);
      } else {
        navigate("/termsAndConditions");
      }
    } else {
      navigate("/termsAndConditions");
    }
  };

  const navigateToprivacyPolicy = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
      const finalResult = shopName
        .trim()
        .replace(/\s(.)/g, (match: any) => match.toUpperCase())
        .replace(/\s/g, "")
        .replace(/^(.)/, (match: any) => match.toLowerCase());
      if (shopName) {
        navigate(`/shop/${finalResult}/privacyPolicy`);
      } else {
        navigate("/privacyPolicy");
      }
    } else {
      navigate("/privacyPolicy");
    }
  };

  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);

  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",");

      return (
        <>
          {cityArr.map((city: any) => (
            <li>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
        </>
      );
    });

    return test;
  };

  return (
    <>
      <section className="bg-white lh-footer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-3 ">
              <h5>Explore</h5>
              <ul className="lh-footer-list">
                <li>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#videomodal"
                  >
                    See how it works
                  </a>
                </li>
                <li>
                  <a onClick={navigateToPricing} style={{ cursor: "pointer" }}>
                    Prices & Services
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 ">
              <h5>Our company</h5>
              <ul className="lh-footer-list">
                <li>
                  <a onClick={navigateToAboutUs} style={{ cursor: "pointer" }}>
                    About Us
                  </a>
                </li>
                {/* <li>
                                <a href={blogUrl}>Blog</a>
                            </li> */}
                <li>
                  <a
                    onClick={navigateToAreaCovered}
                    style={{ cursor: "pointer" }}
                  >
                    Locations
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="col-md-3 ">
                        <h5>Explore</h5>
                        <ul className="lh-footer-list">
                            <li>
                                <a href="#">How it works</a>
                            </li>
                            <li>
                                <a href="#">Prices & Services</a>
                            </li>
                            <li>
                                <a href="#">Help Centre</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3 ">
                        <h5>Explore</h5>
                        <ul className="lh-footer-list">
                            <li>
                                <a href="#">How it works</a>
                            </li>
                            <li>
                                <a href="#">Prices & Services</a>
                            </li>
                            <li>
                                <a href="#">Help Centre</a>
                            </li>
                        </ul>
                    </div> */}
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="videomodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content modal-video">
            <video width="100%" height="auto" autoPlay loop muted>
              <source src={SampleVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <section className="bg-lhfooter lh-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5>Areas Covered</h5>
              <ul className="list-style-f1">
                {Object.keys(areaCovered).length > 0 &&
                  popAreadCovered(areaCovered?.data.data.areas_covered)}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white lh-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="sec-footer">
                <h6>Powered by</h6>
                <a href="http://www.bestatservices.com" target="blank">
                  <img src={poweredbybs} alt="" className="lh-flogo" />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sec-footer">
                <h6>Download our mobile app</h6>
                <a
                  target="blank"
                  href={shopHomeData?.webmaster_settings?.app_store_link}
                >
                  <img src={lh20} alt="" />
                </a>
                <a
                  target="blank"
                  href={shopHomeData?.webmaster_settings?.play_store_link}
                >
                  <img src={lh21} alt="" />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sec-footer">
                <h6>Follow us</h6>
                <ul className="lh-fsocial">
                  <li>
                    <a
                      href={
                        shopHomeData?.home_page_settings
                          ?.shop_home_page_social_link[0]?.link
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        shopHomeData?.home_page_settings
                          ?.shop_home_page_social_link[1]?.link
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa-brands fa-x-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        shopHomeData?.home_page_settings
                          ?.shop_home_page_social_link[2]?.link
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="lhfooter-bottom">
                <p>
                  All rights reserved. © {Object.keys(settings).length > 0 &&
                    settings?.WebsiteSettings?.site_title_en} {year}. By visiting this
                  page you agree to our{" "}
                  <a
                    onClick={navigateToprivacyPolicy}
                    style={{ cursor: "pointer" }}
                  >
                    privacy policy
                  </a>{" "}
                  and{" "}
                  <a
                    onClick={navigateToTermsAndConditions}
                    style={{ cursor: "pointer" }}
                  >
                    terms and conditions
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Sec9;
