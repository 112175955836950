import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { useNavigate } from "react-router-dom";
import { setValue } from "../../utility";
import { fetchGetShopByPinCode } from "../../redux/Actions/checkoutPageActions";
import { DebounceInput } from "react-debounce-input";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {  postContactForm } from "../../redux/Actions";
import { alphabet, email, phoneNumber, alphaNumericPattern } from "../../components/core/regex";
const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = process.env.REACT_APP_BASE_URL;
const formurlDefault = "/productLists";

const postalCodesSearchData = {
  result: {
    Response: {
      response_code: "1",
      response_message: "success",
    },
    data: [[]],
  },
};

const contactUsFormSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(50, "Enter cannot exceed 50 character")
    .matches(alphabet, "Name should be only alphabet")
    .trim(),
  email: yup
    .string()
    .required("Email address is required")
    .matches(email, "Enter a valid email address")
    .max(96, "Enter cannot exceed 96 character")
    .trim(),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(phoneNumber, "Enter a valid mobile number")
    .trim(),
  message: yup
    .string()
    .required("Message is required")
    .matches(
      alphaNumericPattern,
      "Message cannot not contain any special character"
    )
    .max(250, "Message cannot exceed 250 character")
    .trim(),
});

function AreaNotCovered() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const [toClosed, settoClosed] = useState(false);
  const state: any = useSelector((state) => state);
  const href = window.location.href;
  const [postalCodesSearch, setPostalCodesSearch] = useState<any | null>(
    postalCodesSearchData
  );
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    settoClosed(true);
    const headers = {
      Accept: "application/json",
    };

    // axios
    //   .get(`${base_url}/api/get_postal_codes?keyword=${postalCodeValue}`, {
    //     headers: headers,
    //   })
    //   .then( async (e) => {
    //     setPostalCodesSearch({ result: e.data });
    //     //alert(JSON.stringify( e.data.data[0]) )
    //     //alert(JSON.stringify( e.data.data[0].length) +" "+ postalCodesSearch.result.data.length +(postalCodesSearch.result.data[0].length==0) );
    //     let lengthvar = e.data.data[0].length;
    //     if (lengthvar != 1) {
    //       //  alert(JSON.stringify( e.data.data[0].length.toString))
    //       window.location.href = `/areanotcovered`;

    //       //window.open(`${client_base_url}/areanotcovered`);
    //       return false;
    //     } else if (lengthvar == 1) {

    //     }
    //   });

    setValue("postalCode", postalCodeValue.toUpperCase());

    const results = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    );

    if (!results) {
      navigate("/areaNotCovered");
      settoClosed(false);
      return;
    }
    navigate("/productLists");

    // alert(`Please enter a valid postal code`)
  };

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  const toClose = () => {
    settoClosed(true);
  };

  const handleChange = (e: any) => {
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };

  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase());
    // dispatch(fetchPostalCodes(searchTerm));
  };

  const {
    handleSubmit: contactFormSubmit,
    control: contactFormControl,
    reset: contactFormReset,
    formState: { errors: contactFormErrors },
  } = useForm({
    resolver: yupResolver(contactUsFormSchema),
    defaultValues: {
      email: "",
      message: "",
      name: "",
      mobile: "",
    },
  });

  const submitContactForm = async (data: any) => {
    const response: any = await postContactForm(data);
    if (response.status) {
      // setContactUs("contactus-grid");
      contactFormReset();
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } else {
      setMessage(response.message);
    }
  };

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Area Not Covered</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>Area Not Covered</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="not-covered">
        <div className="container">
          <div className="row justify-content-center no-search">
            <div className="col-md-5">
              <div className="form-search">
                {toClosed === false && (
                  <div className="no-search-cont" id="notcoverdalert">
                    <p>
                      Sorry, we don't cover your postal code yet!.
                      <br />
                      <br />
                      Please leave us a message with your details. So, that we can make arrangements to process your order.
                    </p>
                  </div>
                )}
                { (href.includes('wblaundry') || href.includes('localhost')) ? (
                  <div className="row">
                    <form onSubmit={contactFormSubmit(submitContactForm)}>
                      <div className="col-md-12">
                        <div className="form-contact">
                          <div className="mb-3 text-start">
                            <label className="form-label">Name *</label>
                            <Controller
                              name="name"
                              control={contactFormControl}
                              render={({ field }) => (
                                <input
                                  placeholder="Enter name"
                                  {...field}
                                  className={`form-control ${
                                    contactFormErrors?.name ? "invalid" : ""
                                  }`}
                                />
                              )}
                            />
                            {contactFormErrors.name ? (
                              <small className="text-danger">
                                {contactFormErrors.name?.message}
                              </small>
                            ) : null}
                          </div>
                          <div className="mb-3 text-start">
                            <label className="form-label">Email *</label>
                            <Controller
                              name="email"
                              control={contactFormControl}
                              render={({ field }) => (
                                <input
                                  placeholder="Enter email"
                                  {...field}
                                  className={`form-control ${
                                    contactFormErrors?.email ? "invalid" : ""
                                  }`}
                                />
                              )}
                            />
                            {contactFormErrors.email ? (
                              <small className="text-danger">
                                {contactFormErrors.email?.message}
                              </small>
                            ) : null}
                          </div>
                          <div className="mb-3 text-start">
                            <label className="form-label">Mobile Number *</label>
                            <Controller
                              name="mobile"
                              control={contactFormControl}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  placeholder="Enter mobile number"
                                  {...field}
                                  className={`form-control ${
                                    contactFormErrors?.mobile ? "invalid" : ""
                                  }`}
                                />
                              )}
                            />
                            {contactFormErrors.mobile ? (
                              <small className="text-danger">
                                {contactFormErrors.mobile?.message}
                              </small>
                            ) : null}
                          </div>
                          <div className="mb-3 text-start">
                            <label className="form-label">Message *</label>
                            <Controller
                              name="message"
                              control={contactFormControl}
                              render={({ field }) => (
                                <textarea
                                  {...field}
                                  placeholder="Enter Message"
                                  className={`form-control ${
                                    contactFormErrors?.message
                                      ? "invalid text-danger"
                                      : ""
                                  }`}
                                ></textarea>
                              )}
                            />
                            {contactFormErrors.message ? (
                              <small className="text-danger">
                                {contactFormErrors.message?.message}
                              </small>
                            ) : null}
                          </div>
                          {message != "" ? (
                            <div className="mb-3">
                              <small className="text-danger">{message}</small>
                            </div>
                          ) : null}
                          <div className="mb-3">
                            <button type="submit" className="btn secondary-btn w-100">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : 
                  (settingsData?.WebmasterSettings?.booking_online_orders ==
                    1 && (
                    <>
                      <div className="no-search-hint">
                        <p>Please check for another postal code.</p>
                      </div>
                      <form method="get" onSubmit={handleSubmit}>
                        <div className="notcovered">
                          <div className="postcodeform">
                            {/* <input
                          placeholder="Enter Post code"
                          className="form-control area-input-form ui-autocomplete-input"
                          id="postcode-input"
                          required
                          name="pincode"
                          type="text"
                          value={postalCodeValue}
                          autoComplete="off"
                          onChange={handleChange}
                        /> */}
                            <DebounceInput
                              debounceTimeout={500}
                              value={postalCodeValue}
                              placeholder="Enter Postcode"
                              className="form-control area-input-form ui-autocomplete-input"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                            <div className="dropdown">
                              {postalCodeList
                                .filter((item: any) => {
                                  const searchTerm =
                                    postalCodeValue.toLowerCase();
                                  const mainValue = item.postcode.toLowerCase();
  
                                  return (
                                    searchTerm &&
                                    mainValue.startsWith(searchTerm) &&
                                    mainValue !== searchTerm
                                  );
                                })
                                .slice(0, 10)
                                .map((item: any) => (
                                  <div
                                    onClick={() => onSearch(item.postcode)}
                                    className="dropdown-row"
                                    key={item.postcode}
                                  >
                                    <i className="fas fa-map-marker-alt"></i>
                                    {item.postcode}
                                  </div>
                                ))}
                            </div>
                            <button
                              className="btn hover-btn submitCode"
                              id="book_now_btn"
                              type="submit"
                            >
                              Book Now
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AreaNotCovered;
