import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../../redux/Actions";
import { setValue } from "../../../utility";
import { fetchGetShopByPinCode } from "../../../redux/Actions/checkoutPageActions";
import { titleborder, play } from "../../../assets/img";
import SampleVideo from "../../../images/sample.mp4";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";

function SectionTwoBannerThemeTwo() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const jsonData: any = useSelector<any>((state) => state?.shopHomeJsonList);

  const [blogList, setBlogList] = useState<any | null>({});
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
      });
  }, []);

  const faqData: any = useSelector<any>((state) => state?.faq);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
    }
  }, [state]);

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  const handleChange = (e: any) => {
    const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm);
    // dispatch(fetchPostalCodes(searchTerm));
  };
  const handleBooknow = async () => {
    const isValid = state.postalCodeList.some(
      (item: any) => item.postcode === postalCodeValue.toUpperCase()
    );
    setValue("postalCode", postalCodeValue);
    const results = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    );

    if (!results) {
      navigate("/areaNotCovered");
      return;
    }
    if (isValid) {
      navigate("/productLists");
    } else {
      navigate("/areaNotCovered");
    }
  };
  return (
    <>
      <section className="sh2-intro-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="sh2-section-card">
                <div className="sh2-section-title">
                  <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.h3color}`
                    }}>{jsonData?.theme_2?.home?.section_2?.sub_heading_top}</p>
                  <h2 style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.h2color}`
                    }}>{jsonData?.theme_2?.home?.section_2?.title}</h2>
                  <img src={titleborder} alt="" />
                </div>
                <div className="sh2-intro-content">
                  <div className="sh2-intro-img">
                    <img
                      src={jsonData?.theme_2?.home?.section_2?.banner}
                      alt=""
                    />
                  </div>
                  <div className="gr-border">
                    <div className="gr-border-grid sh2-intro-data-box">
                      <ul>
                        {jsonData?.theme_2?.home?.section_2?.card_section_one?.map(
                          (items: any) => {
                            return (
                              <>
                                <li>
                                  {/* <h2 style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.h3color}`
                    }}>{items.count}</h2> */}
                                  <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.h3color}`
                    }}>{items.text}</p>
                                </li>
                              </>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  <p className="sh2-intro-desc" style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.paracolor}`
                    }}>
                    {jsonData?.theme_2?.home?.section_2?.mainparagraph}
                  </p>
                  <div className="sh2-intro-content-btn d-none">
                    <a
                      className="btn"
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#videomodal"
                    >
                      <img src={play} alt="" />
                    </a>
                    <p>
                      Click on this video to check how we work with Our Clients
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sh2-section-card sh2-intro-right-grid">
                <div className="sh2-review-grid text-center">
                  <h2 style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.h2color}`
                    }}>
                    Top rated <span>laundry</span>
                  </h2>
                  <div className="review-stars">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                  <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.paracolor}`
                    }}>
                    {jsonData?.theme_2?.home?.section_2?.rating}{" "}
                    {jsonData?.theme_2?.home?.section_2?.card_sub_text}
                  </p>
                </div>
                {jsonData?.theme_2?.home?.section_2?.card_section_two.map(
                  (item: any) => {
                    return (
                      <>
                        <div className="gr-border">
                          <div className="gr-border-grid sh2-intro-points-box">
                            <img src={item.card_icon} alt="" />
                            <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.paracolor}`
                    }}>{item.card_sub_heading_top}</p>
                            <h5 style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.h3color}`
                    }}>{item.card_title}</h5>
                            <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_2?.paracolor}`
                    }}>{item.card_paragraph}</p>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="videomodal"
          aria-hidden="true"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content modal-video">
              <video width="100%" height="auto" autoPlay loop muted>
                <source src={SampleVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
}

export default SectionTwoBannerThemeTwo;
