import React, { useEffect, useState } from "react";
import { useNavigate,NavLink,useParams } from "react-router-dom";
import { ttw44,ttw45 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";
import SectionFive from "./sectionFive";
import SectionEight from "./sectionEight";
import SectionNine from "./sectionNine";
import SectionEleven from "./sectionEleven";
import SectionTwo from "./sectionTwo";
import SectionThree from "./sectionThree";
import AdditionalService from "./additionalService";

function HeloLocation () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  useEffect(() => {
    // Check for %20 or empty spaces in the URL
    if (window.location.href.includes('%20') || window.location.href.includes(' ')) {
      navigate('/404');
    }
  }, []);
  const params = useParams<any>();
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <div className="t12banner" id="secidsr">
            <div className="container">
                <div className="row h-100">
                    <div className="col-md-12 col-lg-6">
                        <div className="banner-grid">
                            <div className="banner-grid-inner">
                                <h1>Shoe Repairs & Cleaning services in <span className="tcase">{params.location?.replace(/-/g, " ")}</span></h1>
                                <ul className="t12highpoint">
                                    <li>Free Pickup & Delivery</li>
                                    <li>High Quality Services</li>
                                    <li>Lowest Price Guarantee</li>
                                </ul>
                                <ul className="t12applinks">
                                    <li>
                                        <a><img src={ttw44} alt="" /></a>
                                    </li>
                                    <li>
                                        <a><img src={ttw45} alt="" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SectionThree/>
        <SectionFive/>
        <SectionEight/>
        <AdditionalService/>
        <SectionNine/>
        <SectionEleven/>
    </>
  );
}

export default HeloLocation;
