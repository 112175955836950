import React,{ useEffect,useState  } from 'react'
import { render } from 'react-dom'
import './../thankAppDownload/thankApp.css';
//const QuickOrderPopup :React.FC<{ close: any }> = ({ close }) => {
const ThankAppDownload :React.FC<{ close: any }> = ({ close })=>{
    const handleThankAppDownloadPopupClose =() =>{
        close();
    }


    return(
<div className="custom-modal-class modal in" id="app_download_success_popup" style={{display:"block"}}>
<div className="vertical-alignment-helper">
<div className="modal-dialog modal-md modal-dialog-centered">
<div className="modal-content">

<div className="modal-body">
<h3>Thank you for showing interest in our app. You will get the link via SMS soon.</h3>
<input type="submit" value="Close" data-bs-dismiss="modal"  onClick={handleThankAppDownloadPopupClose} />

</div>
</div>
</div>
</div>
</div>
)


}

export default ThankAppDownload;