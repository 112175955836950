import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ihi2,ihi3,ihi4,ihi5,ihi6,ihi7,ihi8,ihi9,ihi10,ihi11,ihi12,ihi13 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";

function SectionTwo () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  let shopURL = window.location.href;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <section id="t11hiw">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-h2">How It Works</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="hiw-grid text-center">
                            <img src={ihi2} alt=""/>
                            <div className="hiw-desc">
                                <span>1.</span>
                                {shopURL?.includes("claphamdrycleaners") ? (
                                    <p>Drop your bag in the above mentioned Parcel Pickup and Drop-off Services.</p>
                                ) : (
                                    <p>Choose <strong>where</strong> and <strong>when</strong> you’d like us to collect and deliver your laundry or items.</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="hiw-grid text-center">
                            <img src={ihi3} alt=""/>
                            <div className="hiw-desc">
                                <span>2.</span>
                                {shopURL?.includes("claphamdrycleaners") ? (
                                    <p>We’ll get your bag, invoice you and clean your items according to your requirements.</p>
                                ) : (
                                    <p>We’ll <strong>collect</strong> your bag, <strong>invoice</strong> you and <strong>clean</strong> your items according to your requirements</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="hiw-grid text-center">
                            <img src={ihi4} alt=""/>
                            <div className="hiw-desc">
                                <span>3.</span>
                                {shopURL?.includes("claphamdrycleaners") ? (
                                    <p>We'll send your items in the same Parcel Pickup and Drop-off Services, within the time required per service.</p>
                                ) : (
                                    <p>We deliver your items, <strong>cleaned</strong> within <strong>24hrs</strong> or, within the time required per service.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="ihrsecbl-bgwhite pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-h2">What Our Client Say</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                        }}
                        className="home-slider"
                    >
                    {homedata?.theme_3?.home?.section_12?.review_card.map(
                    (items: any,index: number) => {
                        return (
                        <SwiperSlide>
                            <div className="sh11-testimonial-card" key={index}>
                            <div className="sh11-profile-user">
                                <a className="sh11-profile-user-img">
                                <img src={items.reviewer_pic} className="rounded-circle" />
                                </a>
                                <div className="sh11-profile-user-info">
                                <h6>
                                    <a>{items.reviewer_name}</a>
                                </h6>
                                <div className="sh11-profile-rating">
                                    <span className="secondary-text">
                                    {Array.from({ length: 5 }, (_, i) => (
                                        <i
                                        key={i}
                                        className={`fas fa-star ${i < items.rating ? 'text-secondary' : 'text-secondary text-lightg'}`}
                                        ></i>
                                    ))}
                                    </span>
                                    <span>{items.rating}</span>
                                </div>
                                </div>
                            </div>
                            <h5>{items.review_highlight}</h5>
                            <p>{items.review}</p>
                            </div>
                        </SwiperSlide>
                        );
                    }
                    )}
                    </Swiper>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-2">
                            <img src={ihi5} alt="" />
                            <p>"Best Digi Dry Cleaner"</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-2">
                            <img src={ihi6} alt="" />
                            <p>"For A Hassle-free Life"</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-2">
                            <img src={ihi7} alt="" />
                            <p>"Britain's Best Delivery Service"</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-2">
                            <img src={ihi8} alt="" />
                            <p>"4.7 average customer rating"</p>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-h2">Pricing</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-3">
                            <img src={ihi9} alt="" />
                            <h3>Shirts</h3>
                            <p>Washed and Pressed</p>
                            <strong>From £2.50</strong>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-3">
                            <img src={ihi10} alt="" />
                            <h3>Day Dress</h3>
                            <p>Dry Clean</p>
                            <strong>From £11.50</strong>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-3">
                            <img src={ihi11} alt="" />
                            <h3>Service Wash</h3>
                            <p>Wash, Dry and Fold</p>
                            <strong>From £16.95</strong>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-3">
                            <img src={ihi12} alt="" />
                            <h3>Bedding</h3>
                            <p>Bed Set (Wash and Press)</p>
                            <strong>From £14.00</strong>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="ihrsecbl">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            {/* <img src={ihi13} alt="" className="mb-3" /> */}
                            <h2 className="section-h2 section-h2w">The best Dry Cleaning delivered <br/>directly to your door</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {shopURL?.includes("claphamdrycleaners") ? (
                            <>
                                <div className="ihr-sec-grid-4">
                                    <p>We are a network of the very best dry cleaners. </p>
                                    <p>We provide the finest dry cleaning and laundry services, combined with exceptional customer service.  </p>
                                </div>
                                <div className="ihr-sec-grid-4">
                                    <h3>DRY CLEANING DELIVERY - HOW DOES IT WORK?</h3>
                                    <p>The service is simple: drop your items in the mentioned Parcel Pickup and Drop-off Services, we professionally clean or repair them, and send them back to the same Parcel Pickup and Drop-off Services in less than 48 hours.</p>
                                    <p>We offer flexible time slots, allowing us to work around your schedule and deliver your clothes beautifully washed, pressed and finished exactly when you need them.</p>
                                    <p>If you’re especially pressed for time, you can drop or collect the items directly from our shop</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="ihr-sec-grid-4">
                                    <p>We are a network of the very best dry cleaners. </p>
                                    <p>We provide the finest dry cleaning and laundry services, combined with exceptional customer service and convenient collection and delivery suited perfectly to your schedule.  </p>
                                </div>
                                <div className="ihr-sec-grid-4">
                                    <h3>DRY CLEANING DELIVERY - HOW DOES IT WORK?</h3>
                                    <p>The service is simple: we collect your items, professionally clean or repair them, and deliver them back to your doorstep in less than 24 hours.</p>
                                    <p>We offer flexible collection and delivery time slots, allowing us to work around your schedule and deliver your clothes beautifully washed, pressed and finished exactly when you need them.</p>
                                    <p>If you’re especially pressed for time, you can opt for same day laundry delivery on items collected before 10am.</p>
                                </div>
                            </>
                        )}
                        <div className="ihr-sec-grid-4">
                            <h3>EXPERT DRY CLEANERS</h3>
                            <p>Founded in 2014, we are the first on demand laundry service in London, and has since expanded its horizons to operate in cities across
the UK, as well as in New York City.</p>
                            <p>We work with a network of the best local dry cleaners, ensuring the best quality dry cleaning, repairs, alterations and more.</p>
                            <p>Every item is treated with care and respect by our master cleaners, who provide world-class cleaning services time and time again. </p>
                            <p>If you’re looking to find the best dry cleaner near you, then look no further than us. </p>
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>OUR LOCATIONS</h3>
                            <ul>
                                <>
                                {Object.keys(areaCovered).length > 0 &&
                                popAreadCovered(areaCovered?.data.data.areas_covered)}
                                
          {Object.keys(areaCovered).length > 0 &&
                                popAreadCovered2(areaCovered?.data.data.areas_covered)}
                                </>
                            </ul>
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>EXPERT LAUNDRY SERVICES</h3>
                            <p>We have a range of premium laundry services on offer, from <strong>dry cleaning for your favourite suits, shirts and dresses, </strong>to expert<strong> tailoring and repairs</strong> on any of your garments, and everything in between.</p>
                            <p>For more information, visit our <strong><a href="">services page, </a></strong> or reach out to our friendly Customer Care team with your queries.</p>
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>LAUNDRY AND DRY CLEANING BLOG</h3>
                            <p>Follow our <strong><a href={blogUrl}> blog</a></strong> to stay up to date with industry and sustainability news and keep up with the exciting things we're doing as a
business. You can also find plenty of tips and tricks and in-depth step by step guides to help you navigate all kinds of laundry and dry cleaning on
our blog.</p>
                        </div>
                    </div>
                </div>
                <div className="ihisecin">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h2 className="section-h2 section-h2w">ARE MY CLOTHES SAFE WITH<br/>YOUR SERVICE?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-3 mt-4">
                            <div className="ihr-sec-grid-5">
                                <div className="ihr-sec-count">71,654</div>
                                <p>Items cleaned in March.</p>
                            </div>
                        </div>
                        <div className="col-md-3 mt-4">
                            <div className="ihr-sec-grid-5">
                                <div className="ihr-sec-count">121</div>
                                <p>Reports of damage to an item.</p>
                            </div>
                        </div>
                        <div className="col-md-3 mt-4">
                            <div className="ihr-sec-grid-5">
                                <div className="ihr-sec-count">99.9%</div>
                                <p>Items cleaned without an issue.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="text-center">
                            <h2 className="section-h2 section-h2w">OUR PROMISE TO YOU</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-11">
                        <div className="ihr-sec-grid-6">
                            <p>We do everything to minimise any risk, but on the very rare occasion an item is damaged, we’ll hold our hands up and admit the mistake and
either fix it or compensate you for any damage or loss</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionTwo;
