import React, { useEffect, useState } from "react";
import {lh1,lh14,lh15,lh16,lh17,lh18,lh19,lh2,lh20,lh21,lh22,lh23,lh24,lh3,lh4,lh5,lh6,lh7,lh8} from "../../../assets/img";
import { Link, useNavigate, NavLink } from "react-router-dom";

function Sec8 () {

return (
    <>
        <section className="bg-white sec-lhofferlinks">
            <div className="container">                
                <div className="row">  
                    <div className="col-md-12">
                        <h5>Get the most out of us</h5>
                    </div> 
                </div>
                <div className="row">  
                    {/* <div className="col-md-6">
                        <div className="sec-offer">
                            <div className="sec-offer-img">
                                <img src={lh22} alt=""/>
                                <a href="#" className="sec-offer-link"><i className="fas fa-arrow-right"></i>Send a gift card</a>
                            </div>
                            <p>Satisfied with our service? Send a gift card to someone you love.</p>
                        </div>
                    </div> */}
                    <div className="col-md-6">
                        <div className="sec-offer">
                            <div className="sec-offer-img">
                                <img src={lh23} alt=""/>
                                <Link className="sec-offer-link" to="/referFriend"><i className="fas fa-arrow-right"></i>Refer a friend and earn</Link>
                            </div>
                            <p>Want to get a discount code? Refer friends and earn.</p>
                        </div>
                    </div>
                </div> 
                {/* <div className="row">  
                    <div className="col-md-12">
                        <h5>Our other offers</h5>
                    </div> 
                </div>
                <div className="row">  
                    <div className="col-md-6">
                        <div className="sec-offer">
                            <div className="sec-offer-img">
                                <img src={lh24} alt=""/>
                                <a href="#" className="sec-offer-link"><i className="fas fa-arrow-right"></i>Do you manage a short-let?</a>
                            </div>
                            <p>Check our solutions for on-demand linen & towels rental for your short-let.</p>
                        </div>
                    </div>
                </div> */}
                <div className="row">  
                    <div className="col-md-12">
                        <hr className="sec-divider mt-0"/>
                    </div> 
                </div>
            </div>
        </section>
    </>
)
}
export default Sec8;