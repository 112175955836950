import { ColorPicker } from "primereact/colorpicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  fetchHomeJsonList,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../redux/Actions";
import ImageCropper from "../../imageCropper/imageCropper";

function SectionTwoThemeFourEdit() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [bannerImageChange, setBannerImageChange] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const images: Array<string> = [
    "assets/img/home/trustimg1.jpg",
    "assets/img/home/trustimg1.jpg",
    "assets/img/home/trustimg1.jpg",
    "assets/img/home/trustimg1.jpg",
    "assets/img/home/trustimg1.jpg",
    "assets/img/home/trustimg1.jpg",
    "assets/img/home/trustimg1.jpg",
  ];
  const icons = [
    "assets/icons/priorityicon1.png",
    "assets/icons/priorityicon2.png",
    "assets/icons/priorityicon3.png",
    "assets/icons/priorityicon4.png",
    "assets/icons/priorityicon5.png",
    "assets/icons/priorityicon6.png",
    "assets/icons/priorityicon7.png",
    "assets/icons/priorityicon8.png",
    "assets/icons/priorityicon9.png",
    "assets/icons/priorityicon10.png",
    "assets/icons/priorityicon11.png",
    "assets/icons/priorityicon12.png",
    "assets/icons/priorityicon13.png",
    "assets/icons/priorityicon14.png",
    "assets/icons/priorityicon15.png",
    "assets/icons/priorityicon16.png",
    "assets/icons/priorityicon17.png",
  ];

  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        setBannerImageChange(false);
        handleCroppedImage(res.target.result);
      };
    }
  };
  const updateImageSectionBanner = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    // setBannerImageChange(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        setBannerImageChange(true);
        handleCroppedImage(res.target.result);
      };
    }
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false && bannerImageChange == true) {
      handleImageChange(imageLink, activeTab);
    }
    if (imageLink != false && bannerImageChange == false) {
      handleBannerImageChange(imageLink);
    }
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  const addCollection = () => {
    let updatedCollection: any = [
      ...editData.theme_4.home.section_2.card_section.collection,
    ];

    let newCard = {
      icon: "assets/icons/priorityicon1.png",
      collection_title: "",
      collection_paragraph: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
        home: {
          ...prevJsonData.theme_4.home,
          section_2: {
            ...prevJsonData.theme_4.home.section_2,
            card_section: {
              btn_group: {
                ...prevJsonData.theme_4.home.section_2.card_section.btn_group,
              },
              collection: updatedCollection,
            },
          },
        },
      },
    }));
  };
  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_4.home.section_2.card_section.collection,
    ];
    updatedCollection.splice(index, 1);
    setActiveTab(activeTab - 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
        home: {
          ...prevJsonData.theme_4.home,
          section_2: {
            ...prevJsonData.theme_4.home.section_2,
            card_section: {
              btn_group: {
                ...prevJsonData.theme_4.home.section_2.card_section.btn_group,
              },
              collection: updatedCollection,
            },
          },
        },
      },
    }));
  };

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    let updatedCollection: any = [
      ...editData.theme_4.home.section_2.card_section.collection,
    ];
    if (index != undefined) {
      updatedCollection = [
        ...editData.theme_4.home.section_2.card_section.collection,
      ];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
        home: {
          ...prevJsonData.theme_4.home,
          section_2: {
            ...prevJsonData.theme_4.home.section_2,
            [name]: value,
            card_section: {
              collection: updatedCollection,
            },
          },
        },
      },
    }));
  };

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [
      ...editData.theme_4.home.section_2.card_section.collection,
    ];
    updatedCollection[index] = {
      ...updatedCollection[index],
      icon: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
        home: {
          ...prevJsonData.theme_4.home,
          section_2: {
            ...prevJsonData.theme_4.home.section_2,
            card_section: {
              ...prevJsonData.theme_4.home.section_2.card_section,
              collection: updatedCollection,
            },
          },
        },
      },
    }));
  };

  const handleBannerImageChange = (image: string) => {
    //Update the banner value in the JSON data
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
        home: {
          ...prevJsonData.theme_4.home,
          section_2: {
            ...prevJsonData.theme_4.home.section_2,
            first_card_image: image,
          },
        },
      },
    }));
  };

  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section2 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_4?.home?.section_2?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                  </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={editData?.theme_4?.home?.section_2?.paragraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_4?.home?.section_2?.h2color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_2?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_4?.home?.section_2?.h3color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_2?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_4?.home?.section_2?.paracolor
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_2?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <label className="form-label">
                          First card background
                        </label>
                        <div className="img-bg active">
                          <img
                            src={
                              editData?.theme_4?.home?.section_2
                                ?.first_card_image
                            }
                            alt="Hero Image"
                          />
                        </div>
                        <div className="img-options">
                          <div className="row">
                            <div className="col-md-12">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 4,
                                  },
                                  1024: {
                                    slidesPerView: 6,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {images.map((images: string) => {
                                  return (
                                    <>
                                      <SwiperSlide>
                                        <div
                                          className="img-option"
                                          onClick={() =>
                                            handleBannerImageChange(images)
                                          }
                                        >
                                          <img
                                            src={images}
                                            alt="Hero Image"
                                            className="img-fluid"
                                          />
                                        </div>
                                      </SwiperSlide>
                                    </>
                                  );
                                })}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Upload Image</label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      className="form-control"
                      name="image"
                      onChange={updateImageSectionBanner}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">First card text</label>
                    <input
                      type="text"
                      className="form-control"
                      name="image_title"
                      value={editData?.theme_4?.home?.section_2?.image_title}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_4?.home?.section_2?.card_section?.collection.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Card Section {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_4?.home?.section_2?.card_section?.collection.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.icon}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {icons.map((icons, iconIndex) => (
                                <SwiperSlide key={iconIndex}>
                                  <div
                                    className="img-option"
                                    onClick={() =>
                                      handleImageChange(icons, index)
                                    }
                                  >
                                    <img
                                      src={icons}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Title<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_title"
                          value={card.collection_title}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={250}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_paragraph"
                          value={card.collection_paragraph}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={1200}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default SectionTwoThemeFourEdit;
