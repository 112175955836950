import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { ro14, ro15, paymethod1, paymethod2, paymethod3, paymethod4, qrscan, logo, ro17 } from '../../assets/img'
import { useDispatch, useSelector } from "react-redux";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload
});

function FooterRogers() {
  const navigate = useNavigate();
  const Url = window.location.host
  const blogUrl = `https://blog.${Url}`
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});

  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link
  const facebook = Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link1
  const twitter = Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link2
  const instagram = Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link6
  const dispatch = useDispatch<any>();

  const [areaCovered, setAreaCovered] = useState<any | null>({})

  const settingsData: any = useSelector<any>(
      (state) => state?.settings
  );

  // useEffect(() => {
  //   if (Object.keys(settings).length === 0 && settingsData == undefined) {
  //     axios({
  //       method: "get",
  //       url: `${base_url}/api/settings`,
  //       headers: {
  //         "Accept": "application/json",
  //         'Access-Control-Allow-Methods': 'GET, POST',
  //       },
  //     }).then((response) => {
  //       if (response.status != 401) {
  //         setSettings(response.data.data);
  //         dispatch(setSettingsAll(response.data.data))
  //       }
  //     }).catch(function (error) {

  //     })
  //   }

  //   const headers = {
  //     'Accept': 'application/json'
  //   }

  //   axios.get(`${base_url}/api/areas_covered`, {
  //     headers: headers

  //   }).then(e => { setAreaCovered({ data: e.data }) })
  // }, [])

  
  const popAreadCovered = (areaList: any) => {



    var test = Object.keys(areaList).map((location, locationindex) => {

      let cityArr = areaList[location].toString().split(',');

      return (<>
        {cityArr.map((city: any) => <li><a href='#' onClick={(e) => {
          e.preventDefault(); navigate('/dry-cleaners/' + city)
        }}>{city}</a></li>)}
      </>)
    })

    return test

  }

  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  return (
    <div>
      {jsonData && (
        <style type="text/css">
          {`
            :root {
              --footer-header: #${jsonData?.theme_1?.home?.footer?.section_heading_color};
              --footer-paragraph: #${jsonData?.theme_1?.home?.footer?.section_paragraph_color};
          }
          `}
        </style>
      )}
      <footer className="footer">
        <div className="container">
          <div className="row footer-sectionone pt-3">
            <div className="col-md-6">
              <h4>North & North West London</h4>
              <ul className='footer-area-links'>
                    <li><a href=''>Barnsbury</a></li>
                    <li><a href=''>Barnet</a></li>
                    <li><a href=''>Lower Edmonton</a></li>
                    <li><a href=''>Edmonton</a></li>
                    <li><a href=''>Enfield</a></li>
                    <li><a href=''>Finchley</a></li>
                    <li><a href=''>Islington</a></li>
                    <li><a href=''>Muswell Hill</a></li>
                    <li><a href=''>Stroud Green</a></li>
                    <li><a href=''>Tufnell Park</a></li>
                    <li><a href=''>East Manor House</a></li>
                    <li><a href=''>Fortis Green</a></li>
                    <li><a href=''>Finsbury Park</a></li>
                    <li><a href=''>Harringay</a></li>
                    <li><a href=''>Holloway</a></li>
                </ul>
            </div>
            <div className="col-md-6">
              <h4>West London Post Code Area</h4>
              <ul className='footer-area-links'>
                {/* {Object.keys(areaCovered).length > 0 && popAreadCovered(areaCovered?.data.data.areas_covered)} */}
                    <li><a href=''>Acton</a></li>
                    <li><a href=''>Boston Manor</a></li>
                    <li><a href=''>East Acton</a></li>
                    <li><a href=''>West Acton</a></li>
                    <li><a href=''>Ealing</a></li>
                    <li><a href=''>Hanwell</a></li>
              </ul>
             
            </div>
            <div className="row footer-sectionone">
                <div className="col-md-6">
                    <div className='logo-footer'>
                        <img src={ro17} alt="" />
                    </div>
                </div> 
                {/* <div className="col-md-4">
                
                <div className='app-social'>
                <ul className="applinks">
                    <li>
                      <a
                        target="_blank"
                        href={
                          jsonData?.theme_1?.home?.footer?.mobile_link_section
                            ?.ios_link
                        }
                      >
                        <img src={ro14} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href={
                          jsonData?.theme_1?.home?.footer?.mobile_link_section
                            ?.android_link
                        }
                      >
                        <img src={ro15} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
                </div> */}
                <div className="col-md-6">
                <div className="footer-social">
                <ul>
                {jsonData?.theme_1?.home?.footer?.footer_section?.social_media?.facebook
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_1?.home?.footer?.footer_section?.social_media
                          ?.facebook?.facebook_link
                      }
                      target="_blank"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_1?.home?.footer?.footer_section?.social_media?.instagram
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_1?.home?.footer?.footer_section?.social_media
                          ?.instagram?.instagram_link
                      }
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_1?.home?.footer?.footer_section?.social_media?.whatsapp
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_1?.home?.footer?.footer_section?.social_media
                          ?.whatsapp?.whatsapp_link
                      }
                      target="_blank"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_1?.home?.footer?.footer_section?.social_media?.linkedin
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_1?.home?.footer?.footer_section?.social_media
                          ?.linkedin?.linkedin_link
                      }
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_1?.home?.footer?.footer_section?.social_media?.youtube
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_1?.home?.footer?.footer_section?.social_media
                          ?.youtube?.youtube_link
                      }
                      target="_blank"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_1?.home?.footer?.footer_section?.social_media?.twitter
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_1?.home?.footer?.footer_section?.social_media
                          ?.twitter?.twitter_link
                      }
                      target="_blank"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                )}
              </ul>
                </div>
            </div>
          </div>
            {/* <div className="col-md-6">
                <h4>West London Post Code Area</h4>
                <ul className='footer-area-links'>
                  <li><a href=''>Acton</a></li>
                  <li><a href=''>Boston Manor</a></li>
                  <li><a href=''>East Acton</a></li>
                  <li><a href=''>West Acton</a></li>
                  <li><a href=''>Ealing</a></li>
                  <li><a href=''>Hanwell</a></li>
                </ul>
              </div> */}
          </div>

          <div className="row footer-sectionthree">
             <div className="col-md col-sm-12">
              <h4>Explore</h4>
              <ul>
                {/* <li><a href=''>How it works</a></li> */}
                <li><a href='/#services-section'>Services</a></li>
                <li><a href='' onClick={(e)=>{e.preventDefault(); navigate('/pricing')}}>Pricing</a></li>
                {/* <li><a href=''>Help Center</a></li> */}
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4>Our solutions</h4>
              <ul>
                <li><a>Laundry</a></li>
                <li><a>Dry Cleaning</a></li>
                <li><a>Dry Cleaners</a></li>
                <li><a>Hotel Laundry</a></li>
                <li><a>Laundry for business</a></li>
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4>Our company</h4>
              <ul>
                <li><a href='' onClick={(e)=>{e.preventDefault(); navigate('/aboutUs')}}>About Us</a></li>
                <li><a target='_blank' href={blogUrl}>Blog</a></li>
                <li><a href='' onClick={(e)=>{e.preventDefault(); navigate('/areaCovered')}}>Locations</a></li>
                {/* <li><a href='' onClick={(e)=>{e.preventDefault(); navigate('/reviews')}}>Reviews</a></li> */}
              </ul>
            </div>
            {/* <div className="col-md col-sm-12">
              <h4>Work with us</h4>
              <ul>
                <li><a href=''>Careers</a></li>
                <li><a href=''>Partner drivers</a></li>
                <li><a href=''>Cleaning partners</a></li>
              </ul>
            </div> */}
            <div className="col-md col-sm-12">
              <h4>Dry cleaning & laundry. The easy way.</h4>
              <ul>
                <li><a>Cashless payments</a></li>
                <li><a>Contactless pickup & delivery</a></li>
                <li><a>Special requests</a></li>
                <li><a>In-app live chat support</a></li>
              </ul>
            </div>
            {/* <div className="col-md col-sm-12">
              <div className="footer-two">
                <div
                  className="footerlist-two">
                  <h4>We are here for you..</h4>
                  <ul>
                    <li><span>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t1_en}</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Need Help?</h4>
                  <ul>
                    <li><span>Phone :</span><span><a href={`tel:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t5}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t5}</a></span></li>
                    <li><span>Email :</span><span><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}</a></span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-2 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Our Policy</h4>
                  <ul>
                    <li><NavLink to={"/termsAndConditions"}><i className="fas fa-angle-right me-1"></i>Conditions of use</NavLink></li>
                    <li><NavLink to={"/privacyPolicy"}><i className="fas fa-angle-right me-1"></i>Privacy Policy</NavLink></li>
                    <li><NavLink to={"/cookiePolicy"}><i className="fas fa-angle-right me-1"></i>Cookie Policy</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Quick Connect</h4>
                  <div
                    className="app-icons">
                    <ul
                      className="list-inline">
                      <li><a target={appStorel == ""? '' : "_blank"} href={appStorel == ""? 'javascript:;' : appStorel} className="hvr-float-shadow imggbg"><img src={appstore} className="img img-fluid" alt="appstore" /></a></li>
                      <li><a target={playStore == ""? '' : "_blank"} href={playStore == ""? 'javascript:;' : playStore} className="hvr-float-shadow imggbg2"><img src={googleplay} className="img img-fluid" alt="appstore" /></a></li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="row  footer-sectionfour">
            <div className="col-md-6 col-xs-12">
              <p className="footer-last">
                {Object.keys(settings).length > 0 && settings?.WebsiteSettings?.site_title_en} © {year}. All rights reserved. | Powered by&nbsp; <a href="http://www.bestatservices.com" target="blank">www.bestatservices.com</a>
                {/* © <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_title_en}</a> 2023. All rights reserved. |
                Designed &amp; Developed By : <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url} target="_blank">{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}</a> */}
              {/* </p>
            </div> */} 
            {/* <div className="col-md-6 col-xs-12">
              <div className='footer-paymethods'>
                <p>Accepted payment methods:</p>
                <ul>
                  <li>
                    <img src={paymethod1} alt="" />
                  </li>
                  <li>
                    <img src={paymethod2} alt="" />
                  </li>
                  <li>
                    <img src={paymethod3} alt="" />
                  </li>
                  <li>
                    <img src={paymethod4} alt="" />
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
          <div className="row  footer-sectionfour">
            <div className="col-md-6 col-xs-12">
              <p className="footer-last">
              Rogers of Pimlico © 2023. All Right Reserved | Powered by&nbsp; <a href="http://www.bestatservices.com" target="blank">www.bestatservices.com</a>
                {/* © <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_title_en}</a> 2023. All rights reserved. |
                Designed &amp; Developed By : <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url} target="_blank">{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}</a> */}
               </p>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className='footer-paymethods'>
                <p>Accepted payment methods:</p>
                <ul>
                  <li>
                    <img src={paymethod1} alt="" />
                  </li>
                  <li>
                    <img src={paymethod2} alt="" />
                  </li>
                  <li>
                    <img src={paymethod3} alt="" />
                  </li>
                  <li>
                    <img src={paymethod4} alt="" />
                  </li>
                </ul>
              </div>
            </div>
        </div>
        </div>
      </footer>
    </div>
  )
}

export default FooterRogers;