import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../../redux/Actions";
import { checkShopListFlow, setValue } from "../../../utility";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchPickupAdress,
  fetchSaveAddress,
} from "../../../redux/Actions/checkoutPageActions";
import * as yup from "yup";
// import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"; 
import Select from "react-select";
import { DebounceInput } from "react-debounce-input";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "../../../config";

// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

const UKPostalCode: RegExp =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Pin code is required")
    .matches(UKPostalCode, "Enter a valid Pin code")
    .trim()
    .max(8, "Enter a valid Pin code"),
});

const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

function SectionOneThemeFive() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [listOfSuggestedAddress, setListOfSuggestedAddress] = useState([]);
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [coveredArea, setCoveredArea] = useState(false);
  const [areaData, setAreaData] = useState([]);
  const navigate = useNavigate();
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  const [selectedSuggestedAddress, setSelectedSuggestedAddress] =
    useState<any>(null);
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);

  useEffect(() => {}, [jsonData]);

  const navigateToCheckout = () => {
    if(checkShopListFlow()){
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());

    let URL = window.location.href.split("/");
    if (URL.length > 4) {
      navigate(`/shop/${finalResult}/productLists`);
    } else {
      navigate("/productLists");
    }
  }else {
    navigate("/productLists");
  }
}else {
  navigate("/productLists");
}
  };

  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });

  // const base_url = "https://revamp.dreamguystech.com"

  // const fetchData = async () => {
  //     const base_url = "https://revamp.dreamguystech.com";
  //     try {
  //         const bodyFormData = new FormData();
  //         bodyFormData.append('keyword', 'Gu11');
  //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
  //         const response = await axios({
  //             method: "post",
  //             url: `${base_url}/api/get_postal_codes`,
  //             data: bodyFormData,
  //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
  //         })
  //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
  //         // const result = await response.then(response => response);

  //         if (response) {
  //             setPostalCodeList(response.data.data[0])
  //
  //         }
  //     } catch (error) {
  //
  //     }
  // }

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  useEffect(() => {
    if (checkWithNewPin == false) {
      setPinCodeFormValue("pinCode", "");
      setSelectedAddress(null);
      resetAddressForm();
    } else {
    }
  }, [checkWithNewPin]);

  useEffect(() => {
    let existingAddressSuggestion = JSON.parse(
      localStorage.getItem("addressSuggestions") || "[]"
    );
    setListOfSuggestedAddress(existingAddressSuggestion);
  }, []);

  const handleChange = (e: any) => {
    setCoveredArea(false);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    setPinCodeFormValue("pinCode", updateValue);
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    setPinCodeFormValue("pinCode", searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase());
  };

  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };

  const submitAddressChange = async () => {
    let postalCode: any = localStorage.getItem("postalCode");
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      let addressSuggestions: any = [{ ...selectedAddress }];
      addressSuggestions[0].postalCode = postalCode;
      if (localStorage.getItem("addressSuggestions")) {
        let existingAddressSuggestion = JSON.parse(
          localStorage.getItem("addressSuggestions") || "[]"
        );
        addressSuggestions = addressSuggestions.concat(
          existingAddressSuggestion
        );
      }
      localStorage.setItem(
        "addressSuggestions",
        JSON.stringify(addressSuggestions)
      );
      setListOfSuggestedAddress(addressSuggestions);
      if (localStorage.getItem("token")) {
        // handle api
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (
          uniqueAddress.status ||
          uniqueAddress.message == "Address already exists"
        ) {
          navigateToCheckout();
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        navigateToCheckout();
      }
    }
  };

  const fetchCoveredAreaCodes = async () => {
    const base_url = process.env.REACT_APP_BACKEND_URL;
    try {
      const response = await axios({
        method: "get",
        url: `${base_url}/api/areas_covered_postcodes`,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Methods": "GET, POST",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (response.data.Response.response_code == "1") {
        setAreaData(response.data.data);
      } else {
        return false;
      }
    } catch (error) {}
  };

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    if (shop) {
      setCoveredArea(false);
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
        setCoveredArea(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
        await fetchCoveredAreaCodes();
        setCoveredArea(true);
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
      await fetchCoveredAreaCodes();
        setCoveredArea(true);
    }
  };

  const submitAddressFromSuggestion = async (selected: any) => {
    setSelectedSuggestedAddress(selected);
    localStorage.setItem("postalCode", selected.postalCode);
    localStorage.setItem(
      "selectedAddressBeforeLogin",
      JSON.stringify(selected)
    );
    if (localStorage.getItem("token")) {
      // handle api
      const contact_name = JSON.parse(localStorage.getItem("userDetails")!).data
        ?.user_info.name;
      const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
        .data?.user_info.mobile;
      const address = selected?.value?.split(",");
      const payloadData = {
        address_type: "pickup",
        address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
          selected.postalCode
        )}"}`,
      };
      const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
      if (
        uniqueAddress.status ||
        uniqueAddress.message == "Address already exists"
      ) {
        navigateToCheckout();
      } else {
        addressFormSetError("address", {
          type: "server",
          message: "Unable to set address try again later",
        });
      }
    } else {
      navigateToCheckout();
    }
  };
  return (
    <div
      className="sh5-home_single_search theme5"
      style={{
        background: `url(${jsonData?.theme_5?.home?.section_1?.banner}) no-repeat top center`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <div className="sh5-banner-search-wrap">
              <span className="sh5-banner-tag">
                {/* <img src={jsonData?.theme_5?.home?.section_1?.banner} alt="" className="me-2" />{" "} */}
              </span>
              <h1>{jsonData?.theme_5?.home?.section_1?.title}</h1>

              {/* <h1>
                A Professional <span>Dry Cleaning &amp; Laundry Service</span>{" "}
                in the UK
              </h1> */}
              <p className="d-none d-md-block">
                {jsonData?.theme_5?.home?.section_1?.paragraph}
              </p>
              <div className="sh5-postcodeform-container">
                <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                  <div
                    className={`${checkWithNewPin == false ? "d-none" : ""}`}
                  >
                    <small className="text-danger postvalidation">
                      {pinCodeFormError.pinCode?.message}
                    </small>
                    <div className="sh5-postcodeform">
                      <Controller
                        name="pinCode"
                        control={pinCodeFormControl}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            id="postcode-input"
                            value={postalCodeValue}
                            placeholder={
                              jsonData?.theme_5?.home?.section_1?.btn_group
                                ?.btn1?.placeholder
                            } 
                            className={`ui-autocomplete-input ${
                              pinCodeFormError.pinCode ? "invalid" : ""
                            }`}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        )}
                      />
                      <button
                        className="btn hover-btn"
                        id="book_now_btn"
                        type="submit"
                        style={{
                          background: `#${jsonData?.theme_5?.home?.section_1?.btn_group?.btn1?.bg_color}`,
                          color: `#${jsonData?.theme_5?.home?.section_1?.btn_group?.btn1?.color}`,
                        }}
                      >
                        {
                          jsonData?.theme_5?.home?.section_1?.btn_group?.btn1
                            ?.text
                        }
                      </button>
                      <div className="dropdown">
                        {postalCodeList
                          .filter((item: any) => {
                            const searchTerm = postalCodeValue.toLowerCase();
                            const mainValue = item.postcode.toLowerCase();

                            return (
                              searchTerm &&
                              mainValue.startsWith(searchTerm) &&
                              mainValue !== searchTerm
                            );
                          })
                          .slice(0, 10)
                          .map((item: any) => (
                            <div
                              onClick={() => onSearch(item.postcode)}
                              className="dropdown-row"
                              key={item.postcode}
                            >
                              <i className="fas fa-map-marker-alt"></i>
                              {item.postcode}
                            </div>
                          ))}
                      </div>
                      {coveredArea && (
                              <div className="dropdown">
                                <div className="dropdown-heading">
                                  Postcode areas we currently covered
                                </div>
                                {areaData.map((item: any) => (
                                  <div
                                    onClick={() => {
                                      onSearch(item);
                                      setCoveredArea(false);
                                    }}
                                    className="dropdown-row"
                                    key={item}
                                  >
                                    <i className="fas fa-map-marker-alt"></i>
                                    {item}
                                  </div>
                                ))}
                              </div>
                            )}
                    </div>
                  </div>
                </form>
                <form>
                  <div
                    className={`adrsselectform ${
                      checkWithNewPin == true ? "d-none" : ""
                    }`}
                  >
                    <small className="text-danger postvalidation">
                      {addressFormError.address?.message}
                    </small>
                    <div className="adrsselectdd adrsselectddinp">
                      <Controller
                        name="address"
                        control={addressFormControl}
                        render={({ field }) => (
                          <Select
                            placeholder="Select your address"
                            {...field}
                            value={selectedAddress}
                            className={`${
                              addressFormError.address ? "invalid" : ""
                            }`}
                            onChange={handleAddressChange}
                            onFocus={() => {
                              if (getValuesAddressForm("address").value == "") {
                                addressFormSetError("address", {
                                  type: "server",
                                  message: "Address is required to proceed",
                                });
                              } else {
                                clearErrors();
                              }
                            }}
                            options={areaOptions}
                          />
                        )}
                      />

                      <input
                        type="button"
                        value="Continue"
                        className="btn hover-btn"
                        onClick={submitAddressChange}
                      />
                    </div>
                    <div className="adrsselect-or">OR</div>
                    <div className="adrsselect-link">
                      <a
                        className="cursor-pointer"
                        onClick={() => {
                          setCheckWithNewPin(true);
                        }}
                      >
                        Check with another Postcode
                      </a>
                    </div>
                  </div>
                </form>
                {listOfSuggestedAddress.length > 0 && (
                  <div className={`adrsselectform`}>
                    <small className="d-block text-center my-2">
                      Or, Select recent address
                    </small>
                    <div className="adrsselectdd">
                      {/* <Select
                      placeholder="Select recent address"
                      value={selectedSuggestedAddress}
                      onChange={submitAddressFromSuggestion}
                      options={listOfSuggestedAddress}
                    /> */}
                      <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        pagination={{
                          el: ".swiper-pagination",
                          clickable: true,
                        }}
                        breakpoints={{
                          640: {
                            slidesPerView: 1,
                          },
                          768: {
                            slidesPerView: 1,
                          },
                          1024: {
                            slidesPerView: 2,
                          },
                        }}
                        className="slider-adrs"
                      >
                        {listOfSuggestedAddress.map(
                          (address: any, index: any) => {
                            return (
                              <>
                                <SwiperSlide key={index}>
                                  <div className="adrsgrid">
                                    {/* <i className="fas fa-arrow-alt-circle-right me-3"></i> */}
                                    <div
                                      className="adrsgriddv"
                                      key={index}
                                      onClick={() =>
                                        submitAddressFromSuggestion(address)
                                      }
                                    >
                                      {/* <i className="fas fa-map-marker-alt me-2"></i> */}
                                      <div className="adrs-label">
                                        {address.label}
                                        <span className="adrs-pcode">
                                          {address.postalCode}
                                        </span>
                                      </div>
                                    </div>
                                    {/* <i className="fas fa-map-marker-alt ms-auto"></i> */}
                                  </div>
                                </SwiperSlide>
                              </>
                            );
                          }
                        )}
                      </Swiper>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-5 d-none d-lg-block">
            <div className="sh5-heroimg">
              {jsonData?.theme_5?.home?.section_1?.banner_type == "image" ? (
                <img
                  src={jsonData?.theme_5?.home?.section_1?.banner_two}
                  alt=""
                  className="img-fluid"
                />
              ) : (
                <>
                  <video
                    loop
                    autoPlay
                    className="img-fluid"
                    src={jsonData?.theme_5?.home?.section_1?.banner_two}
                  ></video>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionOneThemeFive;
