import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {slider1,slider2,slider3,slider4,slider5,slider6,slider7,slider8,upimg2} from "../../assets/img";

function Ironing () {

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">                    
                    <div className="slider-up">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        className="slider-up-slider"
                        >                                                                                   
                            <SwiperSlide >                            
                                <img src={slider3} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider4} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider5} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider6} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider7} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>                            
                            <SwiperSlide >                            
                                <img src={slider8} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider1} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider2} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide> 
                      </Swiper>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">   
                    <div className="up-content">
                        <h2 className="up-h2">IRONING</h2>
                        <p>
                        Our reliable, professional and convenient ironing service takes away the hassle of this chore.
                        </p>
                        <p>Just include ironing as an option to your service wash or give us a call to arrange a pick up.</p>
                        <h4>Price List</h4>
                        <b className="up-sub-head">Serviettes</b>
                        <p>Hand Towels*** 25p per item</p>
                        <p>Bath towels 30p per item</p>
                        <p>Table Clothes 36x36 50p per item</p>
                        <p>Table clothes 54x54 £1.00 per item</p>
                        <p>Large table Clothes £1.20 per item</p>
                        <p>Large Round/Oval £3.00 per item</p>
                        <b className="up-sub-head">Sheet, Bed Sheets</b>
                        <p>Single Size £1.50 per item</p>
                        <p>Double/King Size £2.00 per item</p>
                        <p>Pillowcases £0.40 per item</p>
                        <b className="up-sub-head">Duvet Covers</b>
                        <p>Single Size £1.50 per item</p>
                        <p>Double/King Size £2.00 per item</p>
                        <b className="up-sub-head">Shirts/T-Shirts & Jumpers</b>
                        <p>Children Size (Wash & Dry) £0.70 per item</p>
                        <p>Adult Size (Wash & Dry) £1.50 per item</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="up-img">
                        <img src={upimg2} alt="" />
                    </div>
                    <div className="up-card-bg">
                        <p>Book a Pickup - Call 01792 644238 or<br/> text 07999 999294</p>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default Ironing;