import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import ImageCropper from "../../../imageCropper/imageCropper";
import {
  setShopHomeCms,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";

function SectionSixBannerThemeFive() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [showCropModalTwo, setShowCropModalTwo] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const data: any = useSelector<any>((state) => state.shopHomeJsonList);
  const section6 = editData?.theme_5?.home?.section_6;
  const bannerImages: Array<string> = [
    "/assets/img/about-section/about-img.png",
    "/assets/img/about-section/about-img-1.png",
    "/assets/img/about-section/about-img-2.png",
    "/assets/img/about-section/about-img.png",
    "/assets/img/about-section/about-img-1.png",
    "/assets/img/about-section/about-img-2.png",
  ];
  const icons = [
    "/assets/img/about-section/about-icon-01.png",
    "/assets/img/about-section/about-icon-02.png",
    "/assets/img/about-section/about-icon-03.png",
    "/assets/img/about-section/about-icon-04.png",
    "/assets/img/about-section/about-icon-05.png",
    "/assets/img/about-section/about-icon-06.png",
    "/assets/img/about-section/about-icon-07.png",
    "/assets/img/about-section/about-icon-08.png",
  ];
  useEffect(() => {
    setEditData(data);
  }, [data]);

  const saveJsonDataToFile = () => {
    dispatch(setShopHomeCms(editData));
    dispatch(saveThemeJsonData(editData));
  };
  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    let updatedCollection: any = [
      ...editData.theme_5.home.section_6.card_section.collection,
    ];
    if (index != undefined) {
      updatedCollection = [
        ...editData.theme_5.home.section_6.card_section.collection,
      ];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        home: {
          ...prevJsonData.theme_5.home,
          section_6: {
            ...prevJsonData.theme_5.home.section_6,
            [name]: value,
            card_section: {
              ...prevJsonData.theme_5.home.section_6.card_section,
              ...prevJsonData.theme_5.home.section_6.card_section.collection[
                index
              ],
              collection: updatedCollection,
            },
          },
        },
      },
    }));
  };
  const handleBannerImageChange = (image: string) => {
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        home: {
          ...prevJsonData.theme_5.home,
          section_6: {
            ...prevJsonData.theme_5.home.section_6,
            section_image: image,
            card_section: {
              collection: [
                ...editData.theme_5.home.section_6.card_section.collection,
              ],
            },
          },
        },
      },
    }));
  };
  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_5.home.section_6.card_section.collection,
    ];
    updatedCollection.splice(index, 1);
    setActiveTab(activeTab - 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        home: {
          ...prevJsonData.theme_5.home,
          section_6: {
            ...prevJsonData.theme_5.home.section_6,
            card_section: {
              collection: updatedCollection,
            },
          },
        },
      },
    }));
  };
  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [
      ...editData.theme_5.home.section_6.card_section.collection,
    ];
    updatedCollection[index] = {
      ...updatedCollection[index],
      collection_image: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        home: {
          ...prevJsonData.theme_5.home,
          section_6: {
            ...prevJsonData.theme_5.home.section_6,
            card_section: {
              collection: updatedCollection,
            },
          },
        },
      },
    }));
  };

  // const handleCroppedImage = async (image: any) => {
  //   setShowCropModal(false);
  //   setImagedata(image);
  // };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleBannerImageChange(imageLink);
    }
  };

  const handleCroppedImageTwo = async (image: any) => {
    setShowCropModalTwo(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink, activeTab);
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  const updateImageSectionTwo = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModalTwo(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImageTwo(res.target.result);
      };
    }
  };

  const addCollection = () => {
    let updatedCollection: any = [
      ...editData.theme_5.home.section_6.card_section.collection,
    ];

    let newCard = {
      collection_title: "",
      collection_image: "/assets/img/about-section/about-icon-01.png",
      isEnabled: true,
      collection_paragraph: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_5: {
        home: {
          ...prevJsonData.theme_5.home,
          section_6: {
            ...prevJsonData.theme_5.home.section_6,
            card_section: {
              collection: updatedCollection,
            },
          },
        },
      },
    }));
  };
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section6 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={section6?.title}
                      onChange={(e) => {
                        if (e.target.value.length < 251) {
                          handleChange(e);
                        }
                      }}
                      maxLength={250}
                    />
                  </div>
                  </div>
                  <div className="col-md-auto">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          section6?.h2color
                        }
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          section6?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                  </div>
                  <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">
                      Sub Heading<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sub_heading"
                      value={section6?.sub_heading}
                      onChange={(e) => {
                        if (e.target.value.length < 120) {
                          handleChange(e);
                        }
                      }}
                      maxLength={250}
                    />
                  </div>
                  </div>
                  <div className="col-md-auto">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          section6?.h3color
                        }
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          section6?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                  </div>
                  <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">
                      Paragraph<small>(max 1200 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={section6?.paragraph}
                      onChange={(e) => {
                        if (e.target.value.length < 1200) {
                          handleChange(e);
                        }
                      }}
                      maxLength={1200}
                    />
                  </div>
                  </div>
                  <div className="col-md-auto">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          section6?.paracolor
                        }
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          section6?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
                  </div>
                  <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="background_image_color"
                        value={section6?.background_image_color}
                        onChange={handleChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="background_image_color"
                        format="hex"
                        onChange={handleChange}
                        value={section6?.background_image_color}
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">Upload Image</label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      className="form-control"
                      name="image"
                      onChange={updateImageSectionOne}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="img-bg active">
                          <img src={section6?.section_image} alt="Hero Image" />
                        </div>
                        <div className="img-options">
                          <div className="row">
                            <div className="col-md-12">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 4,
                                  },
                                  1024: {
                                    slidesPerView: 6,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {bannerImages.map(
                                  (images: any, imgIndex: any) => {
                                    return (
                                      <>
                                        <SwiperSlide key={imgIndex}>
                                          <div className="img-option">
                                            <img
                                              onClick={() => {
                                                handleBannerImageChange(images);
                                              }}
                                              src={images}
                                              alt="Hero Image"
                                              className="img-fluid"
                                            />
                                          </div>
                                        </SwiperSlide>
                                      </>
                                    );
                                  }
                                )}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_5?.home?.section_6?.card_section?.collection.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Card Section {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_5?.home?.section_6?.card_section?.collection.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.collection_image}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {icons.map((icons, iconIndex) => (
                                <SwiperSlide key={iconIndex}>
                                  <div
                                    className="img-option"
                                    onClick={() =>
                                      handleImageChange(icons, index)
                                    }
                                  >
                                    <img
                                      src={icons}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Title<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_title"
                          value={card.collection_title}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={250}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Paragraph<small>(max 1200 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_paragraph"
                          value={card.collection_paragraph}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={1200}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionTwo}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
      {showCropModalTwo ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImageTwo}
              close={() => {
                setShowCropModalTwo(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default SectionSixBannerThemeFive;
