import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function SectionEightThemeThree() {
  const data: any = useSelector<any>((state) => state?.shopHomeJsonList);
  const section_8 = data.theme_3.home.section_8;
  return (
    <>
      <section className="sh3-trust-section" id="sh3about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sh3-trust-content">
              <h2 style={{
                      color: `#${section_8?.h2color}`
                    }}>{section_8.title}</h2>
                <p style={{
                      color: `#${section_8?.paracolor}`
                    }}>{section_8.paragraph}</p>
                <ul className="sh3-trust-content-media">
                  {section_8.card_section.collection.map((items: any) => {
                    return (
                      <>
                        <li>
                          <div className="d-flex">
                            <span className="flex-shrink-0 sh3-icon-box">
                              <img src={items.collection_image} alt="" />
                            </span>
                            <div className="flex-grow-1">
                            <h4 style={{
                      color: `#${section_8?.h3color}`
                    }}> {items.collection_title} </h4>
                              <p style={{
                      color: `#${section_8?.paracolor}`
                    }}>{items.collection_paragraph}</p>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
                <div className="sh3-trust-content-btn d-none">
                  <a
                    style={{
                      background: `#${section_8?.btn_group?.btn2_bg_color}`,
                      color: `#${section_8?.btn_group?.btn2_color}`,
                    }}
                    href="#book-now"
                    className="btn title-btn hover-btn"
                  >
                    {section_8.btn_group.btn1_text}
                    <i className="uil uil-arrow-circle-right ms-2"></i>
                  </a>
                  <a
                    style={{
                      background: `#${section_8?.btn_group?.btn1_bg_color}`,
                      color: `#${section_8?.btn_group?.btn1_color}`,
                    }}
                    href="#book-now"
                    className="btn primary-btn hover-btn"
                  >
                    {section_8.btn_group.btn2_text}
                    <i className="uil uil-arrow-circle-right ms-2"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sh3-split-img">
                <img src={section_8.section_image} alt="" />
                <div className="sh3-split-img-framegrid">
                  <div className="sh3-split-img-frame1"></div>
                  <div className="sh3-split-img-frame2"></div>
                  <div className="sh3-split-img-frame3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionEightThemeThree;
