import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  fetchProductAllCategories,
  setUserProductCategories,
} from "../../redux/Actions/productCategoriesAction";
import {
  deleteCartCount,
  setCartCount,
} from "../../redux/Actions/cartCountAction";
import { fetchAddToCart } from "../../redux/Actions/addCartAction";
import { setValue } from "../../utility";
import { fetchSplitPriceDetails } from "../../redux/Actions/splitPriceAction";

function BookingDomestic() {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const state: any = useSelector<any>(
    (state) => state.ProductAllCategories.data
  );
  const selectedcategoryPriceList: any = useSelector<any>(
    (state) => state.splitPriceDetails
  );
  const selectedCategories: any = useSelector<any>(
    (state) => state.userSelectedCategories
  );
  const { cartCount }: any = useSelector<any>((state) => state);
  const [productCategories, setProductCategories] = useState<any>([]);
  const dispatch = useDispatch<any>();

  const handleContinue = () => {
    setValue("orderType", 0);
    const selectedMainCategory = selectedCategories?.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });

    const postalCode = JSON.parse(localStorage.getItem("postalCode")!);
    const shopId = localStorage.getItem("shop_id");
    let finalTotalPrice: any = 0;

    let payload: any = {
      shop_id: "test",
      postal_code: "test",
      total_tax_price: selectedcategoryPriceList?.vat_amount,
      total_price_without_deductions:
        selectedcategoryPriceList?.final_payable_amount,
      total_price_without_vat:
        parseFloat(selectedcategoryPriceList?.total_price_amount) -
        parseFloat(selectedcategoryPriceList?.vat_amount),
      group_id: "1",
      order_type: "0",
      cart_total_count: selectedcategoryPriceList?.itemsCount,
    };

    selectedMainCategory?.map((item: any) => {
      const subcategoryList = item?.sub_categories?.filter(
        (subItem: any) => parseInt(subItem.quantity) > 0
      );

      subcategoryList?.map((subcategory: any) => {
        //TODO: handle thru single selection from the dropdown
        const selectedOption = subcategory?.options[0]; //subcategory?.options?.find((option:any) => option?.isSelected)

        finalTotalPrice =
          finalTotalPrice +
          parseInt(subcategory?.quantity) *
            parseFloat(subcategory?.total_price);
        payload = {
          ...payload,
          [`option_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_id,
          [`process_time_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.process_time,
          [`productcount_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`quantity_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`price_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_price,
          [`product_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.sub_category_name,
        };
      });
    });

    payload = { ...payload, total_price: finalTotalPrice.toString() };
    localStorage.setItem("order_type", payload.order_type);
    localStorage.setItem("shop_id", payload.shop_id);
    // TODO: call the api and pass the
    // dispatch(fetchPickupDelivery(payload))
    dispatch(fetchAddToCart(payload));
  };
  const onAddToCartItemHandler = (
    selectedSubCategoryId: any,
    selectedMainCategoryId: any,
    type: any
  ) => {
    const selectedCategoryItem: any = productCategories?.find(
      (item: any) => item.main_category_id === selectedMainCategoryId.toString()
    );

    const getSubCategory = selectedCategoryItem?.sub_categories?.find(
      (item: any) => item.sub_category_id === selectedSubCategoryId
    );

    const { quantity = 0 } = getSubCategory;
    let updatedQuantity = parseInt(quantity);
    if (type === "minus") {
      updatedQuantity = updatedQuantity - 1;
      dispatch(deleteCartCount(1));
    } else {
      localStorage.removeItem("is_removed");
      updatedQuantity = updatedQuantity + 1;
      dispatch(setCartCount(cartCount + 1));
    }

    const updatedSubCategory = {
      ...getSubCategory,
      quantity: updatedQuantity.toString(),
    };

    const updatedSubCategoriesList = selectedCategoryItem?.sub_categories?.map(
      (item: any) => {
        if (item.sub_category_id === selectedSubCategoryId) {
          return updatedSubCategory;
        }
        return item;
      }
    );

    const updatedCategory = {
      ...selectedCategoryItem,
      sub_categories: updatedSubCategoriesList,
    };

    let updatedCategoriesList = productCategories?.map((item: any) => {
      if (item.main_category_id === selectedMainCategoryId.toString()) {
        return updatedCategory;
      }
      return item;
    });

    if (localStorage.getItem("cartInformationData")) {
      const cartInformationData = JSON.parse(
        localStorage.getItem("cartInformationData") || "[]"
      );
      const extstingSubCat = cartInformationData[0].sub_categories;

      updatedCategoriesList[0].sub_categories = extstingSubCat.map(
        (item: any) => {
          item.quantity =
            item.sub_category_id == selectedSubCategoryId
              ? parseInt(item.quantity) + 1
              : item.quantity;
          return item;
        }
      );
      dispatch(setCartCount(cartCount + 1));
    }

    dispatch(setUserProductCategories(updatedCategoriesList));
    handleContinue();
  };

  useEffect(() => {
    if (state) {
      const { category = [] } = state;
      setProductCategories(category);
      if (category?.length > 0) {
        if (!localStorage.getItem("isloaded")) {
          if (localStorage.getItem("is_removed")) {
            let cat = category?.filter((item: any) => {
              return item?.sub_categories?.some(
                (subItem: any) => parseInt(subItem?.quantity) > 0
              );
            });

            if (cat.length == 0) {
              cat = category;
            }

            let subcat = cat[0]?.sub_categories?.find(
              (subItem: any) => parseInt(subItem?.quantity) > 0
            );

            if (subcat == undefined || subcat.length == 0) {
              subcat = category[0]?.sub_categories[0];
            }

            const selectedCategoryItem: any = category?.find(
              (item: any) =>
                item.main_category_id === cat[0].main_category_id.toString()
            );

            const getSubCategory = selectedCategoryItem?.sub_categories?.find(
              (item: any) => item.sub_category_id === subcat.sub_category_id
            );

            const updatedSubCategory = {
              ...getSubCategory,
              quantity: 0,
            };

            const updatedSubCategoriesList =
              selectedCategoryItem?.sub_categories?.map((item: any) => {
                if (item.sub_category_id === subcat.sub_category_id) {
                  return updatedSubCategory;
                }
                return item;
              });

            const updatedCategory = {
              ...selectedCategoryItem,
              sub_categories: updatedSubCategoriesList,
            };
            const updatedCategoriesList = category?.map((item: any) => {
              if (
                item.main_category_id === (cat[0]?.main_category_id).toString()
              ) {
                return updatedCategory;
              }
              return item;
            });

            dispatch(setUserProductCategories(updatedCategoriesList));
          } else {
            dispatch(setUserProductCategories(category));
          }

          localStorage.setItem("isloaded", "true");
        }
      }
    }
  }, [state]);
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductCategories(e.data.data.category);
      });

  }, []);
  return (
    <>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Booking Domestic</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>Booking Domestic</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="mb-3">
              <ul className="wp-prodlist">
                {productCategories.length > 0 &&
                  productCategories[0].sub_categories?.map((item: any) => {
                    return (
                      <li>
                        <NavLink
                          onClick={() =>
                            onAddToCartItemHandler(
                              item.sub_category_id,
                              productCategories[0].main_category_id,
                              "add"
                            )
                          }
                          to={"/cart"}
                          className="btn btn-primary"
                        >
                          {item.sub_category_name}
                        </NavLink>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BookingDomestic;
