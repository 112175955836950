import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchSplitPriceDetails } from "../../redux/Actions/splitPriceAction";
import {
  alphabet,
  email,
  phoneNumber,
  alphaNumericPattern,
  addressPattern,
  UKPostalCode,
} from "../core/regex";
import { postContactForm } from "../../redux/Actions";
import Payment from "../payment";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCreateSetUpIntent,
  fetchOfferDetailsList,
  fetchPaymentMethods,
  fetchSaveAddress,
  fetchSubmitOrder,
  saveOrderLog,
} from "../../redux/Actions/checkoutPageActions";
import {
  fetchProductAllCategories,
  setProductAllCategories,
  setUserProductCategories,
} from "../../redux/Actions/productCategoriesAction";
import { offers } from "../../assets/img";

function CheckoutWheatley() {
  const dispatch = useDispatch<any>();
  const selectedCategories: any = JSON.parse(
    localStorage.getItem("cartInformationData") || "[]"
  );
  const stripeId: any = useSelector<any>(
    (state) => state?.paymentMethods.stripe_key
  );
  const [message, setMessage] = useState<string>("");
  const [cartInformation, setCartInformation] = useState([]);
  const [shipping, setShipping] = useState<boolean>(false);
  const [cardStatus, setCardStatus] = useState<any>(0);
  const [cardId, setCardId] = useState<any>(0);
  const selectedcategoryPriceList: any = useSelector<any>(
    (state) => state.splitPrice
  );
  const offersDetailList: any = useSelector<any>((state) => state.offersList);

  const settings: any = useSelector<any>((state) => state.settings);
  const [couponCode, setCouponCode] = useState<any>("");
  const applyCouponButton = useRef<any>(null);
  const [couponCodeOffer, setCouponCodeOffer] = useState<any>(false);
  const [couponCodeDetails, setCouponCodeDetails] = useState<any>([]);
  const [couponError, setCouponError] = useState<any>(false);

  const navigate = useNavigate();
  const FormSchema = yup.object({
    billingAddress: yup.object({
      first_name: yup
        .string()
        .required("First Name is required")
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "First Name should be only alphabet")
        .trim(),
      last_name: yup
        .string()
        .required("Last Name is required")
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "Last Name should be only alphabet")
        .trim(),
      company_name: yup
        .string()
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "Company Name should be only alphabet")
        .trim(),
      house_number: yup
        .string()
        .required("House number and street name is required")
        .max(50, "Field cannot exceed 50 character")
        .matches(
          addressPattern,
          "Address should not contain any special character"
        )
        .trim(),
      street_name: yup
        .string()
        .max(50, "Field cannot exceed 50 character")
        .matches(
          addressPattern,
          "Address should not contain any special character"
        )
        .trim(),
      city: yup
        .string()
        .required("City is required")
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "City Name should be only alphabet")
        .trim(),
      country: yup
        .string()
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "Country Name should be only alphabet")
        .trim(),
      postalCode: yup
        .string()
        .required("Postal code is required")
        .matches(UKPostalCode, "Enter a valid postal code")
        .trim(),
      email: yup
        .string()
        .required("Email address is required")
        .matches(email, "Enter a valid email address")
        .max(96, "Enter cannot exceed 96 character")
        .trim(),
      mobile: yup
        .string()
        .required("Mobile number is required")
        .matches(phoneNumber, "Enter a valid mobile number")
        .trim(),
      notes: yup
        .string()
        .matches(
          alphaNumericPattern,
          "Message cannot not contain any special character"
        )
        .max(250, "Message cannot exceed 250 character")
        .trim(),
    }),
    isShippingDifferentAsBilling: yup.boolean().default(false),
    agree_t_c: yup.boolean().required("Accept T&C to proceed").oneOf([true]),
    shippingAddress: yup.object({
      first_name: yup
        .string()
        .test("isRequired", "First Name is required", function (value: any) {
          if (value) return true;
          if (value === "" || value ===  undefined) return !shipping;
        })
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "First Name should be only alphabet")
        .trim(),
      last_name: yup
        .string()
        .test("isRequired", "Last Name is required", function (value: any) {
          if (value) return true;
          if (value === "" || value ===  undefined) return !shipping;
        })
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "Last Name should be only alphabet")
        .trim(),
      company_name: yup
        .string()
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "Company Name should be only alphabet")
        .trim(),
      house_number: yup
        .string()
        .test(
          "isRequired",
          "House number and street name is required",
          function (value: any) {
            if (value) return true;
            if (value === "" || value ===  undefined) return !shipping;
          }
        )
        .max(50, "Field cannot exceed 50 character")
        .matches(
          addressPattern,
          "Address should not contain any special character"
        )
        .trim(),
      street_name: yup
        .string()
        .max(50, "Field cannot exceed 50 character")
        .matches(
          addressPattern,
          "Address should not contain any special character"
        )
        .trim(),
      city: yup
        .string()
        .test("isRequired", "City is required", function (value: any) {
          if (value) return true;
          if (value === "" || value ===  undefined) return !shipping;
        })
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "City Name should be only alphabet")
        .trim(),
      country: yup
        .string()
        .max(50, "Field cannot exceed 50 character")
        .matches(alphabet, "Country Name should be only alphabet")
        .trim(),
      postalCode: yup
        .string()
        .test("isRequired", "Postal code is required", function (value: any) {
          if (value) return true;
          if (value === "" || value ===  undefined) return !shipping;
        })
        .matches(UKPostalCode, "Enter a valid postal code")
        .trim(),
    }),
  });
  const {
    handleSubmit: billingFormSubmit,
    control: billingFormControl,
    reset: billingFormReset,
    setValue: billingFormSetValue,
    getValues: billingFormGetValue,
    formState: { errors: billingFormErrors, isValid: billingFormValidity },
  } = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: {},
  });
  const orderDetails = cartInformation?.map((item: any) => {
    return {
      main_category_id: item?.main_category_id,
      sub_categories: item?.sub_categories
        ?.filter((subItem: any) => parseInt(subItem.quantity) > 0)
        ?.map((subCategory: any) => {
          let selectedOption = 1;
          subCategory.options.forEach((option: any) => {
            if (option.is_default == 1) {
              selectedOption = option.option_id;
            }
          });
          return {
            sub_category_id: subCategory?.sub_category_id,
            quantity: subCategory?.quantity,
            price: subCategory?.total_price * subCategory?.quantity,
            option_selected: selectedOption,
          };
        }),
    };
  });
  const onPlaceHolderBtnClickHandler = async (
    data: any,
    billingAddressId: any,
    shippingAddressId: any
  ) => {
    if (billingFormValidity) {
      const payloadDataa = {
        slug: "place_order_clicked",
      };
      dispatch(saveOrderLog(payloadDataa));
      let setupIntent = localStorage.getItem("setup_intent");
      if (cardStatus == 1) {
        setupIntent = "";
      }
      let iscod = 0;

      if (cardStatus == 2) {
        iscod = 1;
      }

      let orderType = 0;

      const payloadSubmitData = {
        order_type: orderType,
        postal_code: data.billingAddress.postalCode,
        group_id: 1,
        shop_id: 1,
        total_amount: selectedcategoryPriceList?.total_price_amount,
        vat_amount: selectedcategoryPriceList?.vat_amount,
        pickup_date: null,
        pickup_time_slot_id: null,
        delivery_date: null,
        delivery_time_slot_id: null,
        pickup_address: shippingAddressId,
        billing_address: billingAddressId,
        card_details: {
          card_status: cardStatus === 1 ? 1 : 0,
          card_id: cardId,
          setup_intent_id: setupIntent,
        },
        device_info: {
          device: "Desktop",
          platform: "Windows",
          browser: navigator?.userAgent,
        },
        applied_offer_id: 0, //TODo
        original_price: 0, // TODO (total_price_amount - only if coupon applied)
        discount_price: 0, //TOdo (total_price_amount - (total_price_amount - offer discount price))
        price: 0, // TODO (total_price_amount - discount_price)
        order_items: orderDetails,
        cod: iscod,
      };
      const offerPayloadSubmitData = {
        order_type: orderType, //myOrders?.orders_list?.order_type,
        postal_code: data.billingAddress.postalCode,
        group_id: 1,
        shop_id: 1,
        total_amount: JSON.stringify(
          selectedcategoryPriceList?.total_price_amount -
            selectedcategoryPriceList?.offer_amount
        ),
        vat_amount: selectedcategoryPriceList?.vat_amount,
        pickup_date: null,
        pickup_time_slot_id: null,
        delivery_date: null,
        delivery_time_slot_id: null,
        pickup_address: shippingAddressId,
        billing_address: billingAddressId,
        card_details: {
          card_status: cardStatus === 1 ? 1 : 0,
          card_id: cardId,
          setup_intent_id: setupIntent,
        },
        device_info: {
          device: "Desktop",
          platform: "Windows",
          browser: navigator?.userAgent,
        },
        applied_offer_id: offersDetailList.data?.offer_details[0]?.id, //TODo
        original_price: selectedcategoryPriceList?.total_price_amount, // TODO (total_price_amount - only if coupon applied)
        discount_price: selectedcategoryPriceList?.offer_amount, //TOdo (total_price_amount - (total_price_amount - offer discount price))
        price: JSON.stringify(
          selectedcategoryPriceList?.total_price_amount -
            selectedcategoryPriceList?.offer_amount
        ), // TODO (total_price_amount - discount_price)
        order_items: orderDetails,
        cod: iscod,
      };

      if (couponCodeOffer) {
        await dispatch(fetchSubmitOrder(offerPayloadSubmitData));
      } else {
        await dispatch(fetchSubmitOrder(payloadSubmitData));
      }

      const submitOrderResponses = JSON.parse(
        localStorage.getItem("submitOrderResponses")!
      );

      localStorage.removeItem("submitOrderResponses");

      if (submitOrderResponses.Response.response_code === "1") {
        navigate("/thankyou");
        billingFormReset();
      }
    }
  };
  const submitContactForm = async (data: any) => {
    if (billingFormValidity) {
      const contact_name = JSON.parse(localStorage.getItem("userDetails")!).data
        ?.user_info.name;
      const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
        .data?.user_info.mobile;
      const payloadDataBilling = {
        address_type: "pickup",
        address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${
          data.billingAddress.house_number
        }","line_2":"${
          data.billingAddress.street_name ?? ""
        }","line_3":"","line_4":"","locality":"","city":"${
          data.billingAddress.city
        }","county":"${
          data.billingAddress.country ?? ""
        }","label":"Home","pincode":"${data.billingAddress.postalCode}"}`,
      };
      if (data.isShippingDifferentAsBilling === false) {
        const getSameAddress = await dispatch(
          fetchSaveAddress(payloadDataBilling)
        );
        if (getSameAddress) {
          const savedAddress = JSON.parse(
            localStorage.getItem("savedAddress")!
          );
          localStorage.removeItem("savedAddress");
          
          onPlaceHolderBtnClickHandler(
            data,
            savedAddress?.data.address_id,
            savedAddress?.data.address_id
          );
        }
      } else {
        const payloadDataShipping = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${
            data.shippingAddress.house_number
          }","line_2":"${
            data.shippingAddress.street_name ?? ""
          }","line_3":"","line_4":"","locality":"","city":"${
            data.shippingAddress.city
          }","county":"${
            data.shippingAddress.country ?? ""
          }","label":"Home","pincode":"${data.shippingAddress.postalCode}"}`,
        };

        dispatch(fetchSaveAddress(payloadDataBilling)).then(
          async (billAdd: any) => {
            const getShippingAddress = await dispatch(
              fetchSaveAddress(payloadDataShipping)
            );
            
            
            onPlaceHolderBtnClickHandler(
              data,
              billAdd.data?.data.address_id,
              getShippingAddress.data?.data.address_id
            );
          }
        );
      }
    }
  };
  const handleOfferList = (event: any) => {
    const { value } = event.target;
    setCouponCode(value);
    if (event.target.value.length > 0) {
      setCouponError(true);
    } else {
      setCouponError(false);
    }
  };
  const onApplyCouponCodeHandler = () => {
    // API call
    dispatch(fetchOfferDetailsList());
    if (offersDetailList.Response?.response_message === "success") {
      const offerDetails = offersDetailList?.data?.offer_details?.filter(
        (item: any) =>
          item?.offer_code.toLowerCase() === couponCode.toLowerCase()
      );

      if (offerDetails?.length > 0) {
        const PriceDetails = JSON.parse(localStorage.getItem("payload") || "");
        const offerId = offersDetailList.data?.offer_details[0].id || "0";
        const itemsCount = PriceDetails.cart_total_count;
        const finalTotalPrice = PriceDetails.total_price;
        dispatch(
          fetchSplitPriceDetails({ finalTotalPrice, itemsCount, offerId })
        );
        setCouponCodeDetails(offerDetails);
        setCouponCodeOffer(true);
      } else {
        setCouponCodeDetails([]);
        setCouponCodeOffer(false);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchOfferDetailsList()).then((data: any) => {
      if (data.Response?.response_message === "success") {
        const offerDetails = data?.data?.offer_details;
        if (offerDetails?.length > 0) {
          setCouponCode(offerDetails[0].offer_code);
          setCouponCodeDetails(offerDetails);
          // setCouponCodeOffer(true);
          // applyCouponButton.current.click();
          
        }
      }
    });
  }, []);
  const handleCardStatus = (event: any) => {
    if (event == 0) {
      dispatch(fetchCreateSetUpIntent(cardStatus));
    }
    setCardStatus(event);
  };

  const handleCardId = (event: any) => {
    setCardId(event);
  };

  useEffect(() => {
    if (localStorage.getItem("cartInformationData")) {
      const cartInformationData = selectedCategories.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });
      setCartInformation(cartInformationData);
      const selectedMainCategory = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });
      let finalTotalPrice = 0;
      let itemsCount = 0;

      selectedMainCategory?.map((item: any) => {
        const subcategoryList = item?.sub_categories?.filter(
          (subItem: any) => parseInt(subItem.quantity) > 0
        );
        itemsCount = itemsCount + subcategoryList?.length;
        subcategoryList?.map((subcategory: any) => {
          finalTotalPrice =
            finalTotalPrice +
            parseInt(subcategory?.quantity) *
              parseFloat(subcategory?.total_price);
        });
      });
      dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (billingFormValidity) {
      localStorage.setItem(
        "postalCode",
        JSON.stringify(billingFormGetValue("billingAddress.postalCode"))
      );
    }
  }, [billingFormControl]);

  useEffect(() => {
    dispatch(fetchPaymentMethods());
    dispatch(fetchProductAllCategories());
    dispatch(
      setUserProductCategories(
        JSON.parse(localStorage.getItem("cartInformationData") || "[]")
      )
    );
  }, []);

  return (
    <>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Checkout</h2>
                <ul>
                  <li>
                    <Link to="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </Link>
                  </li>
                  <li>
                    <span>Checkout</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <form onSubmit={billingFormSubmit(submitContactForm)}>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="checkout-list  my-5">
                <h2>Billing address</h2>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label>First Name *</label>
                      <Controller
                        name="billingAddress.first_name"
                        control={billingFormControl}
                        render={({ field }) => (
                          <input
                            {...field}
                            onClick={() => {
                              
                            }}
                            className={`form-control ${
                              billingFormErrors?.billingAddress?.first_name
                                ? "invalid"
                                : ""
                            }`}
                          />
                        )}
                      />
                      {billingFormErrors?.billingAddress?.first_name ? (
                        <small className="text-danger">
                          {
                            billingFormErrors.billingAddress?.first_name
                              ?.message
                          }
                        </small>
                      ) : null}
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label>Last Name *</label>
                      <Controller
                        name="billingAddress.last_name"
                        control={billingFormControl}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${
                              billingFormErrors.billingAddress?.last_name
                                ? "invalid"
                                : ""
                            }`}
                          />
                        )}
                      />
                      {billingFormErrors.billingAddress?.last_name ? (
                        <small className="text-danger">
                          {billingFormErrors.billingAddress?.last_name?.message}
                        </small>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label>Company Name</label>
                  <Controller
                    name="billingAddress.company_name"
                    control={billingFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${
                          billingFormErrors.billingAddress?.company_name
                            ? "invalid"
                            : ""
                        }`}
                      />
                    )}
                  />
                  {billingFormErrors.billingAddress?.company_name ? (
                    <small className="text-danger">
                      {billingFormErrors.billingAddress?.company_name?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Street address *</label>
                  <Controller
                    name="billingAddress.house_number"
                    control={billingFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${
                          billingFormErrors.billingAddress?.house_number
                            ? "invalid"
                            : ""
                        }`}
                      />
                    )}
                  />
                  {billingFormErrors.billingAddress?.house_number ? (
                    <small className="text-danger">
                      {billingFormErrors.billingAddress?.house_number?.message}
                    </small>
                  ) : null}
                  <Controller
                    name="billingAddress.street_name"
                    control={billingFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        placeholder="Optional"
                        className={`form-control ${
                          billingFormErrors.billingAddress?.street_name
                            ? "invalid"
                            : ""
                        }`}
                      />
                    )}
                  />
                  {billingFormErrors.billingAddress?.street_name ? (
                    <small className="text-danger">
                      {billingFormErrors.billingAddress?.street_name?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Town / City *</label>
                  <Controller
                    name="billingAddress.city"
                    control={billingFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${
                          billingFormErrors.billingAddress?.city
                            ? "invalid"
                            : ""
                        }`}
                      />
                    )}
                  />
                  {billingFormErrors.billingAddress?.city ? (
                    <small className="text-danger">
                      {billingFormErrors.billingAddress?.city?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>County (optional)</label>
                  <Controller
                    name="billingAddress.country"
                    control={billingFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${
                          billingFormErrors.billingAddress?.country
                            ? "invalid"
                            : ""
                        }`}
                      />
                    )}
                  />
                  {billingFormErrors.billingAddress?.country ? (
                    <small className="text-danger">
                      {billingFormErrors.billingAddress?.country?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Postcode *</label>
                  <Controller
                    name="billingAddress.postalCode"
                    control={billingFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${
                          billingFormErrors.billingAddress?.postalCode
                            ? "invalid"
                            : ""
                        }`}
                      />
                    )}
                  />
                  {billingFormErrors.billingAddress?.postalCode ? (
                    <small className="text-danger">
                      {billingFormErrors.billingAddress?.postalCode?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Phone *</label>
                  <Controller
                    name="billingAddress.mobile"
                    control={billingFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${
                          billingFormErrors.billingAddress?.mobile
                            ? "invalid"
                            : ""
                        }`}
                      />
                    )}
                  />
                  {billingFormErrors.billingAddress?.mobile ? (
                    <small className="text-danger">
                      {billingFormErrors.billingAddress?.mobile?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label>Email address *</label>
                  <Controller
                    name="billingAddress.email"
                    control={billingFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${
                          billingFormErrors.billingAddress?.email
                            ? "invalid"
                            : ""
                        }`}
                      />
                    )}
                  />
                  {billingFormErrors.billingAddress?.email ? (
                    <small className="text-danger">
                      {billingFormErrors.billingAddress?.email?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <Controller
                      name="isShippingDifferentAsBilling"
                      control={billingFormControl}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          {...field}
                          checked={shipping}
                          value={String(shipping)}
                          onChange={() => {
                            billingFormSetValue(
                              "isShippingDifferentAsBilling",
                              !shipping
                            );
                            setShipping(!shipping);
                          }}
                          id="defaultCheck1"
                        />
                      )}
                    />

                    <label className="form-check-label">
                      Ship to a different address?
                    </label>
                  </div>
                </div>

                {shipping && (
                  <div className="checkout-list  my-5">
                    <h2>Shipping address</h2>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label>First Name</label>
                          <Controller
                            name="shippingAddress.first_name"
                            control={billingFormControl}
                            render={({ field }) => (
                              <input
                                {...field}
                                className={`form-control ${
                                  billingFormErrors.shippingAddress?.first_name
                                    ? "invalid"
                                    : ""
                                }`}
                              />
                            )}
                          />
                          {billingFormErrors.shippingAddress?.first_name ? (
                            <small className="text-danger">
                              {
                                billingFormErrors.shippingAddress?.first_name
                                  ?.message
                              }
                            </small>
                          ) : null}
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label>Last Name</label>
                          <Controller
                            name="shippingAddress.last_name"
                            control={billingFormControl}
                            render={({ field }) => (
                              <input
                                {...field}
                                className={`form-control ${
                                  billingFormErrors.shippingAddress?.last_name
                                    ? "invalid"
                                    : ""
                                }`}
                              />
                            )}
                          />
                          {billingFormErrors.shippingAddress?.last_name ? (
                            <small className="text-danger">
                              {
                                billingFormErrors.shippingAddress?.last_name
                                  ?.message
                              }
                            </small>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label>Company Name</label>
                      <Controller
                        name="shippingAddress.company_name"
                        control={billingFormControl}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${
                              billingFormErrors.shippingAddress?.company_name
                                ? "invalid"
                                : ""
                            }`}
                          />
                        )}
                      />
                      {billingFormErrors.shippingAddress?.company_name ? (
                        <small className="text-danger">
                          {
                            billingFormErrors.shippingAddress?.company_name
                              ?.message
                          }
                        </small>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label>Street address *</label>
                      <Controller
                        name="shippingAddress.house_number"
                        control={billingFormControl}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${
                              billingFormErrors.shippingAddress?.house_number
                                ? "invalid"
                                : ""
                            }`}
                          />
                        )}
                      />
                      {billingFormErrors.shippingAddress?.house_number ? (
                        <small className="text-danger">
                          {
                            billingFormErrors.shippingAddress?.house_number
                              ?.message
                          }
                        </small>
                      ) : null}
                      <Controller
                        name="shippingAddress.street_name"
                        control={billingFormControl}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder="Optional"
                            className={`form-control ${
                              billingFormErrors.shippingAddress?.street_name
                                ? "invalid"
                                : ""
                            }`}
                          />
                        )}
                      />
                      {billingFormErrors.shippingAddress?.street_name ? (
                        <small className="text-danger">
                          {
                            billingFormErrors.shippingAddress?.street_name
                              ?.message
                          }
                        </small>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label>Town / City *</label>
                      <Controller
                        name="shippingAddress.city"
                        control={billingFormControl}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${
                              billingFormErrors.shippingAddress?.city
                                ? "invalid"
                                : ""
                            }`}
                          />
                        )}
                      />
                      {billingFormErrors.shippingAddress?.city ? (
                        <small className="text-danger">
                          {billingFormErrors.shippingAddress?.city?.message}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label>County (optional)</label>
                      <Controller
                        name="shippingAddress.country"
                        control={billingFormControl}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${
                              billingFormErrors.shippingAddress?.country
                                ? "invalid"
                                : ""
                            }`}
                          />
                        )}
                      />
                      {billingFormErrors.shippingAddress?.country ? (
                        <small className="text-danger">
                          {billingFormErrors.shippingAddress?.country?.message}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label>Postcode *</label>
                      <Controller
                        name="shippingAddress.postalCode"
                        control={billingFormControl}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${
                              billingFormErrors.shippingAddress?.postalCode
                                ? "invalid"
                                : ""
                            }`}
                          />
                        )}
                      />
                      {billingFormErrors.shippingAddress?.postalCode ? (
                        <small className="text-danger">
                          {
                            billingFormErrors.shippingAddress?.postalCode
                              ?.message
                          }
                        </small>
                      ) : null}
                    </div>
                  </div>
                )}

                <div className="mb-3">
                  <label>Order notes (optional)</label>
                  <Controller
                    name="billingAddress.notes"
                    control={billingFormControl}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        className={`form-control ${
                          billingFormErrors.billingAddress?.notes
                            ? "invalid"
                            : ""
                        }`}
                      ></textarea>
                    )}
                  />
                  {billingFormErrors.billingAddress?.notes ? (
                    <small className="text-danger">
                      {billingFormErrors.billingAddress?.notes?.message}
                    </small>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="amt-payable my-5">
                <div className="price-header text-center">
                  <h2>Your order</h2>
                </div>
                <div className="cs-option-amt">
                  <div className="cs-option-amt-list">
                    <div className="cs-option-amt-left">Subtotal</div>
                    <div className="cs-option-amt-right">
                      {settings?.WebmasterSettings?.currency}{" "}
                      {selectedcategoryPriceList?.total_price_amount}
                    </div>
                  </div>

                  <div className="cs-option-amt-list">
                    <div className="cs-option-amt-left">Shipping</div>
                    <div className="cs-option-amt-right">
                      {selectedcategoryPriceList.pickup_and_delivery_charge >
                      0 ? (
                        <>
                          Flat Rate &nbsp;
                          <b className="rht-orderamt float-end">
                            {settings?.WebmasterSettings?.currency}{" "}
                            {
                              selectedcategoryPriceList.pickup_and_delivery_charge
                            }
                          </b>
                        </>
                      ) : (
                        <span className="rht-orderamt float-end">Free</span>
                      )}
                    </div>
                  </div>
                  {selectedcategoryPriceList.offer_amount > 0 &&
                    selectedcategoryPriceList.offer_percentage > "0" && (
                      <div className="cs-option-amt-list">
                        <div className="cs-option-amt-left">
                          Offer ({selectedcategoryPriceList?.offer_percentage}%)
                        </div>
                        <div className="cs-option-amt-right">
                          (-){settings?.WebmasterSettings?.currency}{" "}
                          {selectedcategoryPriceList?.offer_amount}
                        </div>
                      </div>
                    )}
                  <div className="cs-option-amt-list  cs-option-amt-listbg">
                    <div className="cs-option-amt-left">Amount Payable</div>
                    <div className="cs-option-amt-right">
                      <b>
                        {settings?.WebmasterSettings?.currency}{" "}
                        {selectedcategoryPriceList?.final_payable_amount}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="coupon-code my-20 mt-3">
                  <div className="input-group">
                    <input
                      id="couponCode"
                      className="form-control form-coupon"
                      placeholder="Enter Coupon Code"
                      value={couponCode}
                      type="text"
                      onChange={handleOfferList}
                    />
                    <div className="input-group-btn">
                      <button
                        className="btn btn-offer"
                        type="button"
                        id="applyCoupon"
                        ref={applyCouponButton}
                        disabled={couponCodeOffer}
                        onClick={onApplyCouponCodeHandler}
                      >
                        {!couponCodeOffer ? "Apply" : "Applied"}
                      </button>
                    </div>
                  </div>

                  {couponCodeDetails.length > 0 && (
                    <div
                      className="offers aos-init"
                      data-aos="flip-down"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="offers-title">
                        <img src={offers} className="img-fluid" alt="" />
                        <p className="ms-2 d-inline-block">Offers</p>
                      </div>
                      <div className="offers-content offers-div">
                        {couponCodeDetails.map((item: any) => {
                          return (
                            <div
                              className="ofs-details "
                              id={item?.id}
                              data-id={item?.id}
                              data-percentage={item?.percentage}
                            >
                              <div className="row">
                                <div className="ofs-details-lft col-md-9 col-xs-9">
                                  <h4>{item?.title}</h4>
                                  <p
                                    data-placement="left"
                                    data-bs-toggle="tooltip"
                                    title={item?.title}
                                  >
                                    {item?.description}
                                  </p>
                                </div>
                                <div className="col-md-3 col-xs-3 ofs-details-rht">
                                  <p className="total-discount">
                                    {item?.percentage}
                                  </p>
                                </div>
                              </div>
                              <div className="row coupon-ofs">
                                <div className="col-md-12">
                                  <span>{item?.offer_code}</span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {couponCodeOffer !== null &&
                    !couponCodeOffer &&
                    couponCodeDetails?.length === 0 &&
                    couponError && (
                      <div
                        className="text-center text-danger offer_not_valid"
                        style={{ marginTop: "10px" }}
                      >
                        Invalid Coupon Code
                      </div>
                    )}
                  {couponCodeOffer !== null &&
                    !couponCodeOffer &&
                    couponCodeDetails?.length === 0 &&
                    !couponError && (
                      <div
                        className="text-center text-danger offer_not_valid"
                        style={{ marginTop: "10px" }}
                      >
                        Enter Coupon Code
                      </div>
                    )}
                </div>
                <div className="checkout-pay  my-2">
                  {/* {billingFormValidity && ( */}
                  <div className="mb-3">
                    <div className="form-check">
                      <Controller
                        name="agree_t_c"
                        control={billingFormControl}
                        render={({ field }) => (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...field}
                            checked={billingFormGetValue("agree_t_c")}
                            value={String(billingFormGetValue("agree_t_c"))}
                            id="defaultCheck1"
                          />
                        )}
                      />

                      <label className="form-check-label">
                        I have read and agree to the website terms and
                        conditions *
                      </label>
                      {billingFormErrors.agree_t_c ? (
                        <small className="text-danger">
                          {billingFormErrors.agree_t_c?.message}
                        </small>
                      ) : null}
                    </div>
                  </div>
                  <>
                    <h2>Pay with Credit / Debit Card</h2>
                    <div className="pstepsinner" id="step3">
                      <div className="user_cards">
                        {stripeId && (
                          <>
                            <Payment
                              onPlaceHolderBtnClickHandler={
                                billingFormValidity && submitContactForm
                              }
                              cardStatus={handleCardStatus}
                              cardId={handleCardId}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                  {/* )} */}
                  <>
                    {/* <div className="mb-12">
                        <button
                          type="submit"
                          className="btn primary-btn text-white w-100"
                        >
                          Place Order
                        </button>
                      </div> */}
                  </>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default CheckoutWheatley;
