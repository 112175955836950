import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {lh1,lh14,lh15,lh16,lh17,lh18,lh19,lh2,lh20,lh21,lh3,lh4,lh5,lh6,lh7,lh8} from "../../../assets/img";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../../redux/Actions/policyActions";

function Sec7 () {
    const dispatch = useDispatch<any>();
    const faqData: any = useSelector<any>((state) => state?.faq);
    useEffect(() => {
        dispatch(fetchFaqData());
    }, []);
return (
    <>
        <section className="bg-white seclhfaq">
            <div className="container">                
                <div className="row">                    
                    <div className="col-md-12">
                        <div className="sec-faq">
                            <h2 className="sec-faqh2">Do you have questions?</h2> 
                            <Accordion className="lh-faq">                                
                                {faqData?.slice(0,6).map((item: any) => {
                                return (
                                    <Accordion.Item eventKey={JSON.stringify(item?.id)}>
                                        <Accordion.Header>{item.question}</Accordion.Header>
                                        <Accordion.Body>{item.answer}</Accordion.Body>
                                    </Accordion.Item>
                                )})}                
                            </Accordion>
                        </div>
                    </div>
                    
                </div>       
            </div>
        </section>
    </>
)
}
export default Sec7;