import React, { useEffect, useState } from 'react';
import Login from '../login';

function SignUp() {



    return (
        <Login />
    )
}

export default SignUp