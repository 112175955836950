import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { ttw1,ttw2,ttw3,ttw4,ttw5,ttw6,ttw7,ttw8,ttw9,ttw10,ttw11,ttw12,ttw13,ttw14,ttw15,ttw16,ttw17,ttw18,ttw19,ttw20,ttw21,ttw22,ttw23,ttw24,ttw25,ttw26,ttw27,ttw28,ttw29,ttw30,ttw31,ttw32,ttw33,ttw34,ttw35,ttw36,ttw37,ttw39,ttw40,ttw41,ttw42 } from "../../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../../edit/data";
import { fetchHomeJsonList } from "../../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../../config";

function SectionTwo () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <section className="secrev">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="revh3">
                            <h3>Customer-Ranked #1</h3>
                            <p>London's Top Choice for Traditional Shoe Repair and Restoration</p>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="revstargrid text-end">
                            <div className="revstar">
                                <span><img src={ttw4} alt="" /></span>
                                <span><img src={ttw4} alt="" /></span>
                                <span><img src={ttw4} alt="" /></span>
                                <span><img src={ttw4} alt="" /></span>
                                <span><img src={ttw4} alt="" /></span>
                            </div>
                            <p>Read more reviews</p>
                        </div>
                    </div>
                </div>                
            </div>
        </section>
        <section className="sechpointlist1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="t12-h21">We Collect, Clean, Repair & <br/>Deliver your Shoes</h2>                        
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="hpointlist1">
                                <ul>
                                    <li>
                                        <span className="secavatar1"><img src={ttw5} alt="" /></span>
                                        <p>72h turnaround time</p>
                                    </li>
                                    <li>
                                        <span className="secavatar1"><img src={ttw6} alt="" /></span>
                                        <p>Free collection and delivery</p>
                                    </li>
                                    <li>
                                        <span className="secavatar1"><img src={ttw7} alt="" /></span>
                                        <p>Dedicated 24/7 support</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secpb1">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="secimg">
                            <img src={ttw8} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="secinfoflex">
                            <div className="secinfogrid">
                                <span className="subh6">Let your shoes breathe easy</span>
                                <h2>Your Shoe's VIP Treatment: Designed for You</h2>
                                <p>Experience luxury shoe care with our VIP treatment, designed exclusively for your footwear needs. Our service offers unparalleled care, ensuring every detail is perfected to pamper your footwear with the utmost attention and precision.</p>
                                <a className="btn btnprime" href="#secidhww">See How it Works!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secbg1">
            <div className="container">
                <div className="row">                    
                    <div className="col-md-6">
                        <div className="secinfoflex">
                            <div className="secinfogrid">
                                <span className="subh6">72H TURNAROUND TIME</span>
                                <h2>No need to plan in advance</h2>
                                <ul className="hpointlist2">
                                    <li>
                                        <span className="secavatar2"><img src={ttw10} alt="" /></span>
                                        <p>Schedule a collection today</p>
                                    </li>
                                    <li>
                                        <span className="secavatar2"><img src={ttw11} alt="" /></span>
                                        <p>Get your shoes back in 72h</p>
                                    </li>
                                </ul>
                                <a className="btn btnprime" href="#secidsr">Get Started !</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="secimg">
                            <img src={ttw9} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secpy1">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="secimg">
                            <img src={ttw12} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="secinfoflex">
                            <div className="secinfogrid">
                                <span className="subh6">24/7 CUSTOMER SUPPORT</span>
                                <h2>We're here for you</h2>
                                <p>We are dedicated to providing exceptional support and assistance whenever you need it. Our team is committed to ensuring your satisfaction and resolving any queries promptly. Your needs are our priority.</p>
                                <a className="btn btnprime" href="#secidsr">Get a Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secpb1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="secinfogrid">                            
                            <h2 className="text-center">Shoe Repairs and Cleaning Service Price List</h2>
                            <p className="text-center secinhdrp">Expert shoe repair and cleaning services at competitive prices. Check our detailed price list for quality care and restoration of your footwear.</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="hpointlist3">
                                        <li><span>Ladie’s Shoe Tips</span><span>£14.20</span></li>
                                        <li><span>Ladies Rubber Heels</span><span>£14.00</span></li>
                                        <li><span>Ladies Rubber Half Sole & Rubber Heel</span><span>£33.25</span></li>
                                        <li><span>Ladies Rubber Half Sole</span><span>£24.25</span></li>
                                        <li><span>Ladies Leather Sole & Leather Half Heel</span><span>£44.95</span></li>
                                        <li><span>Ladies Leather Half Sole</span><span>£35.95</span></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="hpointlist3">
                                        <li><span>Men’s Shoe Tips</span><span>£16.95</span></li>
                                        <li><span>Men’s Rubber Heels</span><span>£18.95</span></li>
                                        <li><span>Men’s Rubber Half Sole & Rubber Heel</span><span>£42.95</span></li>
                                        <li><span>Men’s Rubber Half Sole</span><span>£30.25</span></li>
                                        <li><span>Men’s Leather Half Sole & Leather Heel</span><span>£63.95</span></li>
                                        <li><span>Men’s Leather Half Sole</span><span>£41.95</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <NavLink className="btn btnprime" to="/pricing">
                                        Checkout all Prices
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secbg1">
            <div className="container">
                <div className="row"> 
                    <div className="col-md-6">
                        <div className="secimg">
                            <img src={ttw13} alt="" />
                        </div>
                    </div>               
                    <div className="col-md-6">
                        <div className="secinfogrid">
                            <h2>About <span className="texthighlight">H</span>ello Cobble<span className="texthighlight">r</span></h2>
                            <p>Hello Cobbler is your go-to destination for comprehensive shoe repair and cleaning services. Our dedicated team of skilled professionals is committed to delivering top-notch care for your footwear. Whether your shoes need a simple repair or a thorough cleaning, we have the expertise to handle it all.</p>
                            <ul className="hpointlist4">
                                <li>
                                    <p>Schedule Your Service Request</p>
                                </li>
                                <li>
                                    <p>Expert technicians at your service</p>
                                </li>
                                <li>
                                    <p>Get a fresh, new look</p>
                                </li>
                                <li>
                                    <p>Convenient doorstep delivery</p>
                                </li>
                                <li>
                                    <p>Quality assurance for top-notch service</p>
                                </li>
                            </ul>
                            <NavLink className="btn btnprime" to="/aboutUs">
                                Explore Further
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secpy1">
            <div className="container">
                <div className="row"> 
                    <div className="col-md-12">
                        <div className="secinfogrid">
                            <h2 className="text-center secinfogridh2c1">Quality Shoe Repairs</h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row h-100">
                                        <div className="col-md-12">
                                            <div className="secinfocard1 text-end">
                                                <h3>Quality Guarantee</h3>
                                                <p>Experience excellence with our shoe cleaning and repair services, ensuring your satisfaction. Trust our precision and care to restore your footwear to its best condition.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="secinfocard1 text-end">
                                                <h3>Extensive Expertise</h3>
                                                <p>Rely on our skilled team for all your shoe cleaning and repair needs. From minor fixes to complex restorations, expect comprehensive solutions and exceptional results.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="secimg">
                                        <img src={ttw14} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row h-100">
                                        <div className="col-md-12">
                                            <div className="secinfocard1">
                                                <h3>Outstanding Craftsmanship</h3>
                                                <p>Witness meticulous artistry in every shoe cleaning and repair service. Discover superior quality that transforms your footwear into a masterpiece, reflecting our dedication to excellence.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="secinfocard1">
                                                <h3>Professional Refurbishments</h3>
                                                <p>Elevate your footwear with our expert services, breathing new life into your shoes. Trust our skill and creativity for a fresh and stylish makeover tailored to your preferences.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <NavLink className="btn btnprime" to="/pricing">
                                        Checout our Services
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secbg1" id="secidhww">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="secinfogrid">                            
                            <h2 className="text-center">How We Work</h2>
                            <p className="text-center secinhdrp">Our approach involves a meticulous process aimed at delivering exceptional results. We prioritize precision and attention to detail in every task we undertake, ensuring that your needs are met with the highest level of care and expertise.</p>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="secinfocard2">
                                        <div className="cardicon1">
                                            <img src={ttw15} alt="" />
                                        </div>
                                        <h3>Schedule a Collection</h3>
                                        <p>To arrange a collection, please provide your preferred date and time for us to pick up your items.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="secinfocard2">
                                        <div className="cardicon1">
                                            <img src={ttw16} alt="" />
                                        </div>
                                        <h3>Driver’s Arrival</h3>
                                        <p>The driver arrives punctually at the specified destination where you are situated. Their prompt arrival ensures efficient transportation.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="secinfocard2">
                                        <div className="cardicon1">
                                            <img src={ttw17} alt="" />
                                        </div>
                                        <h3>Gentle Shoe Restoration</h3>
                                        <p>Revive your shoes with our gentle restoration services. Bring back their original charm and quality.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="secinfocard2">
                                        <div className="cardicon1">
                                            <img src={ttw18} alt="" />
                                        </div>
                                        <h3>Shoes Renewed to Perfection</h3>
                                        <p>Restore your shoes with expert care. From scuffs to worn-out soles, we revive their original charm.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secbg2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="secinfogrid">                            
                            <h2 className="text-center">Our Lovable Customer Feedback!</h2>
                            <p className="text-center secinhdrp">We deeply appreciate your feedback and strive to provide exceptional service that exceeds your expectations.</p>
                            <div className="row">
                                <div className="col-md-12">
                                    <Swiper
                                        spaceBetween={60}
                                        navigation={true}
                                        modules={[Navigation]}
                                        centeredSlides={true}
                                        centerInsufficientSlides={true}
                                        centeredSlidesBounds={true}
                                        breakpoints={{
                                        640: {
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                        },
                                        }}
                                        className="t12testi-slider"
                                    >
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw39} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Linda Carpenter</div>
                                                        <div className="cardinfoarea">Leland, USA</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>Outstanding service! My shoes were restored to perfection. Highly recommend this expert team.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw40} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Rachel Martinez</div>
                                                        <div className="cardinfoarea">UK</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>Impressed by the meticulous restoration of my shoes. The attention to detail is exceptional, and the results speak for themselves.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw41} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Daniel Clark</div>
                                                        <div className="cardinfoarea">UK</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>The craftsmanship in restoring my shoes was the best. I am delighted with the outcome and will definitely return for future shoe care needs.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw39} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Linda Carpenter</div>
                                                        <div className="cardinfoarea">Leland, USA</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>Outstanding service! My shoes were restored to perfection. Highly recommend this expert team.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw40} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Rachel Martinez</div>
                                                        <div className="cardinfoarea">UK</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>Impressed by the meticulous restoration of my shoes. The attention to detail is exceptional, and the results speak for themselves.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="secinfocard3">
                                                <div className="secinfocarduser">
                                                    <div className="cardicon2">
                                                        <img src={ttw41} alt="" />
                                                    </div>
                                                    <div className="cardinfo">                                                
                                                        <div className="cardinfoname">Daniel Clark</div>
                                                        <div className="cardinfoarea">UK</div>
                                                        <div className="cardinforating">
                                                            <span><img src={ttw42} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secinfocardcontent">
                                                    <p>The craftsmanship in restoring my shoes was the best. I am delighted with the outcome and will definitely return for future shoe care needs.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secbg1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="secinfogrid">                            
                            <h2 className="text-center secinfogridh2c1">Stay Updated with Our Latest News</h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="secinfocard4">
                                        <div className="seccardimg2">
                                            <img src={ttw23} alt="" className="seccardimg2rd" />
                                            <ul className="seccardoverlaylist1">
                                                <li>
                                                    <img src={ttw20} alt=""/>
                                                    <span>15 Jun</span>
                                                </li>
                                                {/* <li>
                                                    <img src={ttw21} alt=""/>
                                                    <span>58</span>
                                                </li>
                                                <li>
                                                    <img src={ttw22} alt=""/>
                                                    <span>1.8k</span>
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div className="secinfocard4content">
                                            <div className="secinfocard4content-top">
                                                <h4>What is the best thing to clean white shoes with?</h4>
                                                <p>Discover the best methods to keep your white shoes pristine and clean, ensuring they look fresh and stylish for longer.</p>
                                            </div>
                                            <div className="secinfocard4content-bottom">
                                                <a className="btn btnprime" target="_blank" href={blogUrl}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="secinfocard4">
                                        <div className="seccardimg2">
                                            <img src={ttw24} alt="" className="seccardimg2rd"/>
                                            <ul className="seccardoverlaylist1">
                                                <li>
                                                    <img src={ttw20} alt=""/>
                                                    <span>15 Jun</span>
                                                </li>
                                                {/* <li>
                                                    <img src={ttw21} alt=""/>
                                                    <span>58</span>
                                                </li>
                                                <li>
                                                    <img src={ttw22} alt=""/>
                                                    <span>1.8k</span>
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div className="secinfocard4content">
                                            <div className="secinfocard4content-top">
                                                <h4>6 Essential Tips for Men's Shoe Repair</h4>
                                                <p>Explore essential tips for men's shoe repair to maintain the quality and longevity of your favorite footwear, saving you time and money in the long run.</p>
                                            </div>
                                            <div className="secinfocard4content-bottom">
                                                <a className="btn btnprime" target="_blank" href={blogUrl}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="secinfocard4">
                                        <div className="seccardimg2">
                                            <img src={ttw25} alt="" className="seccardimg2rd"/>
                                            <ul className="seccardoverlaylist1">
                                                <li>
                                                    <img src={ttw20} alt=""/>
                                                    <span>15 Jun</span>
                                                </li>
                                                {/* <li>
                                                    <img src={ttw21} alt=""/>
                                                    <span>58</span>
                                                </li>
                                                <li>
                                                    <img src={ttw22} alt=""/>
                                                    <span>1.8k</span>
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div className="secinfocard4content">
                                            <div className="secinfocard4content-top">
                                                <h4>How to clean your favourite Fabric Shoes?</h4>
                                                <p>Learn effective techniques to clean and care for your beloved fabric shoes, preserving their appearance and extending their lifespan.</p>
                                            </div>
                                            <div className="secinfocard4content-bottom">
                                                <a className="btn btnprime" target="_blank" href={blogUrl}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secbg3">
            <div className="container">
                <div className="row"> 
                    <div className="col-md-6">
                        <div className="secimg">
                            <img src={ttw33} alt="" />
                        </div>
                    </div>               
                    <div className="col-md-6">
                        <div className="secinfogrid">
                            <h2 className="hrdbtm">Your Shoe Reapir and Cleaning Service just a click away</h2>                            
                            <ul className="hpointlist5">
                                <li>
                                    <span className="secavatar3"><img src={ttw26} alt=""/></span>
                                    <p>Cashless Payments</p>
                                </li>
                                <li>
                                    <span className="secavatar3"><img src={ttw27} alt=""/></span>
                                    <p>Contactless Collection and Delivery</p>
                                </li>
                                <li>
                                    <span className="secavatar3"><img src={ttw28} alt=""/></span>
                                    <p>Real-time Order Updates</p>
                                </li>
                                <li>
                                    <span className="secavatar3"><img src={ttw29} alt=""/></span>
                                    <p>Dedicated 24/7 Support</p>
                                </li>
                            </ul>
                            <ul className="hpointlist6">                                
                                <a className="btn" href={appStorel} target="_blank"><img src={ttw30} alt=""/></a>
                                <a className="btn" href={playStore} target="_blank"><img src={ttw31} alt=""/></a>
                                {/* <div className="hpointlistscan">
                                    <a className="btn" href="#"><img src={ttw32} alt=""/></a>
                                </div> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionTwo;
