import React, { useEffect, useState } from "react";

import { ttimg1,ttimg2,ttimg3,ttimg4,ttimg5,ttimg6,ttimg7,ttimg8,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTPress() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg8} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg7} alt="" />
                               </div>
                                <p>Tired of standing at home ironing shirt after shirt, when you could be doing something else with your valuable spare time! Why not place and order at T&T Dry Cleaners. Our <b>Press Only</b> service is an ideal way to do both. Save time by letting us, expertly press your garments whilst reducing your dry cleaning bill.</p>
                                <p>Why take hours away from your family when it really only takes a few minutes to drop it off and pick it up. For even more convenience, let us collect and deliver your garments straight to your door.</p>
                            </div>
                            <div className="tt-img">
                                <img src={ttimg13} alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg9} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg10} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg11} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg12} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTPress;