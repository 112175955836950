import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  priorityicon1,
  priorityicon2,
  priorityicon3,
  priorityicon4,
  priorityicon5,
  priorityicon6,
} from "../../assets/img";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

function SectionThreeThemeThree() {
  const navigate = useNavigate();
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);

  return (
    <>
      <section className="priority-section ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
        <div className="section-title">
          <span className="yellow-btn">
            {homedata?.theme_3?.home?.section_3?.sub_heading_top}
          </span>
          <h2 style={{
                      color: `#${homedata?.theme_3?.home?.section_3?.h2color}`
                    }}>{homedata?.theme_3?.home?.section_3?.title}</h2>
          <p style={{
                      color: `#${homedata?.theme_3?.home?.section_3?.paracolor}`
                    }}>{homedata?.theme_3?.home?.section_3?.paragraph}</p>
        </div>
        </div>
        </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
          {window.location.href.includes('mydhobi') 
            ? homedata?.theme_3?.home?.section_3?.card_section?.collection.map(
              (items: any,index: number) => {
                return (
                  <div className="col-md-4">
                      <div className="priority-card text-center">
                        <span className="priority-card-icon">
                          <img src={items.icon} alt="" />
                        </span>
                        <h3 style={{
                          color: `#${homedata?.theme_3?.home?.section_3?.h3color}`
                        }}> {items.collection_title} </h3>
                        {index == 0 
                        ? <ul style={{textAlign: 'left'}}>
                            <li><strong>Unmatched Quality:</strong> We treat every garment as if it were our own, using cutting-edge techniques and premium products to deliver flawless results.</li>
                            <li><strong>Effortless Service:</strong> From easy online booking to fast, reliable delivery, we make laundry and dry cleaning convenient and stress-free.</li>
                            <li><strong>Exceptional Value:</strong> Get top-tier care without the premium price tag—luxury service at a price you'll love.</li>
                            <li><strong>Expert Care:</strong> With years of experience, our skilled professionals handle every fabric with precision, ensuring your items are returned in pristine condition.</li>
                            <li><strong>Total Convenience:</strong> We work around your schedule with 24/7 availability and quick turnaround times, so you can focus on what matters most.</li>
                            <li><strong>Pristine Cleanliness:</strong> We adhere to the highest hygiene standards, ensuring your clothes are not just clean, but meticulously sanitized and fresh.</li>
                          </ul>
                        : index == 1
                        ? <>
                            <ul style={{textAlign: 'left'}}>
                              <li><strong>Integrity:</strong> Transparent pricing, no hidden fees, and service you can trust—every time.</li>
                              <li><strong>Attention to Detail:</strong> Every stitch, every fabric, every piece is treated with the utmost care and precision.</li>
                              <li><strong>Customer Delight:</strong> We’re not satisfied until you are. Our goal is to exceed your expectations, every time.</li>
                            </ul>
                            <br />
                            <p style={{textAlign: 'justify', fontWeight: 'bold'}}>Choose My Dhobi for a laundry service that’s designed to impress—because you deserve nothing but the best.</p>
                          </>
                        : <ul style={{textAlign: 'left'}}>
                            <li><strong>Honesty:</strong> We uphold a straightforward approach, ensuring clear communication and no hidden charges. Trust is the foundation of our service.</li>
                            <li><strong>Transparency:</strong> We provide clear information about our processes and pricing, so you know exactly what to expect every step of the way.</li>
                            <li><strong>Excellence:</strong> We are dedicated to maintaining the highest standards in every aspect of our work, ensuring exceptional quality and service.</li>
                            <li><strong>Reliability:</strong> Our commitment to punctuality and consistency means you can depend on us to deliver on time, every time.</li>
                            <li><strong>Respect:</strong> We handle each garment with the utmost respect, acknowledging the personal value and care each item requires.</li>
                            <li><strong>Sustainability:</strong> We actively pursue eco-friendly practices, from energy-efficient operations to environmentally conscious products, reflecting our responsibility to both our customers and the planet.</li>
                            <li><strong>Customer Focus:</strong> We prioritize your needs and preferences, tailoring our services to ensure a personalized and satisfactory experience.</li>
                            <li><strong>Innovation:</strong> Embracing the latest technologies and methods, we continually enhance our services to provide cutting-edge solutions and improved results.</li>
                            <li><strong>Meticulousness:</strong> Our attention to every detail ensures that each item receives the precise care and treatment it deserves, resulting in superior outcomes.</li>
                          </ul>
                        }
                      </div>
                  </div>
                );
              }
            )
          : <Swiper
                spaceBetween={30}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                className="home-slider"
                >
                {homedata?.theme_3?.home?.section_3?.card_section?.collection.map(
                  (items: any,index: number) => {
                    return (
                      <>
                        <SwiperSlide key={index}>
                          <div className="priority-card text-center">
                            <span className="priority-card-icon">
                              <img src={items.icon} />
                            </span>
                            <h3 style={{
                          color: `#${homedata?.theme_3?.home?.section_3?.h3color}`
                        }}> {items.collection_title} </h3>
                            <p style={{
                          color: `#${homedata?.theme_3?.home?.section_3?.paracolor}`
                        }}>{items.collection_paragraph}</p>
                          </div>
                        </SwiperSlide>
                      </>
                    );
                  }
                )}
              </Swiper>
          }
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="text-center load-more d-none">
                <Link
                  style={{
                    background: `#${homedata?.theme_3?.home?.section_3?.card_section?.btn_group?.btn1?.bg_color}`,
                    color: `#${homedata?.theme_3?.home?.section_3?.card_section?.btn_group?.btn1?.color}`,
                  }}
                  onClick={() => navigate("/aboutUs")}
                  className="btn title-btn hover-btn"
                  to={""}
                >
                  {
                    homedata?.theme_3?.home?.section_3?.card_section?.btn_group?.btn1
                      ?.text
                  }
                  <i className="uil uil-arrow-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default SectionThreeThemeThree;
