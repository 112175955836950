import React, { useEffect, useState } from "react";
import {lh1,lh2,lh25,lh26,lh28,lh29,lh3,lh30,lh31,lh32,lh33,lh34,lh4,lh5,lh6,lh7,lh8} from "../../assets/img";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { scroller } from 'react-scroll';
function Sec2 () {
    const navigate = useNavigate();

    const handleClick = (elementName:any) => {
        scroller.scrollTo(elementName, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
    };

    const navigateToPricing = () => {
        const shopName: any = localStorage.getItem("currentShopName");
        if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());
        if (shopName) {
          navigate(`/shop/${finalResult}/pricing`);
        } else {
          navigate("/pricing");
        }
      } else {
        navigate("/pricing");
      }
      };
      
return (
    <>
        <section className="bg-blue sec-pricetable">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table">
                                <tr>
                                    <th className="bg-white">&nbsp;</th>
                                    <th className="lhpricetable1"><div><img src={lh30} alt=""/></div>Wash</th>
                                    <th className="lhpricetable2"><div><img src={lh31} alt=""/></div>Wash & Iron</th>
                                    <th className="lhpricetable3"><div><img src={lh32} alt=""/></div>Dry Cleaning</th>
                                    <th className="lhpricetable4"><div><img src={lh33} alt=""/></div>Ironing</th>
                                    <th className="lhpricetable5"><div><img src={lh34} alt=""/></div>Duvets & Bulky Items</th>
                                </tr>
                                <tr>
                                    <td>What is included</td>
                                    <td><b>WASH + TUMBLE-DRY</b></td>
                                    <td><b>WASH + TUMBLE-DRY + IRONING</b></td>
                                    <td><b>DRY CLEANING + IRONING</b></td>
                                    <td><b>IRONING</b></td>
                                    <td><b>CUSTOM CLEANING</b></td>
                                </tr>
                                <tr>
                                    <td>Typically included items</td>
                                    <td>T-shirts, trousers, bedsheets and undergarments.</td>
                                    <td>Shirts, t-shirts, trousers and bedsheets.</td>
                                    <td>Suits, shirts, dresses and coats.</td>
                                    <td>Shirts, t-shirts and bedsheets.</td>
                                    <td>Duvets, pillows and blankets.</td>
                                </tr>
                                <tr>
                                    <td>Service time</td>
                                    <td><img src={lh25} alt=""/><b>24h*</b></td>
                                    <td><img src={lh25} alt=""/><b>24h*</b></td>
                                    <td><img src={lh25} alt=""/><b>24h*</b></td>
                                    <td><img src={lh25} alt=""/><b>24h*</b></td>
                                    <td>Up to 3 days</td>
                                </tr>
                                <tr>
                                    <td>Pricing</td>
                                    <td>
                                        <p className="lh-fc1">Price per weight</p>
                                        <p className="lh-fc2">from<b>£17.95</b>/ 6kg</p>
                                        <a className="lh-fc3" onClick={navigateToPricing} style={{ cursor: "pointer" }}>See price list</a>
                                    </td>
                                    <td>
                                        <p className="lh-fc1">Price per weight</p>
                                        <p className="lh-fc2">from<b>££1.95</b></p>
                                        <a className="lh-fc3" onClick={navigateToPricing} style={{ cursor: "pointer" }}>See price list</a>
                                    </td>
                                    <td>
                                        <p className="lh-fc1">Price per weight</p>
                                        <p className="lh-fc2">from<b>£1.95</b></p>
                                        <a className="lh-fc3" onClick={navigateToPricing} style={{ cursor: "pointer" }}>See price list</a>
                                    </td>
                                    <td>
                                        <p className="lh-fc1">Price per weight</p>
                                        <p className="lh-fc2">from<b>£1.45</b></p>
                                        <a className="lh-fc3" onClick={navigateToPricing} style={{ cursor: "pointer" }}>See price list</a>
                                    </td>
                                    <td>
                                        <p className="lh-fc1">Price per weight</p>
                                        <p className="lh-fc2">from<b>£11.95</b></p>
                                        <a className="lh-fc3" onClick={navigateToPricing} style={{ cursor: "pointer" }}>See price list</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Delivery</td>
                                    <td><b>FREE</b></td>
                                    <td><b>FREE</b></td>
                                    <td><b>FREE</b></td>
                                    <td><b>FREE</b></td>
                                    <td><b>FREE</b></td>
                                </tr>
                                <tr>
                                    <td>Items returned</td>
                                    <td><div><img src={lh28} alt=""/></div>In a bag</td>
                                    <td><div><img src={lh29} alt=""/></div>On hangers</td>
                                    <td><div><img src={lh29} alt=""/></div>On hangers</td>
                                    <td><div><img src={lh29} alt=""/></div>On hangers</td>
                                    <td><div><img src={lh28} alt=""/></div>In a bag</td>
                                </tr>
                                <tr>
                                    <td>Eco friendly</td>
                                    <td><img src={lh26} alt=""/></td>
                                    <td><img src={lh26} alt=""/></td>
                                    <td><img src={lh26} alt=""/></td>
                                    <td><img src={lh26} alt=""/></td>
                                    <td><img src={lh26} alt=""/></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><a href="#section1" className="btn btn-primary" onClick={() => handleClick('section1')}>Book Now</a></td>
                                    <td><a href="#section1" className="btn btn-primary" onClick={() => handleClick('section1')}>Book Now</a></td>
                                    <td><a href="#section1" className="btn btn-primary" onClick={() => handleClick('section1')}>Book Now</a></td>
                                    <td><a href="#section1" className="btn btn-primary" onClick={() => handleClick('section1')}>Book Now</a></td>
                                    <td><a href="#section1" className="btn btn-primary" onClick={() => handleClick('section1')}>Book Now</a></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>   
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <div className="sec-table-desc">
                            <p>Our minimum order value is £20.</p>
                            <p>*In most locations.</p>
                            {/* <div className="sec-chat">
                                <strong>Have a question about our services and prices?</strong>
                                <p>Ask our team!</p>
                                <Link className="btn btn-white" to="/faq">FAQ</Link>
                            </div> */}
                        </div>
                    </div>
                </div>   
            </div>
        </section>
    </>
)
}
export default Sec2;