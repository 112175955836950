import React, { useEffect, useState } from "react";

import { ttimg43,ttimg2,ttimg3,ttimg4,ttimg5,ttimg6,ttimg44,ttimg45,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTSudeleathercleaning() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg44} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg43} alt="" />
                               </div>
                                <p>T&T Dry Cleaners provides a suede, sheepskin, and leather cleaning service for a range of garments and items, including;</p>
                                <ul>
                                    <li>Coats</li>
                                    <li>Jackets</li>
                                    <li>Suits</li>
                                    <li>Waistcoats</li>
                                    <li>Skirts</li>
                                    <li>Trousers</li>
                                    <li>Gloves</li>
                                    <li>Sheepskin rugs</li>
                                    <li>Motorcycle leathers</li>
                                    <li>Handbags</li>
                                    <li>Ugg boots</li>
                                </ul>
                                <p>We also provide a Furs cleaning service.</p>                                
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg45} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTSudeleathercleaning;