import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import Home from "./sOne";
import SectionBanner from "./banner";

function Rogers() {
    return (
        <>
            <SectionBanner/>
            <Home/>
        </>
    )
};

export default Rogers;