import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList, saveThemeJsonData, fetchCroppedImage } from "../../../redux/Actions";
import ImageCropper from "../../imageCropper/imageCropper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";


const SectionTwoEditThemeTwo = () => {
    const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabOne, setActiveTabOne] = useState(0);

  const banner: Array<string> = [
    "assets/img/trustimg2.png",
    "assets/home/heroimg.png",
    "assets/home/heroimg.png",
  ];

  const icons = [
    "assets/img/icons/priorityicon1.svg",
    "assets/img/icons/priorityicon2.svg",
    "assets/img/icons/priorityicon3.svg",
    "assets/img/icons/priorityicon1.svg",
    "assets/img/icons/priorityicon2.svg",
    "assets/img/icons/priorityicon3.svg",
    "assets/img/icons/priorityicon1.svg",
    "assets/img/icons/priorityicon2.svg",
    "assets/img/icons/priorityicon3.svg",
  ];

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_2: {
          ...prevJsonData?.theme_2,
          home: {
            ...prevJsonData?.theme_2.home,
            section_2   : {
              ...prevJsonData?.theme_2?.home.section_2  ,
              [name]: value,
        },
      },
    },
    }));
  };

  const addCollectionOne = () => {
    let updatedCollection: any = [
      ...editData.theme_2.home.section_2.card_section_one,
    ];

    let newCard = {
      icons: "assets/img/icons/priorityicon1.svg",
      title: "",
      count:""

    };
    updatedCollection.splice(activeTabOne + 1, 0, newCard);
    setActiveTabOne(activeTabOne + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2:{
        ...prevJsonData.theme_2,
      home: {
        ...prevJsonData.theme_2.home,
        section_2: {
          ...prevJsonData.theme_2.home.section_2,
          card_section_one: updatedCollection
        },
      },
    },
    }));
  };

  const removeCollectionOne = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_2.home.section_2.card_section_one,
    ];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2:{
        ...prevJsonData.theme_2,
      home: {
        ...prevJsonData.theme_2.home,
        section_2: {
          ...prevJsonData.theme_2.home.section_2,                
            card_section_one: updatedCollection,
        },
      },
    },
    }));
  };

  const addCollection = () => {
    let updatedCollection: any = [
      ...editData.theme_2.home.section_2.card_section_two,
    ];

    let newCard = {
      icons: "assets/img/icons/priorityicon1.svg",
      title: "",
      count:""

    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2:{
        ...prevJsonData.theme_2,
      home: {
        ...prevJsonData.theme_2.home,
        section_2: {
          ...prevJsonData.theme_2.home.section_2,
          card_section_two: updatedCollection
        },
      },
    },
    }));
  };

  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_2.home.section_2.card_section_two,
    ];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2:{
        ...prevJsonData.theme_2,
      home: {
        ...prevJsonData.theme_2.home,
        section_2: {
          ...prevJsonData.theme_2.home.section_2,                
          card_section_two: updatedCollection,
        },
      },
    },
    }));
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150")
    // setCropImageWidth("150")
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if(imageLink != false){
      handleBannerImageChange(imageLink)
    }
  };

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [
      ...editData.theme_2.home.section_2.card_section_two,
    ];
    updatedCollection[index] = {
      ...updatedCollection[index],
      card_icon: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2:{
        ...prevJsonData.theme_2,
      home: {
        ...prevJsonData.theme_2.home,
        section_2: {
          ...prevJsonData.theme_2.home.section_2,
            ...prevJsonData.theme_2.home.section_2.card_section_two,
            card_section_two: updatedCollection,
        },
      },
    },
    }));
  };

  const handleBannerImageChange = (image: string) => {
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2:{
        ...prevJsonData.theme_2,
      home: {
        ...prevJsonData.theme_2.home,
        section_2: {
          ...prevJsonData.theme_2.home.section_2,
          banner: image,
        },
      },
    },
    }));
  };

    const saveJsonData = () => {
        dispatch(fetchHomeJsonList(editData));
        dispatch(saveThemeJsonData(editData));
      };
    
      useEffect(() => {
        setEditData(jsonData);
      }, [jsonData]);
    
      useEffect(() => {
        if (editData != undefined) {
          setEditData(editData);
        }
      }, [editData]);

return(
    <>
    <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Two Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
          <div className="edit-section">
          <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_2?.home?.section_2?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                  </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Sub Heading Top</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sub_heading_top"
                      value={editData?.theme_2?.home?.section_2?.sub_heading_top}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="mainparagraph"
                      value={editData?.theme_2?.home?.section_2?.mainparagraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_1?.home?.section_4?.h2color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_1?.home?.section_4?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_1?.home?.section_4?.h3color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_1?.home?.section_4?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_1?.home?.section_4?.paracolor
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_1?.home?.section_4?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="edit-section">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={editData?.theme_2?.home?.section_2?.banner}
                              alt="Hero Image"
                            />
                          </div>
                          <div className="img-options">
                    <div className="row">
                      <div className="col-md-12">
                        <Swiper
                          spaceBetween={15}
                          navigation={true}
                          modules={[Navigation]}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 4,
                            },
                            1024: {
                              slidesPerView: 6,
                            },
                          }}
                          className="homejson-slider"
                        >
                          {banner.map((images: string,index:any) => {
                            return (
                              <>
                                <SwiperSlide key={index}>
                                  <div
                                    className="img-option"
                                  >
                                    <img
                                      src={images}
                                      alt="Hero Image"
                                      className="img-fluid"
                                      onClick={() => handleBannerImageChange(images)}
                                    />
                                  </div>
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-section">
              <h5 className="modal-title">Card Section One</h5>
                <ul className="nav nav-tabs">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    className="homejson-slider"
                  >
                    {editData?.theme_2?.home?.section_2?.card_section_one.map(
                      (card: any, index: any) => (
                        <SwiperSlide key={index}>
                          <li key={index} className="nav-item nav-option">
                            <a
                              className={`nav-link ${
                                activeTabOne === index ? "active" : ""
                              }`}
                              onClick={() => setActiveTabOne(index)}
                            >
                              Card {index + 1}
                            </a>
                            <a className="tab-close" onClick={() => {removeCollectionOne(index)}}>
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </ul>
                <div className="tab-content">
                  {editData?.theme_2?.home?.section_2?.card_section_one.map(
                    (card: any, index: any) => (
                      <div
                        key={index}
                        className={`tab-pane fade ${
                          activeTabOne === index ? "show active" : ""
                        }`}
                      >
                        <div className="mb-3">
                          <label className="form-label">
                            Title<small>(max 250 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="card.text"
                            value={card.text}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={250}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Count<small>(max 10 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="card.count"
                            value={card.count}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={10}
                          />
                </div>
                      </div>
                    )
                  )}
                </div>
                <div className="add-section">
                <div className="row">
                  <div
                    onClick={() => {
                      addCollectionOne();
                    }}
                    className="col text-center"
                  >
                    Add Card<i className="fas fa-plus-square ms-2"></i>
                  </div>
                </div>
              </div>
              </div>
              <div className="edit-section">
              <h5 className="modal-title">Card Section Two</h5>
              <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_2?.home?.section_2?.card_section_title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Rating</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sub_heading_top"
                      value={editData?.theme_2?.home?.section_2?.rating}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Sub Heading</label>
                    <input
                      type="text"
                      className="form-control"
                      name="mainparagraph"
                      value={editData?.theme_2?.home?.section_2?.card_sub_text}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
                <ul className="nav nav-tabs">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    className="homejson-slider"
                  >
                    {editData?.theme_2?.home?.section_2?.card_section_two.map(
                      (card: any, index: any) => (
                        <SwiperSlide key={index}>
                          <li key={index} className="nav-item nav-option">
                            <a
                              className={`nav-link ${
                                activeTab === index ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(index)}
                            >
                              Card {index + 1}
                            </a>
                            <a className="tab-close" onClick={() => {removeCollection(index)}}>
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </ul>
                <div className="tab-content">
                  {editData?.theme_2?.home?.section_2?.card_section_two.map(
                    (card: any, index: any) => (
                      <div
                        key={index}
                        className={`tab-pane fade ${
                          activeTab === index ? "show active" : ""
                        }`}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="img-bg active">
                              <img
                                src={card.card_icon}
                                alt={`Card Icon ${index + 1}`}
                              />
                            </div>
                            <div className="img-options w-100">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 4,
                                  },
                                  1024: {
                                    slidesPerView: 6,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {icons.map((icons, iconIndex) => (
                                  <SwiperSlide key={iconIndex}>
                                    <div
                                      className="img-option"
                                      onClick={() =>
                                        handleImageChange(icons, index)
                                      }
                                    >
                                      <img
                                        src={icons}
                                        alt="Hero Image"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Title<small>(max 250 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="card.card_title"
                            value={card.card_title}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={250}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Sub Heading<small>(max 250 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="card.card_sub_heading_top"
                            value={card.card_sub_heading_top}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={250}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Paragraph<small>(max 250 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="card.card_paragraph"
                            value={card.card_paragraph}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={250}
                          />
                        </div>
                        <div className="add-section">
              </div>
                      </div>
                    )
                  )}
                </div>
                <div className="row">
                  <div
                    onClick={() => {
                      addCollection();
                    }}
                    className="col text-center"
                  >
                    Add Card<i className="fas fa-plus-square ms-2"></i>
                  </div>
                </div>
              </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
          <>
            <div
              className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
              id="crop-modal"
              tabIndex={-1}
              aria-labelledby="crop-modal"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <ImageCropper
                src={selectedCropImage}
                height={cropImageHeight}
                width={cropImageWidth}
                handleCrop={handleCroppedImage}
                close={() => {
                  setShowCropModal(false);
                }}
              />
            </div>
          </>
        ) : null}
    </>
)
}

export default SectionTwoEditThemeTwo;