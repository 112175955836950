import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { t3w1,t3w2,t3w3,t3w4,t3w5,t3w6,t3w7,t3w8,t3w9,t3w10,t3w11,t3w12,t3w13,t3w14,t3w15,t3w16,t3w17,t3w18,t3w19,t3w20,t3w21,t3w22,t3w23,t3w24,t3w25,t3w26,t3w27,t3w28,t3w29,t3w30,t3w31,t3w32,t3w33,t3w34,t3w35,t3w36,t3w37,t3w39,t3w40,t3w41,t3w42 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";

function SectionFive () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <section className="secpy1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="secinfogrid">                            
                            <h2 className="text-center">Dry Cleaning, Laundry, Ironing and Repair Price List</h2>
                            <p className="text-center secinhdrp pb-4 pb-md-0">Expert laundry and ironing services at competitive prices. Check our detailed price list for quality care and maintenance of your garments.</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="hpointlist3">
                                        <li><span>Suit 2 Piece</span><span>£16.90</span></li>
                                        <li><span>Trouser</span><span>£8.90</span></li>
                                        <li><span>Shirt wash & iron</span><span>£2.80</span></li>
                                        <li><span>Coats</span><span>From £15.50</span></li>
                                        <li><span>Jackets</span><span>From £11.90</span></li>
                                        <li><span>Skirt Small/Medium/Long and woolen</span><span>From £8.90,£10.50,£12.50</span></li>
                                        <li><span>Blouses/Silk Delicate</span><span>From £8.90,£12.90</span></li>
                                        <li><span>Coats Heavy</span><span>£18.50</span></li>
                                        <li><span>Rain Coats(MAC)</span><span>£18.50</span></li>
                                        <li><span>Cardigans</span><span>£8.90</span></li>
                                        <li><span>Dresses Small</span><span>£13.50</span></li>
                                        <li><span>Dresses Medium</span><span>£13.50</span></li>
                                        <li><span>Dresses Long</span><span>£16.50</span></li>
                                        <li><span>Dresses Silk/Delicate</span><span>£19.90</span></li>
                                        <li><span>Dresses Evening</span><span>£23.00 to £29.00</span></li>
                                        <li><span>Dresses Wedding</span><span>From £120.00</span></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="hpointlist3">                                      
                                        <li><span>Dresses Brides Maids</span><span>From £35.00 to £45.00</span></li>
                                        <li><span>Jumpers</span><span>£8.90</span></li>
                                        <li><span>Heavy Jumpers</span><span>£10.50</span></li>
                                        <li><span>Jackets (puffer) / Canada Goose</span><span>From £20.50 / £45.00 </span></li>
                                        <li><span>Shirts / Polo Shirts Dry Cleaned </span><span>£4.90</span></li>
                                        <li><span>Suit 3 Piece/Dinner Suit</span><span>£25.50</span></li>
                                        <li><span>Scarf From</span><span>£6.90</span></li>
                                        <li><span>Shawl</span><span>From £9.90</span></li>
                                        <li><span>Trouser Silk</span><span>£10.50</span></li>
                                        <li><span>Trouser Linen</span><span>£9.90</span></li>
                                        <li><span>T-Shirts</span><span>£4.90</span></li>
                                        <li><span>Curtain</span><span>£7.90 per Square Meter</span></li>
                                        <li><span>Tie</span><span>£6.90</span></li>
                                        <li><span>Shirt Dry Cleaned</span><span>£5.50</span></li>
                                        <li><span>Blankets</span><span>From £16.50</span></li>
                                        <li><span>Alteration</span><span>Available on Request</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <NavLink className="btn btnprime" to="/prices">
                                        Checkout all Prices
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionFive;
