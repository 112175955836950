import React, { useEffect, useState } from "react";

import { ttimg21,ttimg22,ttimg23,ttimg24,ttimg25,ttimg26,ttimg27,ttimg28,ttimg29,ttimg30,ttimg31,ttimg32,ttimg33,ttimg34,ttimg35,ttimg1,ttimg2,ttimg3,ttimg4,ttimg5,ttimg6,ttimg7,ttimg8,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTRepairalterations() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg21} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg20} alt="" />
                               </div>
                                <p>T & T Dry Cleaners provides great value tailoring and dry cleaning. With over 25 years tailoring experience, we try to strive for excellence with every job we carry out. We know how important that suit is and we are here to guarantee that it fits perfectly.</p>
                                <p>Our tailoring staff is extensively trained to perform major alterations including re-cuts (for coats and pants), sides and blades, as well as resizing dresses. They will replace zippers, shorten sleeves, repair straps, re-sew buttons, shorten or lengthen hems, or anything that you wish to be altered or repaired.</p>
                                <p>We offer professional repairs & alterations service:</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul>
                                        <li>Tops</li>
                                        <li>Formal Dresses</li>
                                        <li>Evening Wear</li>
                                        <li>Wedding Dresses</li>
                                        <li>Jeans</li>
                                        <li>Beaded Dresses</li>
                                        <li>Waist in/out</li>
                                        <li>Sides in/out</li>
                                        <li>Shorten Shoulder</li>
                                        <li>Hem & Re-hemming</li>
                                        <li>Re-cuts</li>
                                        <li>Replace Lining</li>
                                        <li>Taper Sleeves/Dresses</li>
                                        <li>Patch up Jeans</li>
                                        <li>Replace Zippers</li>
                                        <li>Jeans Original Hem</li>
                                        <li>Re-weave</li>
                                    </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul>
                                            <li>Suits</li>
                                            <li>Trousers</li>
                                            <li>Skirts</li>
                                            <li>Shirts</li>
                                            <li>Leather & Suede</li>
                                            <li>Crotch in/out</li>
                                            <li>Restyling</li>
                                            <li>Sleeves Shortened</li>
                                            <li>Dress Hem</li>
                                            <li>Add/Remove Buttons</li>
                                            <li>Replace Buttons</li>
                                            <li>Shorten Coat</li>
                                            <li>Lower Collar</li>
                                            <li>Shorten Collar</li>
                                            <li>Taper Legs</li>
                                            <li>Lower Waist</li>
                                        </ul>
                                    </div>
                                </div>                                
                                <p>If you can't find what you require in the list above, please call us on 0208 316 6675 or visit one of our shops. We can cater to most tailoring and alteration needs.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg22} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTRepairalterations;