import React from "react";
import { useSelector } from "react-redux/es/exports";
import SectionOffer from "../theme-four/section-offer";
import GoogleReviews from "../../googleReview";
import SecSearch from "./sec";
import Review from "./review";
import Sec1 from "./sec1";
import Sec2 from "./sec2";
import Sec3 from "./sec3";
import Sec5 from "./sec5";
import Sec6 from "./sec6";
import Sec7 from "./sec7";
import Sec8 from "./sec8";
import Sec9 from "./sec9";
import NearestShops from "../nearestShops";
function ThemeNine() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  let shopURL = window.location.href.split("/");
  const checkIsSectionEnabled = (index: any) => {
    // if (settingsData?.template == 1) {
    //   return jsonData?.theme_1?.home?.sections[index]?.is_section_enable;
    // }
    // if (settingsData?.template == 3) {
    return jsonData?.theme_3?.home?.sections[index]?.is_section_enable;
    // }
    //   if (settingsData?.template == 5) {
    //     return jsonData?.theme_5?.home?.sections[index]?.is_section_enable;
    //   }
  };

  return (
    <>
    <div className="home-tnine">
        <SecSearch/>
        <Review/>
        <Sec1/>
        {!shopURL.includes('2in1drycleaners') &&
          <Sec2/>
        }
        <Sec3/>
        {/* <Sec4/> */}
        <Sec5/>
        <Sec6/>
        <Sec7/>            
        {/* <Sec4/> */}
        <Sec8/>
        {<NearestShops/>}
        <Sec9/>
    </div>
    </>
  );
}

export default ThemeNine;
