import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { shopimg } from "../../assets/img";
import { fetchProductAllCategories } from "../../redux/Actions/productCategoriesAction";
import { NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { fetchPostalCodes } from '../../redux/Actions';
import { setValue } from '../../utility';
import { fetchGetShopByPinCode, fetchShopList } from '../../redux/Actions/checkoutPageActions';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { fetchShopsBasedPincode } from "../../redux/Actions/shopHomeAction";
import axios from 'axios'
const base_url = process.env.REACT_APP_BACKEND_URL;
const postalCodesData = {
  data: {
    "Response": {
      "response_code": "1",
      "response_message": "success"
    },
    "data": {
      "covered_postcodes": [
      ]
    }
  }
}

const postalCodesSearchData = {
  result: {
    "Response": {
      "response_code": "1",
      "response_message": "success"
    },
    "data": [
      []
    ]
  }
}

function ShopList() {
  const [postalCodes, setPostalCodes] = useState<any | null>(postalCodesData)
  const [postalCodeValue, setPostalCodeValue] = useState("")
  const [postalCodeList, setPostalCodeList] = useState([])
  const [currentCodeValue, setcurrentCodeValue] = useState("")
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const state: any = useSelector(state => state);
  const settings: any = useSelector<any>((state) => state.settings);
  const shopsList: any = useSelector<any>(
    (state) => state.shopsList?.data
  );
  const params = useParams<any>();
  const [showModal, setShowModal] = useState(true);
  const [showAllServices, setShowAllServices] = useState(false);
  const postalCode = JSON.parse(localStorage.getItem("postalCode") || "");

  useEffect(() => {
    dispatch(fetchShopList(postalCode));
  }, []);

  const handleShopClick = (currentShopHome: any, currentShopName: any) => {
    localStorage.setItem("shop_id", currentShopHome);
    localStorage.setItem("currentShopHome", currentShopHome);
    localStorage.setItem("currentShopName", currentShopName);
    const finalResult = currentShopName.toLowerCase().replace(/\s+/g, '');
    navigate(`/shop/${finalResult}/productLists`);
};

const handleShopViewClick = (currentShopHome: any, currentShopName: any) => {
  localStorage.setItem("shop_id", currentShopHome);
  localStorage.setItem("currentShopHome", currentShopHome);
  localStorage.setItem("currentShopName", currentShopName);
  const finalResult = currentShopName.toLowerCase().replace(/\s+/g, '');
  navigate(`/shop/${finalResult}`);
};

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state])
  const handleChange = (e: any) => {
    const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, '')
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);

  }
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase())
    // dispatch(fetchPostalCodes(searchTerm));


  };
  const handleBooknow = async () => {
    const isValid = state.postalCodeList.some((item: any) => item.postcode === postalCodeValue.toUpperCase());
    setValue("postalCode", postalCodeValue.toUpperCase())
    // const results = await dispatch(fetchShopList(postalCodeValue));
    const results = await dispatch(fetchGetShopByPinCode(JSON.stringify(postalCodeValue)));
    // if (!results) {
    //     navigate("/areaNotCovered");
    //     return;
    // }
    if (isValid && results.Response.response_code != "-1") {
      navigate("/productLists");
      // navigate("/shopList");
    } else {
      navigate("/areaNotCovered");
    }

  }
  const shopsDataPostcode = (currentCodeValue: any) => {
    const area = "Croydon"
    dispatch(fetchShopsBasedPincode(area, currentCodeValue))
  }

  useEffect(() => {

    const headers = {
      'Accept': 'application/json'

    }

    axios.get(`${base_url}/api/pincodes_covered_by_district?district=Croydon`, {
      headers: headers

    }).then(e => {
      setPostalCodes({ data: e.data })
      let areaData = e.data.data.covered_postcodes[0].postcode_area
      setcurrentCodeValue(areaData)
    })
  }, [])

  return (
    <>
      {/* <Modal show={showModal} onHide={closeModal} centered className="shop-modal">
        <Modal.Header closeButton>
          <Modal.Title>Try With Postal Codes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="postcodeform-modal">
            
            <DebounceInput debounceTimeout={500} value={postalCodeValue} placeholder="Enter Zipcode" onChange={handleChange} autoComplete="off" />
            <button disabled={postalCodeValue.length === 0} onClick={handleBooknow} className="btn" id="book_now_btn" type="button">Book Now</button>
            <div className="dropdown">
              {postalCodeList
                .filter((item: any) => {
                  const searchTerm = postalCodeValue.toLowerCase();
                  const mainValue = item.postcode.toLowerCase();

                  return (
                    searchTerm &&
                    mainValue.startsWith(searchTerm) &&
                    mainValue !== searchTerm
                  );
                })
                .slice(0, 10)
                .map((item: any) => (
                  <div
                    onClick={() => onSearch(item.postcode)}
                    className="dropdown-row"
                    key={item.postcode}
                  >
                    <i className="fas fa-map-marker-alt"></i>{item.postcode}
                  </div>
                ))}
            </div>
          </div>
          <div className="area-list-01 text-center">
            <h3 className="bold">We Covered the below area pincodes</h3>
            <div className="arealist-slider wow fadeInDown">
              <div className="arcode-slider">
                <Swiper
                  spaceBetween={15}
                  navigation={true}>

                  {


                    postalCodes.data.data.covered_postcodes.map((covered_postcode: any) => {
                      return (
                        <SwiperSlide>
                          <a href='javascript:void(0)' onClick={() => shopsDataPostcode(covered_postcode.postcode_area)}> {covered_postcode.postcode_area}</a>
                        </SwiperSlide>
                      )
                    }
                    )
                  }
                </Swiper>


              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      <div className="container my-5">
        <div className="row">
          <div className="col-md-12">
            <div className="home-shop-hdr">
              <h2 className="d-inline-block home-shop-h2">Showing shops in <span className="shop-hdrspan">{postalCode}</span></h2>
            </div>
          </div>
        </div>
        <div className="row">
          {shopsList?.shops.map((item: any) => {
            return (
              <>
                <div className="col-md-3 d-flex">
                  <div className="shop-grid">
                    <div className="shop-img text-center">
                      {item?.setting?.shop_logo_url ? <img className="p-3" src={item?.setting?.shop_logo_url || shopimg} alt="" /> : <img src={shopimg} alt="" />}
                      {/* <img src={item?.setting?.shop_logo_url || shopimg} alt="" /> */}
                    </div>
                    <div className="shop-details">
                      <h3
                        onClick={() => handleShopClick(item?.id, item?.name)}
                        style={{ cursor: "pointer" }}
                      >
                        {item?.name}
                      </h3>
                      <p className="clr-grey shop-details-adrs">
                        <i className="fas fa-map-marker-alt me-2"></i>
                        {item?.address?.text_address}
                      </p>
                      {item?.rating > 0 && (
                        <div className="strrating-grid">
                          <div>
                            <span className="strrating">
                              {Array.from({ length: 5 }, (_, index) => {
                                const roundedRating = Math.round(item?.rating * 2) / 2;
                                if (roundedRating - index >= 1) {
                                  return <i className="fas fa-star" key={index}></i>;
                                } else if (roundedRating - index === 0.5) {
                                  return <i className="fas fa-star-half-alt" key={index}></i>;
                                } else {
                                  return <i className="far fa-star" key={index}></i>;
                                }
                              })}
                            </span>
                            <span className="strratingcount">
                            <a
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate("/shopreviews", { state: { 
                                        shopId: item.id,
                                        shopName: item.name,
                                        shopLogo: item?.setting?.shop_logo_url || shopimg,
                                        shopAddress: item?.address?.text_address, 
                                      } });
                                    }}
                                  >
                                    {item?.rating_count} Reviews
                                  </a>
                            </span>
                          </div>
                          <div></div>
                        </div>
                      )}
                      <ul className="d-none">
                        {item?.recent_products.map((services: any) => {
                          return (
                            <>
                              <li>
                                <div>
                                  {services?.groupcategory?.subcategory.name}
                                  <span className="clr-grey ms-4">
                                    <i className="far fa-clock me-2"></i>
                                    {services?.groupcategory?.process_time}hrs
                                  </span>
                                </div>
                                <div className="shop-price">
                                  {settings?.WebmasterSettings?.currency}{" "}
                                  {
                                    services?.group_category_default_option_price?.price
                                  }
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                      <div className="shop-serv-link">
                        <a onClick={() => handleShopViewClick(item?.id, item?.name)} className="btn btn1">View Shop<i className="fas fa-angle-right"></i></a>
                        <a onClick={() => handleShopClick(item?.id, item?.name)} className="btn btn2">Book Now<i className="fas fa-angle-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ShopList;
