import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";

// import  "./orderOnline.css"

function SectionFiveThemeFive() {
  const state: any = useSelector((state) => state);

  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  useEffect(() => {
  }, [jsonData]);
  return (
    <div
      className="wash-fold-section"
      style={{
        background: `var(--primary-color) url(${jsonData?.theme_5?.home?.section_5?.banner}) no-repeat top center`,
      }}
    >
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="wash-fold-card">
              <div className="wash-fold-text">
                <h2 style={{
                      color: `#${jsonData?.theme_5?.home?.section_5?.h2color}`
                    }}>{jsonData?.theme_5?.home?.section_5?.title}</h2>
                {/* <h2>WASH <br />& <br/> FOLD</h2> */}
              </div>
              <div className="wash-fold-info">
                <ul>
                  {jsonData?.theme_5?.home?.section_5?.wash_title?.map(
                    (item: any) => {
                      return (
                        <>
                          <li>
                            <i className="fa fa-check"></i>{" "}
                            <p style={{
                      color: `#${jsonData?.theme_5?.home?.section_5?.paracolor}`
                    }}>{item?.wash_list}</p>
                          </li>
                        </>
                      );
                    }
                  )}
                  {/* <li><i className="fa fa-check"></i> <p>Washed under <span className=''>30 degrees</span></p></li>
                                        <li><i className="fa fa-check"></i> <p>Dried at <span className=''>medium heat</span></p></li>
                                        <li><i className="fa fa-check"></i> <p>Customer’s responsibility to <span className=''>make sure all items are washable</span></p></li>
                                        <li><i className="fa fa-check"></i><span> <p><span className=''>Towels and beddings</span> are  excluded and will be charged separately.</p></span></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionFiveThemeFive;
