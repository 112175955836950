import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const ManageCookiesPopup: React.FC<{
  close: any;
  accept: any;
  accepted: any;
}> = ({ close, accept, accepted }) => {
  const cookies = new Cookies();
  const [necessaryOpen, setNecessaryOpen] = useState(false);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

  const handleQuickBookClose = () => {
    close();
  };

  const handleNecessaryToggle = () => {
    setNecessaryOpen(!necessaryOpen);
  };

  const handleAnalyticsToggle = () => {
    setAnalyticsOpen(!analyticsOpen);
  };

  const handleAnalytics = () => {
    setAnalyticsEnabled(!analyticsEnabled);
  };

  const selectAllCookies = () => {
    setAnalyticsEnabled(true);
    accept();
  };

  const confirmMyChoices = () => {
    if (analyticsEnabled === true) {
      accept();
    } else {
      cookies.set("necessaryCookiesAccepted", true, { path: "/" });
      accepted();
    }
  };

  return (
    <div>
      <div
        className="modal in"
        id="app_cookie_popup"
        style={{ display: "block" }}
      >
        <div className="vertical-alignment-helpers">
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="cookiepop-hdr">
                  <h3>Cookies</h3>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={handleQuickBookClose}
                  ></button>
                </div>
                <div className="cookiepop-body">
                  <p>
                    Cookies are small pieces of text sent to your browser by a
                    website you visit. They help that website remember
                    information about your visit, which can both make it easier
                    to visit the site again and make the site more useful to
                    you.
                  </p>
                </div>

                <div className="cookiepop-toggle">
                  <div className="cookiepop-toggle-hdr">
                    <button onClick={handleNecessaryToggle}>
                      <i
                        className={`fas ${
                          necessaryOpen ? "fa-chevron-down" : "fa-chevron-right"
                        }`}
                      ></i>
                      <span>Necessary</span>
                    </button>
                    <span>Always active</span>
                  </div>
                  {necessaryOpen && (
                    <div>
                      <p>
                        Strictly necessary cookies help make a website navigable
                        by activating basic functions such as page navigation
                        and access to secure website areas. Without these
                        cookies, the website would not be able to work properly.
                      </p>
                    </div>
                  )}
                </div>
                <div className="cookiepop-toggle">
                  <div className="cookiepop-toggle-hdr">
                    <button onClick={handleAnalyticsToggle}>
                      <i
                        className={`fas ${
                          analyticsOpen ? "fa-chevron-down" : "fa-chevron-right"
                        }`}
                      ></i>
                      <span>Analytics</span>
                    </button>
                    <label className="switch">
                      <input type="checkbox"  checked={analyticsEnabled} onChange={handleAnalytics} />
                      <span className="sliders round"></span>
                    </label>
                  </div>
                  {analyticsOpen && (
                    <div className="">
                      <p>
                        Analytics cookies help the website owner understand how
                        visitors interact with the website by collecting and
                        reporting information.
                      </p>
                    </div>
                  )}
                </div>
                <div className="cookies-banner-grid-links">
                  <button
                    className="cookies-btn1"
                    data-bs-dismiss="modal"
                    onClick={selectAllCookies}
                  >
                    Accept All
                  </button>
                  <button className="cookies-btn2" onClick={confirmMyChoices}>
                    Confirm my choices
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageCookiesPopup;
