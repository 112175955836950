import React from "react";
import { useSelector } from "react-redux/es/exports";
import SectionOffer from "../theme-four/section-offer";
import GoogleReviews from "../../googleReview";
import SectionOne from "./sectionOne";
import SectionTwo from "./sectionTwo";
import NearestShops from "../nearestShops";
function ThemeEleven() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  let shopURL = window.location.href.split("/");
  const checkIsSectionEnabled = (index: any) => {
    // if (settingsData?.template == 1) {
    //   return jsonData?.theme_1?.home?.sections[index]?.is_section_enable;
    // }
    // if (settingsData?.template == 3) {
    return jsonData?.theme_3?.home?.sections[index]?.is_section_enable;
    // }
    //   if (settingsData?.template == 5) {
    //     return jsonData?.theme_5?.home?.sections[index]?.is_section_enable;
    //   }
  };

  return (
    <>
    <div className="home-ihateironing">
      <SectionOne/>
      <SectionTwo/>
      {<NearestShops/>}
      </div>
    </>
  );
}

export default ThemeEleven;
