import { Component } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function Whatsapp() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const shopHomedata: any = useSelector<any>((state) => state.shopHomeJsonList);
  const location = useLocation();
  const link = settingsData?.WebsiteSettings?.social_link10;
  console.log("shopHomedata",shopHomedata)
  const url = location.pathname;
  const splitURL: any = url.toString().split("/");
  //let link;
  // if (settingsData?.template == 1) {
  //   if (splitURL[1] != "shop" && splitURL[1] != "edit-shop-home") {
  //     link =
  //       homedata?.theme_1?.home?.footer?.footer_section?.social_media?.whatsapp
  //         ?.whatsapp_link;
  //   } else {
  //     link =
  //       shopHomedata?.jsonData?.theme_1?.home?.footer?.footer_section
  //       ?.social_media?.whatsapp?.whatsapp_link;
  //   }
  // } else if (settingsData?.template == 2) {
  //   if (splitURL[1] != "shop" && splitURL[1] != "edit-shop-home") {
  //     link =
  //       homedata?.theme_2?.home?.footer?.footer_section?.social_media?.whatsapp
  //         ?.whatsapp_link;
  //   } else {
  //     link =
  //       shopHomedata?.theme_2?.home?.footer?.footer_section
  //       ?.social_media?.whatsapp?.whatsapp_link;
  //   }
  // } else if (settingsData?.template == 3) {
  //   if (splitURL[1] != "shop" && splitURL[1] != "edit-shop-home") {
  //     link =
  //       homedata?.theme_3?.home?.footer?.footer_section?.social_media?.whatsapp
  //         ?.whatsapp_link;
  //   } else {
  //     link =
  //       shopHomedata?.theme_3?.home?.footer?.footer_section?.social_media?.whatsapp?.whatsapp_link;
  //   }
  // } else if (settingsData?.template == 4) {
  //   if (splitURL[1] != "shop" && splitURL[1] != "edit-shop-home") {
  //     link =
  //       homedata?.theme_4?.home?.footer?.social_media?.whatsapp?.whatsapp_link;
  //   } else {
  //     link =
  //       shopHomedata?.theme_4?.home?.footer?.social_media
  //       ?.whatsapp?.whatsapp_link;
  //   }
  // } else if (settingsData?.template == 5) {
  //   if (splitURL[1] != "shop" && splitURL[1] != "edit-shop-home") {
  //     link =
  //       homedata?.theme_5?.home?.footer?.footer_section?.social_media?.whatsapp
  //         ?.whatsapp_link;
  //   } else {
  //     link =
  //       shopHomedata?.theme_5?.home?.footer?.footer_section?.social_media
  //         ?.whatsapp?.whatsapp_link;
  //   }
  // } 
  console.log("link",settingsData?.templ)
  return (
    <>
      {splitURL[1] !== "maintenance" && (
        <>
        {link ? (
        <div className="link-whatsapp">
          
            <a target="blank" href={`${link}`}>
              <i className="fab fa-whatsapp"></i>
            </a>
          
        </div>
        ) : (
          <></>
        )}
        </>
      )}
    </>
  );
}

export default Whatsapp;
