import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveOrderLog } from "../../redux/Actions/checkoutPageActions";

const CardForm = (props: any) => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [error, setError] = useState<any>(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const stripeId: any = useSelector<any>(
    (state) => state?.paymentMethods.stripe_key
  );

  useEffect(() => {
    if (props.verifyCard) {
      handleSubmit();
    }
  }, [props.verifyCard == true]);

  const handleSubmit = async () => {
    const intent_secret = localStorage.getItem("setup_intent_secret");
    let errorSpan = document.getElementById("card-errors");
    const btn = document.getElementsByTagName("button");
    const abtn = document.getElementsByTagName("a");
    for (let i = 0; i < btn.length; i++) {
      const b = btn[i];
      b.classList.add("disabled");
    }
    for (let i = 0; i < abtn.length; i++) {
      const a = abtn[i];
      a.classList.add("disabled");
    }

    stripe
      .confirmCardSetup(`${intent_secret}`, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      .then(function (result: any) {
        if (result.error) {
          const payloadData = {
            slug : 'card_error',
            message : result.error.message ?? ''
          }
          props?.saveLog(payloadData);
          errorSpan!.innerText = result.error.message;
          setError(result.error ? result.error.message : "");
          props.verificationCompleted();
          return;
        }

        for (let i = 0; i < btn.length; i++) {
          const b = btn[i];
          b.classList.remove("disabled");
        }
        for (let i = 0; i < abtn.length; i++) {
          const a = abtn[i];
          a.classList.remove("disabled");
        }
        localStorage.setItem("setup_intent", result.setupIntent.id);

        const payloadData = {
          slug : 'card_success',
        }
        props?.saveLog(payloadData);

        props.submitOrder();
      }).catch((err: any) => {
        const payloadData = {
          slug : 'card_error',
          message : err.message ?? 'Error message not found'
        }
        props?.saveLog(payloadData);
      });
    props.verificationCompleted();
  };

  const handleChange = async (event: any) => {
    setError(event.error ? event.error.message : "");
    let errorSpan = document.getElementById("card-errors");
    errorSpan!.innerText = "";

    if (event.complete) {
      setCardComplete(event.complete);
      const btn = document.getElementsByTagName("button");
      const abtn = document.getElementsByTagName("a");
      for (let i = 0; i < btn.length; i++) {
        const b = btn[i];
        b.classList.remove("disabled");
      }
      for (let i = 0; i < abtn.length; i++) {
        const a = abtn[i];
        a.classList.remove("disabled");
      }
      props.verificationCompleted();
    }
  };
  useEffect(() => {
    props.onCardVerificationCallBack(!stripe || !cardComplete || processing);
  }, [stripe, cardComplete, processing, handleChange]);
  return (
    <form>
      <CardElement onChange={handleChange} options={{ hidePostalCode: true }} />
    </form>
  );
};
export default CardForm;
