import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function SectionEightThemeThree() {
  const data: any = useSelector<any>((state) => state?.homeJsonList);
  const section_8 = data.theme_3.home.section_8;
  return (
    <>
      <section className="trust-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="trust-content">
                <h2 style={{
                      color: `#${section_8?.h2color}`
                    }}>{section_8.title}</h2>
                <p style={{
                      color: `#${section_8?.paracolor}`
                    }}>{section_8.paragraph}
                    {window.location.href.includes('mydhobi') &&
                    <>
                      <br />
                      <br />
                      <h3>Why We’re Different</h3>
                      <br />
                      <p>Unlike other online laundry platforms that outsource their work, My Dhobi manages every aspect of the process in-house. From the moment you place your order to the final delivery, we handle each step directly with our dedicated team and fleet of drivers. This hands-on approach ensures that every garment receives the utmost care and attention throughout its journey. Our commitment to not outsourcing our services means we have complete control over the quality and efficiency of our operations. Our own branch manages all laundry and dry-cleaning tasks, allowing us to maintain the highest standards of cleanliness and customer service. This direct involvement fosters greater trust and confidence, ensuring a seamless and reliable experience from collection to delivery. Experience the perfect blend of tradition and innovation with My Dhobi, where your laundry needs are met with unparalleled excellence and personal care. Trust us to deliver a level of service that sets us apart and exceeds your expectations.</p>
                    </>
                    }
                    </p>
                <ul className="trust-content-media">
                  {section_8.card_section.collection.map((items: any) => {
                    return (
                      <>
                        <li>
                          <div className="d-flex">
                            <span className="flex-shrink-0 icon-box">
                              <img src={items.collection_image} alt="" />
                            </span>
                            <div className="flex-grow-1">
                              <h4 style={{
                      color: `#${section_8?.h3color}`
                    }}> {items.collection_title} </h4>
                              <p style={{
                      color: `#${section_8?.paracolor}`
                    }}>{items.collection_paragraph}</p>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
                <div className="trust-content-btn d-none">
                  <a
                    style={{
                      background: `#${section_8?.btn_group?.btn2_bg_color}`,
                      color: `#${section_8?.btn_group?.btn2_color}`,
                    }}
                    href="#book-now"
                    className="btn title-btn hover-btn"
                  >
                    {section_8.btn_group.btn1_text}
                    <i className="uil uil-arrow-circle-right ms-2"></i>
                  </a>
                  <a
                    style={{
                      background: `#${section_8?.btn_group?.btn1_bg_color}`,
                      color: `#${section_8?.btn_group?.btn1_color}`,
                    }}
                    href="#book-now"
                    className="btn primary-btn hover-btn"
                  >
                    {section_8.btn_group.btn2_text}
                    <i className="uil uil-arrow-circle-right ms-2"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="split-img">
                <img src={section_8.section_image} alt="" />
                <div className="split-img-framegrid">
                  <div className="split-img-frame1"></div>
                  <div className="split-img-frame2"></div>
                  <div className="split-img-frame3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionEightThemeThree;
