import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {slider1,slider2,slider3,slider4,slider5,slider6,slider7,slider8,upimg1} from "../../assets/img";

function Laundry () {

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">                    
                    <div className="slider-up">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        className="slider-up-slider"
                        >
                            <SwiperSlide >                            
                                <img src={slider2} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>                                                        
                            <SwiperSlide >                            
                                <img src={slider3} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider4} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider5} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider6} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider7} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>                            
                            <SwiperSlide >                            
                                <img src={slider8} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider1} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                      </Swiper>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-md-6">   
                    <div className="up-content">
                        <h2 className="up-h2">SELF SERVICE WASH</h2>
                        <p>
                        We have four different sized washing machines dependant on your load size:
                        </p>
                        <p>
                        <b>Small Load</b> - £5.00 (plus an extra £2.00 for soap powder if required)
                        </p>
                        <p>
                        <b>Medium Load</b> - £7.00 (plus an extra £2.00 for soap powder if required)
                        </p>
                        <p>
                        <b>Large Load</b> - £8.00 (plus an extra £2.50 for soap powder if required)
                        </p>
                        <p>
                        <b>Giant Load (36kg)</b> - £18.00 (plus an extra £3.50 for soap powder if required)
                        </p>
                        <p>
                        With our tumble dryers being £1.00 per 10 minute cycle.
                        </p>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-md-6">   
                    <div className="up-content">
                        <h2 className="up-h2">SERVICE WASH</h2>
                        <p>
                        For one of our operatives to complete your service wash, which includes Wash, Dry & Fold:
                        </p>
                        <p>
                        <b>Large Bag</b> - £14.95
                        </p>
                        <p>
                        <b>Extra Large Bag</b> - £16.95
                        </p>
                        <p>
                        <b>Extra Extra Large Bag</b> - £18.95
                        </p>
                        <p>
                        <b>Giant Bag</b> - £30.00
                        </p>
                        <p>
                        Enjoy an additional 15% discount if you have 2 or more bags.
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="up-img">
                        <img src={upimg1} alt="" />
                    </div>
                </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
                    <div className="up-card-bg">
                        <p>Book a Pickup - Call 01792 644238 or<br/> text 07999 999294</p>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default Laundry;