import React, { useEffect, useState } from "react";
import {cimg1,cimg2,cimg3,cimg4,cimg5,cimg6,cimg7,cimg8,cimg9,cimg10,cimg11,cimg12,cimg13,cimg14,cimg15,cimg16,cimg17,cimg18,cimg19,cimg20,cimg21,cimg22,cimg23,cimg24,cimg25,cimg26,cimg27,cimg28,cimg29, cimg30, cimg31} from "../../assets/img";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Carousel from 'react-bootstrap/Carousel';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { alphabet, email, phoneNumber, alphaNumericPattern } from "../../components/core/regex";
import {  postContactForm } from "../../redux/Actions";
import Select from "react-select";

const contactUsFormSchema = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .max(50, "Enter cannot exceed 50 character")
      .matches(alphabet, "Name should be only alphabet")
      .trim(),
    email: yup
      .string()
      .required("Email address is required")
      .matches(email, "Enter a valid email address")
      .max(96, "Enter cannot exceed 96 character")
      .trim(),
    mobile: yup
      .string()
      .required("Phone number is required")
      .matches(phoneNumber, "Enter a valid phone number")
      .trim(),
    business: yup
      .string()
      .required("Business is required")
      .matches(
        alphaNumericPattern,
        "Business name cannot not contain any special character"
      )
      .max(150, "Business name cannot exceed 150 character")
      .trim(),
    message: yup
      .string()
      .required("Message is required")
      .matches(
        alphaNumericPattern,
        "Message cannot not contain any special character"
      )
      .max(250, "Message cannot exceed 250 character")
      .trim(),
      service_type: yup
        .object()
        .shape({
        label: yup.string().required("Service type is required to proceed"),
        })
        .required("Service type is required to proceed"),
    ref: yup
        .object()
        .shape({
        label: yup.string().required("Reference is required to proceed"),
        })           
        .required("Reference is required to proceed"),
  });

function Commercial () {
    const [message, setMessage] = useState<string>("");
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
      const {
        handleSubmit: contactFormSubmit,
        control: contactFormControl,
        reset: contactFormReset,
        formState: { errors: contactFormErrors },
      } = useForm({
        resolver: yupResolver(contactUsFormSchema),
        defaultValues: {
          email: "",
          message: "",
          name: "",
          mobile: "",
          business: "",    
        },
      });

      const submitContactForm = async (data: any) => {
        const transformedData = {
            ...data,
            service_type: data.service_type.label,
            ref: data.ref.label,
          };
        const response: any = await postContactForm(transformedData);
        console.log("datacf",data);
        console.log("datacfresponse.status",response.status);
        if (response.status) {
          // setContactUs("contactus-grid");
          contactFormReset();
          setMessage(response.message);
          setTimeout(() => {
            setMessage("");
          }, 3000);
        } else {
          setMessage(response.message);
        }
      };

      const options = [
        { label: 'My Company would like to use 2 in 1 Dry Cleaners\'s services', value: 'use_services' },
        { label: 'I\'d like to discuss something else', value: 'discuss' },
      ];
      const optionsref = [
        { label: 'From where did you hear about us', value: '' },
        { label: 'Facebook', value: 'facebook' },
        { label: 'Google', value: 'google' },
        { label: 'Word of mouth', value: 'word_of_mouth' },
        { label: 'Others', value: 'others' },
      ];
return (
    <>
        <section className="servicetop3">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="servicetopslider">
                            <div className="girdboxes">
                                <div className="d-block">
                                    <img src={cimg1} alt=""/>
                                </div>
                                <p className="text12">24 Turn Around Time</p>
                            </div>
                            <div className="girdboxes">
                                <div className="d-block">
                                    <img src={cimg2} alt=""/>
                                </div>
                                <p className="text12">Free Pickup & Delivery</p>
                            </div>
                            <div className="girdboxes">
                                <div className="d-block">
                                    <img src={cimg1} alt=""/>
                                </div>
                                <p className="text12">24 Turn Around Time</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="mainhead mainhead2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="mainheadtext">
                            <div className="mainname">
                                <h1 className="bannertext text55 text-capitalize">Commercial Laundry and Dry Cleaning Service</h1>
                                <p className="bannersubtext text20 mt-2">Looking for commercial laundry service nearby your locality? Here you go! We are a leading industrial launderette and dry cleaner in London and provides professional laundry cleaning services to your business with free pickup and delivery.</p>
                            </div>
                            <div className="inputcode">
                                <a href="#commercialenquiryform" data-scrollto="commercialenquiryform" data-offset="150" className="text16 bluebackground">Enquiry Now</a>
                            </div>
                            <div className="applinkdiv brands">
                                <p className="text20 color-gray mb-2">Brand That Trust Us</p>
                                <div className="brandslider">
                                    <Slider {...settings}>
                                        <div>
                                        <img src={cimg3} alt=""/>
                                        </div>
                                        <div>
                                        <img src={cimg4} alt=""/>
                                        </div>
                                        <div>
                                        <img src={cimg5} alt=""/>
                                        </div>
                                        <div>
                                        <img src={cimg6} alt=""/>
                                        </div>
                                        <div>
                                        <img src={cimg7} alt=""/>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="headimg">
                            <div className="dotcontent">
                                <div className="commimgslider headbanner aboutthisleft bluebg">
                                    <Carousel>
                                        <Carousel.Item>
                                            <img src={cimg8} alt=""/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cimg9} alt=""/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                        <   img src={cimg10} alt=""/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cimg11} alt=""/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cimg12} alt=""/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cimg13} alt=""/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cimg14} alt=""/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cimg15} alt=""/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cimg16} alt=""/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cimg17} alt=""/>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="Commercialhead mt-4 mb-3">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="commgreview">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 style={{color:"#ffd06d"}}>Ranked #1 by customers</h3>
                                    <h5 style={{color:"#ffffff"}}>Ranked Excellent by our Customers in Categories of Dry cleaner, Laundry service, and Laundrette.</h5>
                                </div>
                                <div className="col-md-6 text-end">
                                    <img src={cimg18} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="commcontent">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Laundry Service Commercial</h2>
                        <p>2 in 1 Dry Cleaners specialises in commercial launderette services in London, UK. We provide professional laundry services to hotels, serviced apartments, restaurants, medical departments, casinos, cafes, pizzeria, gyms, fitness studios, salons, spas, security and offices.</p>
                        <p>Our commercial services help business owners and managers across all industries. We give the right commercial laundry and dry cleaning service with free pickup and delivery in London areas. It helps save time and money as our prices are surprisingly competitive.</p>
                        <p>Our exceptionally high quality services and quick turnaround means that our aim to ensure our customer’s requirements always come first. We know that finding a quality commercial laundry services can be difficult, which is why we do this service for you. Whether your business needs a regular laundry service or dry cleaning service, consider 2 in 1 Dry Cleaners, at affordable pricing.</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="combluebg">
                            <h2>Get a Free Quote!</h2>
                            <a className="btn btncomm1" href="#commercialenquiryform"><i className="fas fa-heart"></i> ENQUIRY NOW!</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="seccommercial">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2>What We Offer In Commercial Laundry Service</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Medical Laundry Service</h3>
                        <p>2 in 1 Dry Cleaners offers a wide array of healthcare and medical commercial laundry services in London. Our tailored laundry solutions are entrusted by private and as well as NHS hospitals. High-capacity machines thoroughly clean and decontaminate all types of hospital linens.</p>
                        <div className="secicoimg">
                            <img src={cimg7} alt=""/>
                            <img src={cimg5} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="secimg">
                            <img src={cimg19} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <hr className="secdivi"/>
                </div>
            </div>
        </div>
        <section className="seccommercial">
            <div className="container">
                <div className="row">                    
                    <div className="col-lg-6">
                        <div className="secimg">
                            <img src={cimg20} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h3>Airbnb Cleaning Service</h3>
                        <p>Being Airbnb cleaning experts, we provide top-notch commercial laundry services that include removing and washing heavy stains from towels, linens, cushions, and other items. We cater to the needs of small, medium, and large-sized Airbnb hosts.</p>
                        <div className="secicoimg">
                            <img src={cimg3} alt=""/>
                            <img src={cimg4} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <hr className="secdivi"/>
                </div>
            </div>
        </div>
        <section className="seccommercial">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Workwear Laundry Services</h3>
                        <p>We offer premium workwear laundry services for all types of businesses. Our facilities are equipped with the most advanced commercial laundry equipment in London. Through a meticulously-designed wash process, we ensure that workwear garments are cleaned to the highest standards.</p>
                        
                    </div>                    
                    <div className="col-lg-6">
                        <div className="secimg">
                            <img src={cimg21} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <hr className="secdivi"/>
                </div>
            </div>
        </div>
        <section className="seccommercial">
            <div className="container">
                <div className="row">                    
                    <div className="col-lg-6">
                        <div className="secimg">
                            <img src={cimg22} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h3>Restaurants Laundry Service</h3>
                        <p>Hotel industry is one of the most demanding industries. 2 in 1 Dry Cleaners deep understanding & technical knowledge of restaurant workwear caters to all types of hygiene requirements. We offer end-to-end restaurant laundry service at competitive prices.</p>
                        
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <hr className="secdivi"/>
                </div>
            </div>
        </div>
        <section className="seccommercial">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Hotel Laundry Services</h3>
                        <p>From small-sized hotels to large-sized hotels, 2 in 1 Dry Cleaners offers tailored commercial laundry services across London. We provide free pick-up and delivery services and same-day delivery. We support the hotel sector through reliable, efficient, and trouble-free commercial laundry services.</p>
                        
                    </div>                    
                    <div className="col-lg-6">
                        <div className="secimg">
                            <img src={cimg23} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <hr className="secdivi"/>
                </div>
            </div>
        </div>
        <section className="seccommercial">
            <div className="container">
                <div className="row">                    
                    <div className="col-lg-6">
                        <div className="secimg">
                            <img src={cimg24} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h3>Cafe Laundry Service</h3>
                        <p>The more the clean ambiance of your cafe, the more customer satisfaction. We understand how imperative is commercial laundry service for cafe owners. Therefore, we provide tailored commercial laundry services at market-competitive prices.</p>
                        
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <hr className="secdivi"/>
                </div>
            </div>
        </div>
        <section className="seccommercial">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Spa Laundry Services</h3>
                        <p>Get top-notch and tailored commercial laundry services for spas, unisex salons, beauty and personal care industry in London. With cashless payments and 24/7 dedicated support, we collect, clean, and deliver right at your doorstep.</p>
                       
                    </div>                    
                    <div className="col-lg-6">
                        <div className="secimg">
                            <img src={cimg25} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <hr className="secdivi"/>
                </div>
            </div>
        </div>
        <section className="seccommercial">
            <div className="container">
                <div className="row">                    
                    <div className="col-lg-6">
                        <div className="secimg">
                            <img src={cimg26} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h3>Towel Cleaning Service</h3>
                        <p>We use quality detergents to ensure that your towels are cleaned thoroughly and deeply. Likewise, we use conditioners to keep your towels fluffy and fresh. 2 in 1 Dry Cleaners provides commercial laundry services for microfiber, organic cotton baths, and white towels. Our washing process is tailored-made to suit your unique requirements.</p>
                        
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <hr className="secdivi"/>
                </div>
            </div>
        </div>
        <section className="seccommercial">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Gym Laundry Service</h3>
                        <p>We provide commercial laundry services for towels and robes for gyms, fitness centers, and sports clubs. 2 in 1 Dry Cleaners offers complete gym laundromat services in London and adjoining areas at market-competitive prices.</p>
                        
                    </div>                    
                    <div className="col-lg-6">
                        <div className="secimg">
                            <img src={cimg27} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <hr className="secdivi"/>
                </div>
            </div>
        </div>
        <section className="seccommgrev">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Our Happy Customers</h2>
                        <p className="seccommgrevsub">Rated Excellent<img src={cimg28} alt=""/>By 5,000+ Users</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="seccommgrevgrid">
                            <h3>Fast and Reliable</h3>
                            <p>I have been using 2 in 1 Dry Cleaners for about three years now, and my clothes look great, smell great, and I’m happy! I honestly don’t know what I would do without them.</p>
                            <h4>Dean William</h4>
                            <p>
                                <img src={cimg28} alt=""/>
                            </p>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="seccommgrevgrid">
                            <h3>Very Cooperative Staff</h3>
                            <p>2 in 1 Dry Cleaners app is very easy to use. Brilliant service ! Driver is prompt & polite.</p>
                            <h4>Guiovanny Cardenas</h4>
                            <p>
                                <img src={cimg28} alt=""/>
                            </p>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="seccommgrevgrid">
                            <h3>Good and Convenient Place</h3>
                            <p>Amazing customer service!! I’m visiting from Florida and the gentleman working helped me with everything, he even put the laundry detergent for me!</p>
                            <h4>Ana Paula MI</h4>
                            <p>
                                <img src={cimg28} alt=""/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="subscribesec contactsec">
            <div className="container subscribe">
                <div className="row no-gutters">
                    <div className="col-lg-9">
                        <div className="subscribeleft">
                            <div className="subscribeleftimg">
                                <img src={cimg29} alt="Call dark" loading="lazy" />
                            </div>
                            <div className="subscribelefttext">
                                <h2 className="text48 mb-2">Contact for Quote</h2>
                                <div className="subscribetextlinks pt-1">
                                    <a href="mailto:rasha.hafidh@gmail.com">
                                        <img src={cimg30} className="img-fluid" alt="Email black" loading="lazy" />
                                        rasha.hafidh@gmail.com</a>
                                    <a href="tel:(+44) 7903778069">
                                        <img src={cimg31} className="img-fluid" alt="Call black" loading="lazy" />
                                        (+44) 7903778069</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="subscriberight">
                            <a className="whitebackground text18 text-uppercase special" href="#commercialenquiryform">enquiry</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="mt-4">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="formhdr">For Best Deal Get In Touch One of our team is ready to assist you</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={contactFormSubmit(submitContactForm)}>
                            <div className="commercialenquiryform" id="commercialenquiryform">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="mb-5">
                                            <Controller
                                                name="name"
                                                control={contactFormControl}
                                                render={({ field }) => (
                                                <input
                                                    placeholder="Name"
                                                    {...field}
                                                    className={`form-control ${
                                                    contactFormErrors?.name ? "invalid" : ""
                                                    }`}
                                                />
                                                )}
                                            />
                                            {contactFormErrors.name ? (
                                                <small className="text-danger">
                                                {contactFormErrors.name?.message}
                                                </small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="mb-5">
                                            <Controller
                                                name="business"
                                                control={contactFormControl}
                                                render={({ field }) => (
                                                <input
                                                    type="text"
                                                    placeholder="Business"
                                                    {...field}
                                                    className={`form-control ${
                                                    contactFormErrors?.business ? "invalid" : ""
                                                    }`}
                                                />
                                                )}
                                            />
                                            {contactFormErrors.business ? (
                                                <small className="text-danger">
                                                {contactFormErrors.business?.message}
                                                </small>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="mb-5">
                                            <Controller
                                                name="mobile"
                                                control={contactFormControl}
                                                render={({ field }) => (
                                                <input
                                                    type="text"
                                                    placeholder="Phone"
                                                    {...field}
                                                    className={`form-control ${
                                                    contactFormErrors?.mobile ? "invalid" : ""
                                                    }`}
                                                />
                                                )}
                                            />
                                            {contactFormErrors.mobile ? (
                                                <small className="text-danger">
                                                {contactFormErrors.mobile?.message}
                                                </small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="mb-5">
                                            <Controller
                                                name="email"
                                                control={contactFormControl}
                                                render={({ field }) => (
                                                <input
                                                    placeholder="Email"
                                                    {...field}
                                                    className={`form-control ${
                                                    contactFormErrors?.email ? "invalid" : ""
                                                    }`}
                                                />
                                                )}
                                            />
                                            {contactFormErrors.email ? (
                                                <small className="text-danger">
                                                {contactFormErrors.email?.message}
                                                </small>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-5">
                                            <Controller
                                                name="service_type"
                                                control={contactFormControl}
                                                render={({ field }) => (
                                                    <Select
                                                    {...field}
                                                    options={options}
                                                    classNamePrefix="form-select"
                                                    placeholder="Select a service"
                                                    isDisabled={false}  // Pass disabled if necessary
                                                    />
                                                )}
                                            />
                                            {contactFormErrors.service_type && (
                                                <small className="text-danger">
                                                    {contactFormErrors.service_type?.message}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">                                
                                    <div className="col-12">
                                        <div className="mb-5">
                                            <Controller
                                                name="ref"
                                                control={contactFormControl}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={optionsref}
                                                        classNamePrefix="form-select"
                                                        placeholder="From where did you hear about us"
                                                        isDisabled={false}  // Set if needed
                                                    />
                                                )}
                                            />
                                            {contactFormErrors.ref && (
                                                <small className="text-danger">
                                                    {contactFormErrors.ref?.message}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">                                
                                    <div className="col-12">
                                        <div className="mb-5">
                                            <Controller
                                                name="message"
                                                control={contactFormControl}
                                                render={({ field }) => (
                                                <textarea
                                                    {...field}
                                                    placeholder="Your Message"
                                                    className={`form-control ${
                                                    contactFormErrors?.message
                                                        ? "invalid text-danger"
                                                        : ""
                                                    }`}
                                                ></textarea>
                                                )}
                                            />
                                            {contactFormErrors.message ? (
                                                <small className="text-danger">
                                                {contactFormErrors.message?.message}
                                                </small>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {message != "" ? (
                                            <div className="mb-3">
                                                <small className="text-danger">{message}</small>
                                            </div>
                                            ) : null}
                                    </div>
                                </div>
                                <div className="row">                                
                                    <div className="col-12">
                                        <div className="mb-5 text-center">
                                            <button type="submit" className="btn btnenquiry">Send Enquiry</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default Commercial;