import React, { useEffect, useState } from "react";
import {lh1,lh2,lh3,lh4,lh5,lh6,lh7,lh8} from "../../assets/img";
import SampleVideo from "./../../images/sample.mp4";
function Sec1 () {

return (
    <>
        <section className="bg-white sec-benefit">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="benefit-list">
                            <h2>We collect, clean, and deliver your laundry and dry cleaning.</h2>
                            <ul className="benefit-list-sty1">
                                <li>
                                    <img src={lh4} alt=""/>
                                    <p>24h turnaround time</p>
                                </li>
                                <li>
                                    <img src={lh5} alt="" />
                                    <p>Free collection and delivery</p>
                                </li>
                                <li>
                                    <img src={lh6} alt="" />
                                    <p>Dedicated 24/7 support</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>   
                <div className="row">
                    <div className="col-md-6">
                        <div className="sec-img">
                            <img src={lh1} alt="" className="img-fluid"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="sec-desc">
                            <p className="subhdr">freedom from laundry</p>
                            <h3>A laundry service designed for you</h3>
                            <p className="sec-p">Never worry about staining your favourite shirt. We offer laundry, dry cleaning and ironing at a schedule that fits your lifestyle.</p>
                            <a href="#" data-bs-toggle="modal" data-bs-target="#videomodal">See how it works</a>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="videomodal" aria-hidden="true" data-bs-keyboard="false">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content modal-video">
                            <video width="100%" height="auto" autoPlay loop muted>
                                <source src={SampleVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <hr className="sec-divider "/>
                    </div>
                </div>
                <div className="row">                    
                    <div className="col-md-6">
                        <div className="sec-desc">
                            <p className="subhdr">24h turnaround time</p>
                            <h3>No need to plan in advance</h3>
                            <ul className="benefit-list-styl2">
                                <li>
                                    <img src={lh7} alt="" />
                                    <p>24h turnaround time</p>
                                </li>
                                <li>
                                    <img src={lh8} alt="" />
                                    <p>Free collection and delivery</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="sec-img">
                            <img src={lh2} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div> 
                <div className="row">
                    <div className="col-md-12">
                        <hr className="sec-divider "/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="sec-img">
                            <img src={lh3} alt="" className="img-fluid"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="sec-desc">
                            <p className="subhdr">24/7 customer support</p>
                            <h3>We're here for you</h3>
                            <p className="sec-p">Any change in delivery times or date, or if something goes wrong, we are here. Our online customer support team will assist you day and night.</p>
                            {/* <a href="#">Chat with us</a> */}
                        </div>
                    </div>
                </div>        
            </div>
        </section>
    </>
)
}
export default Sec1;