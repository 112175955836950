import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { setShopHomeCms, saveThemeJsonData } from "../../../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { ColorPicker } from "primereact/colorpicker";

function SectionFifteenThemeThreeEdit() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const data: any = useSelector<any>((state) => state.shopHomeJsonList);
  useEffect(() => {
    setEditData(data);
  }, [data]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  const handleChange = (e: any) => {
    const { value, name, type, checked } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        home: {
          ...prevJsonData.theme_3.home,
          section_15: {
            ...prevJsonData.theme_3.home.section_15,
            [name]: value,
            title:
              name === "section_15.title"
                ? value
                : prevJsonData.theme_3.home.section_15.title,
            btn_group: {
              ...prevJsonData.theme_3.home.section_15.btn_group,
              btn1: {
                ...prevJsonData.theme_3.home.section_15.btn_group.btn1,
                text:
                  name === "section_15btn1.text"
                    ? value
                    : prevJsonData.theme_3.home.section_15.btn_group.btn1.text,
                bg_color:
                  name === "section_15btn1.bg_color"
                    ? value
                    : prevJsonData.theme_3.home.section_15.btn_group.btn1
                        .bg_color,
                color:
                  name === "section_15btn1.color"
                    ? value
                    : prevJsonData.theme_3.home.section_15.btn_group.btn1.color,
              },
            },
          },
        },
      },
    }));
  };
  const saveJsonDataToFile = () => {
    dispatch(setShopHomeCms(editData));
    dispatch(saveThemeJsonData(editData));
  };

  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section14 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_15.title"
                      value={editData?.theme_3?.home?.section_15?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Sub Heading Top</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sub_heading_top"
                      value={
                        editData?.theme_3?.home?.section_15?.sub_heading_top
                      }
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={editData?.theme_3?.home?.section_15?.paragraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Button 1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_15btn1.text"
                      value={
                        editData?.theme_3?.home?.section_15?.btn_group?.btn1
                          ?.text
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Bg Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_15btn1.bg_color"
                        value={
                          editData?.theme_3?.home?.section_15?.btn_group?.btn1
                            ?.bg_color
                        }
                        style={{
                          background: `#${editData?.theme_3?.home?.section_15?.btn_group?.btn1?.bg_color}`,
                        }}
                        onChange={handleChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="section_15btn1.bg_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={
                          editData?.theme_3?.home?.section_15?.btn_group?.btn1
                            ?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_15btn1.bg_color"
                        value={
                          editData?.theme_3?.home?.section_15?.btn_group?.btn1
                            ?.color
                        }
                        style={{
                          background: `#${editData?.theme_3?.home?.section_15?.btn_group?.btn1?.color}`,
                        }}
                        onChange={handleChange}
                        maxLength={250}
                      />
                      <ColorPicker
                        name="section_15btn1.color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={
                          editData?.theme_3?.home?.section_15?.btn_group?.btn1
                            ?.color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionFifteenThemeThreeEdit;
