import React, { useEffect } from "react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";

function OurServices() {
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const navigate = useNavigate();

  useEffect(() => {}, [jsonData]);

  const navigateToPricing = () => {
    navigate("/pricing");
  };

  return (
    <>
      <section className="our-service-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center aos-init mb-5"
                data-aos="zoom-in-up"
              >
                <h2>{jsonData?.theme_5?.home?.section_our_services?.title}</h2>
              </div>
            </div>
          </div>
          <div className="our-service-inner aos-init" data-aos="zoom-in-up">
            <div className="our-service-wrapper">
            <div className="row">
              {jsonData?.theme_5?.home?.section_our_services?.service_section.services.map(
                (item: any) => {
                  return (
                    <>                     
                        <div className="col-md-4 d-flex col-sm-6">
                          <div className="service-widget flex-fill">
                            <div className="services-img">
                              <img
                                src={item.service_image}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <h4>{item.service_title}</h4>
                            <p>{item.service_description}</p>
                            <span>{item.service_text}</span>
                          </div>
                      </div>
                    </>
                  );
                }
              )}
              
              </div>
              <div className="view-price">
                <button
                  type="button"
                  className="btn btn-primary hover-btn"
                  style={{
                    background: `#${jsonData?.theme_5?.home?.section_our_services?.btn_group?.btn1?.bg_color}`,
                    color: `#${jsonData?.theme_5?.home?.section_our_services?.btn_group?.btn1?.color}`,
                  }}
                  onClick={navigateToPricing}
                >
                  {
                    jsonData?.theme_5?.home?.section_our_services?.btn_group
                      ?.btn1?.text
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurServices;
