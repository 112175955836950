import React, { useEffect, useState } from "react";
import axios from "axios";
// import './myFlat.css';
import OfferView from "../offerView";
import { Link } from "react-router-dom";
import {
  offertag,
  offerwashimg,
  offerprod1,
  offerprod2,
  offerprod3,
  offerprod4,
  offerprod5,
  offerprod6,
} from "../../assets/img";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "primereact/tooltip";

const base_url = process.env.REACT_APP_BACKEND_URL;

const offerListData = {
  result: {
    Response: {
      response_code: "1",
      response_message: "success",
    },
    data: {
      offer_details: [],
    },
  },
};

function SectionOfferSingle() {
  
  const notify = (message: string) => toast(message);
  // alert("called")
  const [changePopup, setChangePopup] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [descriptionValue, setDescriptionValueValue] = useState("");
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const [offerList, setOfferList] = useState<any>([]);
  //const params=useParams()
  const settingsData: any = useSelector<any>((state) => state?.settings);
  
  const handleClick = (code: any, title: any, description: any, e: any) => {
    //setMobileValue(e.target.value);
    setCodeValue(code);
    setTitleValue(title);
    setDescriptionValueValue(description);
    setChangePopup(!changePopup);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify("Coupon code copied successfully");
      })
      .catch((err) => {
        notify("Unable to copy coupon code");
      });
  };

  useEffect(() => {
    const headers = {
      Accept: "application/json",
    };

    axios
      .get(`${base_url}/api/list_offer_details`, {
        headers: headers,
      })
      .then((e) => {
        

        setOfferList(e.data);
      });
  }, []);
    
  return (
    <>
    {offerList?.Response?.response_code != "-1" ? (
        <>          
            <Swiper
                spaceBetween={15}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                640: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 1,
                },
                1024: {
                    slidesPerView: 1,
                },
                }}
                className="home-slider tg-offer-slider"
            >
                {offerList?.data?.offer_details?.map(
                (offer_detail: any, offerindex: any) => {
                    return (
                    <>
                        <SwiperSlide key={offerindex}>
                            <div className="tgoffer-grid" key={offer_detail.id}>
                                <div className="tgoffer-grid-lft">
                                    <span className="tgoffer-hrtxt">Special Offer</span>
                                    <div className="tgoffer-per">
                                        {offer_detail.percentage}%
                                    </div>
                                    <div className="tgoffer-desc">
                                        {offer_detail.title}
                                    </div>
                                </div>
                                <div className="tgoffer-grid-ryt">
                                    <span>USE CODE:</span>
                                    <Tooltip
                                    className="text-white"
                                    target=".tgoffer-code"
                                    />
                                    <h4
                                    className="tgoffer-code"
                                    data-pr-tooltip="Copy code"
                                    data-pr-position="top"
                                    onClick={() => {
                                        copyToClipboard(
                                        offer_detail.offer_code
                                        );
                                    }}
                                    >{offer_detail.offer_code}</h4>
                                </div>
                            </div>                        
                        </SwiperSlide>
                    </>
                    );
                }
                )}
            </Swiper>
        </>
      ) : null}
      <ToastContainer />
    </>
  );
}

export default SectionOfferSingle;
