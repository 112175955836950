import React, { useEffect } from "react";
import { useSelector } from "react-redux/es/exports";
import {data} from "../edit/data"

function SelfServices() {
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
// const jsonData = data;
  useEffect(() => {}, [jsonData]);

  return (
    <>
      <section className="how-work-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center aos-init mb-5"
                data-aos="zoom-in-up"
              >
                <h2>{jsonData?.theme_5?.home?.section_self_services?.title}</h2>
              </div>
            </div>
          </div>
          <div className="how-work-inner aos-init" data-aos="zoom-in-up">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <ul className="services-list">
                  {jsonData?.theme_5?.home?.section_self_services?.service_title.map(
                    (item: any) => {
                      return (
                        <>
                          <li>
                            <i className="fas fa-play"></i><p>{item?.service_list}</p>
                          </li>
                        </>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SelfServices;
