import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {slider1,slider2,slider3,slider4,slider5,slider6,slider7,slider8,upimg4} from "../../assets/img";

function Alterations () {

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">                    
                    <div className="slider-up">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        className="slider-up-slider"
                        >       
                            <SwiperSlide >                            
                                <img src={slider5} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider6} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider7} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>                            
                            <SwiperSlide >                            
                                <img src={slider8} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider1} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider2} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide> 
                            <SwiperSlide >                            
                                <img src={slider3} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider4} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                      </Swiper>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">   
                    <div className="up-content">
                        <h2 className="up-h2">CLOTHES ALTERATIONS</h2>
                        <p>A comprehensive range of repairs or alterations are availble at competitive prices.</p>
                        <p>Our services include resizing, restyling, tapering, minor repairs, zip replacements, relining, patching and leatherwork.</p>
                        <p>Contact us on 01792 644238 to discuss your requirements, or pop in with the garment.</p>
                        <h4>Price List</h4>
                        <p>Jeans alterations or repairs from £5.00</p>
                        <p>Skirts alterations or repairs from £8.00</p>
                        <p>Tops/Shirts/Blouses alternations or repairs from £8.00</p>
                        <p>Dresses alterations or repairs from £8.00</p>
                        <p>Wedding Dresses alterations or repairs from £10.00</p>
                        <p>Jackets alterations or repairs from £10.00</p>
                        <p>Coats alterations or repairs from £10.00</p>
                        <p>Leather alterations or repairs from £15.00</p>                        
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="up-img">
                        <img src={upimg4} alt="" />
                    </div>
                    <div className="up-card-bg">
                        <p>Book a Pickup - Call 01792 644238 or<br/> text 07999 999294</p>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default Alterations;