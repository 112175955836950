import React, { useEffect, useState } from "react";
function SLoader() {
    return (
        <>
            <div className="ssc-card-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="ssc ssc-card">
                                <div className="ssc-wrapper">
                                    <div className="flex align-center justify-center">
                                        <div className="mr">
                                            <div className="ssc-base ssc-circle"></div>
                                        </div>
                                        <div className="mr w-70 flex align-center justify-between">
                                            <div className="ssc-base ssc-line w-20 mr"></div>
                                            <div className="ssc-base ssc-line w-20 mr"></div>
                                            <div className="ssc-base ssc-line w-20 mr"></div>
                                            <div className="ssc-base ssc-line w-20 mr"></div>
                                            <div className="ssc-base ssc-line w-20 mr"></div>
                                        </div>
                                        <div className="mr w-20 flex align-center justify-between">
                                            <div className="ssc-base ssc-head-line w-50 mr" style={{height:"30px"}}></div>
                                            <div className="ssc-base ssc-head-line w-50 mr" style={{height:"30px"}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default SLoader;