import React, { useEffect, useState } from "react";

import { ttimg23,ttimg2,ttimg3,ttimg4,ttimg5,ttimg24,ttimg7,ttimg8,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTCollectionDelivery() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg24} alt="" />
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg23} alt="" />
                               </div>
                               <h4>Free Collection & Delivery</h4>
                                <p>The process is as simple as calling us on <b>07792422169</b> and arrange a convenient time for us to collect and when it will be suitable for us to deliver. Phone lines are open 24 hours per day 7 days per week (apart from certain National Holidays).</p>
                                <p>We’ll collect your laundry and cleaning from your doorstep at a time convenient for you. We’re also happy to pick up from your place of work. Your items will be returned to your door, fresh and clean, within 48 hours*. What’s more, collection & delivery is absolutely FREE!</p>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTCollectionDelivery;