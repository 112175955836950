import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHomeJsonList,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";
import CreatableSelect from "react-select/creatable";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ImageCropper from "../../imageCropper/imageCropper";

const SectionSixBannerThemeFour = () => {
    const dispatch = useDispatch<any>();
    const [editData, setEditData] = useState<any>();
    const jsonData: any = useSelector<any>((state) => state.homeJsonList);
    const [activeTab, setActiveTab] = useState(0);
    const [activeTabData, setActiveTabData] = useState(0);

    const [cropImageHeight, setCropImageHeight] = useState("");
    const [cropImageWidth, setCropImageWidth] = useState("");
    const [selectedCropImage, setSelectedCropImage] = useState("");
    const [showCropModal, setShowCropModal] = useState<boolean>(false);
    const [bannerImageChange, setBannerImageChange] = useState<boolean>(false);
    const [imagedata, setImagedata] = useState<any>("" || undefined);
    const images: Array<string> = [
      "assets/home/trustimg3.png",
      "assets/home/trustimg3.png",
      "assets/home/trustimg3.png",
      "assets/home/trustimg3.png",
      "assets/home/trustimg3.png",
      "assets/home/trustimg3.png",
      "assets/home/trustimg3.png",
    ];

  
    const saveJsonDataToFile = () => {
      dispatch(fetchHomeJsonList(editData));
      dispatch(saveThemeJsonData(editData));
    };
  
    const updateImageSectionOne = async (e: any) => {
      const image = e.target.files[0];
      setCropImageHeight("150");
      setCropImageWidth("150");
      setSelectedCropImage(image);
      setShowCropModal(true);
    };
    const updateImageSectionBanner = async (e: any) => {
      const image = e.target.files[0];
      // setCropImageHeight("150");
      // setCropImageWidth("150");
      // setSelectedCropImage(image);
      // setShowCropModal(true);
      // setBannerImageChange(true);
      if (image instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (res: any) => {
          handleCroppedImage(res.target.result);
        };
      }
    };
  
    const handleCroppedImage = async (image: any) => {
      setShowCropModal(false);
      setImagedata(image);
      const imageLink = await dispatch(fetchCroppedImage(image));
      // if (imageLink != false && bannerImageChange == false) {
      //   handleImageChange(imageLink, activeTab);
      // }
      if (imageLink != false) {
        handleBannerImageChange(imageLink);
      }
    };
  
    useEffect(() => {
      setEditData(jsonData);
    }, [jsonData]);
    useEffect(() => {
      if (editData != undefined) {
        setEditData(editData);
      }
    }, [editData]);
  
    const addCollection = () => {
      let updatedCollection: any = [
        ...editData.theme_4.home.section_6.card_section.collection,
      ];
  
      let newCard = {
        collection_list: "",
        id: activeTab + 2,
    };
      updatedCollection.splice(activeTab + 1, 0, newCard);
      setActiveTab(activeTab + 1);
      setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_4: {
          ...prevJsonData.theme_4,
          home: {
            ...prevJsonData.theme_4.home,
            section_6: {
              ...prevJsonData.theme_4.home.section_6,
              card_section: {
                // ...prevJsonData.theme_4.home.section_6?.card_section,

                collection: updatedCollection,
              },
            },
          },
        },
      }));
    };
    // const removeCollection = (index: any) => {
    //   let updatedCollection: any = [
    //     ...editData.home.section_6.card_section.collection,
    //   ];
    //   updatedCollection.splice(index, 1);
    //   setActiveTab(activeTab - 1);

    //   setEditData((prevJsonData: any) => ({
    //     ...prevJsonData,
    //     theme_4: {
    //       ...prevJsonData.theme_4,
    //       home: {
    //         ...prevJsonData.theme_4.home,
    //         section_6: {
    //           ...prevJsonData.theme_4.home.section_6,
    //           card_section: {
    //             // ...prevJsonData.theme_4.home.section_6?.card_section,

    //             collection: updatedCollection,
    //           },
    //         },
    //       },
    //     },
    //   }));
    // };



    const addCollectiondata = () => {
        let updatedCollectiondata: any = [
          ...editData.theme_4.home.section_6.card_section_two.collection,
        ];
    
        let newCards = {
          collection_list: "",
          id: activeTabData + 2,
      };
        updatedCollectiondata.splice(activeTabData + 1, 0, newCards);
        setActiveTabData(activeTabData + 1);
        setEditData((prevJsonData: any) => ({
          ...prevJsonData,
          theme_4: {
            ...prevJsonData.theme_4,
            home: {
              ...prevJsonData.theme_4.home,
              section_6: {
                ...prevJsonData.theme_4.home.section_6,
                card_section_two: {
                  ...prevJsonData.theme_4.home.section_6?.card_section_two,

                  collection: updatedCollectiondata,
                },
              },
            },
          },
        }));
      };
      const removeCollectiondata = (index: any, card_section_name : any) => {
        let updatedCollectiondata: any = [
          ...editData.theme_4.home.section_6[card_section_name].collection,
        ];
        updatedCollectiondata.splice(index, 1);
        if(card_section_name == "card_section"){
          setActiveTab(activeTab - 1);
        }else{
          setActiveTabData(activeTabData - 1);
        }

        setEditData((prevJsonData: any) => ({
          ...prevJsonData,
          theme_4: {
            ...prevJsonData.theme_4,
            home: {
              ...prevJsonData.theme_4.home,
              section_6: {
                ...prevJsonData.theme_4.home.section_6,
                [card_section_name]: {
                  ...prevJsonData.theme_4.home.section_6[card_section_name],

                  collection: updatedCollectiondata,
                },
              },
            },
          },
        }));
      };
  
     
  
    const handleChange = (e: any, index?: any, section?:any) => {
      const { value, name, type, checked } = e.target;
      let updatedCollection: any = [
        ...editData.theme_4.home.section_6.card_section.collection,
      ];
      let updatedCollectiondata: any = [
        ...editData.theme_4.home.section_6.card_section_two.collection,
      ];
      if (index != undefined) {
        if(section == "card_section"){
          updatedCollection = [
            ...editData.theme_4.home.section_6[section].collection,
          ];
          updatedCollection[index] = {
            ...updatedCollection[index],
            [name]: value,
          };
        }
        if(section == "card_section_two"){
          updatedCollectiondata = [
            ...editData.theme_4.home.section_6[section].collection,
          ];
          updatedCollectiondata[index] = {
            ...updatedCollectiondata[index],
            [name]: value,
          };
        }
        
        
      }
      setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_4: {
          ...prevJsonData.theme_4,
          home: {
            ...prevJsonData.theme_4.home,
            section_6: {
              ...prevJsonData.theme_4.home.section_6,
              [name]: value,
              card_section: {
                collection: updatedCollection,
              },
              card_section_two: {
                collection: updatedCollectiondata,
              },
            },
          },
        },
      }));
    };
  
    const handleImageChange = (image: string, index: any) => {
      const updatedCollection = [
        ...editData.theme_4.home.section_6.card_section.collection,
      ];
      updatedCollection[index] = {
        ...updatedCollection[index],
        icon: image,
      };
  
      setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_4: {
          ...prevJsonData.theme_4,
          home: {
            ...prevJsonData.theme_4.home,
            section_6: {
              ...prevJsonData.theme_4.home.section_6,
              card_section: {
                ...prevJsonData.theme_4.home.section_6.card_section,
                collection: updatedCollection,
              },
              
            },
          },
        },
      }));
    };
  
    const handleBannerImageChange = (image: string) => {
      //Update the banner value in the JSON data
      setEditData((prevJsonData: any) => ({
        ...prevJsonData,
        theme_4: {
          ...prevJsonData.theme_4,
          home: {
            ...prevJsonData.theme_4.home,
            section_6: {
              ...prevJsonData.theme_4.home.section_6,
              first_card_image: image,
            },
          },
        },
      }));
    };
  
    return (
      <>
        {/* <Draggable> */}
        <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Section six Theme Four Edit</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-content modal-body">
              <div className="edit-section">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">
                        Title<small>(max 250 char)</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={editData?.theme_4?.home?.section_6?.title}
                        onChange={handleChange}
                        maxLength={250}
                      />
                    </div>
                    </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        name="paragraph"
                        value={editData?.theme_4?.home?.section_6?.paragraph}
                        onChange={handleChange}
                        maxLength={1200}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_4?.home?.section_6?.h2color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_6?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_4?.home?.section_6?.h3color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_6?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_4?.home?.section_6?.paracolor
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_4?.home?.section_6?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-md-12">
                          <label className="form-label">
                            First card background
                          </label>
                          <div className="img-bg active">
                            <img
                              src={
                                editData?.theme_4?.home?.section_6
                                  ?.first_card_image
                              }
                              alt="Hero Image"
                            />
                          </div>
                          <div className="img-options">
                            <div className="row">
                              <div className="col-md-12">
                                <Swiper
                                  spaceBetween={15}
                                  navigation={true}
                                  modules={[Navigation]}
                                  breakpoints={{
                                    640: {
                                      slidesPerView: 1,
                                    },
                                    768: {
                                      slidesPerView: 4,
                                    },
                                    1024: {
                                      slidesPerView: 6,
                                    },
                                  }}
                                  className="homejson-slider"
                                >
                                  {images.map((images: string) => {
                                    return (
                                      <>
                                        <SwiperSlide>
                                          <div
                                            className="img-option"
                                            onClick={() =>
                                              handleBannerImageChange(images)
                                            }
                                          >
                                            <img
                                              src={images}
                                              alt="Hero Image"
                                              className="img-fluid"
                                            />
                                          </div>
                                        </SwiperSlide>
                                      </>
                                    );
                                  })}
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Upload Image</label>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        className="form-control"
                        name="image"
                        onChange={updateImageSectionBanner}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Sub Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="sub_title"
                        value={editData?.theme_4?.home?.section_6?.sub_title}
                        onChange={handleChange}
                        maxLength={1200}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-section">
                <ul className="nav nav-tabs">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    className="homejson-slider"
                  >
                    {editData?.theme_4?.home?.section_6?.card_section?.collection.map(
                      (card: any, index: any) => (
                        <SwiperSlide key={index}>
                          <li key={index} className="nav-item nav-option">
                            <a
                              className={`nav-link ${
                                activeTab === index ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(index)}
                            >
                              Card Section {index + 1}
                            </a>
                            <a
                              className="tab-close"
                              onClick={() => {
                                removeCollectiondata(index, "card_section");
                              }}
                            >
                              <i className="fas fa-times" aria-hidden="true"></i>
                            </a>
                          </li>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </ul>
                <div className="tab-content">
                  {editData?.theme_4?.home?.section_6?.card_section?.collection.map(
                    (card: any, index: any) => {
            
                       return  <div
                          key={index}
                          className={`tab-pane fade ${
                            activeTab === index ? "show active" : ""
                          }`}
                        >
                      
                          <div className="mb-3">
                            <label className="form-label">
                              Title<small>(max 250 char)</small>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="collection_list"
                              value={card.collection_list}
                              onChange={(e) => handleChange(e, index, "card_section")}
                              maxLength={250}
                            />
                          </div>
                         
  
                        </div>
                    
                    }
                  )}
                </div>
              </div>
              <div className="add-section">
                <div className="row">
                  <div
                    onClick={() => {
                      addCollection();
                    }}
                    className="col text-center"
                  >
                    Add Card<i className="fas fa-plus-square ms-2"></i>
                  </div>
                </div>
              </div>



              <div className="edit-section">
              <div className="mb-3">
                      <label className="form-label">Sub Title Two</label>
                      <input
                        type="text"
                        className="form-control"
                        name="sub_title_two"
                        value={editData?.theme_4?.home?.section_6?.sub_title_two}
                        onChange={handleChange}
                        maxLength={1200}
                      />
                    </div>
                <ul className="nav nav-tabs">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    className="homejson-slider"
                  >
                    {editData?.theme_4?.home?.section_6?.card_section_two?.collection.map(
                      (card: any, index: any) => (
                        <SwiperSlide key={index}>
                          <li key={index} className="nav-item nav-option">
                            <a
                              className={`nav-link ${
                                activeTabData === index ? "active" : ""
                              }`}
                              onClick={() => setActiveTabData(index)}
                            >
                              Card Section {index + 1}
                            </a>
                            <a
                              className="tab-close"
                              onClick={() => {
                                removeCollectiondata(index,"card_section_two");
                              }}
                            >
                              <i className="fas fa-times" aria-hidden="true"></i>
                            </a>
                          </li>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </ul>
                <div className="tab-content">
                  {editData?.theme_4?.home?.section_6?.card_section_two?.collection.map(
                    (card: any, index: any) => (
                      <div
                        key={index}
                        className={`tab-pane fade ${
                            activeTabData === index ? "show active" : ""
                        }`}
                      >
                        
                        <div className="mb-3">
                          <label className="form-label">
                            List<small>(max 250 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="collection_list"
                            value={card.collection_list}
                            onChange={(e) => handleChange(e, index, "card_section_two")}
                            maxLength={250}
                          />
                        </div>
                       
                      
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="add-section">
                <div className="row">
                  <div
                    onClick={() => {
                      addCollectiondata();
                    }}
                    className="col text-center"
                  >
                    Add Card<i className="fas fa-plus-square ms-2"></i>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  className="btn primary-btn"
                  onClick={() => {
                    saveJsonDataToFile();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </Draggable> */}
        {showCropModal ? (
          <>
            <div
              className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
              id="crop-modal"
              tabIndex={-1}
              aria-labelledby="crop-modal"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <ImageCropper
                src={selectedCropImage}
                height={cropImageHeight}
                width={cropImageWidth}
                handleCrop={handleCroppedImage}
                close={() => {
                  setShowCropModal(false);
                }}
              />
            </div>
          </>
        ) : null}
      </>
    );
};

export default SectionSixBannerThemeFour;
