import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { fetchCroppedImage, fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { data } from "../data";
import { ColorPicker } from "primereact/colorpicker";
const SectionSevenBannerThemeOne = () => {

    const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const [activeTab, setActiveTab] = useState(0);

  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  // const jsonData: any = data

  const icons = [
    "/assets/img/laundry.png",
    "/assets/img/alterations.png",
    "/assets/img/dry cleaning.png",
    "/assets/img/ironing.png",
    "/assets/img/repairs.png",
  ]


    const handleChange = (e: any,index?:any) => {
        const { value, name, type, checked } = e.target;
        let updatedCollection: any = [
          ...editData?.theme_1?.home.section_7.card_section.collection,
        ];
        if (index != undefined) {
          updatedCollection = [...editData?.theme_1?.home.section_7.card_section.collection];
          updatedCollection[index] = {
            ...updatedCollection[index],
            [name]: value,
          };
        }
        setEditData((prevData:any) => ({
          ...prevData,
          theme_1: {
            ...prevData.theme_1,
          home: {
            ...prevData.theme_1.home,
            section_7: {
              ...prevData.theme_1.home.section_7,
              [name]: value,
              card_section: {
                collection: updatedCollection,
              },
            },
          },
        }
        }));
      };
    
      const saveSection4DataToFile = () => {
        dispatch(fetchHomeJsonList(editData));
        dispatch(saveThemeJsonData(editData));
      };

      useEffect(() => {
        setEditData(jsonData);
      }, [jsonData]);
      useEffect(() => {
        if (editData != undefined) {
          setEditData(editData);
        }
      }, [editData]);

      
  const addCollection = () => {
    let updatedCollection: any = [
      ...editData?.theme_1?.home.section_7.card_section.collection,
    ];

    let newCard = {
      icon: "/assets/img/laundry.png",
      card_title: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
      home: {
        ...prevJsonData.theme_1.home,
        section_7: {
          ...prevJsonData.theme_1.home.section_7,
          card_section: {
            collection: updatedCollection,
          },
        },
      },
    }
    }));
  };

  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData?.theme_1?.home.section_7.card_section.collection,
    ];
    updatedCollection.splice(index, 1);
    setActiveTab(activeTab - 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
      home: {
        ...prevJsonData.theme_1.home,
        section_7: {
          ...prevJsonData.theme_1.home.section_7,
          card_section: {
            collection: updatedCollection,
          },
        },
      },
    }
    }));
  };

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [
      ...editData?.theme_1?.home.section_7.card_section.collection,
    ];
    updatedCollection[index] = {
      ...updatedCollection[index],
      icon: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
      home: {
        ...prevJsonData.theme_1.home,
        section_7: {
          ...prevJsonData.theme_1.home.section_7,
          card_section: {
            ...prevJsonData.theme_1.home.section_7.card_section,
            collection: updatedCollection,
          },
        },
      },
    }
    }));
  };

  const handleCroppedImage = async (image: any) => {
    const imageLink = await dispatch(fetchCroppedImage(image));
    if(imageLink != false){
      handleImageChange(imageLink,activeTab)
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150")
    // setCropImageWidth("150")
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

    return(
        <>
        <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Seven Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_1?.home?.section_7?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_1?.home?.section_9?.h2color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_1?.home?.section_9?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_1?.home?.section_9?.h3color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_1?.home?.section_9?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
            <label className="form-label">Category</label>
                <ul className="nav nav-tabs">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    className="homejson-slider"
                  >
                    {editData?.theme_1?.home?.section_7?.card_section?.collection.map(
                      (card: any, index: any) => (
                        <SwiperSlide key={index}>
                          <li key={index} className="nav-item nav-option">
                            <a
                              className={`nav-link ${
                                activeTab === index ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(index)}
                            >
                              Card Section {index + 1}
                            </a>
                            <a className="tab-close" onClick={() => {removeCollection(index)}}>
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </ul>
                <div className="tab-content">
                  {editData?.theme_1?.home?.section_7?.card_section?.collection.map(
                    (card: any, index: any) => (
                      <div
                        key={index}
                        className={`tab-pane fade ${
                          activeTab === index ? "show active" : ""
                        }`}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="img-bg active">
                              <img
                                src={card.icon}
                                alt={`Card Icon ${index + 1}`}
                              />
                            </div>
                            <div className="img-options w-100">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 4,
                                  },
                                  1024: {
                                    slidesPerView: 6,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {icons.map((icons, iconIndex) => (
                                  <SwiperSlide key={iconIndex}>
                                    <div
                                      className="img-option"
                                      onClick={() =>
                                        handleImageChange(icons, index)
                                      }
                                    >
                                      <img
                                        src={icons}
                                        alt="Hero Image"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Title<small>(max 250 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="card_title"
                            value={card.card_title}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={250}
                          />
                        </div>
                        <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="add-section">
                <div className="row">
                  <div
                    onClick={() => {
                      addCollection();
                    }}
                    className="col text-center"
                  >
                    Add Card<i className="fas fa-plus-square ms-2"></i>
                  </div>
                </div>
              </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveSection4DataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default SectionSevenBannerThemeOne;