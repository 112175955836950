import React, { useEffect, useState } from "react";
import {lh9,lh10,lh11,lh12} from "../../assets/img";
import { Link, useNavigate, NavLink } from "react-router-dom";
function Sec3 () {

return (
    <>
        <section className="bg-cyan sec-lhabt">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="sec-img">
                            <img src={lh9} alt="" className="img-fluid"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="sec-abtflex">
                            <h3>Reinventing the future of laundry and dry cleaning.</h3>
                            <ul>
                                <li>
                                    <img src={lh10} alt="" />
                                    <strong>Zero-emission delivery vehicles</strong>
                                </li>
                                <li>
                                    <img src={lh11} alt="" />
                                    <strong>Efficient water use</strong>
                                </li>
                                <li>
                                    <img src={lh12} alt="" />
                                    <strong>Trustworthy local cleaners</strong>
                                </li>
                            </ul>
                            {/* <div>
                                <Link className="btn btn-white" to="/aboutUs">About us</Link>
                            </div> */}
                        </div>
                    </div>
                </div> 
            </div>
        </section>
    </>
)
}
export default Sec3;