import React, { useEffect, useState } from "react";
import axios from "axios";
// import AreaCoverHeader from '../../components/areaCoverHeader';
// // import CurrentlyCovered from './../../components/currentlyCovered';
//import CurrentlyCovered from '../../components/currentlyCovered';
import "./../areaCovered/Covered.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";

const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = process.env.REACT_APP_BASE_URL;

const areaCoveredData = {
  data: {
    Response: {
      response_code: "1",
      response_message: "success",
    },
    data: {
      areas_covered: {
        R: ["Rushmoor"],
      },
    },
  },
};
const colcount = 0;

function AreaCovered() {
  const navigate = useNavigate();
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const [areaCovered, setAreaCovered] = useState<any | null>(areaCoveredData);

  var metaTitle = "";
  var metaDescription = "";

  useEffect(() => {

    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const queryParamsShopId = url.searchParams.get("shop_id");
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    if (queryParamsShopId) {
      shop_name = queryParamsShopId;
      currentShopId = queryParamsShopId;
    };

    const headers = {
      Accept: "application/json",
    };

    axios
      .get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e) => {
        setAreaCovered({ data: e.data });
      });

    metaTitle = settingsData?.WebsiteSettings?.site_title_en + " Service Area | Laundry and Dry Cleaning Services in London";
    metaDescription = "Discover the service area covered by "+ settingsData?.WebsiteSettings?.site_title_en +" for laundry and dry cleaning services in London. Check if we service your area and book your services online today!";
    document.title = metaTitle;
    document.getElementById("title")?.setAttribute("content", metaTitle);
    document.getElementById("description")?.setAttribute("content", metaDescription);
  }, []);

  const popAreadCovered = (areaList: any, arealistindex: any) => {
    let keys = Object.keys(areaList);
    let area = keys[0];
    // let city =areaList.area;
    // city=city.toString().toLowerCase();
    //for (let j = arealistindex;j < arealistindex+3 ; j++) {
    var test = Object.keys(areaList).map((location, locationindex) => {
      //alert(areaList[location]);

      let cityArr = areaList[location].toString().split(",");

      return (
        <div className="city-list" id={"group_" + location}>
          <h3>{location}</h3>
          <ul>
            {
              cityArr.map((city: any) => (
                <li>
                  {settingsData?.WebsiteSettings?.site_title_en.includes("My Dhobi") ? ( 
                    <>
                    <span>
                        {city}
                      </span>
                    </>
                    ) : (
                        <>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/dry-cleaners/" + city);
                            }}
                          >
                            {city}
                          </a>
                        </>
                    )}                  
                </li>
              )) /*+city.toLowerCase()*/
            }
          </ul>
        </div>
      );
    });

    return test;
  };

  const popAreadCoveredrows = () => {
    let keys = Object.keys(areaCovered.data.data.areas_covered);
    let maxlength = keys.length;
    let rows = Math.ceil(maxlength / 3);

    for (let i = 0; i < rows; i++) {
      let colinit = i * 3;

      if (maxlength != 0) {
        return (
          <div className="alphabet-cont">
            {popAreadCovered(areaCovered.data.data.areas_covered, colinit)}
          </div>
        );
      }
    }
  };

  return (
    <div id="area_covered" >
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Areas covered</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span> Areas Covered</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="abt-section">
        <div className="container">
          <div className="row">
            {/* <div className="section-title text-center">
              <h1>Areas we currently covered</h1>
            </div> */}

            {settingsData?.WebsiteSettings?.site_title_en.includes("Sasian") ? (
              <div className="col-md-12">
                <ul className="areas-covered">
                  <li>
                    <b>Hillingdon</b>
                  </li>
                  <li>
                    <b>Uxbridge</b>
                  </li>
                  <li>
                    <b>Denham</b>
                  </li>
                  <li>
                    <b>Gerrards Cross</b>
                  </li>
                  <li>
                    <b>Beaconsfield</b>
                  </li>
                  <li>
                    <b>Jordans</b>
                  </li>
                  <li>
                    <b>Stoke Poges</b>
                  </li>
                  <li>
                    <b>Chalfont St Peter</b>
                  </li>
                  <li>
                    <b>Chalfont St Giles</b>
                  </li>
                  <li>
                    <b>Little Chalfont</b>
                  </li>
                  <li>
                    <b>Chalfont Heights</b>
                  </li>
                  <li>
                    <b>Chalfont Common</b>
                  </li>
                  <li>
                    <b>Farnham Common</b>
                  </li>
                  <li>
                    <b>Seer Green</b>
                  </li>
                  <li>
                    <b>Harefield</b>
                  </li>
                  <li>
                    <b>Ruislip</b>
                  </li>
                  <li>
                    <b>Ickenham</b>
                  </li>
                  <li>
                    <b>Marlow</b>
                  </li>
                  <li>
                    <b>Wooburn Green</b>
                  </li>
                  <li>
                    <b>Chinnor</b>
                  </li>
                  <li>
                    <b>Stokenchurch</b>
                  </li>
                </ul>
                <div className="areas-covered-scontent">
                  <p>
                    <b className="mb-2">Collection and Delivery times:</b>
                    <br />
                    We provide a free collection and delivery service with our
                    minimum order value of £33 (discounted to £30.00for priority
                    customers).
                  </p>
                  <p>
                    <b className="mb-2">Opening Times:</b>
                    <br />
                    Monday to Thursday - 8:00am - 5:00pm
                    <br />
                    Friday - 8:00am - 12:00pm
                    <br />
                    Saturday: <strong>Closed</strong>
                    <br />
                    Sunday: <strong>Closed</strong>
                  </p>
                  <p>
                    {" "}
                    <strong className="mb-2">Delivery:</strong>
                    <br />
                    Mon - Fri 9:00am - 12:00pm
                    <br />
                    Mon - Thurs 5:30pm - 9.30pm
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className="col-md-12">
                  <div className="about-us">
                    <div className="alphabet-header">
                      <a href="#group_A">A</a> <a href="#group_B">B</a>{" "}
                      <a href="#group_C">C</a> <a href="#group_D">D</a>{" "}
                      <a href="#group_E">E</a> <a href="#group_F">F</a>{" "}
                      <a href="#group_G">G</a> <a href="#group_H">H</a>{" "}
                      <a href="#group_I">I</a> <a href="#group_J">J</a>{" "}
                      <a href="#group_K">K</a> <a href="#group_L">L</a>
                      <a href="#group_M">M</a> <a href="#group_N">N</a>
                      <a href="#group_O">O</a> <a href="#group_P">P</a>{" "}
                      <a href="#group_Q">Q</a> <a href="#group_R">R</a>{" "}
                      <a href="#group_S">S</a> <a href="#group_T">T</a>{" "}
                      <a href="#group_U">U</a> <a href="#group_V">V</a>{" "}
                      <a href="#group_W">W</a> <a href="#group_X">X</a>{" "}
                      <a href="#group_Y">Y</a> <a href="#group_Z">Z</a>
                    </div>
                    {popAreadCoveredrows()}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AreaCovered;
