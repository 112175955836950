import React, { useEffect } from "react";
import { cleancloths, laundrybag1, wing1, wing2 } from "../../assets/img";
import { useSelector } from "react-redux";

const SectionSixThemeOne = () => {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);

  useEffect(() => {
  }, [jsonData]);
  // HTML
  return (
    <>
      <section className="subscriptioninfo-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title" style={{
                      color: `#${jsonData?.theme_1?.home?.section_6?.h2color}`
                    }}>
                {jsonData?.theme_1?.home?.section_6?.heading}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="subscriptioninfo-box subscriptioninfo-bg1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="subscriptioninfo-content">
                      <img src={laundrybag1} alt="" />
                      <h4 style={{
                      color: `#${jsonData?.theme_1?.home?.section_6?.h3color}`
                    }}>
                        {jsonData?.theme_1?.home?.section_6?.card_one_title}
                      </h4>
                      <p style={{
                      color: `#${jsonData?.theme_1?.home?.section_6?.paracolor}`
                    }}>
                        {jsonData?.theme_1?.home?.section_6?.card_one_paragraph}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="subscriptioninfo-img">
                      <img
                        src={jsonData?.theme_1?.home?.section_6?.card_one_image}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="subscriptioninfo-box subscriptioninfo-bg2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="subscriptioninfo-content">
                      <img src={laundrybag1} alt="" />
                      <h4 style={{
                      color: `#${jsonData?.theme_1?.home?.section_6?.h3color}`
                    }}>{jsonData?.theme_1?.home?.section_6?.card_two_title}</h4>
                      <p style={{
                      color: `#${jsonData?.theme_1?.home?.section_6?.paracolor}`
                    }}>{jsonData?.theme_1?.home?.section_6?.card_two_paragraph}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="subscriptioninfo-img">
                      <img
                        src={jsonData?.theme_1?.home?.section_6?.card_two_image}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionSixThemeOne;
