import React, { useEffect, useState } from "react";

import { ttimg40,ttimg2,ttimg3,ttimg4,ttimg5,ttimg6,ttimg41,ttimg42,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTLaoundreet() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg40} alt="" />
                               </div>
                               <h4>We have 8kg and 20kg laundry washing machines</h4>
                                <p>We have 8kg , 15kg and 20kg laundry washing machines, and 15kg and 20kg gas powered laundry dryers for our customers to use.</p>
                                <ul>
                                    <li>Small wash - £4.00</li>
                                    <li>Large wash - £8.00</li>
                                    <li>Dryer 5 mins - £0.50p</li>
                                    <li>Soap powder - £1.00p at counter</li>
                                    <li>Conditioner - £0.50p at counter</li>
                                </ul>
                                <h4>Service Wash</h4>
                                <p>Leave your laundry with us and it will be washed, dried and neatly folded, or simply have your items pressed and ready for collection. Normally a 4 hour service. We offer an express 2.5 hour laundry service for an extra charge.</p>
                                <p>We will wash and dry clothing only – ironing service is available at extra cost</p>
                                <ul>
                                    <li>All cleaning needs to be separated into darks and lights otherwise we will not be     responsible for any colour run as well as colour loss or shrinkage</li>
                                    <li>Please make sure all items are washable and not dry clean as we will not be responsible for dry clean items put in for washing</li>
                                    <li>We will not empty out pockets – please make sure there are no pens, money, loose screws, tissues etc. in the pockets which may cause damage to other garments</li>
                                    <li>We will not be responsible for any loose buttons or other adornments on garments</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg41} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg42} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTLaoundreet;