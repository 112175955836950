import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome-free-6.5.2-web/css/all.min.css";
import "./assets/css/style.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/@iconscout/unicons/css/line.css";
import reportWebVitals from "./reportWebVitals";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import Whatsapp from "./components/whatsapp";
import Loader from "./components/loader";
import Cookie from "./components/cookie/cookie";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Function to dynamically load the Google Analytics script
const loadGAScript = () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-VBH47P9RCF`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) { window.dataLayer.push(args); }
    gtag('js', new Date());
    gtag('config', "G-VBH47P9RCF");
  };
};
const loadGAScriptBL = () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=GTM-PD5VHQN`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) { window.dataLayer.push(args); }
    gtag('js', new Date());
    gtag('config', "GTM-PD5VHQN");
  };
};

// Check the current URL against the allowed URL from environment variables
if (window.location.href === 'https://laundryup.co.uk') {
    loadGAScript();
}
else if (window.location.href === 'https://bestatlaundry.com') {
  loadGAScriptBL();
}


root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Loader />
      <App />
      <Cookie />
      <Whatsapp />
    </BrowserRouter>
  </Provider>
);
reportWebVitals();
