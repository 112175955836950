import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { ttw43 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SectionEight from "./sectionEight";
import SectionNine from "./sectionNine";
import SectionFaq from "./sectionFaq";
import SectionEleven from "./sectionEleven";

const images = [
  'assets/shoe-cleaning/close-up-man-cleaning-shoes-while-getting-ready-wedding-ceremony.jpg',
  'assets/shoe-cleaning/crop-woman-holding-boots.jpg',
  'assets/shoe-cleaning/pexels-mallonymedia-4914661.jpg',
  'assets/shoe-cleaning/wet-sports-shoes-reflect-active-lifestyle-nature-generated-by-ai.jpg',
];

function ShoeCleaning () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  const settingsslider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
    <div className="t12banner t12bannercolor" id="secidsr">
        <div className="container">
            <div className="row h-100">
                <div className="col-md-12 col-lg-6">
                    <div className="banner-grid">
                        <div className="banner-grid-inner">
                            <h1>Shoe Cleaning</h1>
                            <ul className="t12highpoint">
                                <li>Free Pickup & Delivery</li>
                                <li>High Quality Services</li>
                                <li>Lowest Price Guarantee</li>
                            </ul>
                            <ul className="t12applinks">
                                <li>
                                    <NavLink className="btn btnbanner" to="/">Book Now</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-6 d-flex h-100 align-items-center">
                  <div className="hcslider">
                    <Slider {...settingsslider}>
                      {images.map((image, index) => (
                        <div key={index}>
                          <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: 'auto' }} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
            </div>
        </div>
  </div>
  <section className="secbgwhite">
      <div className="container">
          <div className="row">              
              <div className="col-md-12">
                  <div className="secinfogrid3">
                      <div className="t12-h2hdr">About Our Service</div>
                      <p>Give your shoes a new lease on life with our expert shoe cleaning service. We specialise in restoring your footwear to its original splendour. We offer high quality shoe cleaning service in London, the UK for all men’s and women’s shoes at best price.</p>
                      <div className="secinfoclcontent">
                          <h2>Professional Shoe Cleaning Service</h2>
                          <ul>
                            <li><strong>Deep Shoe Cleaning:</strong> Remove dirt, grime, and stains from all types of shoes, including sneakers, boots, and leather shoes.</li>
                            <li><strong>Odour Removal:</strong> Eliminate unpleasant odours and leave your shoes smelling fresh.</li>
                            <li><strong>Stain Removal:</strong> Effectively remove stubborn stains without damaging the material from your shoes and boots.</li>
                            <li><strong>Color Restoration:</strong> Restore the original colour of your faded shoes.</li>
                          </ul>                          
                      </div>
                      <div className="secinfoclcontent">
                          <h3>Gentle Care</h3>
                          <p>We use eco-friendly shoe cleaning products and gentle techniques to preserve the quality of your footwear.</p>
                      </div>
                      <div className="secinfoclcontent">
                        <h3>Convenient and High Quality Service</h3>
                        <p>Schedule your shoe cleaning service online with Hello Cobbler and enjoy our free pick-up and delivery service in London at your doorstep without any hassle. We offer 72h turnaround times without compromising on quality.</p>
                      </div>
                      <div className="secinfoclcontent">
                        <h3>Customer Satisfaction</h3>
                          <p>We're committed to exceeding your expectations. Our goal is to return your shoes looking and feeling like new.</p>                          
                      </div>
                      <div className="secinfogrid text-center">
                          <NavLink className="btn btnprime" to="/product-lists">
                              Book Now
                          </NavLink>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <SectionEight/>
  <SectionNine/>
  <SectionEleven/>
  <SectionFaq/>
    </>
  );
}

export default ShoeCleaning;
