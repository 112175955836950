import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  appstore,
  googleplay,
  paymethod1,
  paymethod2,
  paymethod3,
  paymethod4,
  qrscan,
  payment_methods,
  logowhiterose
} from "../../assets/img";
import { useDispatch, useSelector } from "react-redux";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload,
});

function FooterFour() {
  const navigate = useNavigate();
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});

  const appStorel =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.app_store_link;
  const playStore =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.play_store_link;
  const facebook =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link1;
  const twitter =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link2;
  const instagram =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link6;
  const dispatch = useDispatch<any>();

  const [areaCovered, setAreaCovered] = useState<any | null>({});

  const settingsData: any = useSelector<any>((state) => state?.settings);

  // useEffect(() => {
  //   if (Object.keys(settings).length === 0 && settingsData == undefined) {
  //     axios({
  //       method: "get",
  //       url: `${base_url}/api/settings`,
  //       headers: {
  //         Accept: "application/json",
  //         "Access-Control-Allow-Methods": "GET, POST",
  //       },
  //     })
  //       .then((response) => {
  //         if (response.status != 401) {
  //           setSettings(response.data.data);
  //           dispatch(setSettingsAll(response.data.data));
  //           let URL = window.location.href.split("/");
  //           if (!URL.includes("shop")) {
  //             const faviconLink = document.getElementById(
  //               "favicon"
  //             ) as HTMLLinkElement;
  //             if (faviconLink) {
  //               faviconLink.href = response.data.data.WebsiteSettings.logo_path +
  //               response.data.data.WebsiteSettings.style_fav
  //             } else {
  //               console.error("Favicon link element not found");
  //             }
  //           }
  //         }
  //       })
  //       .catch(function (error) {});
  //   }

  //   const headers = {
  //     Accept: "application/json",
  //   };

  //   axios
  //     .get(`${base_url}/api/areas_covered`, {
  //       headers: headers,
  //     })
  //     .then((e) => {
  //       setAreaCovered({ data: e.data });
  //     });
  // }, []);

  useEffect(() => {
    let URL = window.location.href.split("/");
    if (!URL.includes("shop")) {
      const faviconLink = document.getElementById(
        "favicon"
      ) as HTMLLinkElement;
      if (faviconLink) {
        faviconLink.href = settingsData.WebsiteSettings.logo_path +
        settingsData.WebsiteSettings.style_fav
      } else {
        console.error("Favicon link element not found");
      }
    }
  }, []);


  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",");

      return (
        <>
          {cityArr.map((city: any) => (
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dry-cleaners/" + city);
                }}
              >
                {city}
              </a>
            </li>
          ))}
        </>
      );
    });

    return test;
  };

  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  return (
    <div>
      {jsonData && (
        <style type="text/css">
          {`
            :root {
              --footer-header: #${jsonData?.theme_4.home?.footer?.section_heading_color};
              --footer-paragraph: #${jsonData?.theme_4?.home?.footer?.section_paragraph_color};
          }
              .footer-sectionthree h4 {
                color: var(--footer-header)
              }
          `}
        </style>
      )}
      <footer
        className="footer"
        style={{
          background: `#${jsonData?.theme_4?.home?.footer?.bg_color}`,
          color: `#${jsonData?.theme_4?.home?.footer?.section_paragraph_color}`,
        }}
      >
        <div className="container">
        {settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose") && (
          <div className="row footer-sectionone text-center">
            <div className="col-md-12">
              <div className="logo-footer">
                <img src={logowhiterose} alt="" />
              </div>
              {/* <p>{jsonData?.theme_4?.home?.footer?.text}</p> */}
            </div>
          </div>
        )}
          <div className="row footer-sectionthree text-center">
          {settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose") && (
            <>
            <div className="col-md col-sm-12">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <a>Dry Cleaning</a>
                </li>
                <li>
                  <a>Shirt Service (Wash & Press)</a>
                </li>
                <li>
                  <a>Ironing Service</a>
                </li>
                <li>
                  <a>Duvet & Linen</a>
                </li>
                <li>
                  <a>Duvet, Pillow, Curtain and Soft furnishing</a>
                </li>
                <li>
                  <a>Bag Wash</a>
                </li>
                <li>
                  <a>Repairs and Alterations</a>
                </li>
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4>Commercial Solutions</h4>
              <ul>
                <li>
                  <a>Hotel Guest Services</a>
                </li>
                <li>
                  <a>Hotel Staff Uniforms</a>
                </li>
                <li>
                  <a>Hotel Beddings, Towels, etc</a>
                </li>
                <li>
                  <a>Restaurants</a>
                </li>
                <li>
                  <a>Residential Complexes</a>
                </li>
                <li>
                  <a>Gyms and Spas</a>
                </li>
                <li>
                  <a>Retail</a>
                </li>
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4>Help</h4>
              <ul>
                <li>
                  <a href="" onClick={(e)=>{e.preventDefault(); navigate('/Contactus')}}>Contact Us</a>
                </li>
                <li>
                  <a href={blogUrl}>News & Articles</a>
                </li>
                <li>
                  <a href="" onClick={(e)=>{e.preventDefault(); navigate('/termsAndConditions')}}>Terms and Conditions</a>
                </li>
              </ul>
            </div>
            </>
           )}
          {!settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose") && (
            <>
            <div className="col-md col-sm-12">
              <h4>Quick Links</h4>
              <ul>
                <li><a href='' onClick={(e)=>{e.preventDefault(); navigate('/aboutUs')}}>About Us</a></li>
                {/* <li><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#videomodal">How it works</a></li> */}
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4>Policies</h4>
              <ul>

                <li><NavLink to={"/termsAndConditions"}>Terms of Use and Legal Info</NavLink></li>
                <li><NavLink to={"/privacyPolicy"}>Privacy Policy</NavLink></li>
                <li><NavLink to={"/cookiePolicy"}>Cookie Preferences</NavLink></li>
              </ul>
            </div>
            </>
           )}
          </div>
          <div className=" footer-sectionfour text-center">
            <div className="row">
              <div className="col-md col-sm-12">
                <h4>Connect with Us</h4>
                <ul className="footer-social">
                {jsonData?.theme_4?.home?.footer?.social_media?.facebook
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        `https://${jsonData?.theme_4?.home?.footer?.social_media
                          ?.facebook?.facebook_link}`
                      }
                      target="_blank"
                    >
                      <i className="fab fa-facebook"></i>
                      {/* Facebook */}
                    </a>
                  </li>
                )}
                {jsonData?.theme_4?.home?.footer?.social_media?.instagram
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        `https://${jsonData?.theme_4?.home?.footer?.social_media
                          ?.instagram?.instagram_link}`
                      }
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                      {/* Instagram */}
                    </a>
                  </li>
                )}
                {jsonData?.theme_4?.home?.footer?.social_media?.twitter
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        `https://${jsonData?.theme_4?.home?.footer?.social_media
                          ?.twitter?.twitter_link}`
                      }
                      target="_blank"
                    >
                      <i className="fab fa-twitter"></i>
                      {/* Twitter */}
                    </a>
                  </li>
                )}
                {/* {jsonData?.theme_4?.home?.footer?.social_media?.whatsapp
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_4?.home?.footer?.social_media
                          ?.whatsapp?.whatsapp_link
                      }
                      target="_blank"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                )} */}
                {jsonData?.theme_4?.home?.footer?.social_media?.linkedin
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        `https://${jsonData?.theme_4?.home?.footer?.social_media
                          ?.linkedin?.linkedin_link}`
                      }
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                      {/* Linkedin */}
                    </a>
                  </li>
                )}
                {jsonData?.theme_4?.home?.footer?.social_media?.youtube
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        `https://${jsonData?.theme_4?.home?.footer?.social_media
                          ?.youtube?.youtube_link}`
                      }
                      target="_blank"
                    >
                      <i className="fab fa-youtube"></i>
                      {/* Youtube */}
                    </a>
                  </li>
                )}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md col-sm-12">
                <ul>
                {jsonData?.theme_4?.home?.footer?.mbl_number && (
                <li>
                  <a
                    href={`tel:${jsonData?.theme_4?.home?.footer?.mbl_number}`}
                  >
                    <i className="uil uil-phone-volume me-2"></i>
                    {jsonData?.theme_4?.home?.footer?.mbl_number}
                  </a>
                </li>
                )}
                {jsonData?.theme_4?.home?.footer?.email_val && (
                <li>
                  <a
                    href={`mailto:${jsonData?.theme_4?.home?.footer?.email_val}`}
                    className="primary-text"
                  >
                    <i className="uil uil-envelopes me-2"></i>
                    {jsonData?.theme_4?.home?.footer?.email_val}
                  </a>
                </li>    
                )}            
                <li>
                  <p>
                    {jsonData?.theme_4?.home?.footer?.address_val}
                  </p>
                </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md col-sm-12 pt-4">
                <ul>
                  <li>
                    <a href="" onClick={(e)=>{e.preventDefault(); navigate('/privacyPolicy')}}>Privacy Policy</a>
                  </li>
                  <li>
                    <a href="" onClick={(e)=>{e.preventDefault(); navigate('/cookiePolicy')}}>Cookie Preferences</a>
                  </li>
                  <li>
                    <a href="" onClick={(e)=>{e.preventDefault(); navigate('/termsAndConditions')}}>Guarantee and Cancellation Policy</a>
                  </li>
                </ul>
              </div>
            </div>
            {settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose") && (
            <div className="row">
              <div className="col-md-12">
                <p className="footer-last">
                  © 2023 All rights reserved by White Rose DC&L Ltd | Powered by&nbsp; <a href="http://www.bestatservices.com" target="blank">www.bestatservices.com</a>
                </p>
              </div>
            </div>
            )}
            {!settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose") && (
            <div className="row">
              <div className="col-md-12">
                <p className="footer-last">
                  {Object.keys(settings).length > 0 &&
                    settings?.WebsiteSettings?.site_title_en}{" "}
                  © {year}. All rights reserved. | Powered by&nbsp; <a href="http://www.bestatservices.com" target="blank">www.bestatservices.com</a>
                  {/* © <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_title_en}</a> 2023. All rights reserved. |
                  Designed &amp; Developed By : <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url} target="_blank">{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}</a> */}
                </p>
              </div>
            </div>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default FooterFour;
