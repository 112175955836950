import React, { useEffect, useState } from "react";

import { ttimg32,ttimg2,ttimg33,ttimg34,ttimg5,ttimg6,ttimg7,ttimg8,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTEvening() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg33} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg32} alt="" />
                               </div>
                                <p>T&T Dry Cleaners specialises in cleaning Delicate and beaded evening wear. Evening wear, such as black tie and ball gowns, require special treatment. Our specialist cleaning services return clothing in tip-top condition ready to look stunning for that special occasion.</p>
                                <p>We offer a wide range of Evening Wear Services, including:</p>
                                <ul>
                                    <li>Silk Dresses</li>
                                    <li>Bias Cut Dresses</li>
                                    <li>Hand Painted Dresses</li>
                                    <li>Fine Delicate Materials</li>
                                    <li>Heavily Beaded Dresses</li>
                                    <li>Delicate Metalic Materials</li>
                                </ul>                                
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg34} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTEvening;