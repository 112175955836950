import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appstore, googleplay } from "../../../assets/img";
import { useSelector } from "react-redux/es/exports";
import { shop_data } from "../edit/data";

function SectionThirteenThemeThree() {
  const navigate = useNavigate();
  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);
  // const homedata = shop_data

  return (
    <>
      <section className="sh3-mobapp-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="sh3-mobapp-content">
                <div className="sh3-section-title text-start">
                <h2 style={{
                      color: `#${homedata?.theme_3?.home?.section_13?.h2color}`
                    }}>{homedata?.theme_3?.home?.section_13?.title}</h2>
                  <p style={{
                      color: `#${homedata?.theme_3?.home?.section_13?.paracolor}`
                    }}>{homedata?.theme_3?.home?.section_13?.paragraph}</p> 
                </div>
                <ul className="sh3-app-highlights">
                  {homedata?.theme_3?.home?.section_13?.check_list.map(
                    (items: any) => {
                      return (
                        <li>
                          <div className="d-flex align-items-center">
                            <span className="flex-shrink-0 sh3-icon-box">
                              <img src={items.icons} alt="" />
                            </span>
                            <div className="flex-grow-1">
                            <h5 style={{
                      color: `#${homedata?.theme_3?.home?.section_13?.h3color}`
                    }}>{items.title}</h5>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
                {/* <div className='qr-grid'>
                                    <div className='d-flex'>
                                        <span className="flex-shrink-0">
                                            <img src={qrcode} alt="" />
                                        </span>
                                        <div className="flex-grow-1 ms-2">
                                            <h6>Scan here</h6>
                                            <div className='profile-rating'>
                                                <span className='secondary-text'>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                </span>
                                                <span>5.0</span>
                                            </div>
                                            <a href=''>www.bestatlaundry.com</a>
                                        </div>
                                    </div>
                                </div> */}
                <ul className="sh3-applinks">
                  <li>
                    <a
                      target="_blank"
                      href={
                        homedata?.theme_3?.home?.section_13?.mobile_link_section
                          ?.ios_link
                      }
                    >
                      <img src={appstore} alt="" />
                    </a>
                  </li>

                  {/* <li>
                                    <a className='qrbox'><img src={qrcode} alt=""/></a>
                                </li> */}
                  <li>
                    <a
                      target="_blank"
                      href={
                        homedata?.theme_3?.home?.section_13?.mobile_link_section
                          ?.android_link
                      }
                    >
                      <img src={googleplay} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sh3-mobapp-img text-center">
                <img src={homedata?.theme_3?.home?.section_13?.banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionThirteenThemeThree;
