import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link, useNavigate, NavLink } from "react-router-dom";
import {slider1,slider2,slider3,slider4,slider5,slider6,slider7,slider8,upimg5} from "../../assets/img";

function HouseCleaning () {

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">                    
                    <div className="slider-up">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        className="slider-up-slider"
                        >       
                            
                            <SwiperSlide >                            
                                <img src={slider6} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider7} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>                            
                            <SwiperSlide >                            
                                <img src={slider8} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider1} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider2} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide> 
                            <SwiperSlide >                            
                                <img src={slider3} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider4} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={slider5} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                      </Swiper>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">   
                    <div className="up-content">
                        <h2 className="up-h2">HOUSE & OFFICE CLEANING</h2>
                        <p>We offer a professional and reliable house, apartment, "Air BnB", retail outlet or office cleaning service. Whether you require this daily, weekly, biweekly, our flexible team is there to be called on when required.</p>
                        <h4>Homes & Apartments</h4>
                        <p>If you require extra support to keep on top of the chores, we offer a weekly, biweekly or a spring clean service. Based on an agreed hourly rate we will clean your home whenever required. We also offer a carpet cleaning service for those spring cleans, unfortunate accidents or after a decorating project.</p>
                        <h4>Serviced Holiday Accommodation</h4>
                        <p>If you have a single holiday let or a portfolio of properties, we currently service numerous units for various private landlords and businesses. Offering a complete service, including, cleaning, rubbish removal and laundry. Ensuring a warm, clean welcome is enjoyed by every guest.</p>
                        <h4>Shops & Offices</h4>
                        <p>It's essential during these COVID-19 times that providing a safe and clean environment for both shoppers, staff and visitors to enjoy, is essential. Our experienced team will clean your premises at a time these leasts impacts your business.</p>
                        <h4>Rental Properties</h4>
                        <p>If you are a tenant, landlord, property management business or developer we offer various services to suit your needs:</p>
                        <ul className="up-list">
                            <li><p>End of tenancy cleans</p></li>
                            <li><p>New build hand over cleans</p></li>
                            <li><p>Carpet cleaning</p></li>
                            <li><p>General support</p></li>
                        </ul>
                        <p>Please call 01792 644238 to discuss your requirements and arrange a no obligation site visit.</p>                       
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default HouseCleaning;