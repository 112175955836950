import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { data } from "../edit/data";

function SectionSevenThemeThree() {
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  // const jsonData: any = data

  return (
    <>
      <section className="cate-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-start">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <h2 style={{
                      color: `#${jsonData?.theme_3?.home?.section_7?.h2color}`
                    }}>
                    {jsonData?.theme_3?.home?.section_7?.title}
                    </h2>
                  </div>
                  <div className="col-md">
                    <p style={{
                      color: `#${jsonData?.theme_3?.home?.section_7?.paracolor}`
                    }}>{jsonData?.theme_3?.home?.section_7?.paragraph}</p>
                  </div>
                  {/* <div className="col-md-auto">
                    <a
                      style={{
                        background: `#${jsonData.btn_group.btn1.bg_color}`,
                        color: `#${jsonData.btn_group.btn1.color}`,
                      }}
                      href="#book-now"
                      className="btn title-btn hover-btn"
                    >
                      <i className="uil uil-package me-2"></i>{" "}
                      {jsonData.btn_group.btn1.text}
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Swiper
                spaceBetween={5}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 8,
                  },
                }}
                className="home-slider"
              >
                {jsonData?.theme_3?.home?.section_7?.card_section?.collection.map((item: any) => {
                    return (
                      <SwiperSlide>
                        <div className="card-cate">
                          <img src={item.icon} alt="" />
                          <div className="card-cate-body">
                            <h3 style={{
                      color: `#${jsonData?.theme_3?.home?.section_7?.h3color}`
                    }}>{item.card_title}</h3>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/pricing");
                              }}
                              className="btn primary-btn rounded-circle hover-btn"
                            >
                              <i className="uil uil-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionSevenThemeThree;
