import React, { useEffect, useState } from "react";
import { serviceicon2, serviceicon3, serviceicon4 } from "../../../assets/img";
import { useSelector } from "react-redux";
import { shop_data } from "../edit/data";

function SectionFiveThemeTwo() {
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  // const jsonData = shop_data
  return (
    <>
      <section className="sh2-services-section" id="services-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh2-section-card">
                <div className="sh2-section-title2">
                <h2 style={{
                      color: `#${jsonData?.theme_2?.home?.section_5?.h2color}`
                    }}>{jsonData?.theme_2?.home?.section_5?.title} </h2>
                </div>
                <div className="sh2-services-slider">
                  <div className="row">
                    {jsonData?.theme_2?.home?.section_5?.cards.map((cards: any,cardIndex:any) => {
                      return (
                        <>
                          <div className="col-md-3">
                            <div className={`sh2-services-slider-grid ${cardIndex == 0 ?"sh2-services-slider-grid-lbl":""}`}>
                              <img src={cards.icon} alt="" />
                              <h5 style={{
                      color: `#${jsonData?.theme_2?.home?.section_5?.h3color}`
                    }}>{cards.card_title}</h5>
                              <ul>
                                {cards?.card_options.map((items: any) => {
                                  return (
                                    <>
                                      <li style={{
                      color: `#${jsonData?.theme_2?.home?.section_5?.paracolor}`
                    }}>{items.list}</li>
                                    </>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionFiveThemeTwo;
