import React, { useEffect, useState } from "react";

import { ttimg35,ttimg2,ttimg3,ttimg4,ttimg5,ttimg6,ttimg36,ttimg37,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTCurtainsCleaning() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg35} alt="" />
                               </div>
                                <p>The dry cleaning of curtains is the most effective way to clean your curtains of accumulated dust, dirt and allergens.</p>
                                <p>We advise people to clean their curtains, swags, tails and pelmets at least every six months and much more regularly if anyone in the house suffers from asthma or allergies. Smoke and nicotine also attack curtain fibres.</p>
                                <p>As well as dry cleaning your curtains we can also undertake any necessary alterations you require such as:</p>
                                <ul>
                                    <li>New Lining</li>
                                    <li>Panels</li>
                                    <li>Curtain Design</li>
                                    <li>Shortening</li>
                                    <li>Tapering</li>
                                    <li>New Tapes</li>
                                </ul>                                
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg36} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg37} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTCurtainsCleaning;