import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import { fetchGetShopByPinCode } from "../../redux/Actions/checkoutPageActions";
import DownloadApp from "../downloadApp";
import {
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  eco,
  priorityicon14,
  priorityicon15,
  priorityicon16,
  priorityicon17,
  appdownload,
  appstore,
  googleplay,
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
  trustimg,
  trustimg2,
  trustimg3,
  trustimg4,
  howwe1,
  howwe2,
  howwe3,
  howwe4,
} from "../../assets/img";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";
// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

function SectionElevenThemeFour() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();

  const base_url = process.env.REACT_APP_BACKEND_URL;
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  // const base_url = "https://revamp.dreamguystech.com"

  // const fetchData = async () => {
  //     const base_url = "https://revamp.dreamguystech.com";
  //     try {
  //         const bodyFormData = new FormData();
  //         bodyFormData.append('keyword', 'Gu11');
  //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
  //         const response = await axios({
  //             method: "post",
  //             url: `${base_url}/api/get_postal_codes`,
  //             data: bodyFormData,
  //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
  //         })
  //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
  //         // const result = await response.then(response => response);

  //         if (response) {
  //             setPostalCodeList(response.data.data[0])
  //
  //         }
  //     } catch (error) {
  //
  //     }
  // }

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const [productList, setProductList] = useState<any | null>({});
  const [blogList, setBlogList] = useState<any | null>({});
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });

    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);

  const faqData: any = useSelector<any>((state) => state?.faq);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  // useEffect(() => {
  //     // fetchData();
  //     dispatch(fetchPostalCodes())
  //     // dispatch(fetchPostalCodes());
  // }, [])

  const handleChange = (e: any) => {
    const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm);
    // dispatch(fetchPostalCodes(searchTerm));
  };
  const handleSectionThreeThemeFour = async () => {
    const isValid = state.postalCodeList.some(
      (item: any) => item.postcode === postalCodeValue.toUpperCase()
    );
    setValue("postalCode", postalCodeValue);
    const results = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    );

    if (!results) {
      navigate("/areaNotCovered");
      return;
    }
    if (isValid) {
      navigate("/productLists");
    } else {
      navigate("/areaNotCovered");
    }
  };
  useEffect(() => {
  }, [jsonData]);
  return (
    <>
      <section className="mobapp-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="mobapp-content">
                <div className="section-title text-start">
                  <h2 className="ps-0" style={{
                      color: `#${jsonData?.theme_4?.home?.section_11?.h2color}`
                    }}>
                    {jsonData?.theme_4?.home?.section_11?.title}
                  </h2>
                  <p className="ps-0" style={{
                      color: `#${jsonData?.theme_4?.home?.section_11?.paracolor}`
                    }}>{jsonData?.theme_4?.home?.section_11?.paragraph}</p>
                </div>
                <ul className="app-highlights">
                  {jsonData?.theme_4?.home?.section_11?.check_list?.map(
                    (val: any) => {
                      return (
                        <>
                          <li key={val?.id}>
                            <div className="d-flex align-items-center">
                              <span className="flex-shrink-0 icon-box">
                                <img src={val?.icons} alt="" />
                              </span>
                              <div className="flex-grow-1">
                                <h5 className="ps-0" style={{
                      color: `#${jsonData?.theme_4?.home?.section_11?.h3color}`
                    }}>{val?.title}</h5>
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    }
                  )}
                  {/* <li>
                    <div className="d-flex align-items-center">
                      <span className="flex-shrink-0 icon-box">
                        <img src={priorityicon15} alt="" />
                      </span>
                      <div className="flex-grow-1">
                        <h5>Delivery in less then 24 hours</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <span className="flex-shrink-0 icon-box">
                        <img src={priorityicon16} alt="" />
                      </span>
                      <div className="flex-grow-1">
                        <h5>Standard wash for just £14</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <span className="flex-shrink-0 icon-box">
                        <img src={priorityicon17} alt="" />
                      </span>
                      <div className="flex-grow-1">
                        <h5>In-app live chat support</h5>
                      </div>
                    </div>
                  </li> */}
                </ul>
                {/* <div className='qr-grid'>
                                    <div className='d-flex'>
                                        <span className="flex-shrink-0">
                                            <img src={qrcode} alt="" />
                                        </span>
                                        <div className="flex-grow-1 ms-2">
                                            <h6>Scan here</h6>
                                            <div className='profile-rating'>
                                                <span className='secondary-text'>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                </span>
                                                <span>5.0</span>
                                            </div>
                                            <a href=''>www.bestatlaundry.com</a>
                                        </div>
                                    </div>
                                </div> */}
                <ul className="applinks">
                  {settingsData != null &&
                    Object.keys(settingsData).length > 0 &&
                    settingsData?.WebmasterSettings?.app_store_link !== "" && (
                      <li>
                        <a
                          target="_blank"
                          href={settingsData?.WebmasterSettings?.app_store_link}
                        >
                          <img src={appstore} alt="" />
                        </a>
                      </li>
                    )}

                  {/* <li>
                                    <a className='qrbox'><img src={qrcode} alt=""/></a>
                                </li> */}
                  {settingsData != null &&
                    Object.keys(settingsData).length > 0 &&
                    settingsData?.WebmasterSettings?.play_store_link !== "" && (
                      <li>
                        <a
                          target="_blank"
                          href={
                            settingsData?.WebmasterSettings?.play_store_link
                          }
                        >
                          <img src={googleplay} alt="" />
                        </a>
                      </li>
                    )}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mobapp-img text-center">
                <img src={jsonData?.theme_4?.home?.section_11?.banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionElevenThemeFour;
