import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../aboutUs/aboutUs.css';
import { abouticon5,abouticon6,abouticon7,abouticon8,aboutimg1,aboutimg2 } from '../../assets/img';
import { fetchAboutUsData } from "../../redux/Actions/policyActions";
import SectionEight from '../theme-tweleve/sectionEight';

function AboutUs() {

    const dispatch = useDispatch<any>();

    const aboutUsData: any = useSelector<any>(
        (state) => state?.aboutUs
      );

    const settingsData: any = useSelector<any>((state) => state?.settings);
    let shopURL = window.location.href;
    var metaTitle = "";
    var metaDescription = "";

    useEffect(() => {
        metaTitle = "About "+ settingsData?.WebsiteSettings?.site_title_en +" | Professional Laundry and Dry Cleaning Services in London";
        metaDescription = "Learn more about "+ settingsData?.WebsiteSettings?.site_title_en +", your professional provider of laundry and dry cleaning services in London. Discover our mission, values, and commitment to providing high-quality services.";
        document.title = metaTitle;
        document.getElementById("title")?.setAttribute("content", metaTitle);
        document.getElementById("description")?.setAttribute("content", metaDescription);

        dispatch(fetchAboutUsData());
      }, []);

    return (
        <div>
            <div className="breadcrumpset">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="breadcrumpview">
                            <h2>{aboutUsData?.title_en}</h2>
                            <ul>
                            <li>
                                <a href="/"><i className="fa fa-home" aria-hidden="true"></i> </a>
                            </li>
                            <li>
                                <span>{aboutUsData?.title_en}</span>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-content-wrapper about-content my-77">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="policy-section">
                                <div dangerouslySetInnerHTML={{__html: aboutUsData?.details_en}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {shopURL?.includes("hellocobbler") && (  
            <SectionEight/>
            )}
        </div>
    )
}

export default AboutUs