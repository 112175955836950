import React, { useEffect, useState } from "react";

import { ttimg1,ttimg2,ttimg3,ttimg4,ttimg5,ttimg6,ttimg7,ttimg8,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTHouseholdcleaning() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg18} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg17} alt="" />
                               </div>
                                <p>T&T Dry Cleaners provides a wide range of Household Cleaning services including:</p>
                                <ul>
                                    <li>Sofa covers</li>
                                    <li>Cushion</li>
                                    <li>Cushion Covers</li>
                                    <li>Curtains</li>
                                    <li>Table Cloths</li>
                                    <li>Rugs</li>
                                    <li>Blankets</li>
                                    <li>Pillow Cases</li>
                                    <li>Duvet</li>
                                    <li>Duvet Covers</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg19} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTHouseholdcleaning;