import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, EffectFade, Autoplay } from "swiper";

import { ttslider1, ttslider2, ttslider3, ttslider4, ttslider5, ttslider6, ttslider7, ttslider8, ttslider9 } from "../../assets/img";
function TTSlider() {
    const navigate = useNavigate();
    const navigateTo = (page: string): any => {
        navigate(page)
    }
    const sliderData = [{ img: ttslider1, link: '/ttlaundry' },
    { img: ttslider2, link: '/ttpress' },
    { img: ttslider3, link: '/ttweddingdresscleaning' },
    { img: ttslider4, link: '/tthouseholdcleaning' },
    { img: ttslider5, link: '/ttrepairalterations' },
    { img: ttslider6, link: '/ttcollectiondelivery' },
    { img: ttslider7, link: '/ttduvetcleaning' },
    { img: ttslider8, link: '/ttbedlinencleaning' },
    { img: ttslider9, link: '/ttevening' }
]
    return (
        <>
            <div className="ttslider-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 px-0">
                            <div className="ttslider">
                                <Swiper
                                    spaceBetween={15}
                                    navigation={true}
                                    modules={[Navigation]}
                                    className="tt-slider"
                                >
                                    {sliderData.map((item: any, index: any) => {
                                        return <>
                                            <SwiperSlide key={index}>
                                                <img
                                                    key={index}
                                                    src={item.img}
                                                    alt=""
                                                    onClick={() => navigateTo(item.link)}
                                                />
                                            </SwiperSlide>
                                        </>
                                    })}

                                    {/* <SwiperSlide  >
                                        <div className="ttsl">
                                            <img src={ttslider1} alt="" />
                                            <a href="/ttlaundry"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="ttsl">
                                            <img src={ttslider2} alt="" />
                                            <a href="/ttpress"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="ttsl">
                                            <img src={ttslider3} alt="" />
                                            <a href="/ttweddingdresscleaning"></a>
                                        </div>
                                    </SwiperSlide> */}
                                    {/* <SwiperSlide>     
                                    <Link to="/tthouseholdcleaning">                                      
                                        <img src={ttslider4} alt=""/>
                                        </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>        
                                    <Link to="/ttrepairalterations">                                   
                                        <img src={ttslider5} alt=""/>
                                        </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>         
                                    <Link to="/ttcollectiondelivery">                                  
                                        <img src={ttslider6} alt=""/>
                                        </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>   
                                    <Link to="/ttduvetcleaning">                                        
                                        <img src={ttslider7} alt=""/>
                                        </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>      
                                    <Link to="/ttbedlinencleaning">                                     
                                        <img src={ttslider8} alt=""/>
                                        </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>          
                                    <Link to="/ttevening">                                 
                                        <img src={ttslider9} alt=""/>
                                        </Link>
                                    </SwiperSlide> */}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTSlider;