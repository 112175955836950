import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { trustimg } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";

function SectionFiveThemeThree() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);

  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  useEffect(() => {}, [homedata]);
  
  return (
    <>
      <section className="trust-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="split-img">
                <img src={homedata.theme_10?.home?.section_5?.banner[0]?.banner} alt="" />
                <div className="split-img-framegrid">
                  <div className="split-img-frame1"></div>
                  <div className="split-img-frame2"></div>
                  <div className="split-img-frame3"></div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="trust-content">
                <h2 style={{
                      color: `#${homedata?.theme_10?.home?.section_5?.h2color}`
                    }}>{homedata.theme_10?.home?.section_5?.title}</h2>
                <p style={{
                      color: `#${homedata?.theme_10?.home?.section_5?.paracolor}`
                    }}>{homedata.theme_10?.home?.section_5?.paragraph}</p>
                <ul>
                  {homedata.theme_10?.home?.section_5?.check_list &&
                    Object.keys(homedata.theme_10.home.section_5.check_list).map(
                      (key) => (
                        <li key={key} style={{
                          color: `#${homedata?.theme_10?.home?.section_5?.paracolor}`
                        }}>
                          {homedata.theme_10.home.section_5.check_list[key].list}
                        </li>
                      )
                    )}
                </ul>
                <div className="trust-content-btn d-none">
                  <a
                    href="#book-now"
                    className="btn title-btn hover-btn"
                    style={{
                      background: `#${homedata.theme_10?.home?.section_5?.btn_group?.btn1?.bg_color}`,
                      color: `#${homedata.theme_10?.home?.section_5?.btn_group?.btn1?.color}`,
                    }}
                  >
                    {homedata.theme_10?.home?.section_5?.btn_group.btn1.text}
                    <i className="uil uil-arrow-circle-right ms-2"></i>
                  </a>
                  {/* <a
                    href="#book-now"
                    className="btn primary-btn hover-btn"
                    style={{
                      background: `#${homedata.theme_10?.home?.section_5?.btn_group?.btn2?.bg_color}`,
                      color: `#${homedata.theme_10?.home?.section_5?.btn_group?.btn2?.color}`,
                    }}
                  >
                    {homedata.theme_10?.home?.section_5?.btn_group.btn2.text}
                    <i className="uil uil-arrow-circle-right ms-2"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionFiveThemeThree;
