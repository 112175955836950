import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link, useNavigate, NavLink } from "react-router-dom";
import {upimg5} from "../../assets/img";

function PriceList () {

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-6">   
                    <div className="up-content">
                        <h2 className="up-h2">PRICE LIST</h2>
                        <p>Our competitive prices listed on the following pages:</p>
                        <ul className="up-pricelist">
                            <li>
                                <NavLink to="/laundry">
                                LAUNDRY
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/ironing">
                                IRONING
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/drycleaning">
                                DRY CLEANING
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/alterations">
                                ALTERATIONS
                                </NavLink>
                            </li>
                        </ul>
                        <p>If you have any other requirements not listed on these pages, please feel free to give us a call on 01792 644238.</p>                       
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="up-img">
                        <img src={upimg5} alt="" />
                    </div>
                    <div className="up-card-bg">
                        <p>Book a Pickup - Call 01792 644238 or<br/> text 07999 999294</p>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default PriceList;