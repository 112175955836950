import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux/es/exports";
import { shop_data } from "../edit/data";

const SectionThreeThemeOne = () => {
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state?.shopHomeJsonList);
  // const jsonData = shop_data
  const settingsData: any = useSelector<any>((state) => state?.settings);
  // HTML
  return (
    <>
      <section className="sh1-trust-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sh1-trust-content">
                <h2 className="sh1-section-title" style={{
                      color: `#${jsonData?.theme_1?.home?.section_3?.h2color}`
                    }}>
                  {jsonData?.theme_1?.home?.section_3?.title} 
                </h2>
                <p style={{
                      color: `#${jsonData?.theme_1?.home?.section_3?.paracolor}`
                    }}>{jsonData?.theme_1?.home?.section_3?.paragraph}</p>
                <h4 style={{
                      color: `#${jsonData?.theme_1?.home?.section_3?.h3color}`
                    }}>{jsonData?.theme_1?.home?.section_3?.sub_heading}</h4>
                <ul>
                  {jsonData?.theme_1?.home?.section_3?.check_list &&
                    Object.keys(jsonData.theme_1.home.section_3.check_list).map(
                      (key:any) => (
                        <li key={key} style={{
                          color: `#${jsonData?.theme_1?.home?.section_3?.paracolor}`
                        }}>
                          {jsonData.theme_1.home.section_3.check_list[key].list}
                        </li>
                      )
                    )}
                </ul>

                <div className="sh1-trust-content-btn">
                  <a
                    onClick={() => navigate("/aboutUs")}
                    className="btn primary-btn hover-btn"
                    style={{
                      background: `#${jsonData?.theme_1?.home?.section_3?.btn_group?.btn1?.bg_color}`,
                      color: `#${jsonData?.theme_1?.home?.section_3?.btn_group?.btn1?.color}`,
                    }}
                  >
                    {jsonData?.theme_1?.home?.section_3?.btn_group?.btn1?.text}
                  </a>
                </div>
                
              </div>
            </div>
            <div className="col-md-6">
              <div className="sh1-trust-content-img">
                <img src={jsonData?.theme_1?.home?.section_3?.banner} alt="" />
              </div>
            </div>
          </div>
          {settingsData?.WebsiteSettings?.site_title_en.includes("Sasian") && (
                  <div className="sascallbtn pt-5 text-center">
                    <a href="tel:+(44)7749300244" className="btn primary-btn">Call Us</a>
                  </div>
                )}
        </div>
      </section>
    </>
  );
};

export default SectionThreeThemeOne;
