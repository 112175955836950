import React, { useEffect, useState } from "react";
import {lh13, lh27} from "../../assets/img";
function Sec5 () {

return (
    <>
        <section className="bg-blue sec-lhteam">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sec-team text-center">
                            <div className="text-center">
                                <img src={lh27} alt=""/>
                            </div>
                            <h5>Meet the team</h5>    
                            <p>We're a global team of laundry, tech, and logistics experts on a mission to free people from laundry so that they can spend more time doing what they</p>                        
                            {/* <strong>Team Laundryheap</strong> */}
                            <div className="sec-img">
                                <img src={lh13} alt="" className="img-fluid" />
                            </div>
                        </div>                        
                    </div>
                </div> 
            </div>
        </section>
    </>
)
}
export default Sec5;