import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { t3w1,t3w2,t3w3,t3w4,t3w5,t3w6,t3w7,t3w8,t3w9,t3w10,t3w11,t3w12,t3w13,t3w14,t3w15,t3w16,t3w17,t3w18,t3w19,t3w20,t3w21,t3w22,t3w23,t3w24,t3w25,t3w26,t3w27,t3w28,t3w29,t3w30,t3w31,t3w32,t3w33,t3w34,t3w35,t3w36,t3w37,t3w39,t3w40,t3w41,t3w42 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";

function SectionEleven () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <section className="secbg3">
            <div className="container">
                <div className="row"> 
                    <div className="col-md-6">
                        <div className="secimg">
                            <img src={t3w33} alt="" />
                        </div>
                    </div>               
                    <div className="col-md-6">
                        <div className="secinfogrid">
                            <h2 className="hrdbtm">Your Dry Cleaning and Laundry Services just a click away</h2>                            
                            <ul className="hpointlist5">
                                <li>
                                    <span className="secavatar3"><img src={t3w26} alt=""/></span>
                                    <p>Cashless Payments</p>
                                </li>
                                <li>
                                    <span className="secavatar3"><img src={t3w27} alt=""/></span>
                                    <p>Contactless Collection and Delivery</p>
                                </li>
                                <li>
                                    <span className="secavatar3"><img src={t3w28} alt=""/></span>
                                    <p>Real-time Order Updates</p>
                                </li>
                                <li>
                                    <span className="secavatar3"><img src={t3w29} alt=""/></span>
                                    <p>Dedicated 24/7 Support</p>
                                </li>
                            </ul>
                            <ul className="hpointlist6">                                
                                <a className="btn" href={appStorel} target="_blank"><img src={t3w30} alt=""/></a>
                                <a className="btn" href={playStore} target="_blank"><img src={t3w31} alt=""/></a>
                                <div className="hpointlistscan">
                                    <a className="btn" href="#"><img src={t3w32} alt=""/></a>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionEleven;
