import React, { useEffect, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { ttslider1,ttslider2,ttslider3,ttslider4,ttslider5,ttslider6,ttslider7,ttslider8,ttslider9 } from "../../assets/img";
function TTSidebar() {
    return (
        <>
            <div className="tt-sidebar">
                <ul>                    
                    <li>
                        <NavLink className="nav-bar-link" to="/ttdrycleaning">DRY CLEANING </NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttlaoundreet">LAUNDERETTE</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttpress">PRESS ONLY</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttrepairalterations">REPAIRS & ALTERATIONS</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttlaundry">LAUNDRY</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttweddingdresscleaning">WEDDING DRESSES</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttsudeleathercleaning">SUEDE & LEATHER</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttevening">EVENING WEAR</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttcurtainscleaning">CURTAINS CLEANING </NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttduvetcleaning">DUVET CLEANING</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/tthouseholdcleaning">HOUSEHOLDS CLEANING</NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-bar-link" to="/ttbedlinencleaning">BED LINEN</NavLink>
                    </li>
                </ul>
            </div>
        </>
    );

}
export default TTSidebar;