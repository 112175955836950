import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { data } from "../edit/data";


function SectionSevenThemeFour() {
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  //const jsonData: any = data


  return (
    <>
      <section className="cate-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 style={{
                      color: `#${jsonData?.theme_4?.home?.section_7?.h2color}`
                    }}>{jsonData?.theme_4?.home?.section_7?.title}</h2>
                <p style={{
                      color: `#${jsonData?.theme_4?.home?.section_7?.h3color}`
                    }}>{jsonData?.theme_4?.home?.section_7?.paragraph}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 p-0">
              <Swiper
                spaceBetween={1}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 8,
                  },
                }}
                className="home-slider"
              >
                {jsonData?.theme_4?.home?.section_7?.card_section?.collection.map((item: any) => {
                    return (
                      <SwiperSlide>
                        <div className="card-cate">
                          <img src={item.icon} alt="" />
                          <div className="card-cate-body">
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/pricing");
                              }}
                              style={{
                                color: `#${jsonData?.theme_4?.home?.section_7?.paracolor}`
                              }}
                            >
                              {item.card_title}
                              <i className="uil uil-arrow-right ms-2"></i>
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionSevenThemeFour;
