import React from "react";
import { useSelector } from "react-redux/es/exports";
import SectionOffer from "../theme-four/section-offer";
import GoogleReviews from "../../googleReview";
import SectionOneBannerThemeThree from "./SectionOneBannerThemeThree";
import SectionThreeThemeThree from "./sectionThreeThemeThree";
import SectionFourThemeThree from "./sectionFourThemeThree";
import SectionFiveThemeThree from "./sectionFiveThemeThree";
import SectionSixThemeThree from "./sectionSixThemeThree";
import SectionEightThemeThree from "./SectionEightThemeThree";
import SectionNineThemeThree from "./sectionNineThemeThree";
import SectionElevenThemeThree from "./SectionElevenThemeThree";
import SectionTwelveBannerThemeThree from "./SectionTwelveBannerThemeThree";
import SectionThirteenThemeThree from "./SectionThirteenThemeThree";
import SectionFifteenThemeThree from "./SectionFifteenThemeThree";
import SectionOpeningHours from "./SectionOpeningHours";
import SectionSevenThemeThree from "./sectionSevenThemeThree";
import NearestShops from "../nearestShops";

function ThemeTen() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  let shopURL = window.location.href.split("/");
  const checkIsSectionEnabled = (index: any) => {
    // if (settingsData?.template == 1) {
    //   return jsonData?.theme_1?.home?.sections[index]?.is_section_enable;
    // }
    // if (settingsData?.template == 3) {
    return jsonData?.theme_10?.home?.sections[index]?.is_section_enable;
    // }
    //   if (settingsData?.template == 5) {
    //     return jsonData?.theme_5?.home?.sections[index]?.is_section_enable;
    //   }
  };

  return (
    <>
    <div className="home-tten">
        <SectionOneBannerThemeThree />
          <GoogleReviews />
          <SectionThreeThemeThree />
          <SectionFourThemeThree />
          <SectionFiveThemeThree />
          <SectionSixThemeThree />
          <SectionSevenThemeThree />
          <SectionEightThemeThree />
          <SectionNineThemeThree />
          {/* <SectionTenBannerThemeThree /> */}
          <SectionElevenThemeThree />
          <SectionTwelveBannerThemeThree />
          <SectionThirteenThemeThree />
          <SectionFifteenThemeThree />
          {shopURL[4]?.includes("sewclean") && <SectionOpeningHours/>}
          {<NearestShops/>}
    </div>
    </>
  );
}

export default ThemeTen;
