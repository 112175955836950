import React, { useEffect, useState } from "react";
import axios from "axios";
// import './myFlat.css';
import OfferView from "../../offerView";
import { offerprod1 } from "../../../assets/img";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "primereact/tooltip";

const base_url = process.env.REACT_APP_BACKEND_URL;
const offerListData = {
  result: {
    Response: {
      response_code: "1",
      response_message: "success",
    },
    data: {
      offer_details: [],
    },
  },
};

function OfferHeader() {
  const notify = (message: string) => toast(message);
  // alert("called")
  const [changePopup, setChangePopup] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [descriptionValue, setDescriptionValueValue] = useState("");
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const [offerList, setOfferList] = useState<any>([]);
  //const params=useParams()
  const minimum_order_amount: any = useSelector<any>(
    (state) => state.getShopByPinCode?.data?.minimum_order_amount
  )
  const handleClick = (code: any, title: any, description: any, e: any) => {
    //setMobileValue(e.target.value);
    setCodeValue(code);
    setTitleValue(title);
    setDescriptionValueValue(description);
    setChangePopup(!changePopup);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify("Coupon code copied successfully");
      })
      .catch((err) => {
        notify("Unable to copy coupon code");
      });
  };

  useEffect(() => {
    const headers = {
      Accept: "application/json",
    };

    axios
      .get(`${base_url}/api/list_offer_details`, {
        headers: headers,
      })
      .then((e) => {
        

        setOfferList(e.data);
      });
  }, []);

  return (
    <>
      {offerList?.Response?.response_code != "-1" ? (
        <>
          <div className="offer-section-hdr">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="offer-section-hdr-slider">
                  
                      
                    <Swiper
                      spaceBetween={15}
                      navigation={true}
                      modules={[Navigation]}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 1,
                        },
                        1024: {
                          slidesPerView: 1,
                        },
                      }}
                      className="hdr-slider"
                    >
                      {offerList?.data?.offer_details?.map(
                        (offer_detail: any, offerindex: any) => {
                          return (
                            <>
                              <SwiperSlide key={offerindex}>
                                <div
                                  className="offer-hdr-grid"
                                  key={offer_detail.id}
                                >
                                    {offer_detail.title} : {offer_detail.percentage}% OFF : MINIMUM ORDER £ {shopHomeData?.profile?.minimum_order_amount}
                                  <div className="offer-wrap-left d-none">
                                    
                                    <span className="offer-wrap-price d-none">
                                      {offer_detail.offer_code}
                                      <Tooltip
                                        className="text-white"
                                        target=".fa-copy"
                                      />
                                      <i
                                        className="ms-auto fa fa-copy"
                                        data-pr-tooltip="Copy code"
                                        data-pr-position="top"
                                        onClick={() => {
                                          copyToClipboard(
                                            offer_detail.offer_code
                                          );
                                        }}
                                      ></i>
                                      <i
                                        data-bs-toggle="modal"
                                        data-bs-target="#myOffer-1"
                                        onClick={(e) =>
                                          handleClick(
                                            offer_detail.offer_code,
                                            offer_detail.title,
                                            offer_detail.description,
                                            e
                                          )
                                        }
                                        className="ps-3"
                                      ></i>
                                    </span>
                                  </div>
                                  <div className="offer-wrap-right">
                                    <img
                                      src={offerprod1}
                                      className="img-fluid"
                                      alt="offer"
                                    />
                                  </div>
                                </div>
                              </SwiperSlide>
                            </>
                          );
                        }
                      )}
                    </Swiper>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="myOffer-1"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
          >
            <div className="modal-dialog modal-md modal-dialog-centered">
              <div className="modal-content offermodal-body">
                {changePopup && (
                  <OfferView
                    code={codeValue}
                    title={titleValue}
                    description={descriptionValue}
                    close={() => setChangePopup(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </>
        ) : null}
      <ToastContainer />
    </>
  );
}

export default OfferHeader;
