import React, { useEffect, useState } from "react";

function Review () {

return (
    <>
        <section className="bg-blue">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">                    
                        <div className="lh-star-desc">
                            <h3>Ranked #1 by customers</h3>
                            <p>in categories Dry cleaner, Laundry service and Laundrette</p>
                        </div>
                    </div>
                    <div className="col-md-6">  
                        <div className="lh-star-rate">
                            <div className="lh-star">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </section>
    </>
)
}
export default Review;