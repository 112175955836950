import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {slider1,slider2,slider3,slider4,slider5,slider6,slider7,slider8,upimg4} from "../../assets/img";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchPickupDates, fetchPickupTimeSlotsDeliveryDates } from "../../redux/Actions/checkoutPageActions";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { wheatleyPostContactForm } from "../../redux/Actions";
import { ToastContainer, toast } from "react-toastify";
import { NavLink } from "react-router-dom";

function BookingBusiness () {
    const [startDate, setStartDate] = useState<any>(new Date());
//   const [startTime, setStartTime] = useState('00:00');
  const [pickUpTimeSlots, setPickUpTimeSlots] = useState([]);
  const [selectedPickupTimeSlot, setSelectedPickupTimeSlot] = useState<any>();
  const email: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  const alphaNumericPattern: RegExp = /^[a-zA-Z0-9_ .-]*$/;
  const alphabet: RegExp = /^[A-Za-z\s]+$/;
  const phoneNumber = new RegExp(/^(?:(?:(?:00\s?|\+)44\s?|0\s?|)7(?:[1345789]\d{2}|624)\s?\d{3}\s?\d{3})$/);
  const [message, setMessage] = useState<string>("");
  const [serviceType, setServiceType] = useState<string>("Laundry Collection");

  const handleDateChange = (date:any) => {
    setStartDate(date);
  };

//   const handleTimeChange = (event:any) => {
//     setStartTime(event.target.value);
//   };

  const pickupDatesList: any = useSelector<any>(
    (state) => state.pickupDates?.data?.pickup_dates
  );

  const pickupTimesList: any = useSelector<any>(
    (state) => state.pickupTimesDeliveryDates
  );

  const dispatch = useDispatch<any>();

  const datesFormat = (date: any) => {
    let selectedDate = new Date(date);
    const yyyy = selectedDate.getFullYear();
    let mm: any = selectedDate.getMonth() + 1; // Months start at 0!
    let dd: any = selectedDate.getDate();
  
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
  
    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };

  const onPickUpDateSelectHandler = (date: Date) => {
    const selectedDate = datesFormat(date);
    setStartDate(selectedDate);
    dispatch(
      fetchPickupTimeSlotsDeliveryDates(selectedDate, 0)
    );
  };

  const onPickUpTimeSlotHandler = (event: any) => {
    const selectedTimeSlot = pickUpTimeSlots?.find(
      (item: any) => item?.id === event?.target?.value
    );
    setSelectedPickupTimeSlot(selectedTimeSlot);
  };

  const onServiceTypeChangeHandler = (event: any) => {
    setServiceType(event?.target?.value);
  }

  useEffect(() => {
    localStorage.setItem("shop_id", '1');
    dispatch(fetchPickupDates());
  },[]);

  useEffect(() => {
    if (pickupTimesList) {
      const pickUpTimeSlots = pickupTimesList?.data?.pickup_timeslots;

      setPickUpTimeSlots(pickUpTimeSlots);
      
      if (pickUpTimeSlots == undefined && pickupDatesList?.length > 0) {
        toast.warning("Pickup timeslots not available, please select another date!");
      }
    }
  }, [pickupTimesList]);

  const contactUsFormSchema = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .max(50, "Enter cannot exceed 50 character")
      .matches(alphabet, "Name should be only alphabet")
      .trim(),
    email: yup
      .string()
      .required("Email address is required")
      .matches(email, "Enter a valid email address")
      .max(96, "Enter cannot exceed 96 character")
      .trim(),
    mobile: yup
      .string()
      .required("Mobile number is required")
      .matches(phoneNumber, "Enter a valid mobile number")
      .trim(),
    address: yup
      .string()
      .required("Address is required")
      .matches(
        alphaNumericPattern,
        "Address cannot not contain any special character"
      )
      .max(250, "Address cannot exceed 250 character")
      .trim(),
    message: yup
      .string()
      .required("Message is required")
      .matches(
        alphaNumericPattern,
        "Message cannot not contain any special character"
      )
      .max(250, "Message cannot exceed 250 character")
      .trim(),
  });

  const {
    handleSubmit: contactFormSubmit,
    control: contactFormControl,
    reset: contactFormReset,
    formState: { errors: contactFormErrors },
  } = useForm({
    resolver: yupResolver(contactUsFormSchema),
    defaultValues: {
      email: "",
      message: "",
      name: "",
      mobile: "",
    },
  });

  const submitContactForm = async (data: any) => {
    
    data.service_type = serviceType;
    data.date = startDate;
    data.time = selectedPickupTimeSlot.timeslot;
    const response: any = await wheatleyPostContactForm(data);
    if (response.status) {
      // setContactUs("contactus-grid");
      contactFormReset();
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } else {
      setMessage(response.message);
    }
  };

return (
    <>
        <div className="breadcrumpset">
            <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="breadcrumpview">
                        <h2>Booking</h2>
                        <ul>
                            <li>
                                <a href="/">
                                <i className="fa fa-home" aria-hidden="true"></i>{" "}
                                </a>
                            </li>
                            <li>
                                <span>Booking</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className="container">
            <div className="row justify-content-center">
                <form onSubmit={contactFormSubmit(submitContactForm)}>
                    <div className="col-md-6">                    
                        <div className="wl-form">
                            <div className="mb-3">
                                <label>Service Type</label>
                                <select className="form-select" name="service_type" onChange={onServiceTypeChangeHandler}>
                                    <option value="Laundry Collection" selected>Laundry Collection</option>
                                </select>
                            </div>
                            <div className="mb-3 input-group">
                                <span className="w-50">
                                    <label>Date: </label>
                                    {/* <DatePicker selected={startDate} onChange={handleDateChange} className="form-control" /> */}
                                    <DatePicker
                                        name="date"
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        selected={
                                            startDate != null ? new Date(startDate) : null
                                        }
                                        includeDates={pickupDatesList?.map(
                                            (item: any) => new Date(item?.date)
                                        )}
                                        onChange={onPickUpDateSelectHandler}
                                        placeholderText="Choose Date"
                                    />
                                    {/* {contactFormErrors.date ? (
                                        <small className="text-danger">
                                        {contactFormErrors.date?.message}
                                        </small>
                                    ) : null} */}
                                </span>
                                <span className="w-50">
                                    <label>Time: </label>
                                    <select
                                        name="time"
                                        className="form-control"
                                        required
                                        onChange={onPickUpTimeSlotHandler}
                                    >
                                        <option value="">Select</option>
                                            {pickUpTimeSlots?.map((item: any) => (
                                            <option
                                            value={item?.id}
                                            selected={
                                                selectedPickupTimeSlot &&
                                                selectedPickupTimeSlot?.id === item?.id
                                            }
                                            >
                                        {item.timeslot}
                                        </option>
                                    ))}
                                    </select>
                                    {/* <input type="text" className="form-control" value={startTime} onChange={handleTimeChange}/> */}
                                    {/* {contactFormErrors.time ? (
                                        <small className="text-danger">
                                        {contactFormErrors.time?.message}
                                        </small>
                                    ) : null} */}
                                </span>
                            </div>
                            <div className="mb-3">
                                <label>Your Name</label>
                                <Controller
                                    name="name"
                                    control={contactFormControl}
                                    render={({ field }) => (
                                    <input
                                        placeholder="Enter name"
                                        {...field}
                                        className={`form-control ${
                                        contactFormErrors?.name ? "invalid" : ""
                                        }`}
                                    />
                                    )}
                                />
                                {contactFormErrors.name ? (
                                    <small className="text-danger">
                                    {contactFormErrors.name?.message}
                                    </small>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label>Email</label>
                                <Controller
                                    name="email"
                                    control={contactFormControl}
                                    render={({ field }) => (
                                    <input
                                        placeholder="Enter email"
                                        {...field}
                                        className={`form-control ${
                                        contactFormErrors?.email ? "invalid" : ""
                                        }`}
                                    />
                                    )}
                                />
                                {contactFormErrors.email ? (
                                    <small className="text-danger">
                                    {contactFormErrors.email?.message}
                                    </small>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label>Phone</label>
                                <Controller
                                    name="mobile"
                                    control={contactFormControl}
                                    render={({ field }) => (
                                    <input
                                        type="text"
                                        placeholder="Enter mobile number"
                                        {...field}
                                        className={`form-control ${
                                        contactFormErrors?.mobile ? "invalid" : ""
                                        }`}
                                    />
                                    )}
                                />
                                {contactFormErrors.mobile ? (
                                    <small className="text-danger">
                                    {contactFormErrors.mobile?.message}
                                    </small>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label>Address</label>
                                <Controller
                                    name="address"
                                    control={contactFormControl}
                                    render={({ field }) => (
                                    <input
                                        type="text"
                                        placeholder="Enter address"
                                        {...field}
                                        className={`form-control ${
                                        contactFormErrors?.address ? "invalid" : ""
                                        }`}
                                    />
                                    )}
                                />
                                {contactFormErrors.address ? (
                                    <small className="text-danger">
                                    {contactFormErrors.address?.message}
                                    </small>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label>Notes</label>
                                <Controller
                                    name="message"
                                    control={contactFormControl}
                                    render={({ field }) => (
                                    <textarea
                                        {...field}
                                        placeholder="Enter Notes"
                                        className={`form-control`}
                                    ></textarea>
                                    )}
                                />
                                {contactFormErrors.message ? (
                                    <small className="text-danger">
                                    {contactFormErrors.message?.message}
                                    </small>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <input type="checkbox" required/>
                                 Accept<NavLink to={"/termsAndConditions"} target="_blank"> Terms & Conditions</NavLink>
                            </div>
                            {message != "" ? (
                              <div className="mb-3">
                                <small className="text-danger">{message}</small>
                              </div>
                            ) : null}
                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <ToastContainer />
    </>
)
}
export default BookingBusiness;