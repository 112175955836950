import React, { useEffect, useState } from "react";

import { ttimg25,ttimg2,ttimg3,ttimg4,ttimg5,ttimg26,ttimg27,ttimg8,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTDuvetCleaning() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg26} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg25} alt="" />
                               </div>
                                <p>Big duvets?  No problem, duvets of all sizes can be cleaned and dried and returned to you the next day. We recommend regular cleaning to keep your duvets clean, fresh and free from allergens. Because of the warmth and moisture content, your duvet and pillows are the perfect habitat for dust mites, bed bugs and the bacteria and fungal spores they leave behind, which creates a serious need for regular duvet cleaning.</p>
                                <p>We advise people to clean their duvet and pillows at least every six months and much more regularly if anyone in the house suffers from asthma or allergies. By having them laundered you will ensure your duvet and pillows are refreshed and revived.</p>
                                <p>Our special offers for any size Duvet Laundered for only £9.99</p>                                
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg27} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTDuvetCleaning;