import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList } from "../../redux/Actions";
import SampleVideo from "../../images/sample.mp4";
import { data } from "../edit/data";
import {ttw35,ttw36,ttw37,ttw38} from "../../assets/img";

function FooterTweleve() {
  const navigate = useNavigate();
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const appStorel = settingsData?.WebmasterSettings?.app_store_link; 
  const playStore = settingsData?.WebmasterSettings?.play_store_link;
  const facebook = settingsData?.WebsiteSettings?.social_link1;
  const twitter = settingsData?.WebsiteSettings?.social_link2;
  const instagram = settingsData?.WebsiteSettings?.social_link6;
  const linkedin = settingsData?.WebsiteSettings?.social_link7;
    
  const dispatch = useDispatch<any>();

  const [areaCovered, setAreaCovered] = useState<any | null>({});

  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  // const homedata:any = data

  useEffect(() => {
    // if (Object.keys(settings).length === 0 && settingsData == undefined) {
    //   axios({
    //     method: "get",
    //     url: `${base_url}/api/settings`,
    //     headers: {
    //       Accept: "application/json",
    //       "Access-Control-Allow-Methods": "GET, POST",
    //     },
    //   })
    //     .then((response) => {
    //       if (response.status != 401) {
    //         setSettings(response.data.data);
    //         dispatch(setSettingsAll(response.data.data));
    //       }
    //     })
    //     .catch(function (error) {});
    // }

    const headers = {
      Accept: "application/json",
    };

    axios
      .get(`${base_url}/api/areas_covered`, {
        headers: headers,
      })
      .then((e) => {
        setAreaCovered({ data: e.data });
      });
  }, []);

  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",");

      return (
        <>
          {cityArr.map((city: any) => (
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dry-cleaners/" + city);
                }}
              >
                {city}
              </a>
            </li>
          ))}
        </>
      );
    });

    return test;
  };

  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="flogo">
                            <img src={ttw38} alt=""/>
                        </div>
                        <div className="fslinks">                            
                            <h6>Start Follow Us</h6>
                            <ul>
                                <li>
                                    <a href={facebook} target="_blank"><i className="fab fa-facebook"></i></a>
                                </li>
                                <li>
                                    <a href={twitter} target="_blank"><i className="fab fa-x-twitter"></i></a>
                                </li>
                                <li>
                                    <a href={linkedin} target="_blank"><i className="fab fa-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href={instagram} target="_blank"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div className="fapplinks">
                            <a href={playStore}><img src={ttw36} alt=""/></a>
                            <a href={appStorel}><img src={ttw37} alt=""/></a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="fplinks">
                            <h4>Quick Links</h4>
                            <ul>
                                <li><NavLink to="/about-us">About Us</NavLink></li>
                                <li><NavLink to="/shoe-cleaning">Services</NavLink></li>
                                <li><NavLink to="/prices">Prices</NavLink></li>
                                <li><NavLink to="/faq">FAQ</NavLink></li>
                                <li><NavLink to="/all-location">All Location</NavLink></li>
                                <li><a target="_blank" href={blogUrl}>Blog</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="fplinks">
                            <h4>Join our newsletter today</h4>
                            <div className="fnewsletter">
                                <p>Stay informed and inspired</p>
                                <div className="finpgroup">
                                    <input type="text" placeholder="Enter your e-mail Address" />
                                    <button><img src={ttw35} alt=""/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fbottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="fbottomrights">
                                <p>
                                    All rights reserved © {Object.keys(settings).length > 0 && settings?.WebsiteSettings?.site_title_en} {year}. Developed by <a href="http://www.bestatservices.com" target="_blank">Bestatservices</a>.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="fbottomlinks">
                                <ul>
                                    <li>
                                      <NavLink to="/terms-conditions">Terms & Conditions</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                                    </li>
                                    {/* <li>
                                      <NavLink to="/cookie-policy">Cookie Policy</NavLink>
                                    </li> */}
                                    <li>
                                      <a target="_blank" href="https://hellolaundry.co.uk/">Our Partner</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  );
}

export default FooterTweleve;
