import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { ro1,ro2,ro3,ro4,ro5,ro6,ro7,ro8,ro9,ro10,ro11,ro12,ro13,ro14,ro15} from '../../assets/img'
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";

function Home () {
    const base_url = process.env.REACT_APP_BACKEND_URL;
    const jsonData: any = useSelector<any>((state) => state?.homeJsonList);

    const [blogList, setBlogList] = useState<any | null>({});
    useEffect(() => {
        const headers = {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        };

        axios
        .get(`${base_url}/api/blog_list?`, {
            headers: headers,
        })
        .then((e) => {
            setBlogList(e.data.data);
            // e.data.data.category.slice(0, 1).map((category: any) =>
            //     setActive(category.main_category_name)
            // )
        });
    }, []);

    const Url = window.location.host;
    const blogUrl = `https://blog.${Url}`;
return (
    <>
        <div className="container">
            <div className="row py-5">
                <div className="col-md-12 text-center mb-5">
                    <h2 id="services-section" className="h2sec">Our Services</h2>
                    <p className="descsec">The Premier Launderer And Dry Cleaner For Discerning Clients In The London Area</p>
                </div>
                <div className="col-md-4">
                    <div className="d-flex flex-column align-items-start justify-content-center h-100">
                        <h2 className="h3sec mb-3">Dresses</h2>
                        <p className="mb-3">Silk, linen, cashmere, we can clean anything with a “dry clean only” label. Our process combines artisan care with eco technologies for a high-quality result.</p>
                        <a href="" className="btn btn-primary">Book now</a>
                    </div>
                </div>
                <div className="col-md-8">
                    <img src={ro2} alt="" />
                </div>
            </div>
            <div className="row py-5">                
                <div className="col-md-8">
                    <img src={ro3} alt="" />
                </div>
                <div className="col-md-4">
                    <div className="d-flex flex-column align-items-start justify-content-center h-100">
                        <h2 className="h3sec mb-3">Alterations & Repairs</h2>
                        <p className="mb-3">Need to re-stitch buttons, shorten trousers, or adjust the length of a jacket? Our convenient alterations service will cater to your every need.</p>
                        <a href="" className="btn btn-primary">Book now</a>
                    </div>
                </div>
            </div>
            <div className="row py-5">
                <div className="col-md-4">
                    <div className="d-flex flex-column align-items-start justify-content-center h-100">
                        <h2 className="h3sec mb-3">Shoe Repairs</h2>
                        <p className="mb-3">From jackets and coats to bags and shoes, we offer a specialist service to clean, repair and restore your items in leather, suede, wax and fur.</p>
                        <a href="" className="btn btn-primary">Book now</a>
                    </div>
                </div>
                <div className="col-md-8">
                    <img src={ro13} alt="" />
                </div>
            </div>
            <div className="row py-5">
                <div className="col-md-8">
                    <div className="d-flex flex-column align-items-start justify-content-center h-100 trust-section2">
                        <h2 className="h3sec mb-4">Trust the Experts</h2>
                        <p className="mb-3">Rogers of Pimlico aim has always been to provide 5 star premium dry cleaning and laundry services to expat and local populations of Dubai and the United Arab Emirates.</p>
                        <h4 className="h4sec mb-3">Unique solutions for all your laundry needs</h4>
                        <ul>
                            <li>
                                <b>Clean</b> for professional garment care
                            </li>
                            <li>
                                <b>Wash & Fold</b> for items that don't need pressing
                            </li>
                            <li>
                                <b>Press Only</b> for clean clothes that need pressing
                            </li>
                            <li>
                                <b>ShoeCare</b> for cleaned and restored shoes
                            </li>
                        </ul>
                        <a href="" className="btn btn-primary">More about Rogers of Pimlico</a>
                    </div>
                </div>
                <div className="col-md-4">
                    <img src={ro4} alt="" />
                </div>
            </div>
        </div>
        <section className="rosec-bg">
            <div className="container">
                <div className="row">                
                    <div className="col-md-6">
                        <div className="imglistmob">
                            <img src={ro5} alt="" className="mobsec1"/>
                            <img src={ro6} alt="" className="mobsec2"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-column align-items-center text-center justify-content-center h-100">
                            <h2 className="h2sec">Just a Click Away</h2>
                            <p>Our apps got a better and convenient way of getting your laundry and dry cleaning with our 5-star services. Download now and register to get more exclusive offers</p>
                            <ul className="romoblist">
                                <li>
                                    <a target="_blank" href="https://apps.apple.com/gb/app/rogers-of-pimlico/id6470026113"><img src={ro14} alt="" /></a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dreamguys.rogers"><img src={ro15} alt="" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container">    
            <div className="row py-5">                
                <div className="col-md-12 text-center">
                    <h2 className="h2sec">Customer Speak</h2>
                    {/* <p className="descsec">Lorem ipsum dolor sit amet consectetur adipiscing elit</p> */}
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-md-4">
                    <div className="cardro1 text-center">
                        {/* <div className="cardro1img">
                            <img src={ro7} alt="" />
                        </div> */}
                        <h4>Zoë Rice</h4>
                        <div>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                        </div>
                        <p className="cardro1p1">I needed to urgently fix a dress and the team at Rogers of Pimlico could not have been more helpful. They fixed my dress both rapidly and perfectly. I have also used Rogers for dry cleaning and their quality was great. Would highly recommend.<br /><br /></p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="cardro1 text-center">
                        {/* <div className="cardro1img">
                            <img src={ro8} alt="" />
                        </div> */}
                        <h4>Hannah Cardiff</h4>
                        <div>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                        </div>
                        <p className="cardro1p1">Really professional and quick alterations service. I needed a tricky alteration to the back of a dress and it was done very well, the dress fits perfectly now.  Adam was very communicative and I would definitely come back here!<br /><br /><br /></p>
                        {/* <p className="cardro1p2">UK</p> */}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="cardro1 text-center">
                        {/* <div className="cardro1img">
                            <img src={ro9} alt="" />
                        </div> */}
                        <h4>Darya</h4>
                        <div>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                        </div>
                        <p className="cardro1p1">Highly recommend, helped me twice without charge when I a sure another dry cleaners would have charged me for no reason when it was a 1 min fix . I appreciate the honesty and the excellent  kind service they provide. I live 20mins away and I specifically drove here. Will definitely be my regular  Thank you Adam x</p>
                        {/* <p className="cardro1p2">UK</p> */}
                    </div>
                </div>
            </div>
            <div className="row pt-5">                
                <div className="col-md-12 text-center mb-5">
                    <h2 className="h2sec">Frequently Asked Questions</h2>
                    {/* <p className="descsec">Lorem ipsum dolor sit amet consectetur adipiscing elit</p> */}
                </div>                
            </div>
            <div className="row pb-5 justify-content-center">
                <div className="col-md-6">
                    <div className="rofaq">
                        <Accordion className="faq-home">
                        {jsonData?.theme_1?.home?.section_9?.card_section?.collection &&
                            Object.keys(jsonData.theme_1?.home?.section_9?.card_section?.collection).map(
                            (key) => (
                            <Accordion.Item eventKey={key}>
                                <Accordion.Header>
                                {jsonData.theme_1?.home?.section_9?.card_section?.collection[key].collection_title}
                        
                                </Accordion.Header>
                                <Accordion.Body>
                                {jsonData.theme_1?.home?.section_9?.card_section?.collection[key].collection_paragraph}

            
                                </Accordion.Body>
                            </Accordion.Item>
                            )
                            )}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
        {blogList.length > 0 && (
            <section className="rowsec-bg2">
                <div className="container">    
                    <div className="row py-5">                
                        <div className="col-md-12 text-center mb-5">
                            <h2 className="h2sec">Latest Blogs</h2>
                            <p className="descsec"></p>
                        </div>
                        {blogList.length > 0 && (
                            <div className="col-md-4">
                                <div className="cardro2">
                                    <img src={ro10} alt="" />
                                    <div className="cardro2-grid">                                
                                        <div className="cardro2-gridflex">
                                            <span><i className="fas fa-tag"></i>{blogList[0].page_title.substring(0, 65)} {blogList[0].page_title.length > 65 ? "..." : ""}</span>
                                            <span><i className="far fa-calendar"></i>{blogList[0].formatted_date} {blogList[0].formatted_month}</span>
                                        </div>
                                        <h4>{blogList[0].preview_text.substring(0, 140)} {blogList[0].preview_text.length > 140 ? "..." : ""}</h4>
                                        <a href={`${blogUrl}/blogg/${blogList[0].id}/${blogList[0].url_slug}`}>Read More <i className="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                        )}
                        {blogList.length > 1 && (
                            <div className="col-md-4">
                                <div className="cardro2">
                                    <img src={ro11} alt="" />
                                    <div className="cardro2-grid">                                
                                        <div className="cardro2-gridflex">
                                            <span><i className="fas fa-tag"></i>{blogList[1].page_title.substring(0, 65)} {blogList[1].page_title.length > 65 ? "..." : ""}</span>
                                            <span><i className="far fa-calendar"></i>{blogList[1].formatted_date} {blogList[1].formatted_month}</span>
                                        </div>
                                        <h4>{blogList[1].preview_text.substring(0, 140)} {blogList[1].preview_text.length > 140 ? "..." : ""}</h4>
                                        <a href={`${blogUrl}/blogg/${blogList[1].id}/${blogList[1].url_slug}`}>Read More <i className="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                        )}
                        {blogList.length > 2 && (
                            <div className="col-md-4">
                                <div className="cardro2">
                                    <img src={ro12} alt="" />
                                    <div className="cardro2-grid">                                
                                        <div className="cardro2-gridflex">
                                            <span><i className="fas fa-tag"></i>{blogList[2].page_title.substring(0, 65)} {blogList[2].page_title.length > 65 ? "..." : ""}</span>
                                            <span><i className="far fa-calendar"></i>{blogList[2].formatted_date} {blogList[2].formatted_month}</span>
                                        </div>
                                        <h4>{blogList[2].preview_text.substring(0, 140)} {blogList[2].preview_text.length > 140 ? "..." : ""}</h4>
                                        <a href={`${blogUrl}/blogg/${blogList[2].id}/${blogList[2].url_slug}`}>Read More <i className="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        )}
    </>
)
}
export default Home;