import React, { useEffect, useState } from "react";

import { ttimg28,ttimg2,ttimg3,ttimg4,ttimg5,ttimg29,ttimg30,ttimg31,ttimg9,ttimg10,ttimg11,ttimg12,ttimg13,ttimg14,ttimg15,ttimg16,ttimg17,ttimg18,ttimg19,ttimg20,} from "../../assets/img";
import TTSidebar from "./ttSideBar";
function TTBedLinenCleaning() {
    return (
        <>
            <div className="tt-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <TTSidebar/>
                            <div className="tt-img">
                                <img src={ttimg29} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="tt-grid">
                                <div className="tt-img">
                                    <img src={ttimg28} alt="" />
                               </div>
                                <p>At T & T Dry Cleaners we take pride in the quality of our laundering of your bed linen. Every care is taken at each step of our process to ensure your laundry is beautifully finished prior to being returned to you in neat white parcels.</p>
                                <p>We can also provide bed linen service include care of:</p>
                                <ul>
                                    <li>bath linen</li>
                                    <li>bath towel</li>
                                    <li>hand towel</li>
                                    <li>bath mat</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tt-grid">
                               <div className="tt-img">
                                    <img src={ttimg2} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg30} alt="" />
                               </div>
                               <div className="tt-img">
                                    <img src={ttimg31} alt="" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TTBedLinenCleaning;