import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./header.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import "./header.css";
import Notification from "./../notification";
import Profile from "../profile";
import QuickOrderPopup from "../quickOrderPopup";
import CartSummary from "../cartSummery";
import { useSelector } from "react-redux/es/exports";
import { log } from "console";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { ToastContainer } from "react-toastify";
import Pusher from "pusher-js";
import {
  mobilemenu,
  moreitems,
  quickorder,
  myprofile,
  logout,
  wonderwashLogo,
} from "../../assets/img";
import ReactGA from "react-ga";
import { checkShopListFlow } from "../../utility";
import TagManager from 'react-gtm-module';

// const TRACKING_ID = "UA-265690492-2"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
TagManager.initialize({ gtmId: 'GTM-PD5VHQN' });

const getUser = () => {
  const token: any = localStorage.getItem("userDetails");
  return token ? JSON.parse(token) : "";
};


function HeaderFive() {
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [changePopup, setChangePopup] = useState(false);
  const [changePopupProfile, setChangePopupProfile] = useState(false);
  const [quickBookPopup, setQuickBookPopup] = useState(false);
  const [cartSummary, setCartSummary] = useState(false);
  const [userName, setUserName] = useState<any>("");
  const [userToken, setUserToken] = useState();

  const [cartValue, setCartValue] = useState<any>([]);
  const [pusherKey, setpusherKey] = useState<any>("");
  const state: any = useSelector<any>((state) => state);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const { cartCount }: any = useSelector<any>((state) => state);
  const [notifyCount, setNotifyCount] = useState<any>([]);
  const [cartCounts, setCartCount] = useState<number>(0);
  const [userDetails, setUserDetails] = useState<number>(0);
  const name = localStorage.getItem("username");
  //

  // useEffect(()=>{
  //   const cartInformationData = selectedCategories?.filter((item: any) => {
  //     return item?.sub_categories?.some(
  //       (subItem: any) => parseInt(subItem?.quantity) > 0
  //     );
  //   });
  //   setCartValue(cartInformationData);
  // },[selectedCategories])

  // useEffect(() => {
  //   if (state) {
  //     // const { data = { data: {} } } = state.userDetails;
  //     const data = JSON.parse(localStorage.getItem('userDetails')!);

  //     if (data) {
  //       const { user_info = {} } = data;
  //       const { name = "" } = user_info;
  //
  //       // const token = getToken();
  //       setUserToken(name);
  //       setUserName(name);
  //     }
  //   }
  // }, [state]);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    analytics(window, document, 'script', 'dataLayer', 'GTM-PD5VHQN');
  }, [window.location.href]);

  const selectedCategories: any = useSelector<any>(
    (state) => state.userSelectedCategories
  );
  const notificationList: any = useSelector<any>(
    (state) => state?.all_notification
  );
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  const shopurl = 'https://bestatlaundry.com/laundry'
  const activeUrl = window.location.pathname;
  const settings: any = useSelector<any>((state) => state?.settings);

  useEffect(() => {
    if (state.userDetails.data) {
      const user_info = state.userDetails.data?.user_info;
      const { name = "" } = user_info;
      setUserToken(name);
      setUserName(name);
    }
    const selectedMainCategory = selectedCategories?.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });

    let finalTotalPrice = 0;
    let itemsCount = 0;

    if (selectedMainCategory.length) {
      selectedMainCategory?.map((item: any) => {
        const subcategoryList = item?.sub_categories?.filter(
          (subItem: any) => parseInt(subItem.quantity) > 0
        );
        itemsCount = itemsCount + subcategoryList?.length;
        subcategoryList?.map((subcategory: any) => {
          finalTotalPrice =
            finalTotalPrice +
            parseInt(subcategory?.quantity) *
              parseFloat(subcategory?.total_price);
        });
      });

      // cartInformationData?.map((item: any) => {
      //   const subcategoryList = item?.sub_categories?.filter(
      //     (subItem: any) => parseInt(subItem.quantity) > 0);
      // });
    }
    setCartCount(itemsCount);
  }, [state,userDetails]);

  useEffect(() => {
      const userLogged = localStorage.getItem("userDetails")
      if (userLogged) {
        const userDetailsObject = JSON.parse(userLogged);
        setUserDetails(userDetailsObject);
        setUserToken(userDetailsObject?.data?.user_info?.name);
        setUserName(userDetailsObject?.data?.user_info?.name);
      }
  }, []);

  const navigate = useNavigate();
  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };
  useEffect(() => {
    AOS.init();
    // AOS.refresh();
  }, []);

  const [sticky, setSticky] = useState("");

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 100 ? "fixed" : "";
    setSticky(stickyClass);
  };

  const fixedheader = `header ${sticky}`;
  function addNotify() {
    setNotifyCount((prevState: any) => [...prevState, "1"]);
  }

  let oldNotify = "";

  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      const userInfo = JSON.parse(localStorage.getItem("userDetails")!);

      const { data } = userInfo;

      if (data != null) {
        // const notifyLocalCount = localStorage.getItem('notifyCount');

        const interest = data?.user_info?.notification_interest_list;

        if (interest[1] != undefined) {
          const intrst = interest[1];
          var pusher = new Pusher(pusherKey, {
            cluster: "eu",
          });

          var channel = pusher.subscribe(intrst);
          channel.bind("my-event", function (datas: any) {
            let event = datas.order;
            // alert(event.title);
            // toast.dismiss();

            // setNotifyCount(notifyCount);

            // var newArray = notifyCount.concat('1');

            //

            // const rand = Math.random();

            // setNotifyCount(notifyCount.concat(rand));
            // setNotifyCount((prevState: any) => ([...prevState, '1']))

            if (oldNotify != event.order_id) {
              addNotify();
              oldNotify = event.order_id;
            }

            // localStorage.setItem('notifyCount', `${parseInt(notifyLocalCount!) + 1}`);

            // setNotifyCount(notifyCount + 1);
            // toast(<div>{event.title}<br />{event.body}</div>, { autoClose: false });
          });
        }
      }

      if (data) {
        const user_info = data?.user_info;
        // const { name = "" } = user_info;
        // // const token = getToken();
        // setUserToken(name);
        // setUserName(name);
      }

      // setUserToken(token);
    }
    let handler = (event: { target: Node | null }) => {
      if (menuRef.current) {
        if (!menuRef.current.contains(event.target)) {
          setChangePopupProfile(false);
          setCartSummary(false);
        }
      }
    };
    document.addEventListener("mousedown", function name(params: any) {
      handler(params);
    });
    document.addEventListener("scroll", function name() {
      // AOS.init();
      AOS.refresh();
    });
  }, []);

  const location = useLocation();
  let newurl = location.pathname;
  let splitURL: any = newurl.toString().split("/");

  useEffect(() => {
    sidebarClose();
    cartboxClose();
  }, [location]);

  var sidebar = document.getElementById("sidebar");
  var overlay = document.getElementById("overlay");
  var cartoverlay = document.getElementById("cd-shadow-layer");
  var cdcart = document.getElementById("cd-cart");

  const cartboxClose = () => {
    cdcart?.classList.remove("speed-in");
    cartoverlay?.classList.remove("is-visible");
  };
  const cartboxOpen = () => {
    cdcart?.classList.add("speed-in");
    cartoverlay?.classList.add("is-visible");
  };

  const sidebarClose = () => {
    sidebar?.classList.remove("active");
    overlay?.classList.remove("active");
  };
  const sidebarOpen = () => {
    sidebar?.classList.add("active");
    overlay?.classList.add("active");
  };

  function openQuickOrderPopup() {
    setQuickBookPopup(!quickBookPopup);
    localStorage.setItem("disableLoader", "yes");
  }
  function closeQuickOrderPopup() {
    setQuickBookPopup(false);
    localStorage.removeItem("disableLoader");
  }

  const getNotificationLength = () => {
    let count = [];
    if (notificationList.length > 0) {
      count = notificationList.filter((item: any) => {
        return item.read === "0";
      });
    }
    return count.length;
  };

  return (
    <div>
      {homedata && (
        <style type="text/css">
          {` 
            :root {
              --primary-color: #${homedata?.theme_5?.home?.header?.primary_color};
              --secondary-color: #${homedata?.theme_5?.home?.header?.secondary_color};
              --nav-color: #${homedata?.theme_5?.home?.header?.nav?.menu_font_color};
              --nav-color-hover: #${homedata?.theme_5?.home?.header?.nav?.menu_font_color_hover};
              --nav-color-active: #${homedata?.theme_5?.home?.header?.nav?.menu_font_color_active};
          }
          `}
        </style>
      )}
      <header
        className={fixedheader}
        style={{
          background: `#${homedata?.theme_5?.home?.header?.nav?.bg_color}`,
        }}
      >
        <div className="header-top">
          <div className="container">
            <div className="row header-nav">
              <div className="col-md-3 col-sm-12">
                <div className="logo">
                  <span
                    id="sidebarCollapse"
                    className="d-block d-md-none"
                    onClick={sidebarOpen}
                  >
                    <img src={mobilemenu} className="img-fluid" alt="" />
                  </span>

                  {/* {Object.keys(settings).length > 0 ? (
                    <Link to="/">
                      <img
                        id="site_logo"
                        src={`${
                          Object.keys(settings).length > 0 &&
                          settings.WebsiteSettings.logo_path
                        }${
                          Object.keys(settings).length > 0 &&
                          settings.WebsiteSettings.style_logo_en
                        }`}
                        // src={`${Object.keys(settings).length > 0 && settings.WebsiteSettings.style_logo_en}`}
                        alt="setting logo"
                      />
                    </Link>
                  ) : (
                    <Link to="/">
                      <img
                        id="site_logo"
                        src={wonderwashLogo}
                        // src={`${Object.keys(settings).length > 0 && settings.WebsiteSettings.style_logo_en}`}
                        alt="setting logo"
                      />
                    </Link>
                  )} */}
                  <Link to="/">
                    <img
                      id="site_logo"
                      src={`${settings?.WebsiteSettings?.logo_path}${settings?.WebsiteSettings?.style_logo_en}`}
                      alt=""
                    />
                  </Link>
                  <span className="mob-user hidden-md dropdown d-block d-md-none">
                  <a className="more-items"
                        data-bs-toggle="dropdown"
                        href="#"
                      ></a>
                    <ul
                      role="menu"
                      className="dropdown-menu"
                      aria-labelledby="drop1"
                    >
                    {settings?.quick_order_mode != 0 &&
                      <li>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#quickordermodal"
                        >
                          <img
                            src={quickorder}
                            className="img-fluid"
                            alt="Quick Order"
                          />
                          Quick Order
                        </a>
                      </li>
                    }
                      {!userToken && (
                        <li className="login">
                          <Link to="/login">
                            <img
                              src={myprofile}
                              className="img-fluid"
                              alt="login"
                            />
                            Login
                          </Link>
                        </li>
                      )}
                      {userToken && (
                        <li className="dropdown menu-hover-dropdown">
                          <a
                            className="menuitem"
                            href="#"
                            onClick={(e) => handleLogout(e)}
                          >
                            <img
                              src={logout}
                              className="img-fluid"
                              alt="logout"
                            />
                            Log Out
                          </a>
                        </li>
                      )}
                    </ul>
                  </span>
                </div>
              </div>
              <div className="col-md-9 col-sm-12" ref={menuRef}>
                <ul className="header-link float-end d-none d-md-flex mb-0">
                  <li className="menu-hover">
                    {activeUrl === "/" ? (
                      <NavLink className="nav-bar-link active" to="/">
                        Home
                      </NavLink>
                    ) : (
                      <NavLink className="nav-bar-link" to="/">
                        Home
                      </NavLink>
                    )}
                  </li>
                  {!checkShopListFlow() && 
                  <li className="menu-hover">
                    {activeUrl === "/pricing" ? (
                      <NavLink className="nav-bar-link active" to="/pricing">
                        Pricing
                      </NavLink>
                    ) : (
                      <NavLink className="nav-bar-link" to="/pricing">
                        Pricing
                      </NavLink>
                    )}
                  </li>
                  }
                  <li className="menu-hover">
                    {activeUrl === "/areaCovered" ? (
                      <NavLink
                        className="nav-bar-link active"
                        to="/areaCovered"
                      >
                        Area Covered
                      </NavLink>
                    ) : (
                      <NavLink className="nav-bar-link" to="/areaCovered">
                        Area Covered
                      </NavLink>
                    )}
                  </li>
                  <li className="faq-menu menu-hover">
                    {activeUrl === "/faq" ? (
                      <NavLink className="nav-bar-link active" to="/faq">
                        FAQ
                      </NavLink>
                    ) : (
                      <NavLink className="nav-bar-link" to="/faq">
                        FAQ
                      </NavLink>
                    )}
                  </li>
                  <li className="faq-menu menu-hover">
                    <a target="_blank" href={blogUrl}>
                      Blog
                    </a>
                  </li>
                  {checkShopListFlow() &&
                      <li className="menu-hover">
                        <a target="_blank" href={shopurl} className="signup-m">List Your Laundry</a>
                      </li>
                    }
                    {/* {!checkShopListFlow() &&  */}
                    <>
                  {!userToken && (
                    <>
                      <li className="menu-hover">
                        <Link
                          to="/login"
                          style={{
                            background: `#${homedata?.theme_5?.home?.header?.btn_group?.btn1_bg_color}`,
                            color: `#${homedata?.theme_5?.home?.header?.btn_group?.btn1_color}`,
                            borderColor: `#${homedata?.theme_5?.home?.header?.btn_group?.btn1_border_color}`,
                          }}
                          className="login-m"
                        >
                          {
                            homedata?.theme_5?.home?.header?.btn_group
                              ?.btn1_text
                          }
                        </Link>
                      </li>
                      <li className="menu-hover">
                        <Link
                          to="/signUp"
                          style={{
                            background: `#${homedata?.theme_5?.home?.header?.btn_group?.btn2_bg_color}`,
                            color: `#${homedata?.theme_5?.home?.header?.btn_group?.btn2_color}`,
                            borderColor: `#${homedata?.theme_5?.home?.header?.btn_group?.btn2_border_color}`,
                          }}
                          className="signup-m"
                        >
                          {
                            homedata?.theme_5?.home?.header?.btn_group
                              ?.btn2_text
                          }
                        </Link>
                      </li>
                    </>
                  )}
                  </>
                      {/* } */}
                  {userToken && (
                    <li className="dropdown menu-hover-dropdown login-link cart-list dropdown-notification">
                      <Link
                        to="#"
                        id="drop1"
                        data-bs-toggle="dropdown"
                        className="dropdown-toggle"
                      >
                        <i
                          className="fa fa-bell"
                          aria-hidden="true"
                          onClick={() => setChangePopup(!changePopup)}
                        ></i>
                        {/* <span
                          id="notify_total_count"
                          className="count-blk badge rounded-pill badge-warning"
                        >
                          {getNotificationLength()}
                        </span> */}
                      </Link>
                      <div className="dropdown-menu">
                        <Notification />
                      </div>
                    </li>
                  )}

                  {userToken && (
                    <li className="dropdown menu-hover-dropdown">
                      <Link
                        to="#"
                        id="drop1"
                        data-bs-toggle="dropdown"
                        className="dropdown-toggle"
                        role="button"
                        onClick={() =>
                          setChangePopupProfile(!changePopupProfile)
                        }
                      >
                        {`Hi ${name || userName}!`}
                        <b className="caret"></b>
                      </Link>
                      <div className="dropdown-menu">{<Profile />}</div>
                    </li>
                  )}
                  {!checkShopListFlow() && 
                    <>
                  {activeUrl != "/checkout" && (
                    <>
                    {settings?.quick_order_mode != 0 &&
                    <li
                      className="login menu-hover"
                    >
                      {/* <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#quickordermodal"
                      >
                        Quick Order
                      </a> */}
                      <Link
                        to="#"
                        className="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#quickordermodal"
                        style={{
                          background: `#${homedata?.theme_5?.home?.header?.btn_group?.btn3_bg_color}`,
                          color: `#${homedata?.theme_5?.home?.header?.btn_group?.btn3_color}`,
                          borderColor: `#${homedata?.theme_5?.home?.header?.btn_group?.btn3_border_color}`,
                        }}
                      >
                        {homedata?.theme_5?.home?.header?.btn_group?.btn3_text}
                      </Link>
                    </li>
                    }
                    </>
                  )}
                  </>
                  }
                  {activeUrl != "/checkout" && (
                    <li className="cart-list" onClick={cartboxOpen}>
                      <a
                        className="cart-blk cart-btn"
                        href="javascript:;"
                        id="toggle-cart"
                      >
                        <i
                          className="fa fa-shopping-cart align-middle"
                          aria-hidden="true"
                          style={{
                            color: `#${homedata?.theme_5?.home?.header?.nav?.cart_section?.color}`,
                          }}
                        ></i>
                        <span
                          id="cart_total_count"
                          className="count-blk badge rounded-pill badge-warning"
                          style={{
                            color: `#${homedata?.theme_5?.home?.header?.nav?.cart_section?.color}`,
                            background: `#${homedata?.theme_5?.home?.header?.nav?.cart_section?.cart_value_color}`,
                          }}
                        >
                          {cartCounts}
                        </span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <nav
        id="sidebar"
        className="mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar"
      >
        <div
          id="mCSB_1"
          className="mCustomScrollBox mCS-minimal mCSB_vertical mCSB_outside"
        >
          <div
            id="mCSB_1_container"
            className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
            dir="ltr"
          >
            <div id="dismiss" onClick={sidebarClose}>
              <i className="fa fa-arrow-left"></i>
            </div>
            <div className="sidebar-header"></div>
            <ul className="list-unstyled components">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/areaCovered">Area Covered</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              {checkShopListFlow() && splitURL.length < 3 &&
                <li className="btnmoblist">
                  <a target="_blank" href={shopurl} className="btn">List Your Laundry</a>
                </li>
              }
            </ul>
          </div>
        </div>
        <div
          id="mCSB_1_scrollbar_vertical"
          className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal mCSB_scrollTools_vertical d-none"
        >
          <div className="mCSB_draggerContainer">
            <div id="mCSB_1_dragger_vertical" className="mCSB_dragger">
              <div className="mCSB_dragger_bar"></div>
            </div>
            <div className="mCSB_draggerRail"></div>
          </div>
        </div>
      </nav>
      <div id="overlay" onClick={sidebarClose}></div>
      <div
        className="modal fade"
        id="quickordermodal"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <QuickOrderPopup close={closeQuickOrderPopup} />
          </div>
        </div>
      </div>
      <div id="cd-shadow-layer" onClick={cartboxClose}></div>
      {<CartSummary />}
    </div>
  );
}

export default HeaderFive;

export const analytics = ((w: Window, d: Document, s: string, l: string, i: string) => {
  (w as any).dataLayer = (window as any).dataLayer || [];
  (w as any).dataLayer.push({'gtm.start':new Date().getTime(),event:'gtm.js'});
  var dl = l != 'dataLayer' ? '&l='+l : '';
  var scr = 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
  /*
      To avoid Multiple installations of google tag manager detected warning
  */
      if(!scriptExists(scr)){
          var f = d.getElementsByTagName(s)[0],
              j: HTMLScriptElement = d.createElement("script")
              j.async = true;
              j.src = scr;
              f?.parentNode?.insertBefore(j,f);
      }
  })
  const scriptExists = (url: string) => {
      var scripts = document.getElementsByTagName('script');
      for (var i = scripts.length; i--;) {
        if (scripts[i].src == url) return true;
      }
      return false;
  }
