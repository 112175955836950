import { ColorPicker } from "primereact/colorpicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { fetchHomeJsonList, saveThemeJsonData, fetchCroppedImage } from "../../../redux/Actions";
import ImageCropper from "../../imageCropper/imageCropper";

function SectionNineBannerThemeThree() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const [secondActiveTab, setSecondActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [showCropModalTwo, setShowCropModalTwo] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const icons = [
    "assets/home/priorityimg1.jpg",
    "assets/home/priorityimg2.jpg",
    "assets/home/priorityimg3.jpg",
    "assets/home/priorityimg1.jpg",
    "assets/home/priorityimg2.jpg",
    "assets/home/priorityimg3.jpg",
    "assets/home/priorityimg1.jpg",
    "assets/home/priorityimg2.jpg",
    "assets/home/priorityimg3.jpg",
  ];
  const countIcons = [
    "assets/icons/priorityicon1.png",
    "assets/icons/priorityicon2.png",
    "assets/icons/priorityicon3.png",
    "assets/icons/priorityicon4.png",
    "assets/icons/priorityicon5.png",
    "assets/icons/priorityicon6.png",
    "assets/icons/priorityicon7.png",
    "assets/icons/priorityicon8.png",
    "assets/icons/priorityicon9.png",
    "assets/icons/priorityicon10.png",
    "assets/icons/priorityicon11.png",
    "assets/icons/priorityicon12.png",
    "assets/icons/priorityicon13.png",
    "assets/icons/priorityicon14.png",
    "assets/icons/priorityicon15.png",
    "assets/icons/priorityicon16.png",
    "assets/icons/priorityicon17.png",
  ];

  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150")
    // setCropImageWidth("150")
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  const updateImageSectionTwo = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("50")
    // setCropImageWidth("50")
    // setSelectedCropImage(image);
    // setShowCropModalTwo(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImageTwo(res.target.result);
      };
    }
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if(imageLink != false){
      handleImageChange(imageLink,activeTab)
    }
  };

  const handleCroppedImageTwo = async (image: any) => {
    setShowCropModalTwo(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if(imageLink != false){
      counthandleImageChange(imageLink,secondActiveTab)
    }
  };

  const addCollection = () => {
    let updatedCollection: any = [
      ...editData.theme_3.home.section_9.card_section.collection,
    ];

    let newCard = {
      icon: "assets/home/priorityimg1.jpg",
      collection_title: "",
      collection_paragraph: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
      ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_9: {
          ...prevJsonData.theme_3.home.section_9,
          card_section: {
            ...prevJsonData.theme_3.home.section_9.card_section,
            collection: updatedCollection,
          },
        },
      },
    },
    }));
  };
  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_3.home.section_9.card_section.collection,
    ];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_9: {
          ...prevJsonData.theme_3.home.section_9,
          card_section: {
            ...prevJsonData.theme_3.home.section_9.card_section,
            collection: updatedCollection,
          },
        },
      },
    },
    }));
  };

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    let updatedCollection: any = [
      ...editData.theme_3.home.section_9.card_section.collection,
    ];
    if (index != undefined) {
      updatedCollection = [...editData.theme_3.home.section_9.card_section.collection];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_9: {
          ...prevJsonData.theme_3.home.section_9,
          [name]: value,
          card_section: {
            ...prevJsonData.theme_3.home.section_9.card_section,
            collection: updatedCollection,
          },
        },
      },
    },
    }));
  };

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [
      ...editData.theme_3.home.section_9.card_section.collection,
    ];
    updatedCollection[index] = {
      ...updatedCollection[index],
      icon: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_9: {
          ...prevJsonData.theme_3.home.section_9,
          card_section: {
            ...prevJsonData.theme_3.home.section_9.card_section,
            collection: updatedCollection,
          },
        },
      },
    },
    }));
  };

  const countAddCollection = () => {
    let updatedCount: any = [
      ...editData.theme_3.home.section_9.card_section.count_section,
    ];

    let newCard = {
      icon: "assets/icons/priorityicon1.png",
      text: "",
      count: "",
    };
    updatedCount.splice(secondActiveTab + 1, 0, newCard);
    setSecondActiveTab(secondActiveTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_9: {
          ...prevJsonData.theme_3.home.section_9,
          card_section: {
            ...prevJsonData.theme_3.home.section_9.card_section,
            count_section: updatedCount,
          },
        },
      },
    },
    }));
  };
  const countRemoveCollection = (index: any) => {
    let updatedCount: any = [
      ...editData.theme_3.home.section_9.card_section.count_section,
    ];
    updatedCount.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_9: {
          ...prevJsonData.theme_3.home.section_9,
          card_section: {
            ...prevJsonData.theme_3.home.section_9.card_section,
            count_section: updatedCount,
          },
        },
      },
    },
    }));
  };

  const counthandleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    let updatedCount: any = [
      ...editData.theme_3.home.section_9.card_section.count_section,
    ];
    if (index != undefined) {
      updatedCount = [...editData.theme_3.home.section_9.card_section.count_section];
      updatedCount[index] = {
        ...updatedCount[index],
        [name]: value,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_9: {
          ...prevJsonData.theme_3.home.section_9.card_section,
          [name]: value,
          card_section: {
            ...prevJsonData.theme_3.home.section_9.card_section,
            count_section: updatedCount,
          },
        },
      },
    },
    }));
  };

  const counthandleImageChange = (image: string, index: any) => {
    const updatedCollection = [
      ...editData.theme_3.home.section_9.card_section.count_section,
    ];
    updatedCollection[index] = {
      ...updatedCollection[index],
      icon: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_3: {
        ...prevJsonData.theme_3,
      home: {
        ...prevJsonData.theme_3.home,
        section_9: {
          ...prevJsonData.theme_3.home.section_9,
          card_section: {
            ...prevJsonData.theme_3.home.section_9.card_section,
            count_section: updatedCollection,
          },
        },
      },
    },
    }));
  };

  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section9 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              {/* <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="sub_heading_top"
                        value={editData?.theme_3?.home?.section_9?.sub_heading_top}
                        onChange={handleChange}
                        maxLength={250}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_3?.home?.section_9?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={editData?.theme_3?.home?.section_9?.paragraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_3?.home?.section_9?.h2color
                        }
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_3?.home?.section_9?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_3?.home?.section_9?.h3color
                        }
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_3?.home?.section_9?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_3?.home?.section_9?.paracolor
                        }
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_3?.home?.section_9?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="modal-title">Card Section 1</h5>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_3?.home?.section_9?.card_section?.collection?.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Card Section {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_3?.home?.section_9?.card_section?.collection?.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.icon}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {icons.map((icons, iconIndex) => (
                                <SwiperSlide key={iconIndex}>
                                  <div
                                    className="img-option"
                                    onClick={() =>
                                      handleImageChange(icons, index)
                                    }
                                  >
                                    <img
                                      src={icons}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Title<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_title"
                          value={card.collection_title}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={250}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_paragraph"
                          value={card.collection_paragraph}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={1200}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <h5 className="modal-title">Card Section 2</h5>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_3?.home?.section_9?.card_section?.count_section?.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              secondActiveTab === index ? "active" : ""
                            }`}
                            onClick={() => setSecondActiveTab(index)}
                          >
                            Card Section {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              countRemoveCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_3?.home?.section_9?.card_section?.count_section?.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        secondActiveTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.icon}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {countIcons.map((icons, iconIndex) => (
                                <SwiperSlide key={iconIndex}>
                                  <div
                                    className="img-option"
                                    onClick={() =>
                                      counthandleImageChange(icons, index)
                                    }
                                  >
                                    <img
                                      src={icons}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Text<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="text"
                          value={card.text}
                          onChange={(e) => counthandleChange(e, index)}
                          maxLength={250}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Count</label>
                        <input
                          type="text"
                          className="form-control"
                          name="count"
                          value={card.count}
                          onChange={(e) => counthandleChange(e, index)}
                          maxLength={1200}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionTwo}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    countAddCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
          <>
            <div
              className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
              id="crop-modal"
              tabIndex={-1}
              aria-labelledby="crop-modal"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <ImageCropper
                src={selectedCropImage}
                height={cropImageHeight}
                width={cropImageWidth}
                handleCrop={handleCroppedImage}
                close={() => {
                  setShowCropModal(false);
                }}
              />
            </div>
          </>
        ) : null}
        {showCropModalTwo ? (
          <>
            <div
              className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
              id="crop-modal"
              tabIndex={-1}
              aria-labelledby="crop-modal"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <ImageCropper
                src={selectedCropImage}
                height={cropImageHeight}
                width={cropImageWidth}
                handleCrop={handleCroppedImageTwo}
                close={() => {
                  setShowCropModalTwo(false);
                }}
              />
            </div>
          </>
        ) : null}
    </>
  );
}

export default SectionNineBannerThemeThree;
