import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import {
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
} from "../../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../../edit/data";
import { fetchHomeJsonList } from "../../../redux/Actions";

function SectionTwelveBannerThemeThree() {
  const navigate = useNavigate();
  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);

  return (
    <>
      <section className="sh3-testimonial-section" id="sh3testimonial">
        <div className="container"> 
          <div className="row">
            <div className="col-md-12">
              <div className="sh3-section-title">
                <span className="sh3-yellow-btn">{homedata?.theme_3?.home?.section_12?.sub_heading_top}</span>
                <h2 style={{
                      color: `#${homedata?.theme_3?.home?.section_12?.h2color}`
                    }}>{homedata?.theme_3?.home?.section_12?.title}</h2>
                <p style={{
                      color: `#${homedata?.theme_3?.home?.section_12?.paracolor}`
                    }}>{homedata?.theme_3?.home?.section_12?.paragraph}</p>
              </div>
            </div>
          </div>
            <div className="row justify-content-center">
            <Swiper
                spaceBetween={15}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                className="home-slider"
              >
            {homedata?.theme_3?.home?.section_12?.review_card.map(
              (items: any) => {
                return (
                  <SwiperSlide>
                    <div className="sh3-testimonial-card">
                      <div className="sh3-profile-user">
                        <a className="sh3-profile-user-img">
                          <img src={items.reviewer_pic} className="rounded-circle" alt="" />
                        </a>
                        <div className="sh3-profile-user-info">
                          <h6>
                          <a style={{
                      color: `#${homedata?.theme_3?.home?.section_12?.h3color}`
                    }}>{items.reviewer_name}</a>
                          </h6>
                          <div className="sh3-profile-rating">
                            <span className="secondary-text">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </span>
                            <span>{items.rating}</span>
                          </div>
                        </div>
                      </div>
                      <h5 style={{
                      color: `#${homedata?.theme_3?.home?.section_12?.h3color}`
                    }}>{items.review_highlight}</h5>
                        <p style={{
                      color: `#${homedata?.theme_3?.home?.section_12?.paracolor}`
                    }}>{items.review}</p>
                    </div>
                  </SwiperSlide>
                );
              }
            )}
            </Swiper>
            </div>
          <div className="row">
            <div className="col-md-12">
              <div className="sh3-load-more mt-5 d-none">
                <p className="d-block mb-4">
                  {homedata?.theme_3?.home?.section_12?.review_bottom_paragraph}
                </p>
                <a href="#book-now" className="btn title-btn ms-2 hover-btn" style={{background:`#${homedata?.theme_3?.home?.section_12?.btn_group?.btn1?.bg_color}`,color:`#${homedata?.theme_3?.home?.section_12?.btn_group?.btn1?.color}` }}>
                  {homedata?.theme_3?.home?.section_12?.btn_group?.btn1?.text}
                  <i className="uil uil-arrow-circle-right ms-2"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
}
export default SectionTwelveBannerThemeThree;
