import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  clearUserProductCategories,
  fetchProductAllCategories,
} from "../../redux/Actions/productCategoriesAction";
// import ProductContent from './productContent';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setUserProductCategories } from "./../../redux/Actions/productCategoriesAction";
import { fetchAddToCart } from "../../redux/Actions/addCartAction";
import {
  fetchOfferDetailsList,
  fetchPickupDelivery,
} from "../../redux/Actions/checkoutPageActions";
import { fetchSplitPriceDetails } from "../../redux/Actions/splitPriceAction";
import { fetchGetShopByPinCode } from "./../../redux/Actions/checkoutPageActions/index";
import {
  clearCart,
  clearCartCount,
  clearSplitPrice,
  deleteCartCount,
  setCartCount,
  setOrderType,
} from "../../redux/Actions/cartCountAction";
import { setValue } from "../../utility";
import { shirticon } from "../../assets/img";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { toast, ToastContainer } from "react-toastify";
import { fetchOrderDetails } from "../../redux/Actions/orderDetailsAction";
import { fetchShopHomeData } from "../../redux/Actions/shopHomeAction";
import { checkShopListFlow } from "../../utility";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  fetchAddressByPincode,  
  fetchSaveAddress,
} from "../../redux/Actions/checkoutPageActions";
import * as yup from "yup";
import Select from "react-select";
import { fetchPostalCodes,fetchCroppedImage } from "../../redux/Actions";
import axios from "../../config";
interface SpecialInstructions {
  [key: string]: string;
}
const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});
const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Pin code is required")
    // .matches(UKPostalCode, "Enter a valid Pin code")
    // .trim()
    .max(8, "Enter a valid Pin code"),
    selectService: yup
    .string()
});

const ProductListsCobbler = () => {
  const [productCategories, setProductCategories] = useState([]);
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const state: any = useSelector<any>(
    (state) => state.ProductAllCategories.data
  );
  const statepostal: any = useSelector((state) => state);
  let url = location.pathname;
  let splitURL: any = url.toString().split("/");
  const { cartCount }: any = useSelector<any>((state) => state);
  const settings: any = useSelector<any>((state) => state.settings);
  const shop_details: any = useSelector<any>((state) => state.shopHome);
  const selectedCategories: any = useSelector<any>(
    (state) => state.userSelectedCategories
  );
  
  const selectedcategoryPriceList: any = useSelector<any>(
    (state) => state.splitPriceDetails
  );
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [addToCarts, setAddToCarts] = useState([]);
  const [userName, setUserName] = useState<any>("");
  const [totalCartPrice, setTotalCartPrice] = useState<any>(0);
  const [totalCartQuantity, setTotalCartQuantity] = useState<any>(0);
  const [amountWithoutVat, setAmountWithoutVat] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [vatAmount, setVatAmount] = useState<any>(0);
  const [cartInformation, setCartInformation] = useState([]);
  const userData: any = useSelector<any>((state) => state);
  const [selectedCategoryResIndex, setSelectedCategoryResIndex] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [additionalAddress, setAdditionalAddress] = useState<any>(null);
  const [listOfSuggestedAddress, setListOfSuggestedAddress] = useState([]);
  const [secprod, setsecprod] = useState(false);
  const [specialInstructions, setSpecialInstructions] = useState<any>("");
  const [specialInstructionsCategory, setspecialInstructionsCategory] = useState<any>("");
  const navigate = useNavigate();
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [genderType, setSelectedGender] = useState('both');
  const [coveredArea, setCoveredArea] = useState(false);
  let postalCode: any = localStorage.getItem("postalCode");
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const [areaData, setAreaData] = useState([]);
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
      selectService:""
    },
  });
  const handleGenderChange = (e:any) => {
    setSelectedGender(e.target.value);
  };
  const handleAddtionalAddress = (e:any) => {
    setAdditionalAddress(e.target.value);
  };
  useEffect(() => {
    if (statepostal) {
      setPostalCodeList(statepostal.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [statepostal]);
  const minimum_order_amount: any = useSelector<any>(
    (state) => state.getShopByPinCode?.data?.minimum_order_amount
  );

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });

  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };
  const postalCodeValues: any = localStorage.getItem("postalCode");

  // useEffect(() => { 
  //   let storpostal: any = localStorage.getItem("postalCode")
    
  //   const updateValue = storpostal.replace(/\s/g, "");
  //   setPostalCodeValue(updateValue)
  // });

  const submitAddressChange = async () => {
    let postalCode: any = localStorage.getItem("postalCode");
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      let addressSuggestions: any = [{ ...selectedAddress }];
      addressSuggestions[0].postalCode = postalCode;
      if (localStorage.getItem("addressSuggestions")) {
        let existingAddressSuggestion = JSON.parse(
          localStorage.getItem("addressSuggestions") || "[]"
        );
        addressSuggestions = addressSuggestions.concat(
          existingAddressSuggestion
        );
      }
      localStorage.setItem(
        "addressSuggestions",
        JSON.stringify(addressSuggestions)
      );
      setListOfSuggestedAddress(addressSuggestions);
      if (localStorage.getItem("token")) {
        // handle api
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (
          uniqueAddress.status ||
          uniqueAddress.message == "Address already exists"
        ) {
          await dispatch(fetchProductAllCategories());
          // if (checkShopListFlow()) {
          //   navigate("/shopList");
          // } else {
          //   navigate("/product-lists");
          // }
          setsecprod(true);
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        // if (checkShopListFlow()) {
        //     navigate("/shopList");
        //   } else {
        //     navigate("/product-lists");
        //   }
        setsecprod(true);
      }
    }
  };
  useEffect(() => {
    let existingAddressSuggestion = JSON.parse(
      localStorage.getItem("addressSuggestions") || "[]"
    );
    setListOfSuggestedAddress(existingAddressSuggestion);
    setPinCodeFormValue("pinCode", JSON.parse(localStorage.getItem("postalCode")!)??"")
    setPostalCodeValue(JSON.parse(localStorage.getItem("postalCode")!)??"");
  }, []);
  const navigateToCheckout = () => {
    if (checkShopListFlow()) {
        const shopName: any = localStorage.getItem("currentShopName");
        if (shopName) {
            const finalResult = shopName
                .trim()
                .replace(/\s(.)/g, (match: any) => match.toUpperCase())
                .replace(/\s/g, "")
                .replace(/^(.)/, (match: any) => match.toLowerCase());

            let URL = window.location.href.split("/");
            if (URL.length > 4) {
                navigate(`/shop/${finalResult}/checkOut`);
            } else {
                navigate("/checkout");
            }
        } else {
            navigate("/checkout");
        }
    } else {
        navigate("/checkout");
    }
};

const swiperRef = useRef<any>(null);

const handleNextClick = () => {
  if (swiperRef.current !== null && swiperRef.current.swiper !== undefined) {
      swiperRef.current.swiper.slideNext();
      setSelectedCategoryIndex(prevIndex => prevIndex + 1); 
  }
};

const handlePrevClick = () => {
  if (swiperRef.current !== null && swiperRef.current.swiper !== undefined) {
      swiperRef.current.swiper.slidePrev();
      setSelectedCategoryIndex(prevIndex => prevIndex - 1);
  }
};


  useEffect(() => {
    if (state) {
      setAddToCarts(state.addToCarts);
    }
  }, [state]);

  useEffect(() => {
    if (selectedCategories.length) {
      const cartInformationData = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });

      if (cartInformationData.length) {
        setValue("cartInformationData", cartInformationData);
      }
      setCartInformation(cartInformationData);
      
      const selectedMainCategory = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });
      let finalTotalPrice = 0;
      let itemsCount = 0;

      selectedMainCategory?.map((item: any) => {
        const subcategoryList = item?.sub_categories?.filter(
          (subItem: any) => parseInt(subItem.quantity) > 0
        );
        itemsCount = itemsCount + subcategoryList?.length;
        subcategoryList?.map((subcategory: any) => {
          finalTotalPrice =
            finalTotalPrice +
            parseInt(subcategory?.quantity) *
              parseFloat(subcategory?.total_price);
        });
      });
      // dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
      if (selectedMainCategory.length == 0) {
        localStorage.setItem("is_removed", "true");
      }
    }
    
  }, [selectedCategories]);

  const transformSpecialInstructions = (instructions: SpecialInstructions) => {
    const transformed: SpecialInstructions = {};
    for (const [key, value] of Object.entries(instructions)) {
      transformed[`special_instruction_${key}`] = value;
    }
    return transformed;
  };

  const saveSpecialInstructions = () => {
    localStorage.setItem('specialInstructions', JSON.stringify(specialInstructions));
  };

  const handleContinue = (e: any) => {
    e.preventDefault();
    setValue("orderType", 0);
    localStorage.removeItem("tipamount")
    const selectedMainCategory = selectedCategories?.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });
    const hasSpecialInstructions = Object.values(specialInstructions).some(
      (instruction: any) => instruction.trim() !== ""
    );
    if (selectedMainCategory.length == 0) {
      if(hasSpecialInstructions) {
        handleQuickOrder();
      }
      else {
        toast.warning("Please select atlease one product to continue");
        return false;
      }      
    }

    const postalCode = JSON.parse(localStorage.getItem("postalCode")!);
    let shopId = localStorage.getItem("shop_id");
    let URL = window.location.href.split("/");
    if (URL.length > 4) {
      shopId = localStorage.getItem("currentShopHome");
    }
    let finalTotalPrice: any = 0;

    let payload: any = {
      shop_id: shopId,
      postal_code: postalCode,
      total_tax_price: selectedcategoryPriceList?.vat_amount,
      total_price_without_deductions:
        selectedcategoryPriceList?.final_payable_amount,
      total_price_without_vat:
        parseFloat(selectedcategoryPriceList?.total_price_amount) -
        parseFloat(selectedcategoryPriceList?.vat_amount),
      group_id: "1",
      order_type: "0",
      cart_total_count: selectedcategoryPriceList?.itemsCount,
      additionalAddress: additionalAddress,
      imagedata: imagedata,
    };

    selectedMainCategory?.map((item: any) => {
      const subcategoryList = item?.sub_categories?.filter(
        (subItem: any) => parseInt(subItem.quantity) > 0
      );

      subcategoryList?.map((subcategory: any) => {
        //TODO: handle thru single selection from the dropdown
        const selectedOption = subcategory?.options[0]; //subcategory?.options?.find((option:any) => option?.isSelected)

        finalTotalPrice =
          finalTotalPrice +
          parseInt(subcategory?.quantity) *
            parseFloat(subcategory?.total_price);
        payload = {
          ...payload,
          [`option_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_id,
          [`process_time_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.process_time,
          [`productcount_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`quantity_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`price_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_price,
          [`product_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.sub_category_name,
          // [`specialinstruction_${specialInstructionsCategory}`]:specialInstructions,
          ...transformSpecialInstructions(specialInstructions),
        };
      });
    });

    payload = { ...payload, total_price: finalTotalPrice.toString() };
    localStorage.setItem("order_type", payload.order_type);
    localStorage.setItem("shop_id", payload.shop_id);
    saveSpecialInstructions();
    // TODO: call the api and pass the
    // dispatch(fetchPickupDelivery(payload))
    dispatch(fetchAddToCart(payload));
    const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
    if (userinfo) {
    navigateToCheckout();
    }else if(settingsData?.WebsiteSettings?.site_title_en != "Uplands Laundry"){
      localStorage.setItem("isCheckout", "1");
      navigate("/login");
    } else {
      navigate("/guestLogin");
    }

  };
  const handleQuickOrder = () => {
    const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
    setValue("imagep", imagedata);
    if (userinfo) {
      dispatch(setOrderType(1));
      setValue("orderType", 1);
      localStorage.removeItem("cartInformationData");
      localStorage.removeItem("specialInstructions");
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      localStorage.removeItem("isloaded");
      // dispatch(clearSplitPrice());
      navigateToCheckout();
    } else {
      localStorage.removeItem("cartInformationData");
      localStorage.removeItem("specialInstructions");
      setValue("orderType", 1);
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      localStorage.removeItem("isloaded");
      // dispatch(clearSplitPrice());
      navigate("/guestLogin");
    }
  };

  window.onbeforeunload = (event) => {
    localStorage.removeItem("isloaded");
  };

  useEffect(() => {
    if (state) {
      const { category = [] } = state;
      // setProductCategories(category);
      // 
      // if (category?.length > 0) {
      //   dispatch(setUserProductCategories(category));
      // }
      setProductCategories(category);
      if (category?.length > 0) {
        if (!localStorage.getItem("isloaded")) {
          if (localStorage.getItem("is_removed")) {
            let cat = category?.filter((item: any) => {
              return item?.sub_categories?.some(
                (subItem: any) => parseInt(subItem?.quantity) > 0
              );
            });

            if (cat.length == 0) {
              cat = category;
            }

            let subcat = cat[0]?.sub_categories?.find(
              (subItem: any) => parseInt(subItem?.quantity) > 0
            );

            if (subcat == undefined || subcat.length == 0) {
              subcat = category[0]?.sub_categories[0];
            }

            const selectedCategoryItem: any = category?.find(
              (item: any) =>
                item.main_category_id === cat[0].main_category_id.toString()
            );

            const getSubCategory = selectedCategoryItem?.sub_categories?.find(
              (item: any) => item.sub_category_id === subcat.sub_category_id
            );

            const updatedSubCategory = {
              ...getSubCategory,
              quantity: 0,
            };

            const updatedSubCategoriesList =
              selectedCategoryItem?.sub_categories?.map((item: any) => {
                if (item.sub_category_id === subcat.sub_category_id) {
                  return updatedSubCategory;
                }
                return item;
              });
            // const updatedSubCategoriesList = selectedCategoryItem?.sub_categories
            // ?.filter((item:any) => selectedGender === 'all' || item.suitable_for === selectedGender)
            // .map((item:any) => {
            //   if (item.sub_category_id === subcat.sub_category_id) {
            //     return updatedSubCategory;
            //   }
            //   return item;
            // });

            const updatedCategory = {
              ...selectedCategoryItem,
              sub_categories: updatedSubCategoriesList,
            };
            const updatedCategoriesList = category?.map((item: any) => {
              if (
                item.main_category_id === (cat[0]?.main_category_id).toString()
              ) {
                return updatedCategory;
              }
              return item;
            });

            dispatch(setUserProductCategories(updatedCategoriesList));
          } else {
            dispatch(setUserProductCategories(category));
          }
          localStorage.setItem("isloaded", "true");
        }
      }
    }
  }, [state]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        toast.dismiss();
        const queryParams = new URLSearchParams(location.search);
        const hasNoParams = queryParams.keys().next().done;

        let postalCode;

        if (hasNoParams) {
          postalCode = localStorage.getItem("postalCode");
        } else {
          localStorage.clear();
          const queryData = queryParams.get("data");
          const decryptHexa = (hex: any) => {
            let str = "";
            for (let i = 0; i < hex.length; i += 2) {
              const hexValue = hex.substr(i, 2);
              const decimalValue = parseInt(hexValue, 16);
              str += String.fromCharCode(decimalValue);
            }
            return str;
          };

          const hexToString = decryptHexa(queryData);
          const dataConvertedArray = hexToString.split("&");
          const queryValues: any = {};

          dataConvertedArray.forEach((item) => {
            const [key, value] = item.split("=");
            queryValues[key] = value.replace(/"/g, "").trim();
          });
          postalCode = JSON.stringify(queryValues.postalCode);
          const shopId = queryValues.shop_id || "1";

          if (queryValues.label && queryValues.value) {
            const address = JSON.stringify({
              label: queryValues.label,
              value: queryValues.value,
            });
            localStorage.setItem("selectedAddressBeforeLogin", address);
          }

          localStorage.setItem("postalCode", postalCode);
          localStorage.setItem("shop_id", shopId);
        }

        if (!postalCode || postalCode === "undefined" || postalCode === null) {
          toast.warning("Enter postal code in Book Now Section");
          navigate("/");
        } else {
          await dispatch(fetchProductAllCategories());
          const res = await dispatch(fetchGetShopByPinCode(postalCode));
          
          if (res?.data?.Response?.response_code === "-1") {
            // Handle error or navigate elsewhere
            navigate("/");
          }
        }
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const repeatOrderId = localStorage.getItem("order_id");
    if (
      repeatOrderId != "" ||
      repeatOrderId != undefined ||
      repeatOrderId != null
    ) {
      // repeatOrderData(repeatOrderId).then(()=>{
      //   localStorage.removeItem("order_id")
      // });
    }
  }, [productCategories]);

  // useEffect(()=>{
  //    dispatch(setCartCount(selectedCategories))
  // },[selectedCategories]);

  // useEffect(()=>{
  //     const cartInformationData = selectedCategories?.filter((item: any) => {
  //       return item?.sub_categories?.some(
  //         (subItem: any) => parseInt(subItem?.quantity) > 0
  //       );
  //     });
  //     setCartCount(cartInformationData);
  //   },[selectedCategories])

  const onCategoryClickHandler = (selectedCategoryId: any) => {
    //

    setSelectedCategoryIndex(
      selectedCategories.findIndex(
        (obj: any) => obj.main_category_id === selectedCategoryId
      )
    );

    // setSelectedCategory(productCategories?.find(
    //   (item: any) =>
    //     item.main_category_id === (selectedCategoryId).toString()
    // ));

    /* const selectedCategoryItem: any = productCategories?.find((item: any) => item.main_category_id === selectedCategoryId);
        if (selectedCategoryItem) {
            
            dispatch(setUserProductCategories(selectedCategoryItem));
        } */
  };

  const onAddToCartItemHandler = (
    selectedSubCategoryId: any,
    selectedMainCategoryId: any,
    type: any,
    orderData?: any
  ) => {
    const selectedCategoryItem: any =
      type === "reorder"
        ? productCategories.find(
            (item: any) =>
              item.main_category_id === selectedMainCategoryId.toString()
          )
        : selectedCategories?.find(
            (item: any) =>
              item.main_category_id === selectedMainCategoryId.toString()
          );

    const getSubCategory = selectedCategoryItem?.sub_categories?.find(
      (item: any) => item.sub_category_id === selectedSubCategoryId
    );
    const { quantity = 0 } = getSubCategory;
    let updatedQuantity = parseInt(quantity);
    if (type === "reorder") {
      const repeatOrderMainCat: any = orderData?.find(
        (item: any) =>
          item.main_category_id === selectedMainCategoryId.toString()
      );
      const repeatOrderSubCat = repeatOrderMainCat?.items?.find(
        (item: any) => item.sub_category_id === selectedSubCategoryId
      );
      updatedQuantity = repeatOrderSubCat.item_quantity;
      getSubCategory.quantity = repeatOrderSubCat.item_quantity;
      dispatch(setCartCount(repeatOrderSubCat.item_quantity));
    } else if (type === "minus") {
      updatedQuantity = updatedQuantity - 1;
      dispatch(deleteCartCount(1));
    } else {
      localStorage.removeItem("is_removed");
      updatedQuantity = updatedQuantity + 1;
      dispatch(setCartCount(cartCount + 1));
    }

    const updatedSubCategory = {
      ...getSubCategory,
      quantity: updatedQuantity.toString(),
    };

    const updatedSubCategoriesList = selectedCategoryItem?.sub_categories?.map(
      (item: any) => {
        if (item.sub_category_id === selectedSubCategoryId) {
          return updatedSubCategory;
        }
        return item;
      }
    );

    const updatedCategory = {
      ...selectedCategoryItem,
      sub_categories: updatedSubCategoriesList,
    };

    const updatedCategoriesList = selectedCategories?.map((item: any) => {
      if (item.main_category_id === selectedMainCategoryId.toString()) {
        return updatedCategory;
      }
      return item;
    });

    dispatch(setUserProductCategories(updatedCategoriesList));
  };

  function handleOptionChange(
    e: any,
    selectedMainCategoryId: any,
    selectedSubCategoryId: any
  ) {
    let selectedOption = e.target.value;
    let selectedOptionPrice = "0";

    const selectedCategoryItem: any = selectedCategories?.find(
      (item: any) => item.main_category_id === selectedMainCategoryId.toString()
    );

    const getSubCategory = selectedCategoryItem?.sub_categories?.find(
      (item: any) => item.sub_category_id === selectedSubCategoryId
    );

    const getOption = getSubCategory?.options?.map((option: any) => {
      if (option.option_id == selectedOption) {
        selectedOptionPrice = option.option_price;
        return { ...option, is_default: 1 };
      } else {
        return { ...option, is_default: 0 };
      }
    });

    //

    let option = getOption;
    // let updatedQuantity = parseInt(quantity);

    const updatedSubCategory = {
      ...getSubCategory,
      options: option,
      total_price: selectedOptionPrice,
    };

    //

    const updatedSubCategoriesList = selectedCategoryItem?.sub_categories?.map(
      (item: any) => {
        if (item.sub_category_id === selectedSubCategoryId) {
          return updatedSubCategory;
        }
        return item;
      }
    );

    const updatedCategory = {
      ...selectedCategoryItem,
      sub_categories: updatedSubCategoriesList,
    };

    const updatedCategoriesList = selectedCategories?.map((item: any) => {
      if (item.main_category_id === selectedMainCategoryId.toString()) {
        return updatedCategory;
      }
      return item;
    });

    dispatch(setUserProductCategories(updatedCategoriesList));
  }

  const repeatOrderData = async (id: any) => {
    const response = await dispatch(fetchOrderDetails(JSON.parse(id)));
    if (response.data.Response.response_code == "1") {
      const order: any[] = response?.data?.data?.orders_details?.order_items;
      if (order.length > 0) {
        for (let main_index = 0; main_index < order.length; main_index++) {
          const mainCat = order[main_index].items;
          const main_category_id = order[main_index].main_category_id;
          for (let sub_index = 0; sub_index < mainCat.length; sub_index++) {
            const sub_category_id = mainCat[sub_index].sub_category_id;
            setTimeout(() => {
              onAddToCartItemHandler(
                sub_category_id,
                main_category_id,
                "reorder",
                order
              );
            }, sub_index * 10);
          }
        }
      } else {
        
      }
    }
  };

  // const cartInformation = selectedCategories?.filter((item: any) => {
  //   return item?.sub_categories?.some(
  //     (subItem: any) => parseInt(subItem?.quantity) > 0
  //   );
  // });

  //Construct the api request based on cartInformation
  useEffect(() => {
    if (splitURL.length > 3) {
      dispatch(fetchShopHomeData());
    }
  }, []);

  useEffect(() => {
  let totalQuantity = 0;
  let totalPrice = 0;
  cartInformation?.forEach((item: any) => {
    item?.sub_categories
        ?.filter(
            (subItem: any) =>
                parseInt(subItem.quantity) > 0
        )
        ?.forEach((subCategory: any) => {
            totalQuantity += parseInt(subCategory.quantity);
            totalPrice += parseFloat(subCategory.total_price) * parseFloat(subCategory.quantity);
        });
});

if(selectedcategoryPriceList.final_payable_amount < totalPrice){
  setTotalCartPrice(totalPrice.toFixed(2));
  getTotalCartValue(totalPrice.toFixed(2));
}else{
  setTotalCartPrice(selectedcategoryPriceList.final_payable_amount);
  getTotalCartValue(selectedcategoryPriceList.final_payable_amount);
}

setTotalCartQuantity(totalQuantity);


}, [cartInformation,selectedcategoryPriceList]);

const getTotalCartValue = (totalPrice:any) => {
  setTotalCartPrice(totalPrice);
const vatPercentage = parseFloat(selectedcategoryPriceList.vat_percentage);
    const amountWithoutVat = totalPrice / (1 + vatPercentage / 100);
    const vatAmount = totalPrice - amountWithoutVat;
setAmountWithoutVat(amountWithoutVat);
setVatAmount(vatAmount);
}
const handleSpecialInstructionChange = (e:any, mainCategoryId:any) => {
  const { value } = e.target;
  setSpecialInstructions({
    ...specialInstructions,
    [mainCategoryId]: value,
  });
};
const updateImageSectionOne = async (e: any) => {
  const image = e.target.files[0];
  // setCropImageHeight("150");
  // setCropImageWidth("150");
  // setSelectedCropImage(image);
  // setShowCropModal(true);
  if (image instanceof File) {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (res: any) => {
      handleCroppedImage(res.target.result);
    };
  }
};

const handleCroppedImage = async (image: any) => {
  setShowCropModal(false);
  
  const imageLink = await dispatch(fetchCroppedImage(image));
  setImagedata(imageLink);
  // if(imageLink != false){
  //   handleImageChange(imageLink,activeTab)
  // }
};

const handleRemoveImage = () => {
  setImagedata('');
  if (fileInputRef.current) {
    fileInputRef.current.value = '';
  }
};

const filteredSubCategories = selectedCategories?.length > 0 &&
    selectedCategories[selectedCategoryIndex]?.sub_categories.filter((item:any) => {
      return genderType === 'both' || item.gender_type === genderType;
    });
    console.log("filteredSubCategories",filteredSubCategories)
    // const filteredSubCategoriesins = selectedCategories?.length > 0 &&
    // selectedCategories[selectedCategoryIndex]?.sub_categories.filter((item:any) => {
    //   return item.main_category_id
    // });    
    useEffect(() => {
      if (selectedCategories.length > 0) {
        const currentMainCategoryId = selectedCategories[selectedCategoryIndex]?.main_category_id;
        if (currentMainCategoryId && !specialInstructions[currentMainCategoryId]) {
          setSpecialInstructions({
            ...specialInstructions,
            [currentMainCategoryId]: '',
          });
        }
      }
    }, [selectedCategories, selectedCategoryIndex]);

    const handleChange = (e: any) => {
      setCoveredArea(false);
      const { value } = e.target;
      const updateValue = value.replace(/\s/g, "");
      setPinCodeFormValue("pinCode", updateValue);
      if (value.length > 0) {
        dispatch(fetchPostalCodes(updateValue));
      }
      setPostalCodeValue(updateValue);
    };
    useEffect(() => {
      if (checkWithNewPin == false) {
        setPinCodeFormValue("pinCode", "");
        setSelectedAddress(null);
        resetAddressForm();
      } else {
      }
    }, [checkWithNewPin]);
  const submitPostalForm = async (data: any) => {

    localStorage.setItem("postalCode", JSON.stringify(data));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data))
    );
    if (shop) {
        setCoveredArea(false);
        setCheckWithNewPin(false);
      setCoveredArea(false);
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
        setCoveredArea(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Sorry, we don't offer our services in your area at the moment.",
        });
        await fetchCoveredAreaCodes();
        setCoveredArea(true);
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Sorry, we don't offer our services in your area at the moment.",
      });
      await fetchCoveredAreaCodes();
        setCoveredArea(true);
    }
  };
  const fetchCoveredAreaCodes = async () => {
    const base_url = process.env.REACT_APP_BACKEND_URL;
    try {
      const response = await axios({
        method: "get",
        url: `${base_url}/api/areas_covered_postcodes`,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Methods": "GET, POST",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (response.data.Response.response_code == "1") {
        setAreaData(response.data.data);
      } else {
        return false;
      }
    } catch (error) {}
  };
  const onSearch = (searchTerm: any) => {
    setPinCodeFormValue("pinCode", searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase());
  };
  return (
    <div>
      <div className="breadcrumpset d-none">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>PRODUCT LIST</h2>
                <ul>
                  <li key={1}>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li key={2}>
                    <span> Product List</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {checkShopListFlow() && 
      <div className="section-shop-info">
        <div className="container">
          <div className="row section-shop-hr">
            <div className="col-md-4">
              <div className="sec-shop-img">
                <img src={shop_details?.home_page_settings?.shop_home_page_image?.banner[0]?.file_name} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="sec-shop-details">
                <h2>{shop_details?.profile?.shop_name}</h2>
                <p className="sec-shop-adrs">
                  <i className="fas fa-map-marker-alt me-2"></i>
                  {shop_details?.profile?.address.text_address}
                </p>
                <ul>
                  <li>
                    <i className="fas fa-star me-2"></i>
                    <b>{settings?.WebmasterSettings?.currency}
                    {shop_details?.profile?.minimum_order_amount}</b> minimum
                  </li>
                  <li>
                    <i className="fas fa-star me-2"></i>Well Trained Staffs
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            
          </div>
        </div>
      </div>
      <div className="section-orderlist first-background">
        <div className="">
          <div className="container">
            <div className="selection-page-wrapper">
              <div className="list-breadcums">
                <div className="row">
                  <div className="col-md-6 col-sm-4 d-none d-md-block">
                    <ul>
                      <li key={3}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/");
                          }}
                        >
                          Home
                        </a>
                      </li>
                      /<li key={4}>Product List</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="quick-order-btn mb-15 mobile-order-btn">
                                <a className="btn btn-lg" href="#" >Skip to <strong>Quick Order</strong> <i className="fa fa-info-circle fa-1" title="we'll collect your bag and bill you after"></i></a>
                            </div> */}

              <div className="row">
                <div className="col-sm-12 col-lg-8">
                  <div className="">
                  
                  </div>
                <div className="plistadrsform"> 

                <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                <div
                    className={`${checkWithNewPin == false ? "d-none pladrscheckgrid" : "pladrscheckgrid"}`}
                  >
                    <label style={{paddingLeft:"0 10px",fontWeight: "bold"}}>Post code</label>
                    <small className="text-danger postvalidation">
                          {pinCodeFormError.pinCode?.message}
                      </small>
                    <div className="pladrscheck">
                                    <Controller
                                        name="pinCode"
                                        control={pinCodeFormControl}
                                        render={({ field }) => (
                                            <input
                                            {...field}
                                            type="text"
                                            id="postcode-input"
                                           
                                            className={`ui-autocomplete-input address-form ${
                                                pinCodeFormError.pinCode ? "invalid" : ""
                                            }`}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            />
                                        )}
                                        />
                                        <span
                                            className="btn hover-btn"
                                            id="book_now_btn"
                                            onClick={() => submitPostalForm(postalCodeValue)}
                                        >Search</span>
</div>
                                        <div className="dropdown">
                                                {postalCodeList?.filter((item: any) => {
                                                    const searchTerm = postalCodeValue.toLowerCase();
                                                    const mainValue = item.postcode.toLowerCase();

                                                    return (
                                                    searchTerm &&
                                                    mainValue.startsWith(searchTerm) &&
                                                    mainValue !== searchTerm
                                                    );
                                                })
                                                .slice(0, 10)
                                                .map((item: any) => (
                                                    <div
                                                    onClick={() => onSearch(item.postcode)}
                                                    className="dropdown-row"
                                                    key={item.postcode}
                                                    >
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    {item.postcode}
                                                    </div>
                                                ))}
                                            </div>
                                            {coveredArea && (
                                                    <div className="dropdown">
                                                        <div className="dropdown-heading">
                                                        Postcode areas we currently covered
                                                        </div>
                                                        {areaData.map((item: any) => (
                                                        <div
                                                            onClick={() => {
                                                            onSearch(item);
                                                            setCoveredArea(false);
                                                            }}
                                                            className="dropdown-row"
                                                            key={item}
                                                        >
                                                            <i className="fas fa-map-marker-alt"></i>
                                                            {item}
                                                        </div>
                                                        ))}
                                                    </div>
                                                    )}
                                                    </div>
                  <div
                  className={`adrsselectform`}
                  >
                  <small className="text-danger postvalidation">
                      {addressFormError.address?.message}
                  </small>
                  <div className="adrsselectdd">
                      <Controller
                      name="address"
                      control={addressFormControl}
                      render={({ field }) => (
                          <Select
                          placeholder="Select your address"
                          {...field}
                          value={selectedAddress}
                          className={`${
                              addressFormError.address ? "invalid" : ""
                          }`}
                          onChange={handleAddressChange}
                          onFocus={() => {
                              if (
                              getValuesAddressForm("address").value == ""
                              ) {
                              addressFormSetError("address", {
                                  type: "server",
                                  message: "Address is required to proceed",
                              });
                              } else {
                              clearErrors();
                              }
                          }}
                          options={areaOptions}
                          />
                      )}
                      />

                      <input
                      type="button"
                      value="Continue"
                      className="btn hover-btn"
                      onClick={submitAddressChange}
                      />
                  </div>
                  {/* <div className="adrsselect-or">OR</div>
                  <div className="adrsselect-link">
                      <a
                      className="cursor-pointer"
                      onClick={() => {
                          setCheckWithNewPin(true);
                      }}
                      >
                      Check with another Postcode
                      </a>
                  </div> */}
                  </div>
                  <div className="px-2 mb-3">
                    <label style={{fontWeight: "bold"}}>Please Specify Any Extra Address Details</label>
                    <textarea className="form-control"
                    onChange={(e) => handleAddtionalAddress(e)}
                    ></textarea>
                  </div>
              </form>
            </div>
            {secprod &&(
              <>
                <div className="prodlistswipe">
                    <div className="prodlistswipe-buttons d-md-none">
                      <button onClick={handleNextClick} disabled={selectedCategoryIndex === productCategories.length - 1}><i className="fa-solid fa-angle-left"></i></button>
                      <button onClick={handlePrevClick} disabled={selectedCategoryIndex === 0}><i className="fa-solid fa-angle-right"></i></button>
                    </div>
                  <Swiper
                    ref={swiperRef}
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 5,
                      },
                    }}
                  >
                    {productCategories &&
                      productCategories.map((item: any,index:any) => {
                        return (
                          <SwiperSlide>
                            <div
                              onClick={() => {
                                onCategoryClickHandler(item.main_category_id);
                                setSelectedCategoryResIndex(index);
                            }}
                              className={
                                selectedCategories[selectedCategoryIndex]
                                  ?.main_category_id == item?.main_category_id
                                  ? "category-container active-swiper"
                                  : "category-container"
                              }
                            >
                              <div role="tab" id="heading-1">
                                <a
                                  role="button"
                                  className=""
                                  data-bs-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapse-1"
                                  aria-expanded="true"
                                  aria-controls="collapse-1"
                                >
                                  <div className="order_list orderlist-first">
                                    <div className="user-img">
                                      <img
                                        src={item.web_banner_img}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="list-explore">
                                      <p>{item.main_category_name}</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>                  
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                    <div className="genderselection">
                        <ul>                          
                        <li>
                        <input
                          type="radio"
                          id="both"
                          value="both"
                          checked={genderType === 'both'}
                          onChange={handleGenderChange}
                        />
                        <label htmlFor="both">All</label>
                        </li>
                          <li>
                      <input
                          type="radio"
                          id="male"
                          value="male"
                          checked={genderType === 'male'}
                          onChange={handleGenderChange}
                        />
                        <label htmlFor="male">Men</label>
                        </li>
                        <li>
                        <input
                          type="radio"
                          id="female"
                          value="female"
                          checked={genderType === 'female'}
                          onChange={handleGenderChange}
                        />
                        <label htmlFor="female">Women</label>
                        </li>
                        </ul>
                        </div>
                    </div>
                  </div> 
                  <div className="row my-4">
                  {/* {filteredSubCategories && filteredSubCategories.length > 0 && (
                        filteredSubCategories.map((item:any) => (
                        <>
                        test
                        {item.mainCategoryId}
                        </>
                      
                    )))} */}
                    
                  {filteredSubCategories && filteredSubCategories.length > 0 ? (
                        filteredSubCategories.map((item:any) => (
                          item.sub_category_name !== "Other Services" && (
                          <>
                            
                          <div className="col-md-12 col-custom">                            
                            <div
                              className="panel-body collapse-body list-cloth"
                              id="list-cart"
                            >
                              <div className="list_li">
                                <div className="product">
                                  <div className="pro-det">
                                    {!settingsData?.WebsiteSettings?.site_title_en.includes(
                                      "Whiterose"
                                    ) && (
                                      <img
                                        className="d-none"
                                        src={item.web_sub_category_img}
                                        title={item.sub_category_name}
                                      />
                                    )}
                                    <div>
                                      <h5
                                        className="subcategory-name"
                                        data-sub="1"
                                      >
                                        {item.sub_category_name}
                                      </h5>
                                      <span className="fonsize-10">
                                        {item.description}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="btn-group select-btn">
                                    <select
                                      className={`catselect ${
                                        "show_product_options" in
                                          settingsData?.WebmasterSettings &&
                                        settingsData?.WebmasterSettings
                                          ?.show_product_options == 0
                                          ? "d-none"
                                          : ""
                                      }`}
                                      onChange={(e) => {
                                        handleOptionChange(
                                          e,
                                          selectedCategories[
                                            selectedCategoryIndex
                                          ].main_category_id,
                                          item.sub_category_id
                                        );
                                      }}
                                    >
                                      {item.options.map((subItem: any) => {
                                        return (
                                          <option
                                            value={subItem.option_id}
                                            selected={
                                              subItem.is_default == "1"
                                                ? true
                                                : false
                                            }
                                          >
                                            {subItem?.option_name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="price-amount">
                                    <div className="amount-list">
                                      <div className="amount">
                                        <span>
                                          {
                                            settings?.WebmasterSettings
                                              ?.currency
                                          }
                                        </span>
                                        {parseInt(item?.quantity) === 0
                                          ? parseFloat(item?.total_price)
                                          : parseFloat(item?.total_price)}
                                      </div>
                                    </div>
                                    <div className="amountlist" id="amountlist">
                                      <div className="listcloth-rht">
                                        <span>
                                          <button
                                            type="button"
                                            className="quantity-left-minus btn btn-number"
                                            data-type="minus"
                                            data-field=""
                                            disabled={item.quantity === "0"}
                                            onClick={() =>
                                              onAddToCartItemHandler(
                                                item.sub_category_id,
                                                selectedCategories[
                                                  selectedCategoryIndex
                                                ].main_category_id,
                                                "minus"
                                              )
                                            }
                                          >
                                            <span className="glyphicon glyphicon-minus"></span>
                                          </button>
                                        </span>
                                        <span className="prodcountinp">
                                          {item?.quantity}
                                        </span>
                                        <span>
                                          <button
                                            type="button"
                                            className="quantity-right-plus btn btn-number"
                                            data-type="plus"
                                            data-field=""
                                            onClick={() =>
                                              onAddToCartItemHandler(
                                                item.sub_category_id,
                                                selectedCategories[
                                                  selectedCategoryIndex
                                                ].main_category_id,
                                                "add"
                                              )
                                            }
                                          >
                                            <span className="glyphicon glyphicon-plus"></span>
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                          </>
                             )
                        ))
                      ) : (
                        <p className="my-4">No categories available for the selected gender type.</p>
                      )}
                    
                    {/* { filteredSubCategories.length > 0 && (
                      <>
                        {selectedCategories?.length > 0 && 
                          <div className="special-instruction">
                            <label>Any Other Request?</label>
                            <textarea
                              className="form-control"
                              placeholder="Add any special cleaning instructions or request"
                              onChange={(e) => handleSpecialInstructionChange(e, selectedCategories[selectedCategoryIndex].main_category_id)}
                            ></textarea>
                          </div>
                        }
                      </>
                    )} */}
                    {/* {selectedCategories?.length > 0 && selectedCategories.map((category:any, index:any) => (
                        <div key={category.main_category_id} className="special-instruction">
                          <label>Any Other Request for {category.main_category_name}?</label>
                          <textarea
                            className="form-control"
                            placeholder={`Add any special cleaning instructions or request for ${category.main_category_name}`}
                            onChange={(e) => handleSpecialInstructionChange(e, category.main_category_id)}
                            value={specialInstructions[category.main_category_id] || ''}
                          ></textarea>
                        </div>
                      ))} */}
                      {selectedCategories?.length > 0 && (
                        <>
                          <div className="special-instruction">
                            <label>Any Other Request for {selectedCategories[selectedCategoryIndex].main_category_name}?</label>
                            <textarea
                              className="form-control"
                              placeholder={`Add any special cleaning instructions or request for ${selectedCategories[selectedCategoryIndex].main_category_name}`}
                              onChange={(e) => handleSpecialInstructionChange(e, selectedCategories[selectedCategoryIndex].main_category_id)}
                              value={specialInstructions[selectedCategories[selectedCategoryIndex].main_category_id] || ''}
                            ></textarea>
                          </div>
                          {selectedCategories[selectedCategoryIndex].main_category_name == "Other Services" && (
                            <>
                            <div className="special-instruction">
                                <label>Upload Image</label>
                              <input
                              type="file"
                              accept="image/png, image/jpeg"
                              className="form-control"
                              name="image"
                              ref={fileInputRef}
                              onChange={updateImageSectionOne}
                            />
                            </div>
                            {imagedata && 
                              <div className="imgshcase">
                                  <button onClick={handleRemoveImage} className="btn"><i className="far fa-times-circle"></i></button>
                                  <img src={imagedata} alt="" className="img-fluid"/>
                              </div>
                            }
                            </>
                          )}
                          
                         </>
                        )}
                  </div>
                  
              </>
            )}
                  
                </div>
                <div className="col-sm-12 col-lg-4">
                {secprod &&(
              <>
                  <div className="theiaStickySidebar">
                    <div className="pl-style">
                      <div className="skiptoquickorder text-center">
                        <div className="quick-order-btn mb-15">
                          <button
                            className="btn btn-lg"
                            onClick={handleQuickOrder}
                          >
                            Skip to <strong>Quick Order</strong>
                          </button>
                        </div>
                        <p className="mb-0 text-muted">
                          <i className="fa fa-info-circle"></i> We'll collect
                          your bag and bill you after
                        </p>
                      </div>
                      <div className="price-details Ord-summary cart-summary">
                        <div className="price-header text-center">
                          <h2>Order summary</h2>
                        </div>
                        <div className="price-body">
                          {cartInformation?.map((item: any) => {
                            return (
                              <>
                                <div className="cs-option-price">
                                  <span className="cs-summary-title">
                                    <h4>{item?.main_category_name}</h4>
                                  </span>
                                  {item?.sub_categories
                                    ?.filter(
                                      (subItem: any) =>
                                        parseInt(subItem.quantity) > 0
                                    )
                                    ?.map((subCategory: any) => {
                                      return (
                                        <div className="cs-option-body">
                                          <div className="cs-option-left">
                                            <span>
                                              {subCategory?.sub_category_name}{" "}
                                            </span>
                                            <span className="cs-option-pqty">
                                              ({subCategory?.quantity})
                                            </span>
                                          </div>
                                          <div className="cs-option-right">
                                            {
                                              settings?.WebmasterSettings
                                                ?.currency
                                            }
                                            &nbsp;
                                            {(
                                              parseFloat(
                                                subCategory?.quantity
                                              ) *
                                              parseFloat(
                                                subCategory?.total_price
                                              )
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>
                            );
                          })}
                          <div className="cs-option-amt">
                            <div className="cs-option-amt-list">
                              <div className="cs-option-amt-left">
                                {/* Price ({selectedcategoryPriceList?.itemsCount}{" "}
                                items) */}
                                Price ({totalCartQuantity}{" "}
                                items)
                              </div>
                              <div className="cs-option-amt-right">
                                {settings?.WebmasterSettings?.currency}{" "}
                                {/* {selectedcategoryPriceList?.total_price_amount} */}
                                {totalCartPrice}
                              </div>
                            </div>

                            {selectedcategoryPriceList?.vat_percentage > 0 ? (
                              <>
                                <div className="cs-option-amt-list">
                                  <div className="cs-option-amt-left">
                                    Amount without VAT
                                  </div>
                                  <div className="cs-option-amt-right">
                                    {settings?.WebmasterSettings?.currency}{" "}
                                    {/* {(
                                      parseFloat(
                                        selectedcategoryPriceList?.total_price_amount
                                      ) -
                                      parseFloat(
                                        selectedcategoryPriceList?.vat_amount
                                      )
                                    ).toFixed(2)} */}
                                    {amountWithoutVat.toFixed(2)}
                                  </div>
                                </div>
                                <div className="cs-option-amt-list">
                                  <div className="cs-option-amt-left">
                                    VAT (
                                    {selectedcategoryPriceList?.vat_percentage}
                                    %)
                                  </div>
                                  <div className="cs-option-amt-right">
                                    {settings?.WebmasterSettings?.currency}{" "}
                                    {/* {selectedcategoryPriceList?.vat_amount} */}
                                    {vatAmount.toFixed(2)}
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div className="orders-total">
                              <span className="lft-ordertitle">
                                Delivery Charges
                              </span>
                              {selectedcategoryPriceList.pickup_and_delivery_charge >
                              0 ? (
                                <span className="rht-orderamt float-end">
                                  {settings?.WebmasterSettings?.currency}{" "}
                                  {
                                    selectedcategoryPriceList.pickup_and_delivery_charge
                                  }
                                </span>
                              ) : (
                                <span className="rht-orderamt float-end">
                                  Free
                                </span>
                              )}
                              {/* <small className="text-danger">Note : Delivery charges may vary on checkout</small> */}
                            </div>

                            <div className="cs-option-amt-list cs-option-amt-listbg">
                              <div className="cs-option-amt-left">
                                Amount Payable
                              </div>
                              <div className="cs-option-amt-right">
                                {settings?.WebmasterSettings?.currency}{" "}
                                {/* {
                                  selectedcategoryPriceList?.final_payable_amount
                                } */}
                                {totalCartPrice}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="price-footer">
                          <button
                            type="submit"
                            id="submit-btn"
                            className="continue-btn hover-btn center-block"
                            data-quantity="2"
                            data-price="70.00"
                            // disabled = {selectedcategoryPriceList?.itemsCount === 0}
                            onClick={handleContinue}
                          >
                            Continue{" "}
                            <span className="option-total total-amt">
                              {settings?.WebmasterSettings?.currency}{" "}
                              {/* {selectedcategoryPriceList?.final_payable_amount} */}
                              {totalCartPrice}
                            </span>
                          </button>
                        </div>
                        <div className="text-center price-footer-notes">
                          <small>
                            <b>Note:</b> Minimum order amount is{" "}
                            {settings?.WebmasterSettings?.currency}
                            {minimum_order_amount || ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="resize-sensor">
                      <div className="resize-sensor-expand">
                        <div></div>
                      </div>
                      <div className="resize-sensor-shrink">
                        <div></div>
                      </div>
                    </div>
                  </div>
                  </>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductListsCobbler;
