import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from "react-router-dom";
import { appstore } from '../../assets/img'
import { useDispatch, useSelector } from "react-redux";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload
});

function FooterUplands () {

    const dispatch = useDispatch<any>();
    const [settings, setSettings] = useState<any>({});

  const base_url = process.env.REACT_APP_BACKEND_URL;

  const settingsData: any = useSelector<any>(
    (state) => state?.settings
);

  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  // useEffect(() => {
  //   if (Object.keys(settings).length === 0 && settingsData == undefined) {
  //     axios({
  //       method: "get",
  //       url: `${base_url}/api/settings`,
  //       headers: {
  //         "Accept": "application/json",
  //         'Access-Control-Allow-Methods': 'GET, POST',
  //       },
  //     }).then((response) => {
  //       if (response.status != 401) {
  //         setSettings(response.data.data);
  //         dispatch(setSettingsAll(response.data.data))
  //       }
  //     }).catch(function (error) {

  //     })
  //   }
  // }, [])


    return (
        <>
        <footer>
            <p className='footer-contact'>Call Us: 01792 644238 / 73 Uplands Crescent Swansea SA2 0EX</p>
            <ul className='footer-links'>
                <li>
                    <Link to="/">HOME</Link>
                </li>
                <li>
                    <Link to="/">LAUNDRY</Link>
                </li>
                <li>
                    <Link to="/">IRONING</Link>
                </li>
                <li>
                    <Link to="/">DRY CLEANING</Link>
                </li>
                <li>
                    <Link to="/">ALTERATIONS</Link>
                </li>
                <li>
                    <Link to="/">More</Link>
                </li>
            </ul>
            <ul className='footer-soci'>
                <li>
                    <Link to="/" className='fb-soc'><i className="fab fa-facebook-square"></i></Link>
                </li>
                <li>
                    <Link to="/"><i className="fab fa-twitter"></i></Link>
                </li>
            </ul>
            <div className="container-fluid">
            <div className="row  footer-sectionfour">
              <div className="col-md-6 col-xs-12">
                <p className="footer-last">
                  {Object.keys(settings).length > 0 && settings?.WebsiteSettings?.site_title_en} © {year}. All rights reserved. | Powered by &nbsp;<a href="http://www.bestatservices.com" target="blank">www.bestatservices.com</a>
                </p>
            </div>
            </div>
          </div>
        </footer>
        </>
    )
}
export default FooterUplands;