import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { ttw43 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";
import SectionEight from "./sectionEight";
import SectionNine from "./sectionNine";
import SectionEleven from "./sectionEleven";
import SectionFaq from "./sectionFaq";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SectionFaqRepair from "./sectionFaqrepair";

const images = [
  'assets/shoe-repair/close-up-engraving-art-tools.jpg',
  'assets/shoe-repair/cobbler-works-his-workshop.jpg',
  'assets/shoe-repair/mature-shoemaker-workshop-making-shoes.jpg',
  'assets/shoe-repair/shoemaker-workshop-making-shoes.jpg',
];

function ShoeRepair () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  const settingsslider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
      <div className="t12banner t12bannercolor" id="secidsr">
            <div className="container">
                <div className="row h-100">
                    <div className="col-md-12 col-lg-6">
                        <div className="banner-grid">
                            <div className="banner-grid-inner">
                                <h1>Shoe Repair</h1>
                                <ul className="t12highpoint">
                                    <li>Free Pickup & Delivery</li>
                                    <li>High Quality Services</li>
                                    <li>Lowest Price Guarantee</li>
                                </ul>
                                <ul className="t12applinks">
                                    <li>
                                        <NavLink className="btn btnbanner" to="/">Book Now</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 d-flex h-100 align-items-center">
                      <div className="hcslider">
                        <Slider {...settingsslider}>
                          {images.map((image, index) => (
                            <div key={index}>
                              <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: 'auto' }} />
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                </div>
            </div>
      </div>
      <section className="secbgwhite">
          <div className="container">
              <div className="row">              
                  <div className="col-md-12">
                      <div className="secinfogrid3">
                          <div className="t12-h2hdr">About Our Service</div>
                          <p>At Hello Cobbler, we're dedicated to extending the life of your cherished shoes and boots by our professional shoe repairing service in London. Our skilled shoe repairman offers a comprehensive range of shoe repair services in London to cater to all your footwear needs.</p>                            
                          <div className="secinfoclcontent">
                            <h2>Expert Shoe Repairing Service For Men and Women</h2>
                            <ul>
                              <li><strong>Heel and Sole Replacement:</strong> Restore your shoes' comfort and stability with precision heel and sole replacements.</li>
                              <li><strong>Zipper Repair:</strong> We fix broken zippers on boots, sneakers, and other footwear promptly and efficiently.</li>
                              <li><strong>Leather Restoration:</strong> Breathe new life into your leather shoes with our expert restoration services.</li>
                              <li><strong>Shoe Polishing:</strong> Revitalize the appearance of your shoes with professional polishing.</li>
                            </ul>                          
                          </div>
                          <div className="secinfoclcontent">
                              <h3>Quality Shoe Repairing</h3>
                              <p>We use premium materials and time-honored techniques to ensure exceptional results. Our commitment to quality is reflected in every repair we undertake.</p>
                          </div>
                          <div className="secinfoclcontent">
                            <h3>Convenient & High Quality Service</h3>
                            <p>Book your shoe repairing service online with Hello Cobbler and enjoy our free pick-up and delivery service in London at your convenient location and time. We offer 72h turnaround times without compromising on quality.</p>
                          </div>
                          <div className="secinfoclcontent">
                            <h3>Customer Satisfaction</h3>
                              <p>Your satisfaction is our priority. We stand behind our work with a satisfaction guarantee on your needs.</p>                          
                          </div>
                          <div className="secinfogrid text-center">
                              <NavLink className="btn btnprime" to="/product-lists">
                                  Book Now
                              </NavLink>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <SectionEight/>
      <SectionNine/>
      <SectionEleven/>
      <SectionFaqRepair/>
    </>
  );
}

export default ShoeRepair;
