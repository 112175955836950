import React from "react";
import { useSelector } from "react-redux/es/exports";
import SectionTwelveBannerThemeThree from "./SectionTwelveBannerThemeThree";
import SectionOneBannerThemeThree from "./SectionOneBannerThemeThree";
import SectionElevenThemeThree from "./SectionElevenThemeThree";
import SectionThirteenThemeThree from "./SectionThirteenThemeThree";
import SectionFifteenThemeThree from "./SectionFifteenThemeThree";
import SectionThreeThemeThree from "./sectionThreeThemeThree";
import SectionFourThemeThree from "./sectionFourThemeThree";
import SectionFiveThemeThree from "./sectionFiveThemeThree";
import SectionSixThemeThree from "./sectionSixThemeThree";
import SectionSevenThemeThree from "./sectionSevenThemeThree";
import SectionEightThemeThree from "./SectionEightThemeThree";
import SectionTenBannerThemeThree from "./SectionTenBannerThemeThree";
import TTSlider from "../../components/ttSlider";
import SectionNineThemeThree from "./sectionNineThemeThree";
import SectionOffer from "../theme-four/section-offer";
import GoogleReviews from "../googleReview";
import HomeShopList from "../homeShopList";
import { checkShopListFlow, setValue } from '../../utility';
import DailyDeals from "../dailyDeals";

function ThemeBlack() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  

  return (
    <>
          <SectionOneBannerThemeThree />
          <GoogleReviews />
          <SectionThreeThemeThree />
          <SectionFourThemeThree />
          <SectionFiveThemeThree />
          <SectionSixThemeThree />
          {/* <SectionSevenThemeThree /> */}
          <SectionEightThemeThree />
          <SectionNineThemeThree />
          {/* <SectionTenBannerThemeThree /> */}
          <SectionElevenThemeThree />
          <SectionTwelveBannerThemeThree />
          <SectionThirteenThemeThree />
          <SectionFifteenThemeThree />
        
    </>
  );
}

export default ThemeBlack;
