import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {wlsl1,wlsl2,wlsl3,wlsl4,slider5,slider6,slider7,slider8,upicon1,upicon2,upicon3,upicon4,upicon5,upicon6,upicon7,upicon8} from "../../assets/img";
import { Link, useNavigate, NavLink } from "react-router-dom";

function Home () {

    useEffect(()=>{
        localStorage.setItem("shop_id","1")
    },[])

return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12">                    
                    <div className="slider-wl">
                        <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        className="slider-up-slider"
                        >
                            <SwiperSlide >                            
                                <img src={wlsl1} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={wlsl2} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={wlsl3} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                            <SwiperSlide >                            
                                <img src={wlsl4} alt="Hero Image" className="img-fluid" />
                            </SwiperSlide>
                      </Swiper>
                    </div>
                </div>
            </div>
            <div className="row py-5">
                <div className="col-md-6">   
                    <div className="wl-brn text-center">
                        <NavLink to={"/bookingbusiness"} className="btn btn-primary">
                            Book Collection Business
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-6">   
                    <div className="wl-brn text-center">
                        <NavLink to={"/bookingdomestic"} className="btn btn-primary">
                            Book Collection Domestic
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">  
                    <div className="wl-card">
                        <h3>Launderette <span className="text-secondary">Services</span></h3>
                        <p>Our state of the art JLA/IPSO commercial class washing machines provide a really thorough wash and fast-speed spin meaning less drying and less time. Our computer controlled large capacity gas dryers dry in no time. We have a choice of medium, large & extra large washers and large capacity dryers suitable for even the largest of loads.</p>
                        <h3>Washing Machines</h3>
                        <ul>
                            <li>Medium - £5.50 up to 16lb (8KG)</li>
                            <li>Large - £7.00 up to 30lb (14 KG)</li>
                            <li>Extra Large - £7.50 up to 40lb (18KG)</li>
                        </ul>
                        <p>Change No Problem, All our machines take multiple coins 20p's 50p's £1 and £2 coins</p>
                    </div>
                </div>    
                <div className="col-md-4">  
                    <div className="wl-card">
                        <h3>Laundry <span className="text-secondary">Services</span></h3>
                        <p>Weekly washing getting you down? Too busy to get around to it? We can remove the hassle out of your laundry and give you some extra time to do things which you really enjoy. Whatever your laundry needs, we will have a service to suit you. Whether it’s a spring clean for your duvet or your whole week’s laundry, we can collect from your home or place of work and return it fresh, clean and ready to go. Our service wash is fast, easy and great value. Can’t come to us? Then we can arrange to collect from you. Try our VIP Service, a complete collect and return laundry service.</p>
                    </div>
                </div> 
                <div className="col-md-4">  
                    <div className="wl-card">
                        <h3>Dry <span className="text-secondary">Cleaning</span></h3>
                        <p>We are able to provide dry cleaning for all items of clothing, whether every day wear or special garments such as wedding dresses or formal wear. You can rest assured all your garments will be cleaned to the highest standard, hand checked and finished. We use the latest in garment cleaning technology.</p>
                    </div>
                </div>             
            </div>
            <div className="row justify-content-center">                   
                <div className="col-md-4">  
                    <div className="wl-card">
                        <p><b className="text-primary">Gas <span className="text-secondary">Dryers</span></b> We have recently installed new state of the art Digital Alliance Twin stack gas driers – super fast, super efficient average loads can be dried in 20-30 mins.</p>
                        <p><b className="text-primary">Service <span className="text-secondary">Washes</span></b> We provide one-off or regular service for clothes, bedding, labcoats, workwear etc. Drop off or collection. Priced per Kg.</p>
                    </div>
                </div>            
            </div>
            <div className="row">
                <div className="col-md-4">  
                    <div className="wl-card">
                        <h3>Commercial <span className="text-secondary">Laundry</span> Service</h3>
                        <p>We provide comprehensive laundry services to:</p>
                        <ul>
                            <li>- Dress hire agencies</li>
                            <li>- Sports centers, gyms, and leisure clubs</li>
                            <li>- Restaurants - tablecloths, serviettes, uniforms</li>
                            <li>- Hotels & guest houses - bed linen, duvets, towels</li>
                            <li>- Hairdressing & beauty salons - towels and uniforms</li>
                        </ul>
                    </div>
                </div>    
                <div className="col-md-4">  
                    <div className="wl-card">
                        <h3>Ironing <span className="text-secondary">Services</span></h3>
                        <p>Ironing, usually on everyone’s list of least favorite chores. Pressed for time or just hate ironing? You’ll love our ironing service. Affordable pricing to the highest professional quality using our commercial equipment. PLEASE NOTE we are only equipped to iron bedding and flat items, we DON'T currently iron clothing sorry.</p>
                    </div>
                </div> 
                <div className="col-md-4">  
                    <div className="wl-card">
                        <h3>Opening <span className="text-secondary">Times</span></h3>
                        <h4 className="text-primary">Self-service Open 7.30am – 9.30pm everyday</h4>
                        <h4 className="text-primary">Staffed Mondays to Thursdays 9.30am to 1.00pm for service washes etc</h4>
                    </div>
                </div>             
            </div>
            <div className="row">
                <div className="col-md-12">
                    <p className="mb-3">If you have a regular commercial laundry requirement, please give us a call on 01865 890998, where we will be happy to discuss your requirements. If you are lucky enough to find a service cheaper elsewhere, we would be happy to beat any genuine quote.</p>
                    <p className="mb-5">Collection from Wheatley, Garsington, Horspath Holton, Headington, Summertown, Botley, Cowley, Forest Hill, Thame, Tetsworth, Postcombe, Watlington, Chalgrove, Stadhampton, Iffley, Oxford city and all surrounding areas maximum 15 miles. Delivery charges may apply.</p>
                </div>
            </div>
        </div>
    </>
)
}
export default Home;