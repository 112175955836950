import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";

function FooterTwoEdit() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const data: any = useSelector<any>((state) => state.homeJsonList);
  const [errors, setErrors] = useState({
    mbl_number: "",
    email_val: "",
  });
  useEffect(() => {
    setEditData(data);
  }, [data]);

  const saveJsonDataToFile = (e:any) => {
    e.preventDefault();

    if (validateInputs()) {
      dispatch(fetchHomeJsonList(editData));
      dispatch(saveThemeJsonData(editData));
    }
    else {
      return false
    }
  };

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2: {
        ...prevJsonData.theme_2,
      home: {
        ...prevJsonData.theme_2.home,
        footer: {
          ...prevJsonData.theme_2.home.footer,
          [name]: value,
          footer_section: {
            ...prevJsonData.theme_2.home.footer.footer_section,
            social_media: {
              ...prevJsonData.theme_2.home.footer.footer_section.social_media,
              facebook: {
                is_enable:
                type === "checkbox" && name === "facebook_check"
                ? !prevJsonData.theme_2.home.footer.footer_section.social_media.facebook.is_enable
                : prevJsonData.theme_2.home.footer.footer_section.social_media.facebook.is_enable,
                facebook_link:
                name === "facebook"
                  ? value
                  : prevJsonData.theme_2.home.footer.footer_section.social_media.facebook.facebook_link,
              },
              instagram: {
                is_enable:
                type === "checkbox" && name === "instagram_check"
                ? !prevJsonData.theme_2.home.footer.footer_section.social_media.instagram.is_enable
                : prevJsonData.theme_2.home.footer.footer_section.social_media.instagram.is_enable,
                instagram_link:
                name === "instagram"
                  ? value
                  : prevJsonData.theme_2.home.footer.footer_section.social_media.instagram.instagram_link,
              },
              whatsapp: {
                is_enable:
                type === "checkbox" && name === "whatsapp_check"
                ? !prevJsonData.theme_2.home.footer.footer_section.social_media.whatsapp.is_enable
                : prevJsonData.theme_2.home.footer.footer_section.social_media.whatsapp.is_enable,
                whatsapp_link:
                name === "whatsapp"
                  ? value
                  : prevJsonData.theme_2.home.footer.footer_section.social_media.whatsapp.whatsapp_link,
              },
              linkedin: {
                is_enable:
                type === "checkbox" && name === "linkedin_check"
                ? !prevJsonData.theme_2.home.footer.footer_section.social_media.linkedin.is_enable
                : prevJsonData.theme_2.home.footer.footer_section.social_media.linkedin.is_enable,
                linkedin_link:
                name === "linkedin"
                  ? value
                  : prevJsonData.theme_2.home.footer.footer_section.social_media.linkedin.linkedin_link,
              },
              youtube: {
                is_enable:
                type === "checkbox" && name === "youtube_check"
                ? !prevJsonData.theme_2.home.footer.footer_section.social_media.youtube.is_enable
                : prevJsonData.theme_2.home.footer.footer_section.social_media.youtube.is_enable,
                youtube_link:
                name === "youtube"
                  ? value
                  : prevJsonData.theme_2.home.footer.footer_section.social_media.youtube.youtube_link,
              },
              twitter: {
                is_enable:
                type === "checkbox" && name === "twitter_check"
                ? !prevJsonData.theme_2.home.footer.footer_section.social_media.twitter.is_enable
                : prevJsonData.theme_2.home.footer.footer_section.social_media.twitter.is_enable,
                twitter_link:
                name === "twitter"
                  ? value
                  : prevJsonData.theme_2.home.footer.footer_section.social_media.twitter.twitter_link,
              },
            },
          },
        },
      },
    }
    }));
    validateInputs();
  };
  const validateInputs = () => {
    const newErrors = { mbl_number: "", email_val: "" };
  
    // Validate mobile number (assuming you want to allow only digits and a specific length)
    const mobileRegex = /^[0-9]{10,15}$/;
    if (!mobileRegex.test(editData?.theme_2?.home?.footer?.mbl_number)) {
      newErrors.mbl_number = "Please enter a valid mobile number (10-15 digits).";
    }
  
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(editData?.theme_2?.home?.footer?.email_val)) {
      newErrors.email_val = "Please enter a valid email address.";
    }
  
    setErrors(newErrors);
  
    // Return true if no errors, false otherwise
    return !newErrors.mbl_number && !newErrors.email_val;
  };

  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Footer Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Footer Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="bg_color"
                        value={editData?.theme_2?.home?.footer?.bg_color}
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="bg_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={editData?.theme_2?.home?.footer?.bg_color}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Section Header</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_heading_color"
                        value={editData?.theme_2?.home?.footer?.section_heading_color}
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="section_heading_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={editData?.theme_2?.home?.footer?.section_heading_color}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Paragraph color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="section_paragraph_color"
                        value={editData?.theme_2?.home?.footer?.section_paragraph_color}
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="section_paragraph_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={editData?.theme_2?.home?.footer?.section_paragraph_color}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">About Paragraph<small>(max 250 char)</small></label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="about_section"
                        value={
                          editData?.theme_2?.home?.footer?.footer_section?.about_section
                            ?.text
                        }
                        onChange={handleChange}
                        maxLength={250}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mb-3">
                    <label className="form-label">&nbsp;</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="about_is_enable"
                        value="true"
                        onChange={handleChange}
                        checked={
                          editData?.theme_2?.home?.footer?.footer_section?.about_section
                            ?.is_enable
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col-auto">
                  <div className="mb-3">
                    <label className="form-label">Mobile</label>
                    <input
                      type="text"
                      className="form-control"
                      name="mbl_no.label"
                      value={
                        editData?.theme_2?.home?.footer?.footer_section
                          ?.callnow_for_Services_section?.mbl_no?.label
                      }
                      onChange={handleChange}
                      maxLength={15}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="email.label"
                        value={
                          editData?.theme_2?.home?.footer?.footer_section
                            ?.callnow_for_Services_section?.email?.label
                        }
                        onChange={handleChange}
                      />
                      <input
                        type="text"
                        className="form-control"
                        name="email.value"
                        value={
                          editData?.theme_2?.home?.footer?.footer_section
                            ?.callnow_for_Services_section?.email?.value
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Address<small>(max 250 char)</small></label>
                    <input
                      type="text"
                      className="form-control"
                      name="address.text"
                      value={
                        editData?.theme_2?.home?.footer?.footer_section
                          ?.callnow_for_Services_section?.address?.text
                      }
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="edit-section">
              <div className="row">
                <div className="col-auto">
                  <div className="mb-3">
                    <label className="form-label">Mobile</label>
                    <input
                      type="text"
                      className="form-control"
                      name="mbl_number"
                      value={
                        editData?.theme_2?.home?.footer?.mbl_number
                      }
                      onChange={handleChange}
                      maxLength={15}
                    />
                    {errors.mbl_number && <small className="text-danger">{errors.mbl_number}</small>}
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email_val"
                        value={
                          editData?.theme_2?.home?.footer?.email_val
                        }
                        onChange={handleChange}
                      />
                      {errors.email_val && <small className="text-danger">{errors.email_val}</small>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Address<small>(max 250 char)</small></label>
                    <input
                      type="text"
                      className="form-control"
                      name="address_val"
                      value={
                        editData?.theme_2?.home?.footer?.address_val
                      }
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
            <label className="form-label">Social Links&nbsp;<small>Ex:- www.facebook.com</small></label>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Facebook</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="facebook_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.facebook?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="facebook"
                        onChange={handleChange}
                        value={
                          editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.facebook?.facebook_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Instagram</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="instagram_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.instagram?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="instagram"
                        onChange={handleChange}
                        value={
                          editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.instagram?.instagram_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Twitter</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="twitter_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.twitter?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="twitter"
                        onChange={handleChange}
                        value={
                          editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.twitter?.twitter_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Linkedin</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="linkedin_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.linkedin?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="linkedin"
                        onChange={handleChange}
                        value={
                          editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.linkedin?.linkedin_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Youtube</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="youtube_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.youtube?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="youtube"
                        onChange={handleChange}
                        value={
                          editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.youtube?.youtube_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Whatsapp</label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="whatsapp_check"
                          onClick={handleChange}
                            checked={
                              editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.whatsapp?.is_enable
                            }
                        />
                      </div>
                      <span className="urlplaceholder">
                      <input
                        type="text"
                        className="form-control"
                        name="whatsapp"
                        onChange={handleChange}
                        value={
                          editData?.theme_2?.home?.footer?.footer_section
                            ?.social_media?.whatsapp?.whatsapp_link
                        }
                      />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={(e) => {
                  saveJsonDataToFile(e);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterTwoEdit;
