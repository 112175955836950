import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import Home from "./home";

function UpLands() {
    return (
        <>
            <Home/>
        </>
    )
}

export default UpLands;