import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  slider7,
  slider8,
  upimg4,
  placeholder,
} from "../../assets/img";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setValue } from "../../utility";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import { fetchAddToCart } from "../../redux/Actions/addCartAction";
import { setUserProductCategories } from "../../redux/Actions/productCategoriesAction";
import {
  deleteCartCount,
  setCartCount,
} from "../../redux/Actions/cartCountAction";
import { fetchSplitPriceDetails } from "../../redux/Actions/splitPriceAction";

function WheatleyCart() {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState("00:00");
  const dispatch = useDispatch<any>();
  const { cartCount }: any = useSelector<any>((state) => state);
  const navigate = useNavigate();
  const [cartInformation, setCartInformation] = useState([]);
  const settings: any = useSelector<any>((state) => state.settings);
  const state: any = useSelector<any>(
    (state) => state.ProductAllCategories.data
  );
  const selectedcategoryPriceList: any = useSelector<any>(
    (state) => state.splitPrice
  );
  const selectedCategories: any = useSelector<any>(
    (state) => state.userSelectedCategories
  );

  const onAddToCartItemHandler = (
    selectedSubCategoryId: any,
    selectedMainCategoryId: any,
    type: any
  ) => {
    const selectedCategoryItem: any = selectedCategories?.find(
      (item: any) => item.main_category_id === selectedMainCategoryId.toString()
    );

    const getSubCategory = selectedCategoryItem?.sub_categories?.find(
      (item: any) => item.sub_category_id === selectedSubCategoryId
    );

    const { quantity = 0 } = getSubCategory;
    let updatedQuantity = parseInt(quantity);
    if (type === "remove") {
      updatedQuantity = 0;
      dispatch(deleteCartCount(0));
    } else if (type === "minus") {
      updatedQuantity = updatedQuantity - 1;
      dispatch(deleteCartCount(1));
    } else {
      updatedQuantity = updatedQuantity + 1;
      dispatch(setCartCount(cartCount + 1));
    }

    const updatedSubCategory = {
      ...getSubCategory,
      quantity: updatedQuantity.toString(),
    };

    const updatedSubCategoriesList = selectedCategoryItem?.sub_categories?.map(
      (item: any) => {
        if (item.sub_category_id === selectedSubCategoryId) {
          return updatedSubCategory;
        }
        return item;
      }
    );

    const updatedCategory = {
      ...selectedCategoryItem,
      sub_categories: updatedSubCategoriesList,
    };

    const updatedCategoriesList = selectedCategories?.map((item: any) => {
      if (item.main_category_id === selectedMainCategoryId.toString()) {
        return updatedCategory;
      }
      return item;
    });
    dispatch(setUserProductCategories(updatedCategoriesList));
    setValue("cartInformationData", updatedCategoriesList);
  };
  const handleContinue = (e: any) => {
    e.preventDefault();
    setValue("orderType", 0);
    const selectedMainCategory = selectedCategories?.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });

    if (selectedMainCategory.length == 0) {
      toast.warning("Please select atlease one product to continue");
      return false;
    }

    const postalCode = JSON.parse(localStorage.getItem("postalCode")!);
    const shopId = localStorage.getItem("shop_id");
    let finalTotalPrice: any = 0;

    let payload: any = {
      shop_id: shopId,
      postal_code: postalCode,
      total_tax_price: selectedcategoryPriceList?.vat_amount,
      total_price_without_deductions:
        selectedcategoryPriceList?.final_payable_amount,
      total_price_without_vat:
        parseFloat(selectedcategoryPriceList?.total_price_amount) -
        parseFloat(selectedcategoryPriceList?.vat_amount),
      group_id: "1",
      order_type: "0",
      cart_total_count: selectedcategoryPriceList?.itemsCount,
    };

    selectedMainCategory?.map((item: any) => {
      const subcategoryList = item?.sub_categories?.filter(
        (subItem: any) => parseInt(subItem.quantity) > 0
      );

      subcategoryList?.map((subcategory: any) => {
        //TODO: handle thru single selection from the dropdown
        const selectedOption = subcategory?.options[0]; //subcategory?.options?.find((option:any) => option?.isSelected)

        finalTotalPrice =
          finalTotalPrice +
          parseInt(subcategory?.quantity) *
            parseFloat(subcategory?.total_price);
        payload = {
          ...payload,
          [`option_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_id,
          [`process_time_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.process_time,
          [`productcount_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`quantity_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`price_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_price,
          [`product_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.sub_category_name,
        };
      });
    });

    payload = {
      ...payload,
      total_price: finalTotalPrice.toString(),
      finalTotalPrice: finalTotalPrice.toString(),
    };
    localStorage.setItem("order_type", payload.order_type);
    localStorage.setItem("shop_id", payload.shop_id);
    // TODO: call the api and pass the
    // dispatch(fetchPickupDelivery(payload))
    dispatch(fetchAddToCart(payload));
    navigate("/wheatleycheckout");
  };

  useEffect(() => {
    if (selectedCategories.length) {
      const cartInformationData = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });

      if (cartInformationData.length) {
        setValue("cartInformationData", cartInformationData);
      }
      if (cartInformationData.length == 0) {
        navigate("/");
      }
      setCartInformation(cartInformationData);

      const selectedMainCategory = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });
      let finalTotalPrice = 0;
      let itemsCount = 0;

      selectedMainCategory?.map((item: any) => {
        const subcategoryList = item?.sub_categories?.filter(
          (subItem: any) => parseInt(subItem.quantity) > 0
        );
        itemsCount = itemsCount + subcategoryList?.length;
        subcategoryList?.map((subcategory: any) => {
          finalTotalPrice =
            finalTotalPrice +
            parseInt(subcategory?.quantity) *
              parseFloat(subcategory?.total_price);
        });
      });
      dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
      if (selectedMainCategory.length == 0) {
        localStorage.setItem("is_removed", "true");
      }
    } 
  }, [selectedCategories]);

  useEffect(() => {
    if (state) {
      const { category = [] } = state;
      if (category?.length > 0) {
        if (!localStorage.getItem("isloaded")) {
          if (localStorage.getItem("is_removed")) {
            let cat = category?.filter((item: any) => {
              return item?.sub_categories?.some(
                (subItem: any) => parseInt(subItem?.quantity) > 0
              );
            });

            if (cat.length == 0) {
              cat = category;
            }

            let subcat = cat[0]?.sub_categories?.find(
              (subItem: any) => parseInt(subItem?.quantity) > 0
            );

            if (subcat == undefined || subcat.length == 0) {
              subcat = category[0]?.sub_categories[0];
            }

            const selectedCategoryItem: any = category?.find(
              (item: any) =>
                item.main_category_id === cat[0].main_category_id.toString()
            );

            const getSubCategory = selectedCategoryItem?.sub_categories?.find(
              (item: any) => item.sub_category_id === subcat.sub_category_id
            );

            const updatedSubCategory = {
              ...getSubCategory,
              quantity: 0,
            };

            const updatedSubCategoriesList =
              selectedCategoryItem?.sub_categories?.map((item: any) => {
                if (item.sub_category_id === subcat.sub_category_id) {
                  return updatedSubCategory;
                }
                return item;
              });

            const updatedCategory = {
              ...selectedCategoryItem,
              sub_categories: updatedSubCategoriesList,
            };
            const updatedCategoriesList = category?.map((item: any) => {
              if (
                item.main_category_id === (cat[0]?.main_category_id).toString()
              ) {
                return updatedCategory;
              }
              return item;
            });

            dispatch(setUserProductCategories(updatedCategoriesList));
          } else {
            dispatch(setUserProductCategories(category));
          }
          localStorage.setItem("isloaded", "true");
        }
      }
    }
  }, [state]);

  useEffect(() => {
    dispatch(
      setUserProductCategories(
        JSON.parse(localStorage.getItem("cartInformationData") || "[]")
      )
    );
  }, []);

  return (
    <>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Cart</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>Cart</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="prodcat-list tabble-responsive my-5">
              <table className="table">
                <tr>
                  <th></th>
                  <th>PRODUCT</th>
                  <th>PRICE</th>
                  <th>QUANTITY</th>
                  <th>SUBTOTAL</th>
                </tr>
                {selectedCategories[0]?.sub_categories?.map(
                  (item: any, index: any) => {
                    return (
                      <>
                        {item?.quantity > 0 ? (
                          <tr>
                            <td>
                              <img
                                src={item.web_sub_category_img}
                                title={item.sub_category_name}
                                alt=""
                                width={65}
                              />
                            </td>
                            <td>
                              <h5>{item.sub_category_name}</h5>
                            </td>
                            <td>
                              <div className="amount">
                                <span>
                                  {settings?.WebmasterSettings?.currency}
                                </span>
                                {parseFloat(item?.total_price)}
                              </div>
                            </td>
                            <td className="w-25" >
                              <div className="amountlist" id="amountlist">
                                <div className="listcloth-rht">
                                  <span>
                                    <button
                                      type="button"
                                      className="quantity-left-minus btn btn-number"
                                      data-type="minus"
                                      data-field=""
                                      disabled={item.quantity === "0"}
                                      onClick={() =>
                                        onAddToCartItemHandler(
                                          item.sub_category_id,
                                          selectedCategories[0]
                                            .main_category_id,
                                          "minus"
                                        )
                                      }
                                    >
                                      <span className="glyphicon glyphicon-minus"></span>
                                    </button>
                                  </span>
                                  <span>
                                    <input
                                      placeholder=""
                                      className="form-control input-number inp-item-quantity quantity"
                                      id="quantity_1_1_1"
                                      name="quantity_1_1_1"
                                      type="text"
                                      value={item?.quantity}
                                    />
                                  </span>
                                  <span>
                                    <button
                                      type="button"
                                      className="quantity-right-plus btn btn-number"
                                      data-type="plus"
                                      data-field=""
                                      onClick={() =>
                                        onAddToCartItemHandler(
                                          item.sub_category_id,
                                          selectedCategories[0]
                                            .main_category_id,
                                          "add"
                                        )
                                      }
                                    >
                                      <span className="glyphicon glyphicon-plus"></span>
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="amount">
                                <span>
                                  {settings?.WebmasterSettings?.currency}
                                </span>
                                {parseInt(item?.quantity) === 0
                                  ? parseFloat(item?.total_price)
                                  : parseFloat(item?.total_price) *
                                    parseFloat(item?.quantity)}
                              </div>
                            </td>
                            <td>
                              {
                                <span
                                  onClick={() =>
                                    onAddToCartItemHandler(
                                      item.sub_category_id,
                                      selectedCategories[0].main_category_id,
                                      "remove"
                                    )
                                  }
                                >
                                  X
                                </span>
                              }
                            </td>
                          </tr>
                        ) : null}
                      </>
                    );
                  }
                )}
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <div className="amt-payable my-5">
              <div className="price-header text-center">
                <h2>Cart summary</h2>
              </div>
              <div className="price-body">
                {cartInformation?.map((item: any) => {
                  return (
                    <>
                      <div className="cs-option-price">
                        <span className="cs-summary-title">
                          <h4>{item?.main_category_name}</h4>
                        </span>
                        {item?.sub_categories
                          ?.filter(
                            (subItem: any) => parseInt(subItem.quantity) > 0
                          )
                          ?.map((subCategory: any) => {
                            return (
                              <div className="cs-option-body">
                                <div className="cs-option-left">
                                  <span>{subCategory?.sub_category_name} </span>
                                  <span className="cs-option-pqty">
                                    ({subCategory?.quantity})
                                  </span>
                                </div>
                                <div className="cs-option-right">
                                  {settings?.WebmasterSettings?.currency}
                                  &nbsp;
                                  {(
                                    parseFloat(subCategory?.quantity) *
                                    parseFloat(subCategory?.total_price)
                                  ).toFixed(2)}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  );
                })}
                <div className="cs-option-amt">
                  <div className="cs-option-amt-list">
                    <div className="cs-option-amt-left">
                      Price ({selectedcategoryPriceList?.itemsCount} items)
                    </div>
                    <div className="cs-option-amt-right">
                      {settings?.WebmasterSettings?.currency}{" "}
                      {selectedcategoryPriceList?.total_price_amount}
                    </div>
                  </div>

                  {selectedcategoryPriceList?.vat_percentage > 0 ? (
                    <>
                      <div className="cs-option-amt-list">
                        <div className="cs-option-amt-left">
                          Amount without VAT
                        </div>
                        <div className="cs-option-amt-right">
                          {settings?.WebmasterSettings?.currency}
                          {(
                            parseFloat(
                              selectedcategoryPriceList?.total_price_amount
                            ) -
                            parseFloat(selectedcategoryPriceList?.vat_amount)
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div className="cs-option-amt-list">
                        <div className="cs-option-amt-left">
                          VAT ({selectedcategoryPriceList?.vat_percentage}
                          %)
                        </div>
                        <div className="cs-option-amt-right">
                          {settings?.WebmasterSettings?.currency}{" "}
                          {selectedcategoryPriceList?.vat_amount}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="orders-total">
                    <span className="lft-ordertitle">Delivery Charges</span>
                    {selectedcategoryPriceList.pickup_and_delivery_charge >
                    0 ? (
                      <span className="rht-orderamt float-end">
                        {settings?.WebmasterSettings?.currency}{" "}
                        {selectedcategoryPriceList.pickup_and_delivery_charge}
                      </span>
                    ) : (
                      <span className="rht-orderamt float-end">Free</span>
                    )}
                  </div>

                  <div className="cs-option-amt-list cs-option-amt-listbg">
                    <div className="cs-option-amt-left">Amount Payable</div>
                    <div className="cs-option-amt-right">
                      {settings?.WebmasterSettings?.currency}{" "}
                      {selectedcategoryPriceList?.final_payable_amount}
                    </div>
                  </div>
                </div>
              </div>
              <div className="price-footer">
                <button
                  type="submit"
                  id="submit-btn"
                  className="continue-btn hover-btn center-block"
                  data-quantity="2"
                  data-price="70.00"
                  onClick={handleContinue}
                >
                  Continue{" "}
                  <span className="option-total total-amt">
                    {settings?.WebmasterSettings?.currency}{" "}
                    {selectedcategoryPriceList?.final_payable_amount}
                  </span>
                </button>
              </div>
              {/* <div className="price-header text-center">
                <h2>Cart Totals</h2>
              </div>
              <div className="cs-option-amt">
                <div className="cs-option-amt-list">
                  <div className="cs-option-amt-left">Subtotal</div>
                  <div className="cs-option-amt-right">£53.00</div>
                </div>
                <div className="cs-option-amt-list">
                  <div className="cs-option-amt-left">Shipping</div>
                  <div className="cs-option-amt-right">
                    Flat Rate <b>£5.00</b>
                  </div>
                </div>
                <div className="cs-option-amt-list  cs-option-amt-listbg">
                  <div className="cs-option-amt-left">Amount Payable</div>
                  <div className="cs-option-amt-right">
                    <b>£58.00</b>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WheatleyCart;
