import React, { useEffect, useState } from "react";
import { useNavigate,NavLink,useParams } from "react-router-dom";
import { ttw1,ttw2,ttw3,ttw4,ttw5,ttw6,ttw7,ttw8,ttw9,ttw10,ttw11,ttw12,ttw13,ttw14,ttw15,ttw16,ttw17,ttw18,ttw19,ttw20,ttw21,ttw22,ttw23,ttw24,ttw25,ttw26,ttw27,ttw28,ttw29,ttw30,ttw31,ttw32,ttw33,ttw34,ttw35,ttw36,ttw37,ttw39,ttw40,ttw41,ttw42 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";
import { Accordion } from "react-bootstrap";

function SectionFaqRepair () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const params = useParams<any>();
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <section className="secbgwhite">
            <div className="container">
                <div className="row justify-content-center">              
                    <div className="col-md-8">
                        <div className="secinfogrid3 pb-0">
                            <h2 className="t12-h21">Frequently Asked Questions</h2>
                                <Accordion className="secfaq" defaultActiveKey={['0']}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            How much does it cost to get your shoes repair?                                
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Our pricing varies based on the service required. Contact us for a personalized quote.                    
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            Do you offer pick-up and delivery services?                       
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, we provide convenient pick-up and delivery options nationwide.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            Can you repair patent leather shoes?                
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Absolutely! We specialize in repairing and restoring all types of footwear, including patent leather.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            How often should I polish my shoes?                           
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Depending on usage, we recommend regular polishing to maintain their shine and longevity.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        What makes Hello Cobbler's shoe repair service unique?                  
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        We use premium products and techniques customized to each shoe type for superior results.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        Do you offer tips for shoe care?                  
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes, we provide expert advice on maintaining and caring for your shoes to extend their lifespan.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                        </div>
                        <div className="secinfogrid text-center">
                            <NavLink className="btn btnprime" to="/product-lists">
                                Book Now
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionFaqRepair;
