import React, { useEffect, useState } from "react";
import {
  cleancloths,
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
} from "../../../assets/img";
import axios from "axios";
import { useSelector } from "react-redux";

const SectionTenBannerThemeOne = () => {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const [blogList, setBlogList] = useState<any | null>({});
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);

  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  // HTML
  return (
    <>
      <section className="sh1-latestnews-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="sh1-section-title">
                {jsonData?.theme_1?.home?.section_10?.title}
              </h2>
            </div>
            {jsonData?.theme_1?.home?.section_10?.button?.is_enable && (
              <div className="col-md-6 text-end">
                <a
                  target="_blank"
                  href={blogUrl}
                  className="btn primary-btn hover-btn"
                  style={{
                    background: `#${jsonData?.theme_1?.home?.section_10?.button?.bg_color}`,
                    color: `#${jsonData?.theme_1?.home?.section_10?.button?.color}`,
                  }}
                >
                  {jsonData?.theme_1?.home?.section_10?.button?.text}
                </a>
              </div>
            )}
          </div>
          <div className="row">
            {blogList.length > 0 && (
              <div className="col-md-4">
                <div className="sh1-latestnews-card">
                  <div className="sh1-latestnews-img">
                    <img src={testimonialimg1} alt="" />
                    <div className="sh1-latestnews-overlay">
                      <div className="sh1-newsdate">
                        {blogList[0].formatted_date}
                        <span className="sh1-newsday">
                          {blogList[0].formatted_month}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="sh1-latestnews-info">
                    <div className="sh1-profile-user">
                      <a className="sh1-profile-user-img">
                        <img src={blogList[0].grid_sm_img} alt="" />
                      </a>
                      <div className="sh1-profile-user-info">
                        <h6>
                          <a
                            target="_blank"
                            href={`${blogUrl}/blogg/${
                              blogList[0].id
                            }/${blogList[0].page_title
                              .toLowerCase()
                              .replaceAll(" ", "-")
                              .replaceAll(":", "")}`}
                          >
                            {blogList[0].page_title.substring(0, 65)}
                            {blogList[0].page_title.length > 65 ? "..." : ""}
                          </a>
                        </h6>
                        {/* <p>Chairman and founder</p> */}
                      </div>
                    </div>
                    <p>
                      {blogList[0].preview_text.substring(0, 140)}
                      {blogList[0].preview_text.length > 140 ? "..." : ""}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {blogList.length > 1 && (
              <div className="col-md-4">
                <div className="sh1-latestnews-card">
                  <div className="sh1-latestnews-img">
                    <img src={testimonialimg2} alt="" />
                    <div className="sh1-latestnews-overlay">
                      <div className="sh1-newsdate">
                        {blogList[1].formatted_date}
                        <span className="sh1-newsday">
                          {blogList[1].formatted_month}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="sh1-latestnews-info">
                    <div className="sh1-profile-user">
                      <a className="sh1-profile-user-img">
                        <img src={blogList[1].grid_sm_img} alt="" />
                      </a>
                      <div className="sh1-profile-user-info">
                        <h6>
                          <a
                            target="_blank"
                            href={`${blogUrl}/blogg/${
                              blogList[1].id
                            }/${blogList[1].page_title
                              .toLowerCase()
                              .replaceAll(" ", "-")
                              .replaceAll(":", "")}`}
                          >
                            {blogList[1].page_title.substring(0, 65)}
                            {blogList[1].page_title.length > 65 ? "..." : ""}
                          </a>
                        </h6>
                        {/* <p>Chairman and founder</p> */}
                      </div>
                    </div>
                    <p>
                      {blogList[1].preview_text.substring(0, 140)}
                      {blogList[1].preview_text.length > 140 ? "..." : ""}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {blogList.length > 2 && (
              <div className="col-md-4">
                <div className="sh1-latestnews-card">
                  <div className="sh1-latestnews-img">
                    <img src={testimonialimg3} alt="" />
                    <div className="sh1-latestnews-overlay">
                      <div className="sh1-newsdate">
                        {blogList[2].formatted_date}
                        <span className="sh1-newsday">
                          {blogList[2].formatted_month}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="sh1-latestnews-info">
                    <div className="sh1-profile-user">
                      <a className="sh1-profile-user-img">
                        <img src={blogList[2].grid_sm_img} alt="" />
                      </a>
                      <div className="sh1-profile-user-info">
                        <h6>
                          <a
                            target="_blank"
                            href={`${blogUrl}/blogg/${
                              blogList[2].id
                            }/${blogList[2].page_title
                              .toLowerCase()
                              .replaceAll(" ", "-")
                              .replaceAll(":", "")}`}
                          >
                            {blogList[2].page_title.substring(0, 65)}
                            {blogList[2].page_title.length > 65 ? "..." : ""}
                          </a>
                        </h6>
                        {/* <p>Chairman and founder</p> */}
                      </div>
                    </div>
                    <p>
                      {blogList[2].preview_text.substring(0, 140)}
                      {blogList[2].preview_text.length > 140 ? "..." : ""}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionTenBannerThemeOne;
