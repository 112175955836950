import React from "react";
import { tick } from "../../../assets/img";
import { useSelector } from "react-redux";
import { shop_data } from "../edit/data";

function SectionThreeThemeTwo() {
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);
  // const jsonData = shop_data
  return (
    <>
      <section className="sh2-highlight-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh2-highlight-row">
                <div className="row">
                  <div className="col-md-6">
                    <div className="sh2-highlight-box sh2-highlight-bg1">
                      <div className="sh2-highlight-img">
                        <img
                          src={
                            jsonData.theme_2?.home?.section_3?.cards[0].bg_img
                          }
                          alt=""
                        />
                        <div className="sh2-highlight-overlay">
                        <h3 style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h2color}`
                    }}>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[0]
                                .card_title
                            }
                          </h3>
                          <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.paracolor}`
                    }}>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[0]
                                .card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[0].services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1" style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h3color}`
                    }}>
                                      <img src={tick} alt="" />
                                      {services.list}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="sh2-highlight-content">
                      <h4 style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h2color}`
                    }}>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[0]
                              .card_main_title
                          }
                        </h4>
                        <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h3color}`
                    }}>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[0]
                              .card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[0].card_options.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.paracolor}`
                    }}>
                                    <img src={tick} alt="" />
                                    {checkList.list}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sh2-highlight-box sh2-highlight-bg2">
                      <div className="sh2-highlight-img">
                        <img
                          src={
                            jsonData.theme_2?.home?.section_3?.cards[1].bg_img
                          }
                          alt=""
                        />
                        <div className="sh2-highlight-overlay">
                        <h3 style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h2color}`
                    }}>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[1]
                                .card_title
                            }
                          </h3>
                          <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.paracolor}`
                    }}>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[1]
                                .card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[1].services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1" style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h3color}`
                    }}>
                                      <img src={tick} alt="" />
                                      {services.list}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="sh2-highlight-content">
                      <h4 style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h2color}`
                    }}>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[1]
                              .card_main_title
                          }
                        </h4>
                        <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h3color}`
                    }}>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[1]
                              .card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[1].card_options.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.paracolor}`
                    }}>
                                    <img src={tick} alt="" />
                                    {checkList.list}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sh2-highlight-row">
                <div className="row">
                  <div className="col-md-6">
                    <div className="sh2-highlight-box sh2-highlight-bg2">
                      <div className="sh2-highlight-img">
                        <img
                          src={
                            jsonData.theme_2?.home?.section_3?.cards[2].bg_img
                          }
                          alt=""
                        />
                        <div className="sh2-highlight-overlay">
                        <h3 style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h2color}`
                    }}>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[2]
                                .card_title
                            }
                          </h3>
                          <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.paracolor}`
                    }}>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[2]
                                .card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[2].services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1" style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h3color}`
                    }}>
                                      <img src={tick} alt="" />
                                      {services.list}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="sh2-highlight-content">
                      <h4 style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h2color}`
                    }}>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[2]
                              .card_main_title
                          }
                        </h4>
                        <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h3color}`
                    }}>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[2]
                              .card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[2].card_options.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.paracolor}`
                    }}>
                                    <img src={tick} alt="" />
                                    {checkList.list}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sh2-highlight-box sh2-highlight-bg1">
                      <div className="sh2-highlight-img">
                        <img
                          src={
                            jsonData.theme_2?.home?.section_3?.cards[3].bg_img
                          }
                          alt=""
                        />
                        <div className="sh2-highlight-overlay">
                        <h3 style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h2color}`
                    }}>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[3]
                                .card_title
                            }
                          </h3>
                          <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.paracolor}`
                    }}>
                            {
                              jsonData.theme_2?.home?.section_3?.cards[3]
                                .card_paragraph
                            }
                          </p>
                          <ul>
                            {jsonData.theme_2?.home?.section_3?.cards[3].services.map(
                              (services: any) => {
                                return (
                                  <>
                                    <li className="ml-1" style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h3color}`
                    }}>
                                      <img src={tick} alt="" />
                                      {services.list}
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="sh2-highlight-content">
                      <h4 style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h2color}`
                    }}>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[3]
                              .card_main_title
                          }
                        </h4>
                        <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.h3color}`
                    }}>
                          {
                            jsonData.theme_2?.home?.section_3?.cards[3]
                              .card_main_paragraph
                          }
                        </p>
                        <ul>
                          {jsonData.theme_2?.home?.section_3?.cards[3].card_options.map(
                            (checkList: any) => {
                              return (
                                <>
                                  <li style={{
                      color: `#${jsonData?.theme_2?.home?.section_3?.paracolor}`
                    }}>
                                    <img src={tick} alt="" />
                                    {checkList.list}
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionThreeThemeTwo;
