import React, { useEffect, useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import { ttw1,ttw2,ttw3,ttw4,ttw5,ttw6,ttw7,ttw8,ttw9,ttw10,ttw11,ttw12,ttw13,ttw14,ttw15,ttw16,ttw17,ttw18,ttw19,ttw20,ttw21,ttw22,ttw23,ttw24,ttw25,ttw26,ttw27,ttw28,ttw29,ttw30,ttw31,ttw32,ttw33,ttw34,ttw35,ttw36,ttw37,ttw39,ttw40,ttw41,ttw42 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";

function SectionEight () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  const [settings, setSettings] = useState<any>({});
  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link;
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        <section className="secbg1" id="secidhww">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="secinfogrid">                            
                            <h2 className="text-center">How We Work</h2>
                            <p className="text-center secinhdrp pb-4 pb-md-0">Our approach involves a meticulous process aimed at delivering exceptional results. We prioritize precision and attention to detail in every task we undertake, ensuring that your needs are met with the highest level of care and expertise.</p>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="secinfocard2">
                                        <div className="cardicon1">
                                            <img src={ttw15} alt="" />
                                        </div>
                                        <span>Schedule a Collection</span>
                                        <p>To arrange a collection, please provide your preferred date and time for us to pick up your items.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="secinfocard2">
                                        <div className="cardicon1">
                                            <img src={ttw16} alt="" />
                                        </div>
                                        <span>Driver’s Arrival</span>
                                        <p>The driver arrives punctually at the specified destination where you are situated. Their prompt arrival ensures efficient transportation.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="secinfocard2">
                                        <div className="cardicon1">
                                            <img src={ttw17} alt="" />
                                        </div>
                                        <span>Gentle Shoe Restoration</span>
                                        <p>Revive your shoes with our gentle restoration services. Bring back their original charm and quality.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="secinfocard2">
                                        <div className="cardicon1">
                                            <img src={ttw18} alt="" />
                                        </div>
                                        <span>Shoes Renewed to Perfection</span>
                                        <p>Restore your shoes with expert care. From scuffs to worn-out soles, we revive their original charm.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionEight;
